import React, { useState, useEffect, useRef } from "react";
import {
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  View,
  TextInput,
  FlatList,
  Keyboard,
} from "react-native";
import { gS, Page, H3, Alert } from "bl-components";

const SendButton = ({ onPress }) => (
  <TouchableOpacity
    {...{ onPress }}
    style={{
      width: 28,
      height: 28,
      borderRadius: 1000,
      backgroundColor: gS.darkBlue, //"#1982FC",
      padding: 10,
    }}
  >
    <View
      style={{
        position: "absolute",
        top: 11,
        left: 9,
        width: 10,
        height: 10,
        borderLeftWidth: 4,
        borderTopWidth: 4,
        borderColor: "white",
        transform: [{ rotate: "45deg" }],
      }}
    />
  </TouchableOpacity>
);

const isEmoji = (msg) =>
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
    msg
  ) &&
  !/\w/i.test(msg) &&
  msg.length < 8;

const Msg = ({ i, width, prevSame }) => {
  const emoj = isEmoji(i.msg);
  return (
    <View
      style={{
        width,
        justifyContent: "flex-end",
        flexDirection: i.yours ? "row" : "row-reverse",
      }}
    >
      {typeof i.sending === "undefined" ? null : (
        <ActivityIndicator size="small" color={"blue"} />
      )}
      <View
        style={{
          backgroundColor: emoj ? null : i.yours ? "#3872BB" : "#D9D9D9",
          borderRadius: 20,
          padding: 10,
          paddingTop: 7,
          paddingBottom: 7,
          margin: 10,
          marginTop: 0,
          marginBottom: prevSame ? 5 : 15,
          justifyContent: "center",
          maxWidth: width * 0.7,
        }}
      >
        <H3
          style={{ flexShrink: 1, fontSize: emoj ? 50 : 18 }}
          {...(i.yours ? { white: true } : {})}
        >
          {i.msg}
        </H3>
      </View>
    </View>
  );
};

const Message = ({
  appState: {
    dim: { width, height, tabBarHeight },
  },
  messages,
  setMessages,
  sendMessage,
}) => {
  const [bottomHeight, setBottomHeight] = useState(tabBarHeight);
  const [newMessage, setNewMessage] = useState("");
  const [msgHeight, setMsgHeight] = useState(0);
  const inputRef = useRef();
  useEffect(() => {
    const e1 = Keyboard.addListener(
      //"keyboardDidShow",
      "keyboardWillShow",
      ({ endCoordinates: { height } }) => setBottomHeight(tabBarHeight + height)
    );
    const e2 = Keyboard.addListener(
      //"keyboardDidHide",
      "keyboardWillHide",
      ({ endCoordinates: { height } }) => setBottomHeight(tabBarHeight)
    );
    return () => {
      e1.remove();
      e2.remove();
    };
  });
  return (
    <View style={{ height: height + (Platform.OS !== "web")*tabBarHeight, width }}>
      <FlatList
        inverted
        data={messages}
        keyExtractor={(item) => JSON.stringify(item)}
        renderItem={({ item: i, index }) => (
          <Msg
            {...{ i, width }}
            prevSame={index > 0 && i.yours === messages[index - 1].yours}
          />
        )}
      />
      <View
        style={{
          height: bottomHeight + msgHeight,
          borderTopWidth: 1,
          borderColor: "#D9D9D9",
          flexDirection: "row",
          width,
        }}
      >
        <View
          style={{
            margin: 10,
            padding: 9,
            width: width - 20 - 40,
            height: 5 * (Platform.OS === "web") + 50 + msgHeight,
            backgroundColor: "#FFFFFF",
            borderWidth: 1,
            borderColor: "#DADADA",
            borderRadius: 15,
          }}
        >
          <TextInput
            ref={inputRef}
            multiline
            value={newMessage}
            onChangeText={(text) => setNewMessage(text)}
            onContentSizeChange={({
              nativeEvent: {
                contentSize: { height },
              },
            }) => setMsgHeight(height - 23 > 23 * 5 ? 23 * 5 : height - 23)}
            maxLength={1000}
            style={{
              ...gS.inputText,
              height: 30 + msgHeight,
              textAlign: "left",
            }}
            placeholder="Message"
          />
        </View>
        <View
          style={{
            width: 40,
            height: 45 + msgHeight,
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 15,
          }}
        >
          <View style={{ height: 40, width: 40, marginTop: 20 }}>
            <SendButton
              onPress={async () => {
                //inputRef.current.blur();
                if (newMessage === "") return;
                const tmp = [...messages];
                const tmp2 = [...messages];
                const tmp3 = [...messages];
                tmp2.unshift({ msg: newMessage, yours: true });
                tmp.unshift({ msg: newMessage, yours: true, sending: true });
                setNewMessage("");
                setMessages(tmp);
                try {
                  await sendMessage(newMessage);
                  setMessages(tmp2);
                } catch (e) {
                  setNewMessage(newMessage);
                  setMessages(tmp3);
                  Alert("Error", "Message failed to send");
                }
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const MessagePortal = ({
  appState,
  route: {
    params: { getMessages, sendMessage },
  },
}) => {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    if (messages === null) {
      (async () => {
        setMessages(await getMessages());
      })();
      return () => null;
    } else {
      const interval = setInterval(
        async () => setMessages(await getMessages()),
        1000
      );
      return () => clearInterval(interval);
    }
  }, [setMessages, messages, getMessages]);

  return messages === null ? (
    <Page>
      <View style={{ margin: 40 }}>
        <ActivityIndicator size="large" color={"blue"} />
      </View>
    </Page>
  ) : (
    <>
      <Message {...{ messages, appState, setMessages, sendMessage }} />
    </>
  );
};

export default MessagePortal;
