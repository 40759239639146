import React, { useState } from "react";
import {
  TouchableOpacity,
  View,
  ActivityIndicator,
  Vibration,
} from "react-native";
import gS from "../GlobalStyle";
import Body from "../Text/Body";

export const CircleCheck = ({ scale, color }) => (
  <View
    style={{
      width: 30,
      height: 30,
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <View
      style={{
        borderBottomWidth: 2,
        borderRightWidth: 2,
        height: 10,
        width: 5,
        transform: [{ rotateZ: "45deg" }],
        borderColor: color,
      }}
    />
  </View>
);

export const PlusCircleOutline = ({ color }) => (
  <View
    style={{
      width: 25,
      height: 30,
      justifyContent: "center",
    }}
  >
    <View style={{ width: 10, height: 10 }}>
      <View
        style={{
          top: 0,
          left: 4,
          height: 10,
          width: 2,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          bottom: 6,
          left: 0,
          height: 2,
          width: 10,
          backgroundColor: color,
        }}
      />
    </View>
  </View>
);

const Follow = (props) => {
  var { callback, initial } = props;
  const [state, setState] = useState({
    favToggle: typeof initial === "undefined" ? false : initial,
    activity: false,
  });
  const { favToggle, activity } = state;
  const networkCall = () => {
    setState({ favToggle: favToggle, activity: true });
    callback(
      favToggle,
      () => {
        if (!favToggle) {
          Vibration.vibrate();
        }
        setState({ favToggle: !favToggle, activity: false });
      }, // success
      () => setState({ favToggle: favToggle, activity: false }) // failure
    );
  };

  return (
    <TouchableOpacity
      key={JSON.stringify(state)}
      onPress={() => {
        if (!activity) {
          typeof callback === "undefined"
            ? setState({ favToggle: !favToggle, activity: false })
            : networkCall();
        }
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: !favToggle ? 5 : 6,
          width: 120,
          borderWidth: !favToggle ? 1 : 0,
          borderRadius: 5,
          borderColor: gS.darkGreen,
          marginLeft: 10,
        }}
      >
        {activity ? (
          <View
            style={{
              width: !favToggle ? 25 : 30,
              height: 30,
              justifyContent: "center",
            }}
          >
            <ActivityIndicator animating={true} size="small" color="gray" />
          </View>
        ) : !favToggle ? (
          <PlusCircleOutline color={gS.darkGreen} />
        ) : (
          <CircleCheck color={gS.darkGreen} />
        )}
        <Body bold style={{ color: gS.darkGreen }}>
          {favToggle ? "Following" : "Follow"}
        </Body>
      </View>
    </TouchableOpacity>
  );
};

export default Follow;
