// import library to help create a component
import React from 'react';

import {View, Image, FlatList, TouchableOpacity} from 'react-native';

import {Page, H2, Body} from 'bl-components';

import DataManager from '../../Support/DataManager';

const NoEvents = () => (
  <View style={{flex: 1, marginLeft: 20, marginRight: 20}}>
    <View style={{alignItems: 'center'}}>
      <Image
        style={{resizeMode: 'contain', height: 322, width: 296}}
        source={require('../../Support/NoEvent.png')}
      />
    </View>
    <H2 bold>No Market Events</H2>
    <Body>
      Our market has no events at this time. Any events will be posted here in
      the future.
    </Body>
    <View style={{height: 10}} />
    <H2 bold>Explore our App!</H2>
    <Body>
      Check out the vendors, products, and coupons that can be found at our
      market! Click the vendor tab and explore their page!
    </Body>
  </View>
);
const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const toString = (date) =>
  Months[date.getMonth()] + ' ' + date.getUTCDate() + ', ' + date.getFullYear();

const NewEvent = ({title, description, date, onPress}) => {
  const now = new Date();
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getUTCDate() + 1,
  );
  const eventDate = new Date(date);
  const eventDay = new Date(
    eventDate.getFullYear(),
    eventDate.getMonth(),
    eventDate.getUTCDate() + 1,
  );
  const isToday =
    Math.abs(eventDay.getTime() - today.getTime()) < 24 * 3600 * 1000;

  return (
    <>
      <View
        {...{onPress}}
        style={{height: 50, alignItems: 'center', justifyContent: 'center'}}
      >
        <H2 navy bold>
          {isToday ? 'Today' : toString(eventDate)}
        </H2>
      </View>
      <TouchableOpacity
        {...{onPress}}
        style={{
          height: 80,
          marginTop: 10,
          marginBottom: 10,
          flexDirection: 'row',
          backgroundColor: '#fff',
          borderColor: '#CCCCCC',
          borderTopWidth: 1,
          borderBottomWidth: 1,
        }}
      >
        <Image
          style={{resizeMode: 'contain', height: 50, width: 50, margin: 10}}
          source={require('../../Support/NoEvent.png')}
        />
        <View style={{marginTop: 5, marginBottom: 5, marginRight: 80}}>
          <H2 lines={1}>{title}</H2>
          <Body lines={2}>{description}</Body>
        </View>
      </TouchableOpacity>
    </>
  );
};

const Community = ({
  navigation,
  appState: {
    dim: {tabletWidth},
  },
}) => {
  const {
    Community: {Events},
  } = DataManager;
  const events = [...Events].sort(({date: d1}, {date: d2}) => d1 - d2);
  const ITEM_HEIGHT = 150;
  const now = new Date().getTime();
  return Events.length ? (
    <Page width={tabletWidth}>
      <View style={{flex: 1}}>
        <FlatList
          data={events}
          renderItem={({item, index}) => (
            <NewEvent
              {...item}
              onPress={() => {
                navigation.push('Event', {event: item});
              }}
            />
          )}
          keyExtractor={(i) => JSON.stringify(i)}
          getItemLayout={(data, index) => ({
            length: ITEM_HEIGHT,
            offset: ITEM_HEIGHT * index,
            index,
          })}
          initialScrollIndex={
            events
              .map(({date}, index) => ({index, val: Math.abs(date - now)}))
              .sort(({val: v1}, {val: v2}) => v1 - v2)[0].index
          }
        />
      </View>
    </Page>
  ) : (
    <NoEvents />
  );
};

export default Community;
