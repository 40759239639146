import React, { useState } from "react";
import { useDimensions } from "../Hooks/useDimensions";
import { View } from "react-native";

const Window = ({ children, style }) => {
  const [update, setUpdate] = useState(0);
  const dim = useDimensions();
  dim.windowWidth = dim.width;
  dim.windowHeight = dim.height;
  const appState = {
    rerenderWindow: () => setUpdate(update + 1),
    dim,
    events: {},
  };
  var newChildren = children;
  if (typeof children === "object") {
    if (Array.isArray(children)) {
      newChildren = children.map((i, ind) =>
        React.cloneElement(i, { appState, key: ind })
      );
    } else {
      newChildren = React.cloneElement(children, { appState });
    }
  }
  return (
    <View key={update} style={{ ...style, ...dim }}>
      {newChildren}
    </View>
  );
};
export default Window;
