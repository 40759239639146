import { useState, useEffect } from "react";
import { Dimensions } from "react-native";

export function useActiveWidth(maxWidth) {
  const maxWidth2 = typeof maxWidth === "undefined" ? 600 : maxWidth;
  const wStart = Dimensions.get("window").width;
  const startActiveWidth = wStart < maxWidth2 ? wStart : maxWidth2;
  const [width, setWidth] = useState(startActiveWidth);

  useEffect(() => {
    Dimensions.addEventListener("change", () => {
      const w = Dimensions.get("window").width;
      const activeWidth = w < maxWidth2 ? w : maxWidth2;
      setWidth(activeWidth);
    });
    return () => {
      Dimensions.removeEventListener("change", () =>
        console.log(Dimensions.get("window").width)
      );
    };
  });
  return width;
}
