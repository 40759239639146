// import library to help create a component
import React, { Component } from "react";

import InputField from "./InputField";

// email field styled accordingly
// Takes one prop: onEndEditing that allows you to update the state of parent component
class EmailField extends Component {
  // class level property

  constructor(props) {
    super(props);
    if (typeof props.verify !== "undefined") {
      this.state = {
        email: "Verify Email",
      };
      this.message = "Verify Email";
    } else {
      this.state = {
        email: "Email",
      };
      this.message = "Email";
    }
  }

  // Can only return one tag so wrap with a view
  render() {
    return (
      <InputField
        passRef={this.props.passRef}
        autoCompleteType={"email"}
        maxLength={320}
        defaultString={
          typeof this.props.verify !== "undefined" ? "Confirm Email" : "Email"
        }
        returnKeyType="done"
        onEndEditing={this.props.onEndEditing}
        onFocus={this.props.onFocus}
      />
    );
  }
}

export default EmailField;
