import React, { useState, useRef } from "react";
import { TouchableOpacity, Platform, View, TextInput } from "react-native";
import gS from "../GlobalStyle";
import { useDimensions } from "../Hooks/useDimensions";

const Search = ({ setSearch, width, predictiveContent, initialValue, continuous }) => {
  const defaultString = "Search";
  const [value, setValue] = useState(
    typeof initialValue === "undefined" || initialValue === ""
      ? "Search"
      : initialValue
  );
  const [focus, setFocus] = useState(false);
  const { height } = useDimensions();
  const input = useRef();
  return (
    <>
      <View style={{ alignItems: "center", marginBottom: 10, marginTop: 10 }}>
        <View
          style={{
            backgroundColor: "#fff",
            flexGrow: 1,
            height: 40,
            borderRadius: 50,
            borderColor: "#D4D4D4",
            borderWidth: 1,
            paddingLeft: 20,
            paddingRight: 20,
            width: width - 10,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <TextInput
              ref={input}
              style={{
                height: 40,
                textAlign: "left",
                flexGrow: 1,
                color: value === defaultString ? gS.darkGrey : gS.black,
              }}
              multiline={false}
              autoCapitalize={"none"}
              maxFontSizeMultiplier={1.0}
              maxLength={500}
              value={value}
              onFocus={(e) => {
                if (Platform.OS === "web") {
                  e.preventDefault();
                  e.nativeEvent.preventDefault();
                }
                if (value === defaultString) {
                  setValue("");
                }
                setFocus(true);
              }}
              onChangeText={(text) => {
                setValue(text);
                if ( typeof continuous !== 'undefined' )
                setSearch(text);
              }}
              onBlur={() => {
                if (value === "") {
                  setValue(defaultString);
                }
                setSearch(value);
                //onEndEditing(value);
                setFocus(false);
              }}
            />
          </View>
        </View>
      </View>
      {value !== "Search" &&
      value !== "" &&
      typeof predictiveContent === "function" &&
      focus ? (
        <View
          style={{
            top: 55,
            position: "absolute",
            height,
            width,
            backgroundColor: "#fff",
            zIndex: 1000,
          }}
        >
          {predictiveContent(value, (newValue) => {
            setValue(newValue);
            setSearch(newValue);
            input.current.blur();
          })}
          <TouchableOpacity
            onPress={() => input.current.blur()}
            style={{ flexGrow: 1 }}
          />
        </View>
      ) : null}
    </>
  );
};

export default Search;
