// import library to help create a component
import React, {useState, useEffect} from 'react';
import {Platform, Pressable, View, FlatList} from 'react-native';
import DataManager from '../../Support/DataManager';
import {drawerNav} from '../../Router';

import {
  Alert,
  TextBox,
  H1,
  gS,
  ImageLoading,
  ItemDetail,
  Body,
} from 'bl-components';

const RenderItem = ({width, item: {vendor, product}, navigation}) => {
  const [update, setUpdate] = useState(0);
  const ind = DataManager.Items.findIndex(
    ({id, identifier, vendorID}) =>
      vendorID === product.vendorID &&
      id === product.id &&
      identifier === product.identifier,
  );
  useEffect(() => {
    if (
      DataManager.loggedin &&
      typeof DataManager.Items[ind].favHandlers === 'object'
    ) {
      const handInd =
        DataManager.Items[ind].favHandlers.push(() => setUpdate(update + 1)) -
        1;
      return () => {
        DataManager.Items[ind].favHandlers[handInd] = () => null;
      };
    }
  }, [ind, update, setUpdate]);

  const favCallback = (toggleState, FavType, success, failure) => {
    if (DataManager.loggedin) {
      DataManager.toggleFavorite(
        toggleState,
        product.vendorID,
        product.id,
        product.identifier,
        FavType,
        () => {
          DataManager.Items[ind].fav = !toggleState;
          DataManager.Items[ind].favHandlers.forEach((i) => i());
          if (!toggleState)
            Alert('Item Favorited', 'Item was added to your shopping list!');
          DataManager.myAccountUpdate();
          DataManager.feedUpdate();
          success();
        },
        () => {
          failure();
        },
      );
    } else {
      //Alert('You must sign in to favorite an item');
      drawerNav.navigate('Log In');
      failure();
    }
  };
  return (
    <View
      key={update}
      style={{
        alignItems: 'center',
      }}
    >
      <View
        style={{
          width,
        }}
      >
        <View
          style={{
            width,
            height: 50,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Pressable
            onPress={() => {
              DataManager.vendorClick(vendor.index)
              const {Products, Coupons} = DataManager.GetPC(vendor.index);
              navigation.push('Vendor', {
                Vendor: vendor,
                Products,
                Coupons,
              });
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width - 65,
            }}
          >
            <View style={{width: 20}} />
            <ImageLoading
              source={{uri: vendor.image}}
              style={{width: 40, height: 40, borderRadius: 1000}}
            />
            <View style={{width: width - 90, marginLeft: 10}}>
              <Body lines={1}>{vendor.name}</Body>
            </View>
          </Pressable>
        </View>
        <View
          style={{
            width,
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <ItemDetail
            width={300}
            Item={product}
            favInitial={DataManager.Items[ind].fav}
            favCallback={favCallback}
            onPress={() => {
              const Item = DataManager.Items[ind];
              DataManager.itemClick(vendor.index, Item.id, parseInt(Item.identifier, 10) )
              navigation.navigate('Item', {Item})
            }}
          />
        </View>
      </View>
    </View>
  );
};
const VendorItemsQuick = ({
  width,
  update,
  navigation,
  all,
  Products,
  Vendors,
  Footer,
}) => {
  const [refreshing, setRefreshing] = useState(false);

  const sortedProducts = Products.map((i) => {
    const vendor = Vendors.find(({index}) => index === i.vendorID);
    if (typeof vendor === 'undefined') return null;
    else
      return {
        product: i,
        vendor,
      };
  }).filter((i) => i !== null); //.sort(({product: p1}, {product: p2}) => p2.timestamp - p1.timestamp);

  return (
    <FlatList
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        DataManager.fetch(() => setRefreshing(false));
      }}
      extraData={update}
      data={sortedProducts}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      stickySectionHeadersEnabled={true}
      keyExtractor={({product: {favHandlers, ...p}, ...v}, index) =>
        JSON.stringify(p) + JSON.stringify(v) + index
      }
      ListFooterComponent={Footer}
      ListHeaderComponent={() => {
        const num = sortedProducts.length;
        return (
          <View
            style={{
              ...gS.pageStyle,
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                width: width - 5 * (Platform.OS === 'web'),
              }}
            >
              <TextBox>
                <H1 textAlign="left">
                  {num +
                    (typeof all === 'undefined' || all
                      ? ' total product'
                      : ' result') +
                    (num !== 1 ? 's' : '')}
                </H1>
              </TextBox>
            </View>
          </View>
        );
      }}
      renderItem={({item}) => <RenderItem {...{navigation, item, width}} />}
    />
  );
};

export default VendorItemsQuick;
