// import library to help create a component
import React from "react";
import { TouchableOpacity } from "react-native";
import gS from "../GlobalStyle";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import HoverShadowWrapper from "../misc/HoverShadowWrapper";

const Button = ({
  lightblue,
  body,
  grey,
  blue,
  red,
  gold,
  white,
  light,
  onPress,
  style,
  children,
}) => {
  var color = "#fff";
  var borderColor;
  //textColor = '#fff';
  if (typeof grey !== "undefined" && grey) {
    //color = gS.darkGrey;
    borderColor = gS.darkGrey;
  } else if (typeof red !== "undefined" && red) {
    //color = gS.white;
    borderColor = gS.red;
  } else if (typeof light !== "undefined" && light) {
    color = "#000";
    borderColor = "#F8F8F8";
  } else if (typeof white !== "undefined" && white) {
    color = "#000";
    borderColor = "#FFF";
  } else if (typeof lightblue !== "undefined" && lightblue) {
    color = "#367BF5";
    borderColor = "#E6EFFF";
  } else if (typeof blue !== "undefined" && blue) {
    //color = gS.lightBlue;
    borderColor = gS.darkBlue;
  } else if (typeof gold !== "undefined" && gold) {
    color = "#000";
    borderColor = gS.gold;
  } else {
    //color = gS.lightGreen;
    //borderColor = gS.darkGreen;
    borderColor = gS.darkBlue;
  }

  return (
    <HoverShadowWrapper>
      {(shadow) => (
        <TouchableOpacity
          style={[
            gS.buttonContainerStyle,
            { backgroundColor: borderColor, borderColor },
            style,
            shadow,
          ]}
          onPress={onPress}
        >
          {typeof children === "string" && typeof body === "undefined" ? (
            <H3 {...{ color }}>{children}</H3>
          ) : typeof children === "string" ? (
            <Body
              {...{ color }}
              style={{
                //fontFamily: "Mulish-Bold",
                fontSize: 14,
                //fontSpacing: 0.75,
              }}
            >
              {children}
            </Body>
          ) : (
            children
          )}
        </TouchableOpacity>
      )}
    </HoverShadowWrapper>
  );
};

export default Button;
