import React from "react";
import { View, Image } from "react-native";

import { Body } from "bl-components";
const DrawerMktCard = ({ image, name }) => (
  <View
    style={{
      height: 90,
      borderWidth: 1,
      borderColor: "#EEE",
      flexDirection: "row",
      //margin: 15,
      marginTop: 15,
      marginBottom: 15,
      padding: 10,
      alignItems: "center",
      backgroundColor: "#fff",
    }}
  >
    <Image
      style={{
        width: 70,
        height: 70,
        //borderRadius: 400,
        backgroundColor: "#C4C4C4",
      }}
      source={{ uri: image }}
    />
    <View style={{ marginLeft: 10, flex: 1 }}>
      <Body bold lines={2}>
        {name}
      </Body>
    </View>
  </View>
);

export default DrawerMktCard;
