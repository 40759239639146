const DateToSql = (date) => {
  return (
    date.getUTCFullYear() +
    "-" +
    ("00" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getUTCDate()).slice(-2) +
    " " +
    ("00" + date.getUTCHours()).slice(-2) +
    ":" +
    ("00" + date.getUTCMinutes()).slice(-2) +
    ":" +
    ("00" + date.getUTCSeconds()).slice(-2)
  );
};

export default DateToSql;

// /**
//  * You first need to create a formatting function to pad numbers to two digits…
//  **/
// function twoDigits(d) {
//   if (d >= 0 && d < 10) {
//     return '0' + d.toString();
//   }
//   if (d > -10 && d < 0) {
//     return '-0' + (-1 * d).toString();
//   }
//   return d.toString();
// }
//
// /**
//  * …and then create the method to output the date string as desired.
//  * Some people hate using prototypes this way, but if you are going
//  * to apply this to more than one Date object, having it as a prototype
//  * makes sense.
// Date.prototype.toMysqlFormat = function () {
//   return (
//     this.getUTCFullYear() +
//     '-' +
//     twoDigits(1 + this.getUTCMonth()) +
//     '-' +
//     twoDigits(this.getUTCDate()) +
//     ' ' +
//     twoDigits(this.getUTCHours()) +
//     ':' +
//     twoDigits(this.getUTCMinutes()) +
//     ':' +
//     twoDigits(this.getUTCSeconds())
//   );
// };
//  **/
// function toMysqlFormat(d) {
//   return (
//     d.getFullYear() +
//     '-' +
//     twoDigits(1 + d.getMonth()) +
//     '-' +
//     twoDigits(d.getDate()) +
//     ' ' +
//     twoDigits(d.getHours()) +
//     ':' +
//     twoDigits(d.getMinutes()) +
//     ':' +
//     twoDigits(d.getSeconds())
//   );
// };
//
