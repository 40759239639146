/**
 * @format
 */

import {AppRegistry} from 'react-native';
import App from './WebApp';
import {name as appName} from './app.json';

// window.addEventListener(
//   'beforeunload',
//   (e) => {
//     e.preventDefault();
//     return (e.returnValue =
//       'Are you sure you want to leave the BuyLo Web App? Your current page configuration will be lost.');
//   },
//   {capture: true},
// );

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
