// import libraries for making the header component
import React from "react";
import { View } from "react-native";
import gS from "../GlobalStyle";

// childern has anything passed between Card tags
const Page = ({ children, width }) => (
  <View
    style={[
      gS.pageStyle,
      {
        alignItems: "center",
        flex: 1,
      },
    ]}
  >
    <View style={{ ...gS.pageStyle, overflow: "hidden", width, flex: 1 }}>
      {children}
    </View>
  </View>
);

export default Page;
