import React, {useState, useEffect, useMemo} from 'react';
import {TouchableOpacity, View, Image} from 'react-native';
import {H2, H3, Body, Page, PageScrollMB} from 'bl-components';

import DataManager from '../../Support/DataManager';
import VendorCouponQuick from './VendorCouponQuick';
import {Search} from 'bl-components';

const couponSearchFilter = ({title}, searchReg) => {
  return title.search(searchReg) > -1;
};
const couponSort = ({title: t1}, {title: t2}, searchReg) => {
  const s1 = t1.search(searchReg) > -1;
  const s2 = t2.search(searchReg) > -1;
  return s2 - s1;
};

const Offers = ({
  navigation,
  appState: {
    dim: {tabletWidth},
  },
}) => {
  const [update, setUpdate] = useState(0);
  useEffect(() => {
    DataManager.offersUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.offersUpdate = () => null;
    };
  }, [update, setUpdate]);
  // <Page key={update}>
  const [search, setSearch] = useState('');
  const searchReg = new RegExp(
    search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
    'i',
  );
  const {Items, Vendors} = DataManager;
  const RawCoupons = useMemo(
    () =>
      Items.filter(({index}) => index.split('_')[0] === '3').sort(
        ({timestamp: t1}, {timestamp: t2}) => t2 - t1,
      ),
    [Items],
  );
  const VendorCoupons = RawCoupons.filter((i) =>
    search === '' ? true : couponSearchFilter(i, searchReg),
  )
    .sort((i1, i2) => couponSort(i1, i2, searchReg))
    .map((i) => {
      const vendor = Vendors.find(
        ({index}) => index.toString() === i.vendorID.toString(),
      );
      return {coupon: i, vendor};
    });
  if (RawCoupons.length)
    return (
      <Page width={tabletWidth}>
        <Search
          width={tabletWidth}
          {...{setSearch}}
          predictiveContent={(search, setNewSearch) => {
            const searchReg = new RegExp(
              search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
              'i',
            );
            const Predict = RawCoupons.filter((i) =>
              search === '' ? true : couponSearchFilter(i, searchReg),
            )
              .filter((_, ind) => ind < 100)
              .map(({title}) => title)
              .filter((i, ind, self) => self.indexOf(i) === ind)
              .filter((_, ind) => ind < 20)
              .filter((i) => i.toLowerCase().search(searchReg) > -1)
              .sort((i1, i2) => {
                const s1 = i1.toLowerCase().search(searchReg);
                const s2 = i2.toLowerCase().search(searchReg);
                return s1 - s2;
              });
            return (
              <View style={{margin: 20}}>
                {Predict.map((i, ind) => (
                  <TouchableOpacity
                    key={i + ind}
                    style={{marginBottom: 10}}
                    onPress={() => setNewSearch(i)}
                  >
                    <H3>{i}</H3>
                  </TouchableOpacity>
                ))}
              </View>
            );
          }}
        />
        <VendorCouponQuick
          width={tabletWidth}
          all={search === ''}
          key={search}
          Footer={() => <View style={{height: 200}} />}
          {...{VendorCoupons, navigation}}
        />
      </Page>
    );
  else
    return (
      <PageScrollMB width={tabletWidth} key={update}>
        <View style={{flex: 1, marginLeft: 20, marginRight: 20}}>
          <View style={{alignItems: 'center'}}>
            <Image
              style={{resizeMode: 'contain', height: 322, width: 296}}
              source={require('../../Support/NoOffers.png')}
            />
          </View>
          <H2 bold>Waiting for market to post new offers</H2>
          <Body>
            Explore other markets for great deals and offers! Change the filter
            to view other markets and their offers.
          </Body>
          <View style={{height: 10}} />
          <H2 bold>Explore Vendor Coupons!</H2>
          <Body>
            Remember, our amazing vendors offer coupons on their page! Be sure
            to view a vendors profile to find their coupons
          </Body>
        </View>
      </PageScrollMB>
    );
};

export default Offers;
