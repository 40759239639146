import React from "react";
import { Linking } from "react-native";
import WordButton from "./WordButton";
import { Alert } from "bl-components";

const OpenURLButton = ({ url, children, ...props }) => {
  if (typeof url === "undefined") {
    url = "";
  }
  const trueUrl =
    url.includes("http://") || url.includes("https://") ? url : "http://" + url;
  const handlePress = async () => {
    const trueUrlSupported = await Linking.canOpenURL(trueUrl).catch(() =>
      Alert("Error", `Unable to open URL: ${trueUrl}`)
    );
    if (trueUrlSupported) {
      await Linking.openURL(trueUrl).catch(() =>
        Alert("Error", `Unable to open URL: ${trueUrl}`)
      );
    } else {
      Alert("Error", "Unable to open social media link");
    }
  };
  return (
    <WordButton
      blue
      body
      bold
      {...props}
      onPress={trueUrl === "" ? () => null : handlePress}
    >
      {children}
    </WordButton>
  );
};
export default OpenURLButton;
