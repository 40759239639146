// import library to help create a component
import React, {useState, useEffect, useMemo} from 'react';
import DataManager from '../../Support/DataManager';
import {TouchableOpacity, View} from 'react-native';
import {
  H2,
  H3,
  Body,
  Grid,
  GridItem,
  PageDropDown,
  Search,
  VendorList,
} from 'bl-components';

const vendorSearchFilter = ({tags, hiddenTags, name}, searchReg) => {
  const s =
    hiddenTags
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      tags
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      name.toLowerCase().search(searchReg) >
    -1;
  return s;
};
const vendorSort = (
  {hiddenTags: ht1, tags: t1, name: n1},
  {hiddenTags: ht2, tags: t2, name: n2},
  searchReg,
) => {
  const s1 =
    ht1
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t1
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      n1.toLowerCase().search(searchReg) >
    -1;
  const s2 =
    ht2
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t2
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      n2.toLowerCase().search(searchReg) >
    -1;
  return s2 - s1;
};

const VendorsPage = ({
  appState: {
      dim: {tabletWidth: width, width: fullWidth, height},
  },
  navigation,
}) => {
  const [update, setUpdate] = useState(0);
  const [search, setSearch] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    DataManager.vendorsUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.vendorsUpdate = () => null;
    };
  }, [update, setUpdate]);

  const [section, setSection] = useState('All Sections');

  const {Vendors} = DataManager;
  const sections = useMemo(
    () =>
      Vendors.map(({spaces}) => spaces)
        .filter((i) => i.length)
        .flat()
        .map(({section}) => section)
        .filter((i, ind, self) => self.indexOf(i) === ind)
        .sort(),
    [Vendors],
  );

  if (Vendors.length > 0) {
    const searchReg = new RegExp(
      search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
      'i',
    );
    const sectionData = (
      section === 'All Sections'
        ? [...Vendors]
        : Vendors.filter(({spaces}) =>
            spaces.reduce((pV, cV) => pV + (cV.section === section), 0),
          )
    )
      .map(({tags, hiddenTags, ...otherItems}) => ({
        ...otherItems,
        tags: typeof tags === 'object' ? tags : [],
        hiddenTags: typeof hiddenTags === 'object' ? hiddenTags : [],
      }))
      .sort(({name: n1}, {name: n2}) => (n1 > n2 ? 1 : n1 < n2 ? -1 : 0))
      .sort( (pv,cv) => (cv.spaces.length!==0)-(pv.spaces.length!==0) );
    const data = sectionData.filter((i) =>
      search === '' ? true : vendorSearchFilter(i, searchReg),
    );
    if (search !== '')
      data
        .sort(({name: n1}, {name: n2}) => {
          const s1 = n1.substring(0, search.length).toLowerCase() === search;
          const s2 = n2.substring(0, search.length).toLowerCase() === search;
          return s2 - s1;
        })
        .sort((i1, i2) => vendorSort(i1, i2, searchReg));
    return (
      <React.Fragment key={update}>
        <Search
          {...{setSearch, width}}
          predictiveContent={(search, setNewSearch) => {
            const searchReg = new RegExp(
              search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
              'i',
            );
            var Predict = sectionData
              .filter((i) =>
                search === '' ? true : vendorSearchFilter(i, searchReg),
              )
              .map(({tags, hiddenTags, name}) => [...tags, ...hiddenTags, name])
              .flat()
              .filter((i, ind, self) => self.indexOf(i) === ind)
              .sort((n1, n2) => {
                const s1 =
                  n1.substring(0, search.length).toLowerCase() === search;
                const s2 =
                  n2.substring(0, search.length).toLowerCase() === search;
                return s2 - s1;
              })
              .filter((_, ind) => ind < 20)
              .filter((i) => i.toLowerCase().search(searchReg) > -1)
              .sort((i1, i2) => {
                const s1 = i1.toLowerCase().search(searchReg);
                const s2 = i2.toLowerCase().search(searchReg);
                return s1 - s2;
              });
            return (
              <View style={{margin: 20}}>
                {Predict.map((i, ind) => (
                  <TouchableOpacity
                    key={i + ind}
                    style={{marginBottom: 10}}
                    onPress={() => setNewSearch(i)}
                  >
                    <H3>{i}</H3>
                  </TouchableOpacity>
                ))}
              </View>
            );
          }}
        />
        <View style={{zIndex: 500, alignItems: 'center', marginTop: 10}}>
          <View style={{width: width - 10}}>
            <Grid style={{zIndex: 500}} {...{width}}>
              <GridItem width={160}>
                <View
                  style={{
                    margin: 10,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Body color="#999">Filter</Body>
                </View>
              </GridItem>
              <GridItem width={160}>
                <PageDropDown
                  value={section}
                  setValue={(value) => setSection(value)}
                  values={['All Sections', ...sections]}
                />
              </GridItem>
            </Grid>
          </View>
        </View>
        <VendorList
          refreshing={refreshing}
          onRefresh={() => {
            setRefreshing(true);
            DataManager.fetch(() => setRefreshing(false));
          }}
          Vendors={data}
          all={search === ''}
          onPress={(Vendor) => {
            DataManager.vendorClick(Vendor.index)
            const {Products, Coupons} = DataManager.GetPC(Vendor.index);
            navigation.push('Vendor', {
              Vendor: Vendor,
              Products,
              Coupons,
            });
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
        <View style={{width: fullWidth, height, alignItems: 'center', justifyContent: 'center'}}>
          <H2 navy>No Vendors Available</H2>
        </View>
    );
  }
};

export default VendorsPage;
