import React, { useState } from "react";
import AlertComp from "./Depend/Alert";
import ConfirmComp from "./Depend/Confirm";
import GeneralPopupComp from "./Depend/GeneralPopup";
import PopupXComp from "./Depend/PopupX";
import LoadingScreenComp from "./Depend/LoadingScreen";
//import GlobalPopup from './Depend/GlobalPopup';

const popups = [];
export var addPopup = () => null;

const Popups = () => {
  const [update, setUpdate] = useState(0);
  addPopup = (popup) => {
    popups.push(popup);
    setUpdate(update + 1);
  };
  return popups.map((i, index) =>
    i({
      key: index,
      index,
      deleteMe: () => {
        popups.splice(index, 1);
        setUpdate(update + 1);
      },
    })
  );
};

export default Popups;

var DeleteLoadingScreen = ()  => null
export const CloseLoadingScreen = (callback=()=>null)  => setTimeout(() => {DeleteLoadingScreen();callback();}, 1000)

export function LoadingScreen() {
  addPopup(({deleteMe}) => {
      DeleteLoadingScreen = deleteMe
      return <LoadingScreenComp />
  });
}

export function Alert(title = "Alert", body = "", callback = () => null) {
  //addPopup((props) => <Alert {...props} {...{title, body, callback}} />)
  addPopup((props) => <AlertComp {...props} {...{ title, body, callback }} />);
}
export function AlertButtons(title = "Alert", body = "", buttons = []) {
  addPopup((props) => <AlertComp {...props} {...{ title, body, buttons }} />);
}
export function Confirm(
  callback = () => null,
  title = "Are you sure?",
  body = "Please confirm this action."
) {
  addPopup((props) => (
    <ConfirmComp {...props} {...{ callback, title, body }} />
  ));
}
export function Popup(content = null, buttons = null, initialState = null) {
  if (content === null && buttons === null && initialState === null) {
    addPopup((props) => <GeneralPopupComp {...props} />);
  } else if (content !== null && buttons === null && initialState === null) {
    addPopup((props) => <GeneralPopupComp {...props} {...{ content }} />);
  } else if (content !== null && buttons !== null && initialState === null) {
    addPopup((props) => (
      <GeneralPopupComp {...props} {...{ content, buttons }} />
    ));
  } else {
    addPopup((props) => (
      <GeneralPopupComp {...props} {...{ content, buttons, initialState }} />
    ));
  }
}

export function UpdatePopup(content = null, buttons = null) {
  const current = popups.splice(popups.length - 1, 1);
  if (content === null && buttons === null) {
    addPopup((props) => <GeneralPopupComp {...props} />);
  } else if (content !== null && buttons === null) {
    addPopup((props) => <GeneralPopupComp {...props} {...{ content }} />);
  } else if (content === null && buttons !== null) {
    addPopup((props) =>
      React.createElement(current, { ...props, ...{ content, buttons } })
    );
  } else {
    addPopup((props) => (
      <GeneralPopupComp {...props} {...{ content, buttons }} />
    ));
  }
}

export function PopupX(content = () => null) {
  addPopup((props) => <PopupXComp {...props} {...{ content }} />);
}
