// import library to help create a component
import React from "react";
import { TouchableOpacity } from "react-native";
import Chevron from "../Icons/Chevron";

const BackButton = ({ onPress, color, children }) => {
  const style = {
    // padding is part of the touchable margin is not
    view: { padding: 20, flexDirection: "row", alignItems: "center" },
  };
  return (
    <TouchableOpacity onPress={onPress} style={style.view}>
      <Chevron {...{ color }} />
      {children}
    </TouchableOpacity>
  );
};

export default BackButton;
