// import library to help create a component
import React, {useState} from 'react';
//import ShareLink from '../../Components/ShareLink';
import DataManager from '../../Support/DataManager';
import {Page, Alert, Vendor as VendorComp, DateToSql} from 'bl-components';
import {drawerNav} from '../../Router';
import {Platform, Share} from 'react-native';

const VendorPage = ({
  appState: {
    dim: {tabletWidth: width},
    events: {injectURL},
  },
  navigation,
  route: {
    params: {Vendor, Products, Coupons},
  },
}) => {
  const [fav, setFav] = useState(
    DataManager.vendFavorites.filter(
      ({vendorID}) => vendorID === Vendor.index,
    ),
  );
  // need this because of the vendor app... not a good solution but better than a conditional hook
  const [selected, setSelected] = useState(0);

  const getMessages = async () => {
    return await DataManager.getMessages("v\t"+Vendor.index);
  };
  const sendMessage = async (msg) => {
    await DataManager.sendMessage(msg, "v\t"+Vendor.index);
    return await DataManager.getMessages("v\t"+Vendor.index);
  };

  const favCallback = (toggleState, success, failure) => {
    if (DataManager.loggedin) {
      DataManager.toggleFavorite(
        toggleState,
        Vendor.index,
        -1,
        -1,
        0,
        () => {
          if (toggleState) {
            DataManager.vendFavorites.splice(
              DataManager.vendFavorites.indexOf(fav[0]),
              1,
            );
            setFav([]);
          } else {
            var d = new Date();
            const fav2 = {
              time: DateToSql(d),
              vendorID: Vendor.index,
            };
            DataManager.vendFavorites.push(fav2);
            Alert('Following Vendor', 'Vendor was added to "My Vendors!"');
            setFav([fav2]);
          }
          DataManager.myAccountUpdate();
          success();
        },
        () => {
          failure();
        },
      );
    } else {
      drawerNav.navigate('Log In', {}, true);
      failure();
    }
  };
  // Messaging enabled
  const message = typeof Vendor.message !== 'undefined' && Vendor.message;
  // need to find a better coupon redeem code than the user index
  return (
    <Page {...{width}}>
      <VendorComp
        {...{width}}
        selected={selected}
        setSelected={setSelected}
        favInitial={fav.length}
        favCallback={favCallback}
        {...{Products, Coupons}}
        Vendor={Vendor}
        shareCallback={(info) => {
          const data = new URLSearchParams({
            ...info,
            marketID: DataManager.marketID,
          });
          if (Platform.OS === 'ios')
            Share.share({
              message: 'Check out this vendor on BuyLo!',
              url: 'https://www.buylo.app/?' + data.toString(),
            });
          else if (Platform.OS === 'android')
            Share.share({
              title: 'Check out this vendor on BuyLo! ',
              message: 'https://www.buylo.app/?' + data.toString(),
            });
          else Alert('Vendor URL', 'https://www.buylo.app/?' + data.toString());
        }}
        onRedeem={() => {
          if (!DataManager.loggedin) {
            drawerNav.navigate('Log In', {}, true);
            return false;
          }
          return true;
        }}
        userIndex={0}
        ItemNavigate={(Item) => {
            DataManager.itemClick(Vendor.index, Item.id, parseInt(Item.identifier, 10) )
            navigation.navigate('Item', {Item})
        }}
        MessageNavigate={
          message
            ? () => {
                if (!DataManager.loggedin)
                  drawerNav.navigate('Log In', {}, true);
                else
                  drawerNav.navigate(
                    'Message',
                    {getMessages, sendMessage},
                    true,
                  );
              }
            : undefined
        }
        Map={DataManager.Map}
        onInfoRoute={(route, close) => {
          close();
          if (route !== `vendorIndex=${Vendor.index}`) {
            navigation.pop();
            injectURL('?' + route);
          }
        }}
      />
    </Page>
  );
};

export default VendorPage;
