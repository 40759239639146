// import library to help create a component
import React from 'react';

import {View, Image, TouchableOpacity} from 'react-native';

import {Map, PopupX, PageScrollMB, H2, Body, Container} from 'bl-components';

import DataManager from '../../Support/DataManager';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const toString = (date) =>
  Months[date.getMonth()] + ' ' + date.getUTCDate() + ', ' + date.getFullYear();

const Event = ({
  navigation,
  route: {
    params: {event},
  },
  appState: {
    events,
    dim: {width},
  },
}) => {
  const {title, description, date, section, subsection} = event;
  const eventDate = new Date(date);

  const imgdim = width > 600 ? 600 : width;
  return (
    <PageScrollMB>
      <Image
        style={{
          resizeMode: 'contain',
          height: (imgdim * 3) / 4,
          width: imgdim,
          margin: 10,
        }}
        source={require('../../Support/NoEvent.png')}
      />
      <Container>
        <View style={{alignItems: 'center', justifyContent: 'center'}}>
          <H2 navy bold>
            {toString(eventDate)}
          </H2>
          <View style={{marginTop: 10}}>
            <H2>{title}</H2>
          </View>
        </View>
        {description === '' ? null : (
          <View style={{marginTop: 10}}>
            <Body>{description}</Body>
          </View>
        )}
      </Container>
      <TouchableOpacity
        onPress={() => {
          PopupX((close) => (
            <Map
              width={width}
              initialSection={section}
              initialSubsection={subsection}
              data={DataManager.Map}
              onInfoRoute={(route) => {
                close();
                events.injectURL('?' + route);
              }}
            />
          ));
        }}
      >
        <Container>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <H2 navy bold>
              Location
            </H2>
            <H2 lines={1}>{section + ' ' + subsection}</H2>
          </View>
        </Container>
      </TouchableOpacity>
    </PageScrollMB>
  );
};

export default Event;
