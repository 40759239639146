// import library to help create a component
import React, { Component } from "react";
import {
  Platform,
  ScrollView,
  View,
  Animated,
  Easing,
  Pressable,
} from "react-native";
import gS from "../GlobalStyle";
import H3 from "../Text/H3";

class HorizBar extends Component {
  state = {
    selectedIndex:
      typeof this.props.initial === "undefined" ? 0 : this.props.initial,
    underlineWidths: [...Array(this.props.barStrings.length).fill(0)],
    underlineX: [...Array(this.props.barStrings.length).fill(0)],
  };
  render() {
    const { selectedIndex } = this.state;
    const { underlineWidth } = this.props;
    const { underlineWidths, underlineX } = this.state;
    const translateX = new Animated.Value(0);
    const scaleX = new Animated.Value(1);
    const changePosition = (translate, scale, start) => {
      Animated.parallel([
        Animated.timing(translateX, {
          toValue: translate,
          duration: 100,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
        Animated.timing(scaleX, {
          toValue: scale,
          duration: 100,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
        //]).start(() => setState({translate, scale}));
      ]).start(start);
    };
    const bar = (pad) =>
      this.props.barStrings.map((item, index) => {
        const selected = index === selectedIndex;

        var underlineStyle = gS.underlineStyle;
        if (typeof underlineWidth !== "undefined") {
          underlineStyle = { ...underlineStyle, width: underlineWidth };
        }
        return (
          <Pressable
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              if (underlineWidths[index] === 0) {
                //console.log('height:', layout.height);
                //console.log('width:', layout.width);
                //console.log('x:', layout.x);
                //console.log('y:', layout.y);
                underlineWidths[index] = layout.width - pad;
                underlineX[index] = layout.x;
                this.setState({ underlineWidths, underlineX });
              }
            }}
            key={index}
            onPress={() => {
              changePosition(
                underlineX[index] -
                  underlineX[selectedIndex] +
                  (underlineWidths[index] - underlineWidths[selectedIndex]) / 2,
                underlineWidths[index] / underlineWidths[selectedIndex],
                //1,
                () => {
                  // Execute parent state change as a call back!! or else it lags
                  this.setState({ selectedIndex: index }, () => {
                    if (typeof this.props.onChange === "function") {
                      this.props.onChange(this.state.selectedIndex);
                    }
                  });
                }
              );
            }}
          >
            <View
              style={{
                justifyContent: "center",
                paddingRight: pad,
                alignItems: "center",
                flex: 1,
              }}
            >
              <H3
                style={{
                  color: selected ? gS.MainColor : gS.black,
                  fontSize: 16,
                }}
              >
                {item}
              </H3>
              <Animated.View
                style={
                  selected
                    ? [
                        underlineStyle,
                        {
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          transform: [
                            { translateX },
                            { scaleX },
                            { scaleX: 1.5 },
                          ],
                          width: underlineWidths[index],
                        },
                      ]
                    : { height: 0 }
                }
              />
            </View>
          </Pressable>
        );
      });
    const scroll = () => {
      if (typeof this.props.scroll !== "undefined") {
        return (
          <View
            key={this.selectedIndex}
            style={[
              gS.horizScrollView,
              {
                flexDirection: "row",
                justifyContent: "flex-start",
              },
            ]}
          >
            <ScrollView
              horizontal={true}
              style={{
                width: "100%",
                paddingRight: 20,
                paddingLeft: 20,
              }}
              showsHorizontalScrollIndicator={false}
            >
              {bar(30)}
            </ScrollView>
          </View>
        );
      } else {
        return (
          <View
            key={this.selectedIndex}
            style={[
              gS.horizScrollView,
              {
                flexDirection: "row",
                justifyContent: "space-around",
                paddingLeft: 20,
                borderColor: "#D7D7D7",
                //borderLeftWidth: 1,
                //borderRightWidth: 1,
                borderBottomWidth: 1,
                zIndex: 1000000000,
              },
            ]}
          >
            {bar(25)}
          </View>
        );
      }
    };
    return scroll();
  }
}

export default HorizBar;
