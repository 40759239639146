// import library to help create a component
import React from "react";
import { ActivityIndicator, View } from "react-native";
import gS from "../GlobalStyle";
import Button from "./Button";

const ActivityButton = ({ isVisible, children, ...props }) => {
  if (isVisible) {
    return <Button {...props}>{children}</Button>;
  } else {
    return (
      <View style={{ margin: 10 }}>
        <ActivityIndicator size="large" color={gS.sepC} />
      </View>
    );
  }
};

export default ActivityButton;
