import React from "react";
import { Alert, Linking } from "react-native";
import BlueClickable from "./BlueClickable";

const PhoneLink = ({ number, children }) => {
  const handlePress = async () => {
    const appDeepLink = `tel://${number}`;
    const appDeepLinkSupported = await Linking.canOpenURL(appDeepLink).catch(
      () => null
    ); //Alert.alert(`Unable to open URL:`, `${appDeepLink}`));
    if (appDeepLinkSupported) {
      await Linking.openURL(appDeepLink).catch(() =>
        Alert.alert("Unable to call number :", `${number}`)
      );
    } else {
      alert("Unable to open phone app");
    }
  };
  return (
    <BlueClickable onPress={number === "" ? () => null : handlePress}>
      {children}
    </BlueClickable>
  );
};
export default PhoneLink;
