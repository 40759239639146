import React from "react";
import { View } from "react-native";

import { gS, H2, H3, Body, VGrid, GridItem } from "bl-components";
//const color = '#C0302A'; // red

const Item = ({ width, name, price, description }) => (
  <View style={{ paddingBottom: 10 }}>
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <View style={{width: width-80}}>
        <H3 lines={1}>{name}</H3>
      </View>
      <View style={{alignItems: 'flex-end', width: 60}}>
        <H3 lines={1}>${parseFloat(price).toFixed(2)}</H3>
      </View>
    </View>
    <View style={{ paddingLeft: 5 }}>
      <Body lines={1}>{description}</Body>
    </View>
  </View>
);

const Category = ({ width, name, category }) => (
  <View style={{ width, padding: 10 }}>
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <H2 color={gS.darkBlue}>{name}</H2>
      <H3>Price</H3>
    </View>
    <View style={{ height: 10 }} />
    {Object.keys(category.items).map((i) => (
      <Item key={i} width={width} {...category.items[i]} name={i} />
    ))}
  </View>
);

const Menu = ({ width, name, menu }) => (
  <View style={{ width, backgroundColor: "#fff" }}>
    <View
      style={{
        padding: 20,
        width,
        flexDirection: width < 800 ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: width < 800 ? 150 : 80,
        backgroundColor: gS.darkBlue,
        marginBottom: 20,
      }}
    >
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Body white>
          {menu.locations
            .map(({ section, subsection }) => section + "-" + subsection)
            .reduce((pv, cv) => pv + " " + cv, "") + " "}
        </Body>
      </View>
      <View style={{ flex: 1, justifyContent: "center" }}>
        <H2 white>{name}</H2>
      </View>
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Body white>
          Available {menu.hours.open + " - " + menu.hours.close}
        </Body>
      </View>
    </View>
    <VGrid {...{ width }}>
      {Object.keys(menu.categories).map((i) => (
        <GridItem key={i} width={300}>
          <Category name={i} width={300} category={menu.categories[i]} />
        </GridItem>
      ))}
    </VGrid>
  </View>
);

export default Menu;
