// import libraries for making the header component
import React from "react";
import BaseText from "./BaseText";
import gS from "../GlobalStyle.js";

const H1 = ({ style, children, ...props }) => {
  return (
    <BaseText {...props} style={[gS.H1, style]}>
      {children}
    </BaseText>
  );
};

export default H1;
