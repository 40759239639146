import React from "react";
import { Pressable, Linking } from "react-native";
import { SvgIcon, Alert } from "bl-components";
import Svg, { Stop, Circle, RadialGradient, Path } from "react-native-svg";

const InstagramIcon = ({ onPress }) => (
  <Svg viewBox="0 0 48 48" width="48px" height="48px">
    <RadialGradient
      id="yOrnnhliCrdS2gy~4tD8ma"
      cx="19.38"
      cy="42.035"
      r="44.899"
      gradientUnits="userSpaceOnUse"
    >
      <Stop offset="0" stopColor="#fd5" />
      <Stop offset=".328" stopColor="#ff543f" />
      <Stop offset=".348" stopColor="#fc5245" />
      <Stop offset=".504" stopColor="#e64771" />
      <Stop offset=".643" stopColor="#d53e91" />
      <Stop offset=".761" stopColor="#cc39a4" />
      <Stop offset=".841" stopColor="#c837ab" />
    </RadialGradient>
    <Path
      fill="url(#yOrnnhliCrdS2gy~4tD8ma)"
      d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20 c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"
    />
    <RadialGradient
      id="yOrnnhliCrdS2gy~4tD8mb"
      cx="11.786"
      cy="5.54"
      r="29.813"
      gradientTransform="matrix(1 0 0 .6663 0 1.849)"
      gradientUnits="userSpaceOnUse"
    >
      <Stop offset="0" stopColor="#4168c9" />
      <Stop offset=".999" stopColor="#4168c9" stopOpacity="0" />
    </RadialGradient>
    <Path
      fill="url(#yOrnnhliCrdS2gy~4tD8mb)"
      d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20   c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"
    />
    <Path
      fill="#fff"
      d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5    s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
    />
    <Circle cx="31.5" cy="16.5" r="1.5" fill="#fff" />
    <Path
      fill="#fff"
      d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12  C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
    />
  </Svg>
);
const TwitterIcon = ({ onPress }) => (
  <Svg viewBox="0 0 48 48" width="48px" height="48px">
    <Path
      fill="#03A9F4"
      d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
    />
  </Svg>
);
const FacebookIcon = ({ onPress }) => (
  <Svg viewBox="0 0 48 48" width="48px" height="48px">
    <Path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z" />
    <Path
      fill="#fff"
      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
    />
  </Svg>
);

const OpenURLButton = ({ username, type }) => {
  const handlePress = async () => {
    //type === 'facebook' ? deepLinkProtocol = 'fb' : deepLinkProtocol = type
    var deepLinkProtocol;
    type === "facebook" ? (deepLinkProtocol = "") : (deepLinkProtocol = type);

    var params;
    switch (type) {
      case "facebook":
        params = "";
        break;
      case "instagram":
        params = "user?username=";
        break;
      case "twitter":
        params = "user?screen_name=";
        break;
      case "linkedin":
        params = "in/";
        break;
      default:
        params = "";
        break;
    }
    const appDeepLink = `${deepLinkProtocol}://${params}${username}`;
    //console.log(deepLinkProtocol)
    //console.log(type)
    // Checking if the link is supported for links with custom URL scheme.
    //Alert.alert("Navigate to "+type+"?", "", [{text: "Yes", onPress: handlePress}, {text: "No"}]) }
    const appDeepLinkSupported = await Linking.canOpenURL(appDeepLink).catch(
      () => null
    ); //Alert.alert(`Unable to open URL:`, `${appDeepLink}`));
    if (appDeepLinkSupported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(appDeepLink).catch(() =>
        Alert("Error", "Unable to open URL:", `${appDeepLink}`)
      );
    } else {
      var urlDeepLink;
      type === "facebook" || type === "instagram"
        ? (urlDeepLink = `https://www.${type}.com/${username}`)
        : (urlDeepLink = `https://www.${type}.com/${params}${username}`);
      //urlDeepLink = `https://www.${type}.com/${params}${username}`

      const urlDeepLinkSupported = await Linking.canOpenURL(urlDeepLink).catch(
        () => null
      ); //Alert.alert(`Unable to open URL:`, `${appDeepLink}`));
      if (urlDeepLinkSupported) {
        await Linking.openURL(urlDeepLink).catch(() =>
          Alert("Error", "Unable to open URL:", `${urlDeepLink}`)
        );
      } else {
        Alert("Error", "Unable to open social media link");
      }
    }
  };
  var Icon = () => <SvgIcon color="gray" size={30} />;
  switch (type) {
    case "facebook":
      Icon = FacebookIcon;
      break;
    case "instagram":
      Icon = InstagramIcon;
      break;
    case "twitter":
      Icon = TwitterIcon;
      break;
    case "linkedin":
      break;
    default:
      break;
  }
  return (
    <Pressable onPress={username === "" ? () => null : handlePress}>
      <Icon />
    </Pressable>
  );
};

export default OpenURLButton;
