import { Platform } from "react-native";
import { Alert } from "../Popups/Popups";
import axios from "axios";

class ImageManager {
  constructor() {
    this.images = [];
  }
  // Gives failure alert corresponding to the type of error
  #failure = (response, failureMsg = null) => {
    const msgs = [];
    if (
      typeof response.data.loggedin !== "undefined" &&
      response.data.loggedin
    ) {
      msgs.push("Error fetching data");
      msgs.push("Please login again to try again");
    } else {
      msgs.push("You are not logged in");
      msgs.push("Please login again to access your account");
      //msgs.push(JSON.stringify(response));
    }
    if (failureMsg === null) {
      Alert(msgs[0], msgs[1], this.resetApp);
    } else if (failureMsg !== "") {
      Alert(failureMsg, "", this.resetApp);
    }
    console.log(response);
  };
  networkError = (res) => {
    //Alert('Network Error', 'Check your network connection and try again ' + JSON.stringify(res) + '!!');
    Alert("Network Error", "Check your network connection and try again");
    console.log(res);
    //console.log(response.data);
  };
  // takes a url, data object, success callback, always callback, success message callback, and failure
  pushData = (
    url,
    Data,
    success,
    always = () => null,
    msg = null,
    failure = () => null,
    failureMsg = null,
    cancelToken = null
  ) => {
    //console.log("caller is " + this.pushData.caller);
    axios
      .post(url, { ...this.cred, ...Data }, { cancelToken })
      .then((response) => {
        //console.log((new Error()).stack)
        if (response.data.success) {
          if (msg !== null) {
            Alert("Saved", msg, () => {
              success(response);
            });
          } else {
            success(response);
          }
        } else {
          failure(response);
          this.#failure(response, failureMsg);
          //console.log(response.data);
        }
      })
      .catch((res) => {
        if (
          typeof res.message !== "undefined" &&
          res.message === "Image was unmounted"
        ) {
          console.log(res.message);
        } else {
          this.networkError(res);
          console.log("failed push " + url);
        }
      })
      .finally(always);
  };

  getImage = (location, setImage, cancelToken) => {
    const localImages = this.images.filter((i) => i.location === location);
    if (localImages.length) {
      setImage(localImages[0].image);
    } else {
      this.pushData(
        Platform.OS === "web"
          ? "/Assets/v1.0/Image/get"
          : "https://www.buylo.app/Assets/v1.0/Image/get",
        { assetLocation: location },
        ({ data }) => {
          const { result: image } = data;
          this.images.push({ location, image });
          if (
            this.images.reduce(
              (a, v) => a + v.location.length + v.image.length,
              this.images[0].location.length + this.images[0].image.length
            ) /
              1000000 >
            100
          ) {
            this.images.shift();
          }
          setImage(image);
        },
        () => null,
        null,
        () => null,
        null,
        cancelToken
      );
    }
  };
}

const IM = new ImageManager();
export default IM;
