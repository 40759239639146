import React, { useState } from "react";
import {
  TouchableOpacity,
  View,
  ActivityIndicator,
  Vibration,
} from "react-native";
import H3 from "../Text/H3";
import SvgIcon from "../Icons/SvgIcon";

const Follow = (props) => {
  var { callback, initial, noword } = props;
  const [state, setState] = useState({
    favToggle: typeof initial === "undefined" ? false : initial,
    activity: false,
  });
  const { favToggle, activity } = state;
  const networkCall = () => {
    setState({ favToggle: favToggle, activity: true });
    callback(
      favToggle,
      () => {
        if (!favToggle) {
          Vibration.vibrate();
        }
        setState({ favToggle: !favToggle, activity: false });
      }, // success
      () => setState({ favToggle: favToggle, activity: false }) // failure
    );
  };

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: typeof noword === "undefined" ? 120 : 40,
      }}
      onPress={() => {
        if (!activity) {
          typeof callback === "undefined"
            ? setState({ favToggle: !favToggle, activity: false })
            : networkCall();
        }
      }}
    >
      <View
        style={{
          width: 25,
          height: 25,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {activity ? (
          <ActivityIndicator size="small" color="gray" />
        ) : !favToggle ? (
          <SvgIcon name="heart-outline" size={28} color="#DF6565" />
        ) : (
          <SvgIcon name="heart" size={28} color="#DF6565" />
        )}
      </View>
      {typeof noword === "undefined" ? (
        <H3>{favToggle ? " Favorited" : " Favorite"}</H3>
      ) : null}
    </TouchableOpacity>
  );
};
export default Follow;
