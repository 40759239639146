// import library to help create a component
import React, {useState} from 'react';

import {View, Image} from 'react-native';

import {PageScrollMB, H2, Body, Button, MyDate} from 'bl-components';

const NewsItem = ({date, title, image}) => {
  const [w, setW] = useState(null);
  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: {width},
        },
      }) => (width > 0 && w === null ? setW(width) : null)}
      style={{
        overflow: 'hidden',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#D7D7D7',
      }}
    >
      {image ? (
        <View style={{alignItems: 'center'}}>
          <Image
            style={{
              resizeMode: 'contain',
              height: w === null ? null : (w * 3) / 4,
              width: w,
            }}
            source={image}
          />
        </View>
      ) : null}
      <View
        style={{
          padding: 10,
        }}
      >
        <MyDate date={date} />
        <H2 bold>{title}</H2>
        <View style={{height: 10}} />
        <Button blue>View</Button>
      </View>
    </View>
  );
};

const NoNews = () => (
  <View style={{flex: 1, marginLeft: 20, marginRight: 20}}>
    <View style={{alignItems: 'center'}}>
      <Image
        style={{resizeMode: 'contain', height: 322, width: 296}}
        source={require('../../Support/NoEvent.png')}
      />
    </View>
    <H2 bold>No Market News</H2>
    <Body>
      Our market has no news at this time. Any news or events will be posted
      here in the future.
    </Body>
    <View style={{height: 10}} />
    <H2 bold>Explore our App!</H2>
    <Body>
      Check out the vendors, products, and coupons that can be found at our
      market! Click the vendor tab and explore their page!
    </Body>
  </View>
);

const Community = () => {
  return (
    <PageScrollMB>
      <NewsItem
        image={require('../../Support/NoEvent.png')}
        date={new Date()}
        title="test"
      />
      <NoNews />
    </PageScrollMB>
  );
};

export default Community;
