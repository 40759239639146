import React from "react";
import SvgIcon from "../Icons/SvgIcon";
import { TouchableOpacity } from "react-native";

const SvgButton = ({ name, color, size, style, onPress }) => (
  <TouchableOpacity {...{ style, onPress }}>
    <SvgIcon {...{ name, color, size }} />
  </TouchableOpacity>
);

export default SvgButton;
