import React from "react";
import { View } from "react-native";
import H3 from "../../Text/H3";
import Body from "../../Text/Body";
import FullScreenModal from "./FullScreenModal";

const Confirm = ({ title, body, callback, deleteMe }) => (
  <FullScreenModal
    popup={true}
    exitButtons={[
      {
        name: "CANCEL",
        callback: deleteMe,
        color: { lightblue: true },
      },
      {
        name: "CONFIRM",
        callback: () => {
          callback();
          deleteMe();
        },
        color: { blue: true },
      },
    ]}
    containerWidth={300}
    button="Messages"
  >
    <View style={{ paddingLeft: 25, paddingRight: 25, width: "100%" }}>
      <H3
        bold
        style={{ color: "rgba(0,0,0,0.75)", fontSize: 20 }}
        textAlign="left"
      >
        {typeof title === "undefined" ? "Are you sure?" : title}
      </H3>
      <View style={{ height: 15 }} />
      <Body style={{ color: "#5E6366", fontSize: 16 }} textAlign="left">
        {typeof body === "undefined" ? "Please confirm this action." : body}
      </Body>
    </View>
  </FullScreenModal>
);

export default Confirm;
