// import libraries for making the header component
import React from "react";
import { View, TouchableOpacity } from "react-native";
import Body from "../Text/Body";
import ImageLoading from "../Images/ImageLoading";
import { gS } from "bl-components";
import Favorite from "./Favorite";

const ItemDetailWide = ({
  favInitial,
  favCallback,
  width,
  Item,
  NotTouchable,
  type,
  draftIndex,
  renderSwitch,
  onPress,
}) => {
  const { price, title, images } = Item;

  return (
    <TouchableOpacity
      disabled={typeof NotTouchable !== "undefined"}
      onPress={() => onPress(Item)}
      style={{
        backgroundColor: "#FFF",
        borderColor: "#D7D7D7",
        borderWidth: 1,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 7,
        marginTop: 7,
        borderRadius: 5,
        ...gS.shadow,
      }}
    >
      <ImageLoading
        style={{
          width: typeof width === "undefined" ? 150 : width,
          height: typeof width === "undefined" ? 150 : width,
        }}
        source={{ uri: images[0] }}
      />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: 50,
          borderRadius: 2,
          paddingLeft: 20,
        }}
      >
        <View style={{ width: width - 65 }}>
          <Body lines={1}>
            {title === "" ? (price < 0.01 ? "" : "$" + price) : title}
          </Body>
        </View>
        {typeof favCallback === "undefined" ? null : (
          <Favorite
            noword
            initial={favInitial}
            callback={(toggleState, success, failure) =>
              favCallback(toggleState, 1, success, failure)
            }
            type="Item"
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const ItemDetailOrig = ({
  Item,
  NotTouchable,
  type,
  draftIndex,
  renderSwitch,
  onPress,
}) => {
  const { price, images } = Item;

  return (
    <TouchableOpacity
      disabled={typeof NotTouchable !== "undefined"}
      onPress={() => onPress(Item)}
      style={{
        borderColor: "#D7D7D7",
        borderWidth: 1,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 7,
        marginTop: 7,
        ...gS.shadow,
      }}
    >
      <ImageLoading
        style={{
          width: 150,
          height: 150,
        }}
        source={{ uri: images[0] }}
      />
      <View
        style={{
          position: "absolute",
          bottom: 0,
          right: -2,
          padding: 2,
          borderRadius: 2,
          opacity: 0.75,
        }}
      >
        <Body style={{ opacity: 1, color: "#fff" }}>
          {price < 0.01 ? "" : "$" + price}
        </Body>
      </View>
    </TouchableOpacity>
  );
};

const ItemDetail = (props) =>
  typeof props.width === "undefined" ? (
    <ItemDetailOrig {...props} />
  ) : (
    <ItemDetailWide {...props} />
  );

export default ItemDetail;
