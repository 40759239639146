// import libraries for making the header component
import React from "react";
import { View } from "react-native";
import gS from "../GlobalStyle.js";

const TextBox = (props) => {
  var {
    children,
    passRef,
    sepTop,
    sepBottom,
    sepLeft,
    sepRight,
    backgroundColor,
    flexDirectionRow,
    style,
    ...componentProps
  } = props;
  // Come up with better way to handle network error
  //if (typeof wrap === 'undefined') { bold = null } else { bold = {flexWrap: 'wrap'} }
  if (typeof sepTop !== "undefined") {
    sepTop = { borderTopWidth: 2 };
  }
  if (typeof sepBottom !== "undefined") {
    sepBottom = { borderBottomWidth: 2 };
  }
  if (typeof sepLeft !== "undefined") {
    sepLeft = { borderLeftWidth: 2 };
  }
  if (typeof sepRight !== "undefined") {
    sepRight = { borderRightWidth: 2 };
  }
  if (typeof flexDirectionRow !== "undefined") {
    flexDirectionRow = { flexDirection: "row" };
  }
  if (typeof backgroundColor === "string") {
    backgroundColor = { backgroundColor: backgroundColor };
  }
  if (typeof passRef === "undefined") {
    passRef = () => null;
  }

  return (
    <View
      {...componentProps}
      ref={(view) => passRef(view)}
      style={[
        gS.textbox,
        sepTop,
        sepBottom,
        sepLeft,
        sepRight,
        backgroundColor,
        flexDirectionRow,
        style,
      ]}
    >
      {children}
    </View>
  );
};

export default TextBox;
