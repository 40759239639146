import React, { useEffect } from "react";
import { View } from "react-native";
import TabBar from "./TabBar";

const TabRouterComponent = ({
  titles,
  components,
  iconNames,
  side,
  rightSide,
  onUpdate,
  appState,
  updateHeader,
  analytics=()=>null,
}) => {
  const {
    dim,
    events: { setTabIndex: setIndex },
    tabIndex: index,
  } = appState;
  const { width, height } = dim;
  useEffect(() => {
    if (typeof onUpdate === "function") {
      onUpdate(index);
    }
  }, [index, onUpdate]);

  return (
    <View style={[{ width, height, flex: 1 }]}>
      <View style={{ height }}>
        {components.map((c, i) => (
          <View
            key={i}
            style={{
              top: 0,
              left: 0,
              opacity: (index === i) * 1.0,
              transform: [{ translateY: height * (index !== i) }],
              flex: (index === i) + (index !== i) * 0.0001,
            }}
          >
            <View
              style={{
                width,
                height,
                flex: 1,
                flexDirection: "row",
              }}
            >
              <View style={{ height, flexGrow: 1 }}>
                {React.createElement(
                  components[i],
                  {
                    appState,
                    updateHeader: i === index && updateHeader,
                  },
                  null
                )}
              </View>
            </View>
          </View>
        ))}
      </View>
      {appState.dim.activeTabBar ? (
        <TabBar {...{ iconNames, names: titles, setIndex: (index) => {
            analytics(titles[index]);
            setIndex(index);
        }, index }} />
      ) : null}
    </View>
  );
};

export default TabRouterComponent;
