import React from "react";
import { View } from "react-native";
import Body from "../Text/Body";
import { InputField, WordButton, Confirm } from "bl-components";

//const backgroundColors = ['#ECF7FF', '#F2FFEC', '#FFFAEC'];
//const borderColors = ['#002FA9', '#18A900', '#A97A00'];

const backgroundColors = ["#002FA9", "#18A900", "#A97A00"];

const Tags = ({ tags }) => (
  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
    {tags.map((i, index) => (
      <View
        key={i}
        style={{
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          marginRight: 5,
          marginBottom: 5,
          height: 35,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <View
          key={i}
          style={{
            backgroundColor: backgroundColors[index % 3],
            marginRight: 5,
            width: 3,
            height: 35,
            justifyContent: "center",
          }}
        />
        <Body color={backgroundColors[index % 3]}>{i}</Body>
      </View>
    ))}
  </View>
);

export const EditTags = ({ tags, maxTags, setTags }) => (
  <>
    {tags.map((i, index) => (
      <View key={i + index} style={{ flexDirection: "row" }}>
        <View style={{ flex: 2 }}>
          <InputField
            returnKeyType="done"
            autoCapatalize={"sentences"}
            onEndEditing={(text) => {
              const tmp = [...tags];
              tmp[index] = text;
              setTags(tmp);
            }}
            maxLength={40}
            initialString={i}
          />
        </View>
        <View style={{ width: 120 }}>
          {index !== 0 ? (
            <WordButton
              body
              underline
              onPress={() =>
                Confirm(
                  () => {
                    const tmp = [...tags];
                    tmp.splice(index, 1);
                    setTags(tmp);
                  },
                  "Remove Tag",
                  "This will remove the selected tag."
                )
              }
            >
              Remove
            </WordButton>
          ) : null}
        </View>
      </View>
    ))}
    {typeof maxTags === "undefined" || tags.length >= maxTags ? null : (
      <WordButton
        body
        underline
        green
        onPress={() =>
          Confirm(
            () => {
              const tmp = [...tags];
              tmp.push("");
              setTags(tmp);
            },
            "Add Tag",
            "This will add a blank tag for you to modify."
          )
        }
      >
        Add Tag
      </WordButton>
    )}
  </>
);

export default Tags;
