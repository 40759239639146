import React from 'react';
import {View} from 'react-native';

import {Button, H3, EmailField, PageScrollMB} from 'bl-components';

const Page1 = ({navigation: {navigate}}) => {
  return (
    <PageScrollMB>
      <View style={{margin: 20}}>
        <H3>Enter email to send your friend an invite!</H3>
      </View>
      <View style={{marginLeft: 10, marginRight: 10}}>
        <EmailField onEndEditing={() => null} />
      </View>
      <View style={{height: 20}} />
      <Button onPress={() => null}>Submit</Button>
    </PageScrollMB>
  );
};

export default Page1;
