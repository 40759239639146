import React, { useState } from "react";
import { Platform, TouchableOpacity, View, FlatList } from "react-native";

import {
  ImageLoading,
  SvgIcon,
  TextBox,
  Sep3,
  H2,
  H3,
  Body,
} from "bl-components";

const SinceTime = ({ timestamp }) => {
  const s = 1000;
  const min = 60000;
  const hour = 3600000;
  const day = hour * 24;
  const week = day * 7;
  const month = week * 4;
  const year = month * 12;
  const diff = new Date().getTime() - timestamp;
  return (
    <Body navy>
      {diff / min < 1
        ? (diff / s < 1 ? "1" : Math.floor(diff / s)) + "s"
        : diff / hour < 1
        ? Math.floor(diff / min) + "min"
        : diff / day < 1
        ? Math.floor(diff / hour) + "hr"
        : diff / week < 1
        ? Math.floor(diff / day) + "d"
        : diff / month < 1
        ? Math.floor(diff / week) + "w"
        : diff / year < 1
        ? Math.floor(diff / month) + "mo"
        : Math.floor(diff / year) + "y"}
    </Body>
  );
};

const Notification = ({ notif, navigate, ackNotif }) => {
  const {
    id,
    title,
    timestamp,
    description,
    image,
    //route,
    type,
    ack: initialAck,
  } = notif;
  var iconName;
  switch (type) {
    case "product":
      iconName = "priceTag";
      break;
    default:
      iconName = "priceTag";
  }

  const [ack, setAck] = useState(initialAck);
  const [acking, setAcking] = useState(false);
  return (
    <>
      <TouchableOpacity
        key={ack}
        style={{
          alignItems: "center",
          height: 100,
          flexDirection: "row",
          marginLeft: 20,
          marginRight: 20,
          opacity: 0.75 * (!ack + 1),
        }}
        activeOpacity={0.75}
        onPress={async () => {
          if (!ack && !acking) {
            setAcking(true);
            if (await ackNotif(id)) setAck(true);
            setAcking(false);
          }
          navigate("Notification", { notif });
        }}
      >
        <View
          style={{
            height: 5,
            width: 5 * !ack,
            backgroundColor: "#1E3B66",
            borderRadius: 100,
            marginRight: 10,
            marginLeft: 5 * ack,
          }}
        />
        {typeof image === "undefined" || image === "" ? (
          <View
            style={{
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon color="#6993FD" name={iconName} size={40} />
          </View>
        ) : (
          <ImageLoading
            style={{ width: 40, height: 40 }}
            source={{ uri: image }}
          />
        )}
        <View
          style={{ marginTop: 10, marginLeft: 10, width: "80%" }}
        >
          <View
            style={{
              height: 30,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <H3 navy lines={1}>
              {title}
            </H3>
            <View style={{ width: 5 }} />
            <SinceTime timestamp={timestamp} />
          </View>
          <View style={{ height: 60 }}>
            <Body lines={2}>{description}</Body>
          </View>
        </View>
      </TouchableOpacity>
      <Sep3 />
    </>
  );
};

const Notifications = ({
  ackNotif,
  onRefresh,
  getNavigation,
  getNotifications,
  appState,
  CloseRightDrawer,
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const navigate = (str, obj) => {
    getNavigation().navigate(str, obj);
    if (typeof CloseRightDrawer === "function") CloseRightDrawer();
  };
  const notifications = getNotifications();

  return (
    <View style={{ flex: 1, borderLeftWidth: 1, borderColor: "#d7d7d7" }}>
      {Platform.OS === "web" ? null : <View style={{ height: 70 }} />}
      <TextBox>
        <H2 blue style={{ fontWeight: "600" }} textAlign={"center"}>
          Notifications
        </H2>
      </TextBox>
      <Sep3 />
      <FlatList
        refreshing={refreshing}
        onRefresh={() => {
          if (typeof onRefresh !== "undefined") {
            setRefreshing(true);
            onRefresh(() => setRefreshing(false));
          }
        }}
        data={notifications}
        showsVerticalScrollIndicator={Platform.OS === "web"}
        keyExtractor={(item, index) => JSON.stringify(item) + index}
        renderItem={({ item }) => (
          <Notification {...{ ackNotif, navigate }} notif={item} />
        )}
      />
    </View>
  );
};

export default Notifications;
