import React from "react";
import { View, Pressable, Modal } from "react-native";
import { useDimensions } from "../../Hooks/useDimensions";

import X from "../../Icons/X";

const GeneralPopup = ({ content, deleteMe }) => {
  const { width, height } = useDimensions();

  const style = {
    width: width,
    height: height,
    backgroundColor: "rgba(0,0,0,0.8)",
    flex: 1,
  };

  return (
    <Modal
      style={{ ...style }}
      animationType="fade"
      transparent={true}
      visible={true}
    >
      <View style={style}>
        <View
          style={{
            margin: 80,
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {content(deleteMe)}
        </View>
        <Pressable
          style={{
            position: "absolute",
            top: 50,
            right: 0,
            width: 45,
            height: 50,
            zIndex: 1000,
          }}
          onPress={deleteMe}
        >
          <X size={30} color="#ddd" />
        </Pressable>
      </View>
    </Modal>
  );
};

export default GeneralPopup;
