// import library to help create a component
import React from 'react';

import {View, Image, Linking} from 'react-native';
import DataManager from '../../Support/DataManager';

import {PageScrollMB, H2, H3, Button} from 'bl-components';

const Imag = ({source, width}) => (
  <View style={{alignItems: 'center'}}>
    <Image
      style={{
        width,
        height: 187.5,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
      }}
      source={source}
    />
  </View>
);

const LinkButton = (props) => (
  <Button
    {...props}
    onPress={async () => {
      if (typeof DataManager.AboutUs.website !== 'undefined') {
        const link = DataManager.AboutUs.website;

        const linkSupported = await Linking.canOpenURL(link).catch(() =>
          console.log('Link error'),
        );
        if (linkSupported) {
          await Linking.openURL(link).catch(() => console.log('Link error'));
        } else {
          console.log('Link error');
        }
      }
    }}
  >
    More Information
  </Button>
);
const Tmp = ({title, description, image, width}) => (
  <View
    style={{
      margin: 10,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#E5E5E5',
      overflow: 'hidden',
    }}
  >
    <Imag width={width - 20} source={image} />
    <View style={{padding: 10}}>
      <H2 bold>{title}</H2>
      <H3>{description}</H3>
      <View style={{alignItems: 'center'}}>
        <View style={{width: 300, margin: 10, marginTop: 15}}>
          <LinkButton blue />
        </View>
      </View>
    </View>
  </View>
);

const Community = ({
  appState: {
    dim: {tabletWidth: width},
  },
}) => (
  <PageScrollMB {...{width}}>
    <Tmp
      {...{width}}
      description={'Our market has over 1600 spaces. Start selling today!'}
      title={'Become a Vendor'}
      image={require('../../Support/CommunityMore/BecomeVendor.png')}
    />
    <View style={{height: 10, backgroundColor: '#fff'}} />
    <Tmp
      {...{width}}
      description={'Our market has over 1600 spaces. Start selling today!'}
      title={'Become an Entertainer'}
      image={require('../../Support/CommunityMore/BecomeEntertainer.png')}
    />
    <View style={{height: 10, backgroundColor: '#fff'}} />
    <Tmp
      {...{width}}
      description={'Our market has over 1600 spaces. Start selling today!'}
      title={'Become an Employee'}
      image={require('../../Support/CommunityMore/BecomeEmployee.png')}
    />
  </PageScrollMB>
);

export default Community;
