// import libraries for making the header component
import React from "react";

import { Platform, ScrollView, View } from "react-native";

const ScrollMB = ({ children, passRef, footerHeight, ...props }) => {
  return (
    <ScrollView
      keyboardDismissMode="on-drag"
      showsVerticalScrollIndicator={Platform.OS === "web"}
      ref={passRef}
      style={{ backgroundColor: "#fff" }}
      {...props}
    >
      {children}
      <View
        style={{
          height:
            typeof footerHeight !== "undefined" && footerHeight !== null
              ? footerHeight
              : 75,
        }}
      />
    </ScrollView>
  );
};
export default ScrollMB;
