// import library to help create a component
import React from "react";
import { View } from "react-native";

// Hamburger Style

const X = ({ size, color }) => {
  const line2 = {
    height: 3,
    width: size,
    backgroundColor: color,
    transform: [{ translateY: -3 }, { rotateZ: "-45deg" }],
  };
  const line1 = {
    height: 3,
    width: size,
    backgroundColor: color,
    transform: [{ rotateZ: "45deg" }],
  };
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: size,
        width: size,
      }}
    >
      <View
        style={[
          line1,
          typeof color === "undefined" ? null : { borderColor: color },
        ]}
      />
      <View
        style={[
          line2,
          typeof color === "undefined" ? null : { borderColor: color },
        ]}
      />
    </View>
  );
};

export default X;
