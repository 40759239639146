// import libraries for making the header component
import { useEffect } from "react";

import { BackHandler } from "react-native";

const DisableAndroidBack = () => {
  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", () => true);
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", () => true);
  }, []);

  return null;
};

export default DisableAndroidBack;
