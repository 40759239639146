import React, { useState } from "react";
import { TouchableOpacity, ScrollView } from "react-native";
import Svg, { Path } from "react-native-svg";
import { Search, Grid, GridItem } from "bl-components";

import icons from "./icons.json";

export const FindIcon = ({ width, setIcon }) => {
  const [search, setSearch] = useState("");
  return (
    <>
      <Search continuous {...{ width, setSearch }} />
      <ScrollView style={{ width, height: 100 }}>
        <Grid {...{ width }}>
          {Object.keys(icons)
            .filter((i) => i.includes(search))
            .sort()
            .map((name) => (
              <GridItem width={35} key={name}>
                <TouchableOpacity
                  onPress={() => setIcon(name)}
                  style={{ marginTop: 10 }}
                >
                  <SvgIcon {...{ name }} color="grey" />
                </TouchableOpacity>
              </GridItem>
            ))}
        </Grid>
      </ScrollView>
    </>
  );
};

const SvgIcon = ({ name, color, size }) => (
  <Svg
    width={typeof size === "undefined" ? 24 : size}
    height={typeof size === "undefined" ? 24 : size}
    color={color}
    viewBox={
      typeof icons[name] === "object" ? icons[name].viewBox : "0 0 24 24"
    }
  >
    <Path
      fill={color}
      d={
        typeof icons[name] === "undefined"
          ? icons["question-mark"]
          : typeof icons[name] === "object"
          ? icons[name].d
          : icons[name]
      }
    />
  </Svg>
);
export default SvgIcon;

//var images = {};
//const promises = Object.keys(icon).map((i) => {
//  return new Promise((resolve, reject) => {
//    const size = 24;
//    const color = 24;
//    const svg = `<svg width="${typeof size === 'undefined' ? 24 : size}"
//    xmlns="http://www.w3.org/2000/svg"
//    height="${typeof size === 'undefined' ? 24 : size}"
//    color="${color}"
//    viewBox="0 0 24 24">
//    <path
//      fill="${color}"
//      d="${typeof icon[i] === 'undefined' ? icon['question-mark'] : icon[i]}"
//    />
//  </svg>`;
//
//    var parser = new DOMParser();
//    var doc = parser.parseFromString(svg, 'image/svg+xml');
//
//    // get svg data
//    var xml = new XMLSerializer().serializeToString(doc.documentElement);
//
//    // make it base64
//    var svg64 = btoa(xml);
//    var b64Start = 'data:image/svg+xml;base64,';
//
//    // prepend a "header"
//    var image64 = b64Start + svg64;
//
//    const img = new Image();
//
//    // set it as the source of the img element
//    img.onload = function () {
//      // draw the image onto the canvas
//      images[i] = {img, size, color};
//      resolve();
//    };
//    img.src = image64;
//  });
//});

const images = [];

export async function SvgIconCanvas(
  ctx,
  x,
  y,
  name,
  color,
  size,
  ctxFxn = () => null
) {
  return new Promise((resolve, reject) => {
    const index = images.findIndex(
      (image) =>
        image.name === name && image.size === size && image.color === color
    );
    if (index > -1) {
      const { img } = images[index];
      ctxFxn();
      ctx.drawImage(img, x, y);
      resolve();
    } else {
      const svg = `<svg width="${typeof size === "undefined" ? 24 : size}"
                     xmlns="http://www.w3.org/2000/svg"
                     height="${typeof size === "undefined" ? 24 : size}"
                     color="${color}"
                     viewBox="0 0 24 24">
                     <path
                       fill="${color}"
                       d="${
                         typeof icons[name] === "undefined"
                           ? icons["question-mark"]
                           : icons[name]
                       }"
                     />
                    </svg>`;

      var parser = new DOMParser();
      var doc = parser.parseFromString(svg, "image/svg+xml");

      // get svg data
      var xml = new XMLSerializer().serializeToString(doc.documentElement);

      // make it base64
      var svg64 = btoa(xml);
      var b64Start = "data:image/svg+xml;base64,";

      // prepend a "header"
      var image64 = b64Start + svg64;

      const img = new Image();

      // set it as the source of the img element
      img.onload = function () {
        // draw the image onto the canvas
        images.push({ name, img, size, color });
        ctxFxn();
        ctx.drawImage(img, x, y);
        resolve();
      };
      img.src = image64;
    }
  });
}
/*
export async function SvgIconCanvas(
  ctx,
  x,
  y,
  name,
  color,
  size,
  ctxFxn = () => null,
) {
  return new Promise((resolve, reject) => {
    const svg = `<svg width="${typeof size === 'undefined' ? 24 : size}"
    xmlns="http://www.w3.org/2000/svg"
    height="${typeof size === 'undefined' ? 24 : size}"
    color="${color}"
    viewBox="0 0 24 24">
    <path
      fill="${color}"
      d="${
        typeof icons[name] === 'undefined' ? icons['question-mark'] : icons[name]
      }"
    />
  </svg>`;

    var parser = new DOMParser();
    var doc = parser.parseFromString(svg, 'image/svg+xml');

    // get svg data
    var xml = new XMLSerializer().serializeToString(doc.documentElement);

    // make it base64
    var svg64 = btoa(xml);
    var b64Start = 'data:image/svg+xml;base64,';

    // prepend a "header"
    var image64 = b64Start + svg64;

    const img = new Image();

    // set it as the source of the img element
    img.onload = function () {
      // draw the image onto the canvas
      ctxFxn();
      ctx.drawImage(img, x, y);
      resolve();
    };
    img.src = image64;
  });
} */
