// import library to help create a component
import React, {useState, useEffect} from 'react';
import DataManager from '../../Support/DataManager';
import {Platform, Share} from 'react-native';

import {
  InputField,
  Popup,
  Alert,
  ProductDisplay,
  PageScrollMB,
} from 'bl-components';
import {drawerNav} from '../../Router';

const ItemPage = ({
  appState: {
    dim: {tabletWidth: width},
  },
  navigation,
  route,
}) => {
  const [update, setUpdate] = useState(0);
  const {params} = route;
  const {Item: product} = params;

  const ind = DataManager.Items.findIndex(
    ({id, identifier, vendorID}) =>
      vendorID === product.vendorID &&
      id === product.id &&
      identifier === product.identifier,
  );
  useEffect(() => {
    if (
      DataManager.loggedin &&
      typeof DataManager.Items[ind].favHandlers === 'object'
    ) {
      const handInd =
        DataManager.Items[ind].favHandlers.push(() => setUpdate(update + 1)) -
        1;
      return () => {
        DataManager.Items[ind].favHandlers[handInd] = () => null;
      };
    }
  }, [update, setUpdate, ind]);

  const favCallback = (toggleState, FavType, success, failure) => {
    if (DataManager.loggedin) {
      DataManager.toggleFavorite(
        toggleState,
        product.vendorID,
        product.id,
        product.identifier,
        FavType,
        () => {
          DataManager.Items[ind].fav = !toggleState;
          DataManager.Items[ind].favHandlers.forEach((i) => i());
          if (!toggleState)
            Alert('Item Favorited', 'Item was added to your shopping list!');
          DataManager.myAccountUpdate();
          DataManager.feedUpdate();
          setUpdate(update + 1);
          success();
        },
        () => {
          failure();
        },
      );
    } else {
      //Alert('You must sign in to favorite an item');
      drawerNav.navigate('Log In', {}, true);
      failure();
    }
  };

  return (
    <PageScrollMB {...{width}}>
      <ProductDisplay
        Item={product}
        favCallback={favCallback}
        favInitial={DataManager.Items[ind].fav}
        shareCallback={(info) => {
          const data = new URLSearchParams({
            ...info,
            marketID: DataManager.marketID,
          });
          if (Platform.OS === 'ios')
            Share.share({
              message: 'Check out this product on BuyLo!',
              url: 'https://www.buylo.app/?' + data.toString(),
            });
          else if (Platform.OS === 'android')
            Share.share({
              title: 'Check out this product on BuyLo! ',
              message: 'https://www.buylo.app/?' + data.toString(),
            });
          else
            Alert('Product URL', 'https://www.buylo.app/?' + data.toString());
        }}
        onReport={(info) =>
          Popup(
            ({state: {comment}, setState}) => (
              <InputField
                defaultString="Comment"
                returnKeyType="done"
                blurOnSubmit={true}
                mult
                maxLength={500}
                onEndEditing={(comment) => setState({comment})}
              />
            ),
            [
              {
                text: 'Submit',
                color: {green: true},
                onPress: ({comment}) => {
                  //Confirm(() =>
                  DataManager.pushReport({comment, ...info});
                  //"Send Counterfit Report", "This well send your report to the market so they can look into it further"
                },
              },
              {text: 'Exit', color: {red: true}},
            ],
            {comment: ''},
          )
        }
      />
    </PageScrollMB>
  );
};

export default ItemPage;
