import { useState, useEffect } from "react";
import { Dimensions } from "react-native";

export function useDimensions() {
  const [dimensions, setDimensions] = useState(Dimensions.get("window"));

  useEffect(() => {
    Dimensions.addEventListener("change", () =>
      setDimensions(Dimensions.get("window"))
    );
    return () => {
      Dimensions.removeEventListener("change", () =>
        console.log(Dimensions.get("window"))
      );
    };
  });
  return dimensions;
}
