// import libraries for making the header component
import React from "react";
import { Platform, FlatList, View } from "react-native";
import VendorDetail from "./VendorDetail";
import H1 from "../Text/H1";
import TextBox from "../Views/TextBox";
import { useActiveWidth } from "../Hooks/useActiveWidth";

import gS from "../GlobalStyle";

const VendorList = ({ all, Vendors, Footer, onPress, ...otherProps }) => {
  const width = useActiveWidth();
  return (
    <View style={{ width: "100%", flex: 1 }}>
      <FlatList
        data={Vendors}
        {...otherProps}
        showsVerticalScrollIndicator={Platform.OS === "web"}
        keyExtractor={(item, index) => JSON.stringify(item) + index}
        ListHeaderComponent={() => {
          const num = Vendors.map((i) => i.data).flat().length;
          return (
            <View
              style={{
                ...gS.pageStyle,
                alignItems: "center",
                width: "100%",
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  width: width - 5 * (Platform.OS === "web"),
                }}
              >
                <TextBox>
                  <H1 textAlign="left">
                    {num +
                      (typeof all === "undefined" || all
                        ? " total vendor"
                        : " result") +
                      (num !== 1 ? "s" : "")}
                  </H1>
                </TextBox>
              </View>
            </View>
          );
        }}
        ListFooterComponent={
          typeof Footer === "undefined"
            ? () => <View style={{ height: 75 }} />
            : Footer
        }
        ListFooterComponentStyle={{ height: 75 }}
        renderItem={({ item }) => (
          <View
            style={{
              ...gS.pageStyle,
              alignItems: "center",
              width: "100%",
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                width: width - 5 * (Platform.OS === "web"),
              }}
            >
              <VendorDetail
                Vendor={item}
                onPress={(Vendor) => onPress(Vendor)}
                index={item.index}
                categories={[item.category1, item.category2]}
              />
            </View>
          </View>
        )}
      />
    </View>
  );
};

export default VendorList;
