// import libraries for making the header component
import React from "react";
import { View } from "react-native";

// props.childern has anything passed between Card tags
const RowSpaceBetween = (props) => {
  const viewStyle = { justifyContent: "center", alignItems: "center" };

  return <View style={viewStyle}>{props.children}</View>;
};

export default RowSpaceBetween;
