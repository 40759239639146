// import libraries for making the header component
import React from "react";
import { View, TouchableOpacity } from "react-native";
import H2 from "../Text/H2";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import TextBox from "../Views/TextBox";
import ImageLoading from "../Images/ImageLoading";
import gS from "../GlobalStyle";

const VendorDetail = ({ Vendor, onPress, categories }) => {
  const { name, image, imageUrl, description, spaces } = Vendor;

  //const space = spaces.length ? spaces[0].section + " Row" : "";
  const color = spaces.length ? spaces[0].color : "#C7C7C7";

  // Come up with better way to handle network error
  return (
    <TouchableOpacity
      onPress={() => onPress(Vendor)}
      style={{
        ...gS.shadow,
        backgroundColor: "#fff",
        borderWidth: 0.5,
        borderColor: "rgba(0,0,0,0.1)",
        borderRadius: 10,
        margin: 10,
        overflow: "hidden",
      }}
    >
      {/* Title and favorite */}
      <View
        style={{
          flexDirection: "row",
          height: 70,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: color,
          flexGrow: 1,
        }}
      >
        <ImageLoading
          style={{ marginLeft: 10, width: 50, height: 50, borderRadius: 1000 }}
          source={{ uri: imageUrl + image }}
        />
        <View style={{ flexDirection: "row" }}>
          {spaces.length
            ? spaces // unique then max 4 then render
                .filter(
                  (i, ind, self) =>
                    self.findIndex((j) => j.section === i.section) === ind
                )
                .filter((_, ind) => ind < 4)
                .map(({ section, color }) => (
                  <View
                    key={section}
                    style={{
                      backgroundColor: "white",
                      marginRight: 10,
                      height: 35,
                      borderRadius: 1000,
                      width: 16 * (section + " Row").length,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <H3 color={color}>{section + " Row"}</H3>
                  </View>
                ))
            : null}
        </View>
      </View>
      <TextBox>
        <TextBox>
          <H2 lines={1}>{name}</H2>
          <Body lines={3} style={{ color: "#ABABAB" }}>
            {description}
          </Body>
        </TextBox>
      </TextBox>
    </TouchableOpacity>
  );
};

export default VendorDetail;
