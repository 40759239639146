// import library to help create a component
import React from 'react';
import {PageScrollMB, ConcessionsInfoDetail} from 'bl-components';

const ConcessionsInfo = ({navigation, route}) => {
  const {params} = route;
  const {Concession} = params;

  return (
    <PageScrollMB>
      <ConcessionsInfoDetail Concession={Concession} />
    </PageScrollMB>
  );
};

export default ConcessionsInfo;
