// import library to help create a component
import React, {useState, useEffect} from 'react';
import {Linking, TouchableOpacity, StyleSheet, View} from 'react-native';

import Welcome from './Welcome/Welcome';
import Events from './Events';
import More from './More';

import DataManager from '../../Support/DataManager';

import {Alert, SvgIcon, HorizBar} from 'bl-components';

const styles = StyleSheet.create({
  phoneTouch: {
    bottom: 10,
    zIndex: 2000,
    position: 'absolute',
    right: 20,
  },
  phoneView: {
    right: 10,
    bottom: 10,
    backgroundColor: '#007DF1',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    zIndex: 10,
  },
});
const PhoneButton = () => {
  const handlePress = async () => {
    const number = 4803805572;
    const appDeepLink = `tel://${number}`;
    const appDeepLinkSupported = await Linking.canOpenURL(appDeepLink).catch(
      () => Alert('Unable to open URL:', `${appDeepLink}`),
    );
    if (appDeepLinkSupported) {
      await Linking.openURL(appDeepLink).catch(() =>
        Alert('Unable to call number :', `${number}`),
      );
    } else {
      Alert('Unable to open phone app');
    }
  };

  return (
    <TouchableOpacity onPressIn={() => handlePress()} style={styles.phoneTouch}>
      <View style={styles.phoneView}>
        <SvgIcon name="phone" color="#fff" size={32} />
      </View>
    </TouchableOpacity>
  );
};

const Community = ({appState, navigation}) => {
  const [update, setUpdate] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {navigate} = navigation;

  useEffect(() => {
    DataManager.communityUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.communityUpdate = () => null;
    };
  }, [update, setUpdate]);

  const barStrings = ['Welcome', 'Events', 'More'];
  const {dim} = appState;

  const renderType = [
    () => <Welcome {...{appState, navigate}} key={update} />,
    () => <Events {...{appState, navigation}} />,
    () => <More {...{appState, navigate}} />,
  ];

  //<H1>My Name is {Community.name}</H1>
  return (
    <View style={dim} key={update}>
      <HorizBar
        barStrings={barStrings}
        onChange={(index) => setSelectedIndex(index)}
      />
      {renderType[selectedIndex]()}
      {!selectedIndex ? <PhoneButton /> : null}
    </View>
  );
};
export default Community;
