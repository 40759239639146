// import library to help create a component
import React from 'react';

import {ConcessionsList, Page} from 'bl-components';

const Concessions = ({navigation, route}) => {
  const {params} = route;
  const {category} = params;

  return (
    <Page>
      <ConcessionsList
        navigation={navigation}
        onPress={(Concession) =>
          navigation.push('ConcessionsInfo', {Concession})
        }
        Concessions={category}
        image={category.image}
      />
    </Page>
  );
};

export default Concessions;
