import React from "react";
import { View } from "react-native";
import Body from "../Text/Body";
import SvgIcon from "../Icons/SvgIcon";

export const allConcessionTags = {
  "gluten-free": { color: "#C83333", text: "gluten free" },
  alcohol: { color: "#78909C", text: "contains alcohol" },
  star: { color: "#FFD130", text: "market favorite" },
  heart: { color: "#DF6565", text: "healthy" },
};
const Tags = ({ tags }) => (
  <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
    {tags.map((i, index) => (
      <View
        key={i}
        style={{
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          marginRight: 5,
          marginBottom: 5,
          height: 35,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <SvgIcon size={30} color={allConcessionTags[i].color} name={i} />
        <View style={{ width: 10 }} />
        <Body color={"#999999"}>{allConcessionTags[i].text}</Body>
      </View>
    ))}
  </View>
);

export default Tags;
