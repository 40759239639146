import React, { useState } from "react";
import { useHover } from "@use-gesture/react";
import gS from "../GlobalStyle";

const HoverShadowWrapper = ({ children }) => {
  const [hover, setHover] = useState(false);
  const bind = useHover(({ active }) => setHover(active));
  const hoverStyle = hover ? gS.shadow : {};
  return <div {...bind()}>{children(hoverStyle)}</div>;
};

export default HoverShadowWrapper;
