// import library to help create a component
import React from "react";
import { TouchableOpacity, View } from "react-native";
import gS from "../GlobalStyle";
const { hamburgerColor } = gS;

// Hamburger Style
const hamburgerLine = {
  marginLeft: 10,
  height: 4,
  width: 30,
  backgroundColor: hamburgerColor,
  borderRadius: 2,
  marginBottom: 4,
};

const Hamburger = ({ onPress, color }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={[
          hamburgerLine,
          typeof color === "undefined" ? null : { backgroundColor: color },
        ]}
      />
      <View
        style={[
          hamburgerLine,
          typeof color === "undefined" ? null : { backgroundColor: color },
        ]}
      />
      <View
        style={[
          hamburgerLine,
          typeof color === "undefined"
            ? null
            : { marginBottom: 0, backgroundColor: color },
        ]}
      />
    </TouchableOpacity>
  );
};

export default Hamburger;
