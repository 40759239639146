// import libraries for making the header component
import React from "react";
import { View } from "react-native";

// import LinearGradient from 'react-native-linear-gradient';
//         <LinearGradient
//           colors={['#4c669f', '#3b5998', '#192f6a']}
//           style={{position: 'absolute', bottom: 0}}
//         ></LinearGradient>

const LinearGradient = ({ children, width }) => (
  <View
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      height: 60,
      width,
      background:
        "linear-gradient(180deg, rgba(47, 47, 47, 0) 0%, rgba(0, 0, 0, 0.5) 87.22%)",
      justifyContent: "center",
    }}
  >
    {children}
  </View>
);

export default LinearGradient;
