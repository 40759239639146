// import libraries for making the header component
import React, { useState, useRef } from "react";
import { View, Animated, Easing } from "react-native";
import gS from "../GlobalStyle.js";
import Container from "../Views/Container";
import H1 from "../Text/H1";
import H3 from "../Text/H3";
import TextBox from "../Views/TextBox";
import BackButton from "../Buttons/BackButton";
import Button from "../Buttons/Button";
import { ShortDate } from "../Dates/MyDate";
import ImageLoading from "../Images/ImageLoading";

import QRCode from "../Utils/QRCode";

const CouponDetail = ({ Item, userIndex, onRedeem }) => {
  const { id, title, description, expires, discount } = Item;
  const [flip, setFlip] = useState(1);
  const [height, setHeight] = useState(0);
  const existing = typeof Item.images[0] !== "undefined";

  const rotateY = useRef(new Animated.Value(0)).current;
  const changePosition = () => {
    setFlip(!flip ? 1 : 0);
    Animated.timing(rotateY, {
      toValue: flip,
      duration: 700,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
    //}).start(() => rotateY.setValue(0));
  };
  const rotate = rotateY.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "180deg"],
  });
  const scale = rotateY.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [1, 1.075, 1],
  });
  const opacity = rotateY.interpolate({
    inputRange: [0, 0.499, 0.5, 1],
    outputRange: [1, 1, 0, 0],
  });
  const opacity2 = rotateY.interpolate({
    inputRange: [0, 0.499, 0.5, 1],
    outputRange: [0, 0, 1, 1],
  });
  const width = 320;
  return (
    <Animated.View
      key={height}
      style={{
        height: height === 0 ? 100 : height + 30,
        transform: [{ rotateY: rotate }, { scale }],
      }}
    >
      <Container {...{ height }} width={width}>
        <Animated.View
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            //setHeight(layout.height);
            if (height < layout.height) {
              setHeight(layout.height);
            }
          }}
          style={{
            opacity,
            zIndex: flip ? 1 : 0,
          }}
        >
          <View style={{ margin: 10 }}>
            {existing ? (
              <View style={{ alignItems: "center" }}>
                <ImageLoading
                  style={{ width: width - 20, height: width - 20 }}
                  source={{ uri: Item.images[0] }}
                />
              </View>
            ) : (
              <>
                <View
                  style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
                >
                  <H1
                    style={{ fontWeight: "bold", fontSize: 35 }}
                    textAlign="left"
                  >
                    {discount}
                  </H1>
                  <View style={{ height: 15 }} />
                  <H3 style={{ color: "green" }} textAlign="left">
                    {discount}
                  </H3>
                </View>
                <TextBox>
                  <H3 style={{ fontWeight: "bold" }}>{title}</H3>
                  <H3>{description}</H3>
                </TextBox>
              </>
            )}
            <TextBox flexDirectionRow>
              <H3 style={{ color: "#696969" }}>Expires </H3>
              <ShortDate date={new Date(expires)} />
            </TextBox>
            <Button
              style={{ margin: 5 }}
              onPress={() => {
                if (typeof onRedeem === "undefined" || onRedeem())
                  changePosition();
              }}
            >
              Redeem
            </Button>
          </View>
        </Animated.View>
        <Animated.View
          style={{
            position: "absolute",
            width,
            opacity: opacity2,
            top: 0,
            transform: [{ rotateY: "180deg" }],
            zIndex: flip ? 0 : 1,
          }}
        >
          <View
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              if (height < layout.height) {
                setHeight(layout.height);
              }
            }}
          >
            <BackButton color={gS.darkBlue} onPress={() => changePosition()}>
              <View style={{ width: 4 }} />
              <H1 blue textAlign="left">
                Back
              </H1>
            </BackButton>
            <View style={{ height: 10, alignItems: "center" }}>
              <View style={{ height: 180, width: 180, alignItems: "center" }}>
                <QRCode
                  size={180}
                  value={JSON.stringify({
                    couponIndex: id,
                    userIndex:
                      typeof userIndex === "undefined" ? -1 : userIndex,
                  })}
                />
              </View>
            </View>
            <View style={{ height: 180, alignItems: "center", margin: 10 }} />
          </View>
        </Animated.View>
      </Container>
    </Animated.View>
  );
};

export default CouponDetail;
