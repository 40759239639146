import { Platform, StyleSheet, Dimensions } from "react-native";

const SeperatorColor = "rgb(80, 152, 164)";
//const MainColor = '#D03828';
const MainColor = "#3872BB";
const FontFamily = "HelveticaNeue";

const black = "#303030";
const grey = "#f5f5f5";
const white = "#fff";
const gold = "#FFD232";
const yellow = "#f3ff00";
//const darkBlue = '#4C94FF';
const darkBlue = "#3872BB";
const lightGrey = "#fafafa";

const shadow = {
  elevation: 4,
  shadowColor:
    Platform.OS === "android" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.125)",
  shadowOffset: { width: 0, height: 4 },
  shadowOpacity: 1.0,
  shadowRadius: 4,
};
const BackgroundColor = white;

const add = Dimensions.get("window").width > 500 ? 2 : 0;

const gS = StyleSheet.create({
  color: { color: MainColor },
  shadow: shadow,
  backgroundStyle: {
    flex: 1,
    backgroundColor: BackgroundColor,
  },
  sepC: { backgroundColor: SeperatorColor },
  // Text
  BaseText: {
    fontFamily: FontFamily,
    color: "#333",
    //flexWrap: 'wrap',
  },
  BaseTextThin: {
    fontFamily: "Arial",
    fontWeight: 100,
  },
  H1: {
    textAlign: "center",
    fontSize: 21 + add,
  },
  H2: {
    fontSize: 21 + add,
  },
  H3: {
    fontSize: 18 + add,
  },
  Body: {
    fontSize: 16 + add,
  },
  topTitleText: {
    fontSize: 21 + add,
  },
  // Containers
  containerStyle: {
    backgroundColor: white,
    borderColor: "#D7D7D7",
    borderWidth: 1,
    marginBottom: 7,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 7,
    ...shadow,
  },
  textbox: {
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: SeperatorColor,
    padding: 5,
    margin: 5,
  },
  pageStyle: {
    flex: 1,
    // backgroundColor: "#FAFAFA", //gS.bC
    backgroundColor: "#fff", //gS.bC
    width: "100%",
    height: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    backgroundColor: BackgroundColor,
    borderColor: SeperatorColor,
    paddingBottom: 10,
  },
  headerCard: {
    backgroundColor: white,
    ...shadow,
    marginTop: 20,
    marginBottom: 20,
    borderColor: SeperatorColor,
    paddingRight: 20,
    paddingLeft: 20,
  },
  buttonTouchStyle: {
    flex: 1,
    alignItems: "center",
    height: 50,
  },
  buttonContainerStyle: {
    alignItems: "center",
    borderRadius: 5,
    padding: 10,
  },
  // Input style
  textInputView: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    margin: 10,
    backgroundColor: lightGrey,
    borderWidth: 1,
    borderColor: "#CECECE",
  },
  inputText: {
    fontSize: 18,
    fontFamily: FontFamily,
    textAlign: "center",
  },
  // Header
  headerView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: white,
  },
  // Horiz Scroll Bar
  horizScrollView: {
    height: 46,
    justifyContent: "center",
    //width: "100%",
    flexDirection: "row",
    // shadowColor: 'rgba(0,0,0,0.5)',
    // shadowOffset: {width: 2, height: 2},
    // shadowOpacity: 0.9,
    // shadowRadius: 4,
    overflow: "hidden",
  },
  selectedText: {
    fontSize: 20,
    fontFamily: FontFamily,
    textAlign: "center",
    color: black,
  },
  plainText: {
    fontSize: 20,
    fontFamily: FontFamily,
    textAlign: "center",
    color: black,
  },
  underlineStyle: {
    width: 60,
    height: 0,
    borderBottomWidth: 2,
    borderColor: MainColor,
  },
  // Images
  browseImage: {
    borderRadius: 3,
  },
  // Coupon Detail
  couponDetailImage: {
    borderWidth: 0,
    borderRadius: 10,
    overflow: "hidden",
    width: Dimensions.get("window").width - 20,
    height: Dimensions.get("window").width - 20,
  },
  emptyCouponDetailView: {
    margin: 10,
    backgroundColor: "#aaa",
    width: Dimensions.get("window").width - 20,
    height: Dimensions.get("window").width - 20,
    alignItems: "center",
    justifyContent: "center",
  },
  itemImageStyle: {
    height: 150,
    width: 150,
    //height: Dimensions.get('window').width - 50,
    //width: Dimensions.get('window').width - 50,
    resizeMode: "cover",
    borderRadius: 2,
  },
  // Item Detail Style
  switchTextView: {
    marginRight: 10,
  },
  switchView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemTouch: {
    width: Dimensions.get("window").width - 50,
    alignItems: "center",
  },
  itemContainerWidth: {
    width: (Dimensions.get("window").width - 50 + 4) / 2.0,
  },
  // Item Info Detail Style
  sliderImage: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").width * 3) / 4,
  },
  descriptionView: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});
const AllStyles = {
  ...gS,
  white: "#fff",
  black: black,
  //darkGrey: '#C7C7C7',
  darkGrey: "#7A7A7A",
  grey,
  gold,
  yellow,
  lightGrey,
  hamburgerColor: black,
  headerColor: grey,
  lightBlue: "#C9F2FF",
  darkBlue,
  lightGreen: "#CAFFC9",
  green: "#1AC609",
  darkGreen: "#03AC00",
  red: "#D60000",
  darkRed: "#B10101",
  MainColor,
  shadow,
  bodyFontSize: 16 + add,
  H3FontSize: 18 + add,
  H2FontSize: 21 + add,
};
export const container = {
  backgroundColor: "#fff",
  marginTop: 10,
  marginBottom: 10,
  padding: 10,
  borderWidth: 1,
  borderColor: "#D9D9D9",
};
export default AllStyles;
