// import library to help create a component
import React, {useState, useEffect} from 'react';
import DataManager from '../../Support/DataManager';

import {HorizBar, Page} from 'bl-components';

import Login from './Login';
import FavoriteVendors from './FavoriteVendors';
import FavoriteItems from './FavoriteItems';

const MyAccount = ({navigation}) => {
  const [update, setUpdate] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    DataManager.myAccountUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.myAccountUpdate = () => null;
    };
  }, [update]);

  //const barStrings = ['My Vendors', 'My Shopping List', 'Settings'];
  const barStrings = ['My Vendors', 'My Shopping List'];
  const renderType = [
    () => <FavoriteVendors navigation={navigation} />,
    () => <FavoriteItems navigation={navigation} />,
    () => null,
  ];

  return (
    <Page key={selectedIndex + '' + update}>
      {DataManager.loggedin ? (
        <>
          <HorizBar
            barStrings={barStrings}
            initial={selectedIndex}
            onChange={(index) => setSelectedIndex(index)}
          />
          {renderType[selectedIndex]()}
        </>
      ) : (
        <Login />
      )}
    </Page>
  );
};

export default MyAccount;
