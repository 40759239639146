import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  ActivityIndicator,
  View,
  FlatList,
} from "react-native";
import { ImageLoading, Page, H2, H3, Body } from "bl-components";

const MessagePortal = ({
  appState,
  navigation,
  route: {
    params: { getConversations, getMessages, sendMessage },
  },
}) => {
  const [conversations, setConversations] = useState(null);
  const {
    dim: { width },
  } = appState;

  useEffect(() => {
    if (conversations === null) {
      (async () => {
        // sort largest timestamp first
        const c = (await getConversations()).sort(
          (pv, cv) => cv.timestamp - pv.timestamp
        );
        setConversations(typeof c === "object" ? c : null);
      })();
      return () => null;
    } else {
      const interval = setInterval(async () => {
        const c = (await getConversations()).sort(
          (pv, cv) => cv.timestamp - pv.timestamp
        );
        setConversations(typeof c === "object" ? c : null);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [getConversations, setConversations, conversations]);

  return conversations === null ? (
    <Page>
      <View style={{ margin: 40 }}>
        <ActivityIndicator size="large" color={"blue"} />
      </View>
    </Page>
  ) : conversations.length ? (
    <Page>
      <FlatList
        data={conversations}
        keyExtractor={(item) => JSON.stringify(item)}
        renderItem={({ item: { name, image, index, msg, read, yours } }) => (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("Message", {
                getMessages: async () => getMessages(index),
                sendMessage: (msg) => sendMessage(msg, index),
              })
            }
            style={{
              width,
              padding: 20,
              paddingLeft: !read && !yours ? 10 : 20,
              flexDirection: "row",
            }}
          >
            {!read && !yours ? (
              <View style={{ justifyContent: "center" }}>
                <View
                  style={{
                    height: 5,
                    width: 5,
                    backgroundColor: "#1E3B66",
                    borderRadius: 100,
                    marginRight: 5,
                  }}
                />
              </View>
            ) : (
              true
            )}
            <ImageLoading
              style={{ width: 50, height: 50, borderRadius: 500, margin: 5 }}
              source={{ uri: typeof image === "undefined" ? "" : image }}
            />
            <View style={{ width: width - 50 - 50 - 10, paddingLeft: 10 }}>
              <H3 lines={1}>{typeof name === "undefined" ? index : name}</H3>
              <Body color="#999" lines={2}>
                {msg}
              </Body>
            </View>
          </TouchableOpacity>
        )}
      />
    </Page>
  ) : (
    <View style={{ alignItems: "center", margin: 40 }}>
      <H2>No Messages</H2>
    </View>
  );
};

export default MessagePortal;
