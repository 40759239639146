import React from "react";
import { ScrollView, View } from "react-native";

import { ImageLoading, SvgIcon, Sep3, H3, Body } from "bl-components";

const Notification = ({
  route: {
    params: { notif },
  },
}) => {
  const { title, description, image, type } = notif;
  var iconName;
  switch (type) {
    case "product":
      iconName = "priceTag";
      break;
    default:
      iconName = "priceTag";
  }

  return (
    <ScrollView>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          margin: 20,
        }}
      >
        {typeof image === "undefined" || image === "" ? (
          <View
            style={{
              width: 60,
              height: 60,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon color="#6993FD" name={iconName} size={60} />
          </View>
        ) : (
          <ImageLoading
            style={{ width: 60, height: 60 }}
            source={{ uri: image }}
          />
        )}
        <View
          style={{
            marginLeft: 20,
            alignItems: "center",
          }}
        >
          <H3 navy>{title}</H3>
        </View>
      </View>
      <Sep3 />
      <View style={{ margin: 20 }}>
        <Body>{description}</Body>
      </View>
      <Sep3 />
    </ScrollView>
  );
};

export default Notification;
