import {Platform} from 'react-native';
import PermStorage from './PermStorage';
import {
  SetNotifsAlert,
  GetNotificationToken,
  DataManagerBase,
  Alert,
} from 'bl-components';

class DataManager extends DataManagerBase {
  constructor() {
    super();
    this.appStoreUrl =
      Platform.OS === 'ios'
        ? 'https://apps.apple.com/us/app/buylo/id1505156806'
        : 'https://play.google.com/store/apps/details?id=com.weingoworks.buylo&hl=en_US&gl=US';
    this.version = '1.0.3';
    this.loggedin = false;
    this.currentMarket = '';

    this.dataUrl =
      Platform.OS === 'web'
        ? `/Users/v${this.version}/`
        : `https://www.buylo.app/Users/v${this.version}/`;

    this.messagingUrl =
      Platform.OS === 'web'
        ? `/messaging/`
        : `https://www.buylo.app/messaging/`;
    this.errorMsg =
      'Please check your internet connection and refresh by clicking this';

    // for the horiz bar top index with map and info buttons
    this.aboutusIndex = 0;
    // Need this for drawer
    this.AboutUs = {market: '', images: ['']};

    this.id = 0;

    // Favorites
    this.vendFavorites = [];
    // Toggle Favorite
    this.toggleError = false;
    // Login
    this.email = '';
    // Logged in
    this.Notifications = [];
    // Fetch Data every 10 seconds
    this._interval = null;

    //
    this.communityUpdate = () => null;
    this.concessionsUpdate = () => null;
    this.vendorsUpdate = () => null;
    this.offersUpdate = () => null;
    this.feedUpdate = () => null;
    this.myAccountUpdate = () => null;
    this.accountSettings = {types: [], methods: []};

    this.appUpdate = () => null;
    this.routerUpdate = () => null;

    this.updateNotif = () => null;
    this.drawer1Update = () => null;
    this.drawer2Update = () => null;
    this.accountUpdate = () => {
      this.drawer1Update();
      this.drawer2Update();
    };
    // markets
    this.markets = {};
    this.marketID = '';
    // Cache
    (async () => {
      this.Map = await PermStorage.getData('@Map');
      this.Items = await PermStorage.getData('@Items');
      this.Vendors = await PermStorage.getData('@Vendors');
      this.Concessions = await PermStorage.getData('@Concessions');
      this.Community = await PermStorage.getData('@Community');
      this.AboutUs = await PermStorage.getData('@AboutUs');
    })();
  }
  GetPC(vID) {
    const it = this.Items.filter(({vendorID}) => vendorID === vID);
    return {
      Products: it.filter(({index}) => index.split('_')[0] !== '3'),
      Coupons: it.filter(({index}) => index.split('_')[0] === '3'),
    };
  }

  checkMarket(marketID, existsCallback, notexistsCallback) {
    const url = `${this.dataUrl}/Market/check`;
    this.pushData(url, {marketID}, ({data: {result: exists}}) => {
      if (exists) existsCallback();
      else notexistsCallback();
    });
  }

  fetchMarkets(MarketsCallback, FoundCallback) {
    const url = `${this.dataUrl}/Market/get_markets`;
    const callback2 = async () => {
      let marketID = null;
      if (this.markets.length === 1) {
        marketID = this.markets[0].id;
      } else {
        marketID = await PermStorage.getData('@marketID');
      }
      if (marketID !== null) {
        this.checkMarket(
          marketID,
          () => {
            this.initialFetchNoAccount(marketID, FoundCallback);
          },
          MarketsCallback,
        );
      } else {
        MarketsCallback();
      }
    };
    this.fetchData(
      url,
      ({markets}) => {
        this.markets = markets;
      },
      callback2,
    );
  }


  fetchMaintenance(callback, handler) {
    const url = `${this.dataUrl}/Maintnance/get`;
      this.fetchData(url, ({status}) => {
        if (status) {
          clearInterval(this._interval);
          Alert('App is under maintenance', 'Please try again later!', () => {
            this.fetch(callback);
            if (this._interval == null || this._interval._destroyed)
              this._interval = setInterval(this.fetch, 60000);
          });
        } else {
          handler();
        }
      }, () => null)
  }

  submitProblem = async (problem) => {
    const url = `${this.dataUrl}/Problems/new`;
    await this.pushDataAsync(url, {
      marketID: this.marketID,
      problem,
    });
  };
  pushReport = async (info) => {
    const url = `${this.dataUrl}/Problems/new_item`;
    await this.pushDataAsync(url, {...info, marketID: this.marketID});
    Alert('Success', 'Thank you for reporting an issue with this product.');
  };


  // takes toggle state argument that is true or false
  toggleFavorite(
    toggleState,
    VendorIndex,
    ItemIndex,
    Identifier,
    FavType,
    success,
    failure,
  ) {
    const url = `${this.dataUrl}/Accounts/Favorites/toggle`;

    const postObj = {
      favToggle: Boolean(toggleState),
      vendorID: parseInt(VendorIndex, 10),
      itemID: parseInt(ItemIndex, 10),
      identifier: parseInt(Identifier, 10),
      type: FavType,
    };
    const dataCallback = async ({data}) => {
      this.toggleError = 0;
      if (this.toggleError) {
        Alert('Failed to save favorite', 'Please Try Again!');
        failure();
      } else success();
    };

    this.pushData(url, postObj, dataCallback, () => null, null, failure);
  }

  fetchFavorites = async () => {
    const url = `${this.dataUrl}/Accounts/Favorites/get`;
    const tmp = await this.fetchDataAsync(url);
    if (tmp !== null) {
      this.vendFavorites = tmp.vendors;
      this.Items = this.Items.map((i) => {
        const {identifier, id, vendorID} = i;
        const fav = tmp.items.findIndex(
          (item) =>
            item.identifier === parseInt(identifier, 10) &&
            item.itemID === id &&
            item.vendorID === vendorID,
        );
        return {...i, fav: fav > -1, favHandlers: []};
      });
    }
  };

  updateAccountSettings = async (types, methods) => {
    const url = `${this.dataUrl}/Accounts/update_settings`;
    const success = await this.pushDataAsync(url, {types, methods});
    if (success !== null) {
      return success;
    }
    return false;
  };
  fetchSettings = async () => {
    const url = `${this.dataUrl}/Accounts/get_settings`;
    const settings = await this.fetchDataAsync(url);
    if (settings !== null) {
      this.accountSettings.methods =
        typeof settings.methods !== 'object' ? [] : settings.methods;
      this.accountSettings.types =
        typeof settings.types !== 'object' ? [] : settings.types;
    }
  };

  // Notifications
  pushToken = async () => {
    const token = await GetNotificationToken();
    if (token !== '') {
      const url = `${this.dataUrl}/Notifications/push_device_token`;
      await this.pushDataAsync(url, {token, platform: Platform.OS});
    }
  };
  ackNotif = async (id) => {
    const url = `${this.dataUrl}/Notifications/ack`;
    const success = await this.pushDataAsync(url, {id});
    if (success !== null) {
      return success;
    }
    return false;
  };
  fetchNotifications = async () => {
    const url = `${this.dataUrl}/Notifications/get`;
    const notifs = await this.fetchDataAsync(url);
    if (notifs !== null) {
      this.Notifications = notifs;
      SetNotifsAlert(notifs.reduce((pV, {ack}) => pV + !ack, 0));
    }
  };
  // End Notifications

  // Click Analytics
  pageClick = async (page) => {
    const userID = this.id
    const url = `${this.dataUrl}/Analytics/page_click`;
    this.pushDataAsync(url, {userID, page});
  };
  vendorClick = async (vendorID) => {
    const userID = this.id
    const url = `${this.dataUrl}/Analytics/vendor_click`;
    this.pushDataAsync(url, {userID, vendorID});
  };
  itemClick = async (vendorID, itemID, identifier) => {
    const userID = this.id
    const url = `${this.dataUrl}/Analytics/item_click`;
    this.pushDataAsync(url, {userID, vendorID, itemID, identifier});
  };
  // end Analytics
  
  getUnread = async () => {
    const url = `${this.messagingUrl}/unread`;
    const d = await this.pushDataAsync(url, {}, false);
    if (typeof d !== 'object') return false;
    return d.data;
  };
  getConversations = async () => {
    const url = `${this.messagingUrl}/messaged`;
    const d = await this.pushDataAsync(url, {}, false);

    if (typeof d !== 'object') return [];
    return d.data
      .map((i) => {
        const vi = parseInt(i.index.replace('v', ''), 10);
        return {
          ...i,
          v: vi,
          localIndex: this.Vendors.findIndex(
            ({index}) => parseInt(index, 10) === vi,
          ),
        };
      })
      .filter(({localIndex}) => localIndex > -1)
      .map((i) => ({...i, ...this.Vendors[i.localIndex]}))
      .filter(({message}) => typeof message !== 'undefined' && message);
  };
  sendMessage = async (msg, vendorIndex) => {
    const url = `${this.messagingUrl}/send`;
    await this.pushDataAsync(url, {
      reciever: `v${vendorIndex}`,
      msg,
    });
  };

  getMessages = async (vendorIndex) => {
    const url = `${this.messagingUrl}/get`;
    const d = await this.pushDataAsync(
      url,
      {
        reciever: `v${vendorIndex}`,
      },
      false,
    );
    if (typeof d !== 'object') return [];
    return d.data;
  };

  changePassword = (Data, success) => {
    this.pushData(
      `${this.dataUrl}/Accounts/change_password`,
      Data,
      success,
      () => null,
      'Password was changed!',
    );
  };
  recoverPassword = async (email) => {
    const url = `${this.dataUrl}/Accounts/recover_password`;
    const d = await this.pushDataAsync(url, {email});

    if (d === null) {
      Alert('Error', 'There was an unexpected problem creating the account');
      return false;
    }
    const {exist, badEmail} = d;
    if (!exist) {
      Alert('Error', 'Email is not in our system!');
    } else if (badEmail) {
      Alert('Error', 'Email is not deliverable! Try a different email.');
    } else {
      Alert('Success', 'Recovery email was sent!');
    }
    return !(!exist || badEmail);
  };
  createAccount = async (email) => {
    const url = `${this.dataUrl}/Accounts/new_account`;
    const d = await this.pushDataAsync(url, {email});

    if (d === null) {
      Alert('Error', 'There was an unexpected problem creating the account');
      return false;
    }
    const {taken, badEmail} = d;
    if (taken) {
      Alert('Error', 'Email is taken!');
    } else if (badEmail) {
      Alert('Error', 'Email is not deliverable! Try a different email.');
    } else {
      Alert('Success', 'Account was added!');
    }
    return !(taken || badEmail);
  };

  initialFetch = async () => {
    var finalUpdate;
    if (this.loggedin) finalUpdate = this.routerUpdate;
    else finalUpdate = this.appUpdate();
    this.loggedin = true;
    PermStorage.storeCred(this.cred);
    await this.fetchSettings();
    await this.fetchFavorites();
    await this.fetchNotifications();
    this.pushToken();

    finalUpdate();
  };
  resetApp = () => {
    //PermStorage.storeCred({PHPSESSID: '', username: ''});
    this.vendFavorites = [];
    this.loggedin = false;
    this.appUpdate();
    this.updateNotif();
  };

  initialFetchNoAccount = async (marketID, callback = () => null) => {
    this.marketID = marketID; // save this for use in fetch routines
    PermStorage.storeData('@marketID', marketID);
    var isNull = false;
    isNull = isNull || null === this.Map;
    isNull = isNull || null === this.Items;
    isNull = isNull || null === this.Vendors;
    isNull = isNull || null === this.Concessions;
    isNull = isNull || null === this.Community;
    isNull = isNull || null === this.AboutUs;
    if (!isNull) callback();
    this.fetchMaintenance(callback, () => {
      this.fetchAllData(async () => {
        await PermStorage.getCred(async (cred) => {
          this.cred = cred;
          this.loggedin = await this.getSessionBackground();
          if (this.loggedin) await this.initialFetch();
        });
        if (isNull) callback();
        if (this._interval === null) {
          this._interval = setInterval(() => this.fetch(), 5 * 60000);
        }
      });
    });
  };
  fetch = (callback = () => null) => {
    this.fetchMaintenance(callback, () => {
      this.fetchAllData(async () => {
        if (this.loggedin) await this.initialFetch();
        callback();
        this.communityUpdate();
        this.concessionsUpdate();
        this.vendorsUpdate();
        this.offersUpdate();
        this.feedUpdate();
        this.myAccountUpdate();
        if (this._interval === null) {
          clearInterval(this._interval);
          this._interval = setInterval(() => this.fetch(), 5 * 60000);
        }
      });
    });
  };

  fetchAllData(callback = () => null) {
    const url = `${this.dataUrl}/Market/get`;
    this.pushData(
      url,
      {marketID: this.marketID},
      ({data: {result: data}}) => {
        const {Map, Items, Vendors, Concessions, Community, AboutUs} = data;

        PermStorage.storeData('@Map', Map);
        PermStorage.storeData('@Items', Items);
        PermStorage.storeData('@Vendors', Vendors);
        PermStorage.storeData('@Concessions', Concessions);
        PermStorage.storeData('@Community', Community);
        PermStorage.storeData('@AboutUs', AboutUs);

        this.Map = Map;
        this.Items = Items;
        this.Vendors = typeof Vendors === 'undefined' ? [] : Vendors;
        this.Concessions = Concessions;
        this.Community = Community;
        this.AboutUs = AboutUs;
      },
      () => {
        this.vendorsUpdate();
        callback();
      },
      null,
      //() => this.resetApp()
    );
  }

  addName = (a) =>
    a.map((i) => {
      const tmp = i.index.split('\t');
      if (tmp.length === 2 && tmp[0] === 'v') {
        const ind = this.Vendors.findIndex(({index}) => index === parseInt(tmp[1],10));
        if (ind > -1) return {...i, name: this.Vendors[ind].name};
      }
      return i;
    });
}

const DM = new DataManager();
export default DM;
