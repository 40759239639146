// import libraries for making the header component
import React from "react";
import { View } from "react-native";
import ImageLoading from "../Images/ImageLoading";
import gS from "../GlobalStyle.js";
import TextBox from "../Views/TextBox";
import H1 from "../Text/H1";
import H2 from "../Text/H2";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import { useActiveWidth } from "../Hooks/useActiveWidth";
import ConcessionTags from "./ConcessionTags";
// Just need a functional component to show detail to the user

import LinearGradient from "./Gradient.js";

const images = [
  require("./Visa.png"),
  require("./MasterCard.png"),
  require("./GooglePay.png"),
  require("./ApplePay.png"),
  require("./Stripe.png"),
];

const Payments = () => (
  <TextBox>
    <H3 bold>Payments Accepted</H3>
    <View style={{ flexDirection: "row" }}>
      {images.map((i) => (
        <ImageLoading
          style={{
            width: 24,
            height: 24,
            marginBottom: 5,
            marginTop: 10,
            marginRight: 20,
          }}
          key={i}
          source={i}
        />
      ))}
    </View>
    <Body>
      Please be ready with your order at the window. Wait times can vary
      depending on the crowd size. Enjoy your meal!
    </Body>
  </TextBox>
);

const ConcessionsInfoDetail = ({ Concession, width }) => {
  const { tags, resturantName, name, price, extras, description, image } =
    Concession;
  const w = useActiveWidth();
  const w2 = typeof width === "undefined" ? w : width;

  return (
    <>
      <View style={{ marginTop: 0, alignItems: "center" }}>
        <ImageLoading
          style={{
            resizeMode: "cover",
            margin: 0,
            height: (w2 * 2) / 3,
            width: w2,
          }}
          source={{ uri: image }}
        />
        <LinearGradient {...{ width: w }}>
          <H1 style={{ marginLeft: 15 }} bold white textAlign="left">
            {resturantName}
          </H1>
        </LinearGradient>
      </View>
      <View
        style={{
          flexDirection: "row",
          margin: 10,
          justifyContent: "space-between",
        }}
      >
        <H2 bold>{name}</H2>
        <View style={{ width: 10 }} />
        <H3 bold style={{ color: gS.darkGreen }}>
          ${parseFloat(price).toFixed(2)}
        </H3>
      </View>
      <ConcessionTags tags={typeof tags === "undefined" ? [] : tags} />
      {typeof description !== "undefined" ? (
        <TextBox>
          <Body>{description}</Body>
        </TextBox>
      ) : null}
      {typeof extras !== 'undefined' && extras.length ? (
        <>
          <TextBox>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <H3>Want More With Your Meal?</H3>
              <Body
                style={{ color: gS.darkGrey, fontSize: 15, marginLeft: 10 }}
              >
                Sides & Add Ons
              </Body>
            </View>
          </TextBox>
          {extras.map((item) => (
            <View key={item.name} style={{ flexDirection: "row" }}>
              <View style={{ flex: 0.7, alignItems: "flex-start" }}>
                <TextBox>
                  <Body>{item.name}</Body>
                </TextBox>
              </View>
              <View style={{ flex: 0.3, alignItems: "flex-end" }}>
                <TextBox>
                  <Body>+${parseFloat(item.price).toFixed(2)}</Body>
                </TextBox>
              </View>
            </View>
          ))}
        </>
      ) : null}
      <Payments />
    </>
  );
  //      <TextBox>
  //        <View
  //          style={{
  //            flexDirection: 'row',
  //            justifyContent: 'space-between',
  //            alignItems: 'center',
  //          }}>
  //          <H3>Enjoy Your Meal?</H3>
  //          <Body style={{color: gS.darkGrey, fontSize: 15, marginLeft: 10}}>
  //            Provide Feedback
  //          </Body>
  //        </View>
  //        <Button>Write a Review</Button>
  //      </TextBox>
};

export default ConcessionsInfoDetail;
