// import library to help create a component
import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import DataManager from '../../Support/DataManager';

import {ConcessionsCategoryList, Menu, PageScrollMB, H2} from 'bl-components';

const Concessions = ({
  appState: {
    dim: {width, height},
  },
  navigation,
}) => {
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    DataManager.concessionsUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.concessionsUpdate = () => null;
    };
  }, [update, setUpdate]);
  if (
    typeof DataManager.Concessions !== 'object' ||
    DataManager.Concessions === null ||
    Array.isArray(DataManager.Concessions) ||
    !Object.keys(DataManager.Concessions).length 
  )
    return (
        <View style={{width,height, alignItems: 'center', justifyContent: 'center'}}>
          <H2 navy>No Menus Available</H2>
        </View>
    );
  else {
    const keys = Object.keys(DataManager.Concessions);
    const tmp = keys.filter( i => JSON.stringify(DataManager.Concessions[i]).includes('image') )
      if ( ! tmp.length ) return null
    const key = tmp[0]
    if ( JSON.stringify(DataManager.Concessions[key]).includes('image') )
    return (
      <PageScrollMB {...{width}}>
      <ConcessionsCategoryList
        navigation={navigation}
        onPress={(category) => navigation.push('Category', {category})}
        Concessions={
          DataManager.Concessions[key]
        }
      />
      </PageScrollMB>
    );
    else
    return (
      <PageScrollMB {...{width}}>
        <Menu
          name={keys[0]}
          menu={DataManager.Concessions[key]}
          {...{width}}
        />
      </PageScrollMB>
    );
  }
};

export default Concessions;
