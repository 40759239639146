import React from 'react';
import {Image, View, Pressable} from 'react-native';

import {H1, H3, Body, PageScrollMB} from 'bl-components';

import DataManager from '../../Support/DataManager';

const MktCard = ({onPress, image, name, address, location}) => (
  <Pressable
    {...{onPress}}
    style={{
      height: 90,
      borderWidth: 1,
      borderColor: '#EEE',
      elevation: 4,
      shadowColor: 'rgba(0, 0, 0, 0.125)',
      shadowOffset: {width: 0, height: 4},
      shadowOpacity: 1.0,
      shadowRadius: 4,
      flexDirection: 'row',
      //margin: 15,
      marginTop: 15,
      marginBottom: 15,
      padding: 10,
      alignItems: 'center',
    }}
  >
    <Image
      style={{
        width: 70,
        height: 70,
        //borderRadius: 400,
        backgroundColor: '#C4C4C4',
      }}
      source={image}
    />
    <View style={{marginLeft: 10, flex: 1}}>
      <Body bold lines={1}>
        {name}
      </Body>
      <Body lines={1}>{address}</Body>
      <Body lines={1}>{location}</Body>
    </View>
  </Pressable>
);

const Page3 = ({
  loadMarket,
  route: {
    params: {search},
  },
}) => {
  const {markets} = DataManager;

  const states = markets
    .map((i) => i.state)
    .filter((i, ind, self) => self.indexOf(i) === ind)
    .sort();
  if (search.state !== null)
    states.sort((v1, v2) => {
      const stateArr = search.state.toLowerCase().split('');
      const v1Val = v1
        .toLowerCase()
        .split('')
        .map((i, ind) => i === stateArr[ind])
        .reduce((pV, cV) => pV + cV);
      const v2Val = v2
        .toLowerCase()
        .split('')
        .map((i, ind) => i === stateArr[ind])
        .reduce((pV, cV) => pV + cV);
      return v1Val > v2Val ? -1 : v1Val < v2Val ? 1 : 0;
    });
  // default algo states.sort((v1, v2) => (v1 < v2) ? -1 : (v1 > v2) ? 1 : 0)

  return (
    <PageScrollMB>
      <View style={{height: 30}} />
      <H1 bold color="#0081CA" style={{fontSize: 28}}>
        Choose where to shop
      </H1>
      <View style={{margin: 30}}>
        <Body>
          We will automatically connect you to your closest local market!
          Discover what they have to offer. You can always find another market
          later!
        </Body>
      </View>
      <View style={{height: 2, backgroundColor: '#3872BB'}} />
      <View style={{margin: 30}}>
        {search.name === null ? null : (
          <View style={{marginTop: 10}}>
            <H1 bold textAlign="left">
              Name Search Result
            </H1>
            {markets
              .sort(({name: v1}, {name: v2}) => {
                const nameArr = search.name.toLowerCase().split(' ');
                const v1Val = nameArr
                  .map((i) => v1.toLowerCase().search(new RegExp(i)) > -1)
                  .reduce((pV, cV) => pV + cV);
                const v2Val = nameArr
                  .map((i) => v2.toLowerCase().search(new RegExp(i)) > -1)
                  .reduce((pV, cV) => pV + cV);
                if (v1Val === -1 && v2Val === -1) return 0;
                if (v1Val === -1) return 1;
                if (v2Val === -1) return -1;
                return v1Val > v2Val ? -1 : v1Val < v2Val ? 1 : 0;
              })
              .slice(0, 3)
              .map((mkt, ind) => (
                <MktCard
                  key={mkt.name + ind}
                  address={mkt.address}
                  location={mkt.city + ',' + mkt.state}
                  name={mkt.name}
                  image={mkt.image}
                  onPress={() => loadMarket(mkt.id)}
                />
              ))}
            <H1 bold textAlign="left">
              Markets By State
            </H1>
          </View>
        )}
        {states.map((state) => {
          const mkts = markets.filter((mkt) => mkt.state === state);
          const cities = mkts
            .map((i) => i.city)
            .filter((i, ind, self) => self.indexOf(i) === ind)
            .sort();
          if (search.city !== null)
            cities.sort((v1, v2) => {
              const cityArr = search.city.toLowerCase().split('');
              const v1Val = v1
                .toLowerCase()
                .split('')
                .map((i, ind) => i === cityArr[ind])
                .reduce((pV, cV) => pV + cV);
              const v2Val = v2
                .toLowerCase()
                .split('')
                .map((i, ind) => i === cityArr[ind])
                .reduce((pV, cV) => pV + cV);
              return v1Val > v2Val ? -1 : v1Val < v2Val ? 1 : 0;
            });
          return (
            <View style={{marginTop: 10}} key={state}>
              <H1 bold textAlign="left">
                {state}
              </H1>
              {cities.map((city) => {
                const mkts2 = mkts.filter((mkt) => mkt.city === city);
                return (
                  <View style={{marginTop: 10}} key={city}>
                    <H3 bold textAlign="left">
                      {city}
                    </H3>
                    {mkts2.map((mkt, ind) => (
                      <MktCard
                        address={mkt.address}
                        location={mkt.city + ',' + mkt.state}
                        name={mkt.name}
                        image={mkt.image}
                        onPress={() => loadMarket(mkt.id)}
                        key={mkt.name + ind}
                      />
                    ))}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </PageScrollMB>
  );
};

export default Page3;
