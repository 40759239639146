// import library to help create a component
import React from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';
import DataManager from '../../Support/DataManager';

import {
  Confirm,
  gS,
  SvgIcon,
  DrawerMktCard,
  TextBox,
  Sep3,
  H2,
  H3,
  ScrollMB75,
  Button,
} from 'bl-components';

//import EmailLink from '../Components/Buttons/EmailLink';

const Drawer = ({navigate, tabs, setTabIndex, currentTabIndex}) => {
  const {images, market} = DataManager.AboutUs;

  return (
    <View style={{flex: 1, borderRightWidth: 1, borderColor: '#d7d7d7'}}>
      <ScrollMB75>
        {Platform.OS === 'web' ? null : <View style={{height: 70}} />}
        <TextBox>
          <H2 blue style={{fontWeight: '600'}} textAlign={'center'}>
            My Market
          </H2>
          <DrawerMktCard name={market} image={images[0]} />
        </TextBox>
        {DataManager.markets.length === 1 ? null : (
          <TextBox>
            <Button blue onPress={() => navigate('Change Local Market')}>
              Change Local Market
            </Button>
          </TextBox>
        )}
        {typeof tabs === 'undefined' ? null : (
          <>
            <Sep3 />
            {tabs.iconNames.map((i, ind) => (
              <TouchableOpacity
                key={ind}
                onPress={() => setTabIndex(ind)}
                style={{
                  margin: 10,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SvgIcon
                  key={ind}
                  color={currentTabIndex === ind ? gS.MainColor : '#333333'}
                  name={i}
                />
                <View style={{width: 10}} />
                <H3 color={currentTabIndex === ind ? gS.MainColor : '#333333'}>
                  {tabs.titles[ind]}
                </H3>
              </TouchableOpacity>
            ))}
          </>
        )}
        <Sep3 />
        {/*
      <TextBox>
        <H2 textAlign={'left'}>
          Profile and Settings
        </H2>
      </TextBox>
      */}
        <TouchableOpacity onPress={() => navigate('Report a Problem')}>
          <TextBox>
            <H3 textAlign={'left'}>Report a Problem</H3>
          </TextBox>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigate('Help')}>
          <TextBox>
            <H3 textAlign={'left'}>Help</H3>
          </TextBox>
        </TouchableOpacity>
        {DataManager.loggedin ? (
          <>
            <TouchableOpacity onPress={() => navigate('My Vendors')}>
              <TextBox>
                <H3 textAlign={'left'}>My Vendors</H3>
              </TextBox>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigate('Shopping List')}>
              <TextBox>
                <H3 textAlign={'left'}>Shopping List</H3>
              </TextBox>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigate('Account Settings')}>
              <TextBox>
                <H3 textAlign={'left'}>Account Settings</H3>
              </TextBox>
            </TouchableOpacity>
            <View style={{alignItems: 'center', margin: 20}}>
              <Button
                red
                style={{width: 200}}
                onPress={() =>
                  Confirm(
                    () => DataManager.Logout(),
                    'Logout',
                    'This will log you out of your BuyLo account on this device',
                  )
                }
              >
                Log Out
              </Button>
            </View>
          </>
        ) : (
          <View style={{alignItems: 'center', margin: 20}}>
            <Button style={{width: 200}} onPress={() => navigate('Log In')}>
              Log In
            </Button>
          </View>
        )}
      </ScrollMB75>
    </View>
  );
};
//        <TouchableOpacity onPress={() => navigation.navigate('Walkthrough')}>
//          <TextBox>
//            <H3 textAlign={'left'}>Walkthrough</H3>
//          </TextBox>
//        </TouchableOpacity>

//        <TouchableOpacity onPress={() => navigation.navigate('Notifications')}>
//          <TextBox>
//            <H3 textAlign={'left'}>Notification Settings</H3>
//          </TextBox>
//        </TouchableOpacity>

export default Drawer;
