// import library to help create a component
import React, {Component} from 'react';
import {View} from 'react-native';
import DataManager from '../../Support/DataManager';

import {
  Alert,
  UserField,
  ActivityButton,
  Sep,
  Center,
  TextBox,
  Body,
  PageScrollMB,
} from 'bl-components';

class Login extends Component {
  // class level property
  state = {
    email: '',
    emailConfirm: '',
    buttonVisible: true,
  };
  scrollViewRef = null;
  scrollToRef = null;
  passScrollToRef = null;
  scrollButtonHandler = (y) => {
    if (typeof this.scrollViewRef.scrollTo === 'function') {
      this.scrollViewRef.scrollTo({x: 0, y: y, animated: true});
    }
  };

  recoverPassword = async () => {
    if (this.state.email !== this.state.emailConfirm) {
      Alert('Error', 'Emails do not match');
      return;
    }

    this.setState({buttonVisible: false});
    const success = await DataManager.recoverPassword(this.state.email);
    this.setState({buttonVisible: true});
    if (success) {
      this.props.navigation.pop();
    }
  };

  // Can only return one tag so wrap with a view
  render() {
    return (
      <PageScrollMB
        passRef={(ref) => {
          this.scrollViewRef = ref;
        }}
      >
        <View style={{marginTop: 10, backgroundColor: '#fff'}}>
          <TextBox />
          <TextBox>
            <Body>
              Enter your email to recover your password. We will send you an
              email with a temporary password that you can use to login. The
              temporary password can be changed once you login.
            </Body>
          </TextBox>
          <View
            ref={(view) => {
              this.scrollToRef = view;
            }}
          />
          <Center>
            <View style={{width: '90%'}}>
              <UserField
                defaultString="Email"
                onFocus={() =>
                  this.scrollToRef.measure((fx, fy, width, height, px, py) =>
                    this.scrollButtonHandler(fy + 50),
                  )
                }
                onEndEditing={(text) => this.setState({email: text})}
              />
              <UserField
                defaultString="Confirm Email"
                onFocus={() =>
                  this.scrollToRef.measure((fx, fy, width, height, px, py) =>
                    this.scrollButtonHandler(fy + 50),
                  )
                }
                onEndEditing={(text) => this.setState({emailConfirm: text})}
              />
              <Sep />
            </View>
            <View style={{margin: 20, width: 200}}>
              <ActivityButton
                isVisible={this.state.buttonVisible}
                onPress={this.recoverPassword}
              >
                Submit
              </ActivityButton>
            </View>
          </Center>
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
        </View>
      </PageScrollMB>
    );
  }
}

export default Login;
