// import libraries for making the header component
import React from "react";
import { TouchableOpacity, View } from "react-native";
import H2 from "../Text/H2";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import TextBox from "../Views/TextBox";
import Sep from "../Views/Sep";
import Favorite from "./Favorite";
import WordButton from "../Buttons/WordButton";
//import Rate from '../Rate';
import Carousel from "../Images/Carousel";
import SvgIcon from "../Icons/SvgIcon";
import { Tags, useActiveWidth } from "bl-components";
// Just need a functional component to show detail to the user

const ItemInfoDetail = ({
  onReport,
  favInitial,
  favCallback,
  shareCallback,
  Item,
  route,
}) => {
  var item;
  if (typeof Item === "undefined") {
    item = route.params.Item;
  } else {
    item = Item;
  }
  const { index, title, images, description, price, tags } = item;
  const width = useActiveWidth();

  return (
    <View>
      <Carousel images={images} />
      <TextBox>
        <TextBox>
          <H2 bold textAlign="left">
            {title}
          </H2>
          {tags.length ? (
            <>
              <View style={{ height: 20 }} />
              <Tags {...{ tags }} />
            </>
          ) : null}
          <View style={{ height: 20 }} />
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {price < 0.001 ? null : (
              <H2 style={{ color: "#27C300" }}>
                {"$" + parseFloat(price).toFixed(2)}
              </H2>
            )}
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                width: price < 0.001 ? width - 40 : 210,
              }}
            >
              {typeof favCallback === "undefined" ? null : (
                <Favorite
                  VendorIndex={Item.vendorID}
                  ItemIndex={index}
                  initial={favInitial}
                  callback={(toggleState, success, failure) =>
                    favCallback(toggleState, 1, success, failure)
                  }
                  type="Item"
                />
              )}
              {typeof shareCallback === "undefined" ? null : (
                <TouchableOpacity
                  onPress={() =>
                    shareCallback({
                      identifier: Item.identifier,
                      item: Item.id,
                      vendor: Item.vendorID,
                    })
                  }
                  style={{
                    flexDirection: "row",
                    width: 80,
                    justifyContent: "space-between",
                  }}
                >
                  <SvgIcon name="share" color="#008BD0" />
                  <H3>Share</H3>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={{ height: 20 }} />
          {/*
          <H2 textAlign="center">
            {identifier === "0"
              ? "Product"
              : identifier === "1"
              ? "Service"
              : "Class"}
          </H2>
          */}
          <View style={{ height: 10 }} />
          <H3 bold>Description</H3>
          <Sep />
          <Body>{description}</Body>

          {typeof onReport === "undefined" ? null : (
            <>
              <View style={{ height: 20 }} />
              <H3 bold>Issues with your product?</H3>
              <Body>
                Do you suspect this product is illegal and or a counterfeit?
                Report the item so our team can take action!
              </Body>
              <View style={{ alignItems: "center" }}>
                <View style={{ width: 200, margin: 10 }}>
                  <WordButton
                    underline
                    grey
                    body
                    onPress={() =>
                      onReport({
                        itemID: Item.id,
                        vendorID: Item.vendorID,
                        identifier: Item.identifier,
                      })
                    }
                  >
                    Report Counterfeit
                  </WordButton>
                </View>
              </View>
            </>
          )}
        </TextBox>
      </TextBox>
    </View>
  );
};

export default ItemInfoDetail;
