// import libraries for making the header component
import React from "react";
import { View } from "react-native";

const Sep3 = ({ color }) => {
  const style = {
    height: 0.5,
    width: "100%",
    backgroundColor: typeof color === "undefined" ? "#D9D9D9" : color,
  };
  return <View style={style} />;
};
export default Sep3;
