import { useState, useEffect } from "react";
import { Linking } from "react-native";

export const useInitialURL = () => {
  const [url, setUrl] = useState(null);
  const [eventHandler, setEventHandler] = useState(null);

  useEffect(() => {
    (async () => {
      // Get the deep link used to open the app
      if (url === null) {
        const initialUrl = await Linking.getInitialURL();
        setUrl(initialUrl === null ? "" : initialUrl);
      }
    })();
    if (eventHandler === null)
      setEventHandler(
        Linking.addEventListener("url", ({ url }) => setUrl(url))
      );
    return () => {
      if (typeof eventHandler !== "undefined" && eventHandler !== null) {
        eventHandler.remove();
        setEventHandler(null);
      }
    };
  }, [eventHandler, url]);

  return url;
};
