// import library to help create a component
import React, {useState, useEffect, useMemo} from 'react';
import {TouchableOpacity, View} from 'react-native';
import DataManager from '../../Support/DataManager';

import VendorItemsQuick from './VendorItemsQuick';
import {Search, H3, H2} from 'bl-components';

const productSearchFilter = ({tags, hiddenTags, title}, searchReg) => {
  const s =
    hiddenTags
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      tags
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      title.toLowerCase().search(searchReg) >
    -1;
  return s;
};
const productSort = (
  {hiddenTags: ht1, tags: t1, title: ti1},
  {hiddenTags: ht2, tags: t2, title: ti2},
  searchReg,
) => {
  const s1 =
    ht1
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t1
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      ti1.toLowerCase().search(searchReg) >
    -1;
  const s2 =
    ht2
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t2
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      ti2.toLowerCase().search(searchReg) >
    -1;
  return s2 - s1;
};

const Feed = ({
  appState: {
      dim: {tabletWidth: width, width: fullWidth, height},
  },
  navigation,
}) => {
  const [update, setUpdate] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    DataManager.feedUpdate = () => setUpdate(update + 1);
    return () => {
      DataManager.feedUpdate = () => null;
    };
  }, [update, setUpdate]);

  //const sorter = (a, b) =>
  //  new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
  const searchReg = new RegExp(
    search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
    'i',
  );
  const {Items, Vendors} = DataManager;
  const RawProducts = useMemo(
    () =>
      Items.filter(({index}) => index.split('_')[0] !== '3').sort(
        ({timestamp: t1}, {timestamp: t2}) => t2 - t1,
      ),
    [Items],
  );
  const Products = RawProducts.filter((i) =>
    search === '' ? true : productSearchFilter(i, searchReg),
  ).sort((i1, i2) => productSort(i1, i2, searchReg));

    if ( !Vendors.length) return (
        <View style={{width: fullWidth, height, alignItems: 'center', justifyContent: 'center'}}>
          <H2 navy>No Vendors Available</H2>
        </View>
    );
  return (
    <>
      <Search
        {...{setSearch, width}}
        predictiveContent={(search, setNewSearch) => {
          const searchReg = new RegExp(
            search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').toLowerCase(),
            'i',
          );
          const Predict = RawProducts.filter((i) =>
            search === '' ? true : productSearchFilter(i, searchReg),
          )
            .filter((_, ind) => ind < 100)
            .map(({tags, hiddenTags, title}) => [...tags, ...hiddenTags, title])
            .flat()
            .filter((i, ind, self) => self.indexOf(i) === ind)
            .filter((_, ind) => ind < 20)
            .filter((i) => i.toLowerCase().search(searchReg) > -1)
            .sort((i1, i2) => {
              const s1 = i1.toLowerCase().search(searchReg);
              const s2 = i2.toLowerCase().search(searchReg);
              return s1 - s2;
            });
          return (
            <View style={{margin: 20}}>
              {Predict.map((i, ind) => (
                <TouchableOpacity
                  key={i + ind}
                  style={{marginBottom: 10}}
                  onPress={() => setNewSearch(i)}
                >
                  <H3>{i}</H3>
                </TouchableOpacity>
              ))}
            </View>
          );
        }}
      />
      <VendorItemsQuick
        {...{width}}
        key={search}
        update={update}
        all={search === ''}
        Footer={() => <View style={{height: 200}} />}
        {...{Vendors, Products, navigation}}
      />
    </>
  );
};

export default Feed;
