// import library to help create a component
import React, { Component } from "react";
import InputField from "./InputField";

class QuantityField extends Component {
  render() {
    const { onEndEditingSetQuantity, initialQuantity, maxLength, ...otherProps } = this.props;
    const defaultQuantity =
      typeof initialQuantity === "undefined"
        ? "0"
        : parseInt(initialQuantity, 10) + "";
    return (
      <InputField
        {...otherProps}
        keyboardType="numeric"
        multiline={false}
        returnKeyType="done"
        maxLength={typeof maxLength === "undefined" ? 10 : maxLength}
        initialString={
          typeof initialQuantity === "undefined"
            ? undefined
            : parseInt(initialQuantity, 10) + ""
        }
        defaultString={typeof initialQuantity === "undefined" ? "0" : undefined}
        onBlur={(quantity, setState) => {
          if (
            isNaN(parseInt(quantity, 10)) ||
            quantity === "" ||
            parseFloat(quantity) < 0
          ) {
            setState({ value: defaultQuantity, selected: 0 });
            onEndEditingSetQuantity(defaultQuantity);
          } else {
            // const newQuantity = parseInt(quantity, 10);
            // setState({ value: newQuantity, selected: 0 });
            // onEndEditingSetQuantity(newQuantity);
            setState({ value: quantity, selected: 0 });
            onEndEditingSetQuantity(quantity);
          }
        }}
      />
    );
  }
}

export default QuantityField;
