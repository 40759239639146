// import library to help create a component
import React from 'react';
import {Image, View} from 'react-native';
import DataManager from '../../Support/DataManager';
import VendorItemsQuick from '../Feed/VendorItemsQuick';
import {H3} from 'bl-components';

const VendorsPage = ({
  appState: {
    dim: {tabletWidth: width},
  },
  navigation,
}) => {
  const {Items, Vendors} = DataManager;
  const Products = Items.filter(({fav}) => fav);

  return (
    <VendorItemsQuick
      Footer={() => (
        <View style={{alignItems: 'center', marginTop: 10}}>
          <Image
            source={require('../../Support/Discover.png')}
            style={{width: 300, height: 225}}
          />
          <H3>Go Discover More Items!</H3>
        </View>
      )}
      {...{Vendors, Products, navigation, width}}
    />
  );
};

export default VendorsPage;
