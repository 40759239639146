// import library to help create a component
import React from 'react';
import {Image, View} from 'react-native';
import DataManager from '../../Support/DataManager';
import {VendorList, H3, Page} from 'bl-components';

const FavoriteVendors = ({navigation}) => {
  const {vendFavorites} = DataManager;
  const Vendors = vendFavorites
    .map(({vendorID}) =>
      DataManager.Vendors.find(
        (Vendor) => Vendor.index === vendorID,
      ),
    )
    .filter((Vendor) => typeof Vendor !== 'undefined');

  return (
    <Page>
      <VendorList
        key={0}
        Vendors={Vendors}
        onPress={(Vendor) => {
          DataManager.vendorClick(Vendor.index)
          const {Products, Coupons} = DataManager.GetPC(Vendor.index);
          navigation.push('Vendor', {
            Vendor: Vendor,
            Products,
            Coupons,
          });
        }}
        Footer={() => (
          <View style={{alignItems: 'center', marginTop: 10}}>
            <Image
              source={require('../../Support/Discover.png')}
              style={{width: 300, height: 225}}
            />
            <H3>Go Discover More Vendors!</H3>
          </View>
        )}
      />
    </Page>
  );
};

export default FavoriteVendors;
