import React, { useState } from "react";
import FullScreenModal from "./FullScreenModal";
import { useActiveWidth } from "../../Hooks/useActiveWidth";

const GeneralPopup = ({ initialState, buttons, color, content, deleteMe }) => {
  const width = useActiveWidth();
  const [state, setState] = useState(
    typeof initialState !== "undefined" ? initialState : {}
  );
  const [activeButtons, setActiveButtons] = useState(true);

  return (
    <FullScreenModal
      popup={true}
      exitButtons={
        typeof buttons === "undefined"
          ? [{ name: "Exit", callback: deleteMe }]
          : buttons.map((i) => ({
              name: i.text,
              callback: activeButtons
                ? async () => {
                    setActiveButtons(false);
                    if (typeof i.onPress !== "undefined") {
                      const retVal = await i.onPress(state);
                      // Don't exit the popup if the return value is false
                      if (typeof retVal !== "undefined" && !retVal) {
                        setActiveButtons(true);
                        return false;
                      }
                    }
                    setActiveButtons(true);
                    deleteMe();
                  }
                : () => null,
              color: i.color,
            }))
      }
      containerWidth={width}
    >
      <React.Fragment>
        {React.createElement(content, {
          state,
          setState: (newState) => setState({ ...state, ...newState }),
        })}
      </React.Fragment>
    </FullScreenModal>
  );
};

export default GeneralPopup;
