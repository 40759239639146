import { Platform, Linking } from "react-native";
import { Alert } from "./Popups/Popups";
import axios from "axios";

class DataManagerBase {
  constructor() {
    this.cred = { PHPSESSID: "", username: "" };
    this.resetApp = () => null;
    this.initialFetch = () => null;
  }
  // Gives failure alert corresponding to the type of error
  #failure = (response, failureMsg = null, auth = false) => {
    const msgs = [];
    if (
      typeof response.data.loggedin !== "undefined" &&
      response.data.loggedin
    ) {
      msgs.push("Error fetching data");
      msgs.push("Please login again to try again");
    } else {
      msgs.push("You are not logged in");
      msgs.push("Please login again to access your account");
      //msgs.push(JSON.stringify(response));
    }
    if (failureMsg === null) {
      Alert(msgs[0], msgs[1], auth ? () => null : this.resetApp);
    } else if (failureMsg !== "") {
      Alert(failureMsg, "", auth ? () => null : this.resetApp);
    }
    //console.log(response);
    //console.log(response.data);
  };
  networkError = (res) => {
    //Alert('Network Error', 'Check your network connection and try again ' + JSON.stringify(res) + '!!');
    Alert("Network Error", "Check your network connection and try again");
    // console.log(res);
    // console.log(res.data);
  };
  // Fetch Data takes a state handler and post object
  fetchData = (url, setData, success, fatal = true) => {
    axios
      .post(url, this.cred)
      .then((response) => {
        if (response.data.loggedin && response.data.success) {
          //console.log(response.data.result);
          setData(response.data.result);
          success(response);
        } else {
          if (fatal) this.#failure(response);
        }
      })
      .catch((response) => {
        //console.log("failed fetch " + url);
        if (fatal) {
          this.networkError(response);
          this.resetApp();
        }
      });
  };
  fetchDataAsync = async (url, fatal = true) => {
    try {
      const data = await new Promise((resolve, reject) => {
        this.fetchData(
          url,
          (result) => resolve(result),
          () => null,
          fatal
          //reject,
        );
      });
      return data;
    } catch (e) {
      return null;
    }
  };
  // returns true for bad version and false for good version
  isBadVersion = (version, minVersion) => {
    const splitVersion = version.split(".");
    const splitMinVersion = minVersion
      .toString()
      .split(".")
      .map((i) => parseInt(i, 10));
    if (splitVersion[0] < splitMinVersion[0]) {
      return true;
    }
    if (
      splitVersion[0] === splitMinVersion[0] &&
      splitVersion[1] < splitMinVersion[1]
    ) {
      return true;
    }
    if (
      typeof splitVersion[2] !== "undefined" &&
      splitVersion[0] === splitMinVersion[0] &&
      splitVersion[1] === splitMinVersion[1] &&
      splitVersion[2] < splitMinVersion[2]
    ) {
      return true;
    }
    return false;
  };
  checkAppStatus = (data) => {
    if ( !data.success ) return;
    if (this.isBadVersion(this.version, data.minVersion)) {
      const handlePress = async () => {
        try {
          const supported = await Linking.canOpenURL(this.appStoreUrl);
          if (supported) {
            await Linking.openURL(this.appStoreUrl);
          } else {
            Alert("Error", "Unable to open app store");
          }
        } catch (e) {
          console.log(e);
          Alert("Error", "Unable to open app store");
        }
      };
      Alert(
        "Update required",
        "Press ok to visit the app store to update your app!",
        handlePress
      );
      return false;
    }
    if (data.maintenance) {
      Alert(
        "App is under maintenance",
        "Sorry for the inconvenience, please try again later!"
      );
      return false;
    }

    return true;
  };
  // function for pushing data to server
  // takes a url, data object, success callback, always callback, success message callback, and failure
  pushData = (
    url,
    Data,
    success,
    always = () => null,
    msg = null,
    failure = () => null,
    failureMsg = null,
    cancelToken = null,
    auth = false,
    fatal = true
  ) => {
    //console.log("caller is " + this.pushData.caller);
    axios
      .post(url, { ...this.cred, ...Data }, { cancelToken })
      .then((response) => {
        //console.log((new Error()).stack)
        if (response.data.loggedin && response.data.success) {
          if (msg !== null) {
            Alert("Saved", msg, () => {
              success(response);
            });
          } else {
            success(response);
          }
        } else {
          failure(response);
          if (fatal) this.#failure(response, failureMsg, auth);
          //console.log(response.data);
        }
      })

      .catch((res) => {
        if (
          typeof res.message !== "undefined" &&
          res.message === "Image was unmounted"
        ) {
          console.log(res.message);
        } else {
          if (fatal) this.networkError(res);
          console.log("failed push " + url);
        }
      })
      .finally(always);
  };
  pushDataAsync = async (url, Data, fatal = true) => {
    try {
      const data = await new Promise((resolve, reject) => {
        this.pushData(
          url,
          Data,
          ({ data }) => resolve(data === null ? null : data.result),
          () => null,
          null,
          reject,
          null,
          null,
          false,
          fatal
        );
      });
      return data;
    } catch (e) {
      return null;
    }
  };

  Login = (password, always, success = () => null, failure = () => null) => {
    var postObj = {
      ...this.cred,
      password: password,
    };
    const successFull = async (response) => {
      this.cred.PHPSESSID = response.data.PHPSESSID;
      if (typeof response.data.marketID !== "undefined")
        this.cred.marketID = response.data.marketID;
      if (typeof response.data.availablePermissions !== "undefined")
        this.availablePermissions = response.data.availablePermissions;
      if (typeof response.data.permissions !== "undefined")
        this.permissions = response.data.permissions;
      if (typeof response.data.id !== "undefined") this.id = response.data.id;
      await this.initialFetch();
      success();
    };
    this.pushData(
      this.dataUrl + "Accounts/Login",
      postObj,
      successFull,
      always,
      null,
      failure,
      "Incorrect Username or Password",
      null,
      true
    );
  };

  getSessionBackground = async () => {
    const { data } = await axios.post(this.dataUrl + "Accounts/GetSession", {
      ...this.cred,
    });
    if (this.checkAppStatus(data)) {
      if (typeof data.availablePermissions !== "undefined")
        this.availablePermissions = data.availablePermissions;
      if (typeof data.permissions !== "undefined")
        this.permissions = data.permissions;
      if (typeof data.id !== "undefined") this.id = data.id;
      return data.loggedin;
    } else return false;
  };

  getSession = (success = () => null, always = () => null) => {
    this.pushData(
      this.dataUrl + "Accounts/GetSession",
      this.cred,
      (res) => {
        if (this.checkAppStatus(res.data)) {
          if (typeof res.data.permissions !== "undefined")
            this.permissions = res.data.permissions;
          if (typeof res.data.id !== "undefined") this.id = res.data.id;
          success();
          this.initialFetch();
        }
      },
      always,
      null,
      (res) => {
        this.checkAppStatus(res.data);
      },
      "",
      null,
      true
    );
  };

  Logout = () => {
    this.pushData(
      this.dataUrl + "Accounts/Logout",
      this.cred,
      () => null,
      (res) => {
        this.resetApp();
      },
      null,
      () => null,
      null,
      null,
      false,
      false
    );
  };
  downloadWeb = (url, data, fileName, success = () => null, always=()=>null) => {
    axios({
      url,
      method: "POST",
      responseType: "blob",
      data: { ...data, ...this.cred },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        success();
      })
      .catch((response) => this.networkError(response))
      .finally(always);
  };
  hasPermission = (group) =>
    this.permissions.indexOf("Owner") > 1 ||
    this.permissions.indexOf("Manager") > 1 ||
    this.permissions.indexOf(group) > 1;

  // Messaging
  getUnread = async () => {
    const url = `${this.messagingUrl}/unread`;
    const d = await this.pushDataAsync(url, {}, false);
    if ( typeof d !== 'object') return false;
    return d.data;
  };

  addName = (a) => a
  getConversations = async () => {
    const url = `${this.messagingUrl}/messaged`;
    const d = await this.pushDataAsync(url, {}, false);
    if (typeof d === 'undefined' || d === null) return [];
    return this.addName(d.data);
  };
  sendMessage = async (msg, theirId) => {
    const url = `${this.messagingUrl}/send`;
    await this.pushDataAsync(url, {
      reciever: theirId,
      msg,
    });
  };
  getMessages = async (theirId) => {
    const url = `${this.messagingUrl}/get`;
    const d = await this.pushDataAsync(url, {
      reciever: theirId,
    }, false);
    if (typeof d === 'undefined' || d === null) return [];
    return d.data;
  };
  messagingUrl =
    Platform.OS === 'web'
      ? `/messaging/`
      : `https://www.buylo.app/messaging/`;

}

export default DataManagerBase;
