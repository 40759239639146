import React from "react";
import {
  View,
  Platform,
  Animated,
  Easing,
  Pressable,
  StyleSheet,
  Dimensions,
} from "react-native";
import SvgIcon from "../Icons/SvgIcon";
import gS from "../GlobalStyle";
import Body from "../Text/Body";

const style = StyleSheet.create({
  tabView: {
    backgroundColor: "#fff", //fC
    flexDirection: "row",
    height: 90,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    justifyContent: "center",
    alignItems: "center",
  },
  overlineStyle: {
    width: 60,
    height: 2,
    borderRadius: 4,
    backgroundColor: gS.MainColor,
  },
  style1: { color: gS.MainColor, fontSize: 12 },
  style2: { color: "#999", fontSize: 12 },
  tabTouch: {
    flex: 1,
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

const TabBar = ({ index, names, setIndex, iconNames }) => {
  const { width } = Dimensions.get("window");
  const translateX = new Animated.Value(0);

  const activeTabIndex = index;
  const changePosition = (translate, start) => {
    Animated.timing(translateX, {
      toValue: translate,
      duration: 75,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== "web",
    }).start(start);
  };

  const icons = (iconName, text, focused) => (
    <View style={{ width: "100%", height: 90 }}>
      <View
        style={{
          width: "100%",
          //backgroundColor: focused
          //  ? 'rgba(208, 56, 40, 0.1)'
          //  : 'rgba(255, 255, 255, 0)',
          height: 60,
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        <Animated.View
          style={
            focused
              ? [
                  style.overlineStyle,
                  {
                    transform: [{ translateX }],
                    //width: (width / iconNames.length) * 0.5,
                    width: 50,
                  },
                ]
              : { height: 2 }
          }
        />
        <View
          style={{ height: 60, alignItems: "center", justifyContent: "center" }}
        >
          <SvgIcon
            color={focused ? gS.MainColor : "#999"}
            type="material-community"
            size={30}
            name={iconName}
          />
          <Body
            color={focused ? gS.MainColor : "#999"}
            style={{ fontSize: width > 600 ? 15 : 10 }}
            textAlign="center"
          >
            {text}
          </Body>
        </View>
      </View>
      <View
        style={{
          backgroundColor: "#fff",
          height: Platform.OS === "ios" ? 30 : 10,
          width: "100%",
        }}
      />
    </View>
  );

  return (
    <View style={[style.tabView, { position: "relative", bottom: 0, left: 0 }]}>
      {names.map((name, i) => {
        const label = name;

        const isFocused = activeTabIndex === i;

        const onPress = () => {
          changePosition(
            ((width - 50) / iconNames.length) * (i - index),
            () => {
              if (!isFocused) {
                setIndex(i);
              }
            }
          );
        };

        return (
          <Pressable
            key={i}
            accessibilityRole="button"
            accessibilityStates={isFocused ? ["selected"] : []}
            onPress={onPress}
            style={style.tabTouch}
          >
            {icons(iconNames[i], label, i === activeTabIndex)}
          </Pressable>
        );
      })}
    </View>
  );
};

export default TabBar;
