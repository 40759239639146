// import libraries for making the header component
import React, { useState } from "react";
import { SectionList, View } from "react-native";
import H1 from "../Text/H1";
import TextBox from "../Views/TextBox";
import gS from "../GlobalStyle";
import { useActiveWidth } from "../Hooks/useActiveWidth";
import { Grid, GridItem } from "bl-components";

// Create a functional component, returns some amount of jsx
// Class components have lifecycle methods and has states builtin
// set state updates a component and rerenders the screen

const ItemList = ({
  Items,
  onRefresh,
  itemWidth,
  children,
  passRef,
  Footer,
  ...props
}) => {
  const width = useActiveWidth();
  const [refreshing, setRefreshing] = useState(false);

  const renderItem = ({ item }) => (
    <Grid {...{ width }} around>
      {item.map((i, ind) => (
        <GridItem key={ind} width={itemWidth}>
          {i}
        </GridItem>
      ))}
    </Grid>
  );

  const rows = Items.map(({ title, data }) => {
    return { title, data: [data] };
  });
  return (
    <SectionList
      ref={passRef}
      refreshing={refreshing}
      onRefresh={
        typeof onRefresh !== "undefined"
          ? () => {
              setRefreshing(true);
              onRefresh();
            }
          : undefined
      }
      sections={rows}
      showsVerticalScrollIndicator={false}
      stickySectionHeadersEnabled={true}
      keyExtractor={(item, index) => JSON.stringify(item) + index}
      ListFooterComponent={
        typeof Footer === "undefined"
          ? () => <View style={{ height: 75 }} />
          : Footer
      }
      ListFooterComponentStyle={{ height: 75 }}
      renderItem={renderItem}
      renderSectionHeader={({ section: { title } }) => (
        <View style={gS.pageStyle}>
          <TextBox>
            <H1 textAlign="left">{title}</H1>
          </TextBox>
        </View>
      )}
      {...props}
    />
  );
};

export default ItemList;
