import React, {useState, Component} from 'react';

import DataManager from './Support/DataManager';

import {Platform} from 'react-native';

import Items from './Pages/Feed/Items';

import Item from './Pages/Vendors/Item';
import Vendor from './Pages/Vendors/Vendor';
import Vendors from './Pages/Vendors/Vendors';
import MyAccount from './Pages/MyAccount/MyAccount';
import Login from './Pages/MyAccount/Login';
import AccountSettings from './Pages/MyAccount/AccountSettings';
import ForgotPassword from './Pages/MyAccount/ForgotPassword';
import CreateAccount from './Pages/MyAccount/CreateAccount';
import FavoriteItems from './Pages/MyAccount/FavoriteItems';
import FavoriteVendors from './Pages/MyAccount/FavoriteVendors';
import Offers from './Pages/Offers/Offers';

import Page1 from './Pages/Welcome/Page1';
import Page2 from './Pages/Welcome/Page2';
import Page3 from './Pages/Welcome/Page3';

import Event from './Pages/Community/Event';
import Community from './Pages/Community/Community';
import NewsInfo from './Pages/Community/NewsInfo';
import ConcessionsInfo from './Pages/Concessions/ConcessionsInfo';
import Concessions from './Pages/Concessions/Concessions';
import Category from './Pages/Concessions/Category';
import {
  Window,
  Layout,
  useInitialURL,
  Loading,
  Stack,
  Tabs,
  Popups,
  Alert,
  DisableAndroidBack,
  Message,
  MessagePortal,
  Notifications,
  Notification,
} from 'bl-components';

import {isMobile} from 'react-device-detect';

import DrawerContents from './Pages/Drawer/Drawer';
import Help from './Pages/Drawer/Help';
import ReportProblem from './Pages/Drawer/ReportProblem';
import InviteFriends from './Pages/Drawer/InviteFriends';

const FeedStack = (props) => (
  <Stack analytics={(name) => DataManager.pageClick(name)}
    {...props}
    names={['Listings', 'Item', 'Vendor']}
    components={[Items, Item, Vendor]}
  />
);

const ConcessionsStack = (props) => (
  <Stack analytics={(name) => DataManager.pageClick(name)}
    {...props}
    names={['Concessions', 'Category', 'ConcessionsInfo']}
    components={[Concessions, Category, ConcessionsInfo]}
  />
);

const VendorsStack = ({appState, ...props}) => {
  const url = appState.url;
  var initialVendorIndex = -1;
  var initialItemIndex = -1;
  var initialIdentifier = -1;
  if (typeof url !== 'undefined' && url !== null) {
    const CGI = url.split('?');
    if (CGI.length > 1) {
      const vars = CGI[1].split('&');
      var varIndex = vars.findIndex((e) => !e.search('vendor'));
      if (varIndex > -1) {
        const keyvalue = vars[varIndex].split('=');
        if (keyvalue.length > 1) {
          const value = keyvalue[1];
          const vendorIndex = parseInt(value, 10);
          initialVendorIndex = isNaN(vendorIndex) ? -1 : vendorIndex;
        }
      }
      varIndex = vars.findIndex((e) => !e.search('item'));
      if (varIndex > -1) {
        const keyvalue = vars[varIndex].split('=');
        if (keyvalue.length > 1) {
          const value = keyvalue[1];
          const itemIndex = parseInt(value, 10);
          initialItemIndex = isNaN(itemIndex) ? -1 : itemIndex;
        }
      }
      varIndex = vars.findIndex((e) => !e.search('identifier'));
      if (varIndex > -1) {
        const keyvalue = vars[varIndex].split('=');
        if (keyvalue.length > 1) {
          const value = keyvalue[1];
          const identifier = parseInt(value, 10);
          initialIdentifier = isNaN(identifier) ? -1 : identifier;
        }
      }
    }
  }
  const initialStack = {stack: ['Vendors'], params: [{}]};
  if (initialVendorIndex > -1) {
    const vendorsArrayIndex = DataManager.Vendors.findIndex(
      (e) => parseInt(e.index, 10) === initialVendorIndex,
    );
    if (vendorsArrayIndex > -1) {
      const Products = DataManager.Items.filter(
        (i) =>
          parseInt(i.vendorIndex, 10) === initialVendorIndex && i.price >= 0,
      );
      const Coupons = DataManager.Items.filter(
        (i) =>
          parseInt(i.vendorIndex, 10) === initialVendorIndex && i.price < 0,
      );
      initialStack.stack.push('Vendor');
      initialStack.params.push({
        Vendor: DataManager.Vendors[vendorsArrayIndex],
        Products,
        Coupons,
      });

      if (initialItemIndex > -1) {
        const Item = DataManager.Items.find(
          (i) =>
            parseInt(i.vendorIndex, 10) === initialVendorIndex &&
            parseInt(i.id, 10) === initialItemIndex &&
            parseInt(i.identifier, 10) === initialIdentifier,
        );
        if (typeof Item === 'undefined') {
          Alert(
            'Product Not Found',
            'Sorry, the product you requested is not currently active at this market',
          );
        } else {
          initialStack.stack.push('Item');
          initialStack.params.push({Item});
        }
      }
    } else {
      Alert(
        'Vendor Not Found',
        'Sorry, the vendor you requested is not active at this market',
      );
    }
  }

  return (
    <Stack analytics={(name) => DataManager.pageClick(name)}
      key={url + initialStack.stack.length}
      {...{appState, initialStack}}
      {...props}
      names={['Vendors', 'Vendor', 'Item']}
      components={[Vendors, Vendor, Item]}
    />
  );
};

const CommunityStack = (props) => (
  <Stack analytics={(name) => DataManager.pageClick(name)}
    {...props}
    names={['Community', 'Information', 'Event']}
    components={[Community, NewsInfo, Event]}
  />
);

const WelcomeStack = ({loadMarket, ...props}) => (
  <Stack analytics={(name) => DataManager.pageClick(name)}
    {...props}
    names={['Page1', 'Page2', 'Select Market']}
    components={[
      Page1,
      Page2,
      (params) => <Page3 {...params} {...{loadMarket}} />,
    ]}
  />
);

const OffersStack = (props) => (
  <Stack analytics={(name) => DataManager.pageClick(name)}
    {...props}
    names={['Offers', 'Item', 'Vendor']}
    components={[Offers, Item, Vendor]}
  />
);

export var drawerNav = () => null;
const titles = ['Vendors', 'Listings', 'Community', 'Concessions', 'Offers'];
const iconNames = [
  'store-outline',
  'world',
  'account-group-outline',
  'food',
  'money',
];

const components = [
  VendorsStack,
  FeedStack,
  CommunityStack,
  ConcessionsStack,
  OffersStack,
];
const tabs = {components, titles, iconNames};
const TabsTmp = ({url, appState, events, ...otherProps}) => (
  <Tabs analytics={(name) => DataManager.pageClick(name)} {...tabs} {...{appState, events}} {...otherProps} />
);

const TabsUrl = ({appState, url: origURL, ...otherProps}) => {
  return (
    <Stack analytics={(name) => DataManager.pageClick(name)}
      {...{appState}}
      {...otherProps}
      setNavigation={(handle) => {
        drawerNav = handle;
        DataManager.drawer1Update();
        DataManager.drawer2Update();
      }}
      updateHeader
      header
      names={[
        'tabs',
        'Loading',
        'My Account',
        'Shopping List',
        'My Vendors',
        'Forgot Password',
        'Create Account',
        'Log In',
        'Account Settings',
        'Vendor',
        'Item',
        'Help',
        'Change Local Market',
        'Select Market',
        'Report a Problem',
        'Invite Friends',
        'Notification',
        'Message Portal',
        'Message',
      ]}
      components={[
        TabsTmp,
        Loading,
        MyAccount,
        FavoriteItems,
        FavoriteVendors,
        ForgotPassword,
        CreateAccount,
        Login,
        AccountSettings,
        Vendor,
        Item,
        Help,
        Page2,
        (params) => <Page3 {...params} loadMarket={(marketID) => {
          DataManager.initialFetchNoAccount(marketID, DataManager.resetApp);
        }} />,
        ReportProblem,
        InviteFriends,
        Notification,
        MessagePortal,
        Message,
      ]}
    />
  );
};

class RouterComponentLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseMarket: true,
      loadedMarket: false,
      loadedMarkets: false,
      update: 0,
      url: this.props.url,
    };
  }
  componentDidMount() {
    DataManager.fetchMarkets(
      () => this.setState({loadedMarkets: true}),
      () =>
        this.setState({
          loadedMarkets: true,
          chooseMarket: false,
          loadedMarket: true,
        }),
    );
    DataManager.routerUpdate = () =>
      this.setState({update: this.state.update + 1});
  }

  render() {
    const injectURL = (newUrl) => this.setState({url: newUrl});
    this.props.appState.events.injectURL = injectURL;
    this.props.appState.url = this.state.url;
    return this.state.loadedMarket ? (
      <Layout
        key={this.state.url}
        initialIndex={
          typeof this.state.url === 'string' &&
          this.state.url.split('?').length > 1
            ? 0
            : 2
        }
        {...{tabs}}
        getNavigation={() => drawerNav}
        getUnread={DataManager.loggedin ? DataManager.getUnread : null}
        sendMessage={DataManager.loggedin ? DataManager.sendMessage : null}
        getMessages={DataManager.loggedin ? DataManager.getMessages : null}
        getConversations={
          DataManager.loggedin ? DataManager.getConversations : null
        }
        onInactiveMessaging={
          DataManager.loggedin
            ? null
            : () =>
                drawerNav.navigate('Log In', {}, drawerNav.maxStackLength > 1)
        }
        onNotificationPress={
          DataManager.loggedin
            ? null
            : () =>
                drawerNav.navigate('Log In', {}, drawerNav.maxStackLength > 1)
        }
        leftDrawer={DrawerContents}
        RightDrawerComponent={(props) => (
          <Notifications
            {...props}
            ackNotif={DataManager.ackNotif}
            onRefresh={async (done) => {
              await DataManager.fetchNotifications();
              done();
            }}
            getNotifications={() => DataManager.Notifications}
            getNavigation={() => drawerNav}
          />
        )}
        appState={this.props.appState}
      >
        <TabsUrl url={this.props.url} />
      </Layout>
    ) : this.state.loadedMarkets && this.state.chooseMarket ? (
      <WelcomeStack
        dim={this.props.appState.dim}
        loadMarket={(marketID) => {
          DataManager.initialFetchNoAccount(marketID, () =>
            this.setState({loadedMarket: true}),
          );
          this.setState({chooseMarket: false});
        }}
      />
    ) : (
      <Loading />
    );
  }
}

const RouterPopups = () => {
  const [refreshApp, setRefreshApp] = useState(0);
  DataManager.appUpdate = () => setRefreshApp(refreshApp + 1);
  const url = useInitialURL();
  const [tryApp, setTryApp] = useState(Platform.OS === 'web' && isMobile);
  if (url !== null) {
    if (tryApp) {
      const splitUrl = url.split('/');
      window.location.assign(
        'BuyLo://' + splitUrl.slice(3, splitUrl.length).join('/'),
      );
      setTryApp(false);
    }
  }

  if (tryApp) return <Loading />;
  else
    return (
      <Window key={refreshApp + url} style={{backgroundColor: '#fff'}}>
        <RouterComponentLoading {...{url}} />
        <DisableAndroidBack />
        <Popups />
      </Window>
    );
};

export default RouterPopups;
