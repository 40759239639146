// import libraries for making the header component
import React from "react";
import { View } from "react-native";

const Sep = (props) => {
  const style = { height: 20 };
  return <View style={style} />;
};

export default Sep;
