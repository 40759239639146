// import libraries for making the header component
import React from "react";
import { Text } from "react-native";
import gS from "../GlobalStyle.js";

const BaseText = ({
  children,
  wrap,
  white,
  navy,
  red,
  blue,
  green,
  grey,
  textAlign,
  style,
  lines,
  thin,
  color,
  bold,
  maxLength,
  ...componentProps
}) => {
  // Come up with better way to handle network error
  if (typeof red !== "undefined") {
    style.push({ color: "#B10000" });
  }
  if (typeof navy !== "undefined") {
    style.push({ color: "#1E3B66" });
  }
  if (typeof blue !== "undefined") {
    style.push({ color: gS.darkBlue });
  }
  if (typeof grey !== "undefined") {
    style.push({ color: gS.darkGrey });
  }
  if (typeof white !== "undefined") {
    style.push({ color: gS.white });
  }
  if (typeof green !== "undefined") {
    style.push({ color: gS.darkGreen });
  }
  if (typeof wrap !== "undefined") {
    style.push({ flexWrap: "wrap" });
  }
  if (typeof textAlign !== "undefined") {
    style.push({ textAlign });
  }
  if (typeof thin !== "undefined") {
    style.push(gS.BaseTextThin);
  }
  if (typeof bold !== "undefined") {
    style.push({ fontWeight: "600" });
  }
  if (typeof color !== "undefined") {
    style.push({ color });
  }
  var max_length = 1000000;
  if (typeof maxLength !== "undefined") {
    max_length=maxLength-3;
  }

  return (
    <Text
      {...componentProps}
      selectable={true}
      maxFontSizeMultiplier={1.1}
      numberOfLines={typeof lines === "undefined" ? null : lines}
      style={[gS.BaseText, style]}
    >
      {(typeof children === 'string' && max_length<children.length)?children.substring(0,max_length)+"...":children}
    </Text>
  );
};

export default BaseText;
