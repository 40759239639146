// import library to help create a component
import React, {Component} from 'react';
import {View} from 'react-native';
import DataManager from '../../../Support/DataManager';

import {
  PageScrollMB,
  Carousel,
  Container,
  H2,
  H3,
  Body,
  Button,
  Tags,
  DirectionMap,
  DropOver,
  SvgIcon,
  MapPopup,
} from 'bl-components';
import DirMap from './DirMap';

function timeConvert(string) {
  let time = string.split(':');
  let hour = parseInt(time[0], 10);
  let min = parseInt(time[1], 10);
  return hour > 12
    ? hour - 12 + ':' + (min === 0 ? '00' : min) + ' p.m.'
    : hour + ':' + (min === 0 ? '00' : min) + ' a.m.';
}

function dayConvert(string) {
  let dayIndex = parseInt(string, 10);
  let dayArray = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return dayArray[dayIndex];
}

function itemOpen(object) {
  let day = parseInt(object.day, 10);
  let open = object.open.split(':');
  let openHour = parseInt(open[0], 10);
  let openMin = parseInt(open[1], 10);
  let close = object.close.split(':');
  let closeHour = parseInt(close[0], 10);
  let closeMin = parseInt(close[1], 10);
  let now = new Date();
  return now.getDay() === day &&
    now.getHours() + now.getMinutes() / 60.0 > openHour + openMin / 60.0 &&
    now.getHours() + now.getMinutes() / 60.0 < closeHour + closeMin / 60.0
    ? 1
    : 0;
}
function isOpen() {
  return DataManager.Community.hours.map(itemOpen).reduce((a, b) => a + b, 0) >
    0
    ? true
    : false;
}

class Welcome extends Component {
  state = {open: isOpen()};
  constructor() {
    super();
    this.timer = null;
  }
  componentDidMount() {
    const {open} = this.state;
    let timer = setInterval(
      () => (open !== isOpen() ? this.setState({open: !open}) : null),
      10000,
    );
    this.timer = timer;
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {open} = this.state;

    const {images, tags, market, description, lon, lat} = DataManager.AboutUs;
    return (
      <PageScrollMB width={this.props.appState.dim.tabletWidth}>
        <View style={{backgroundColor: '#FAFAFA'}}>
          <View style={{height: 50, zIndex: 100, marginBottom: 5}}>
            <DropOver
              arrowLabel="Hours"
              label={() => (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <H3 style={{color: open ? '#1ac609' : 'red'}}>
                    Market is {open ? 'Open' : 'Closed'}
                  </H3>
                </View>
              )}
            >
              {DataManager.Community.hours.map((item) => (
                <View
                  key={JSON.stringify(item)}
                  style={{
                    marginTop: 10,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <H3 textAlign={'center'}>{dayConvert(item.day)}</H3>
                  <H3 textAlign={'center'}>
                    {timeConvert(item.open)} - {timeConvert(item.close)}
                  </H3>
                </View>
              ))}
            </DropOver>
          </View>
          <Carousel
            width={this.props.appState.dim.tabletWidth}
            images={images}
          />
          <Container>
            <H2 bold>{market}</H2>
            <View style={{height: 5}} />
            <Body>{description}</Body>
            <View style={{height: 10}} />
            <Tags {...{tags}} />
          </Container>
          <View style={{margin: 20}}>
            <H2 bold>Need Help Around the Market?</H2>
          </View>
          <View style={{alignItems: 'center', marginBottom: 10}}>
            <Button
              style={{width: 300}}
              gold
              onPress={MapPopup(this.props.appState, DataManager.Map)}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SvgIcon color="black" size={18} name="map" />
                <View style={{width: 10}} />
                <H3 bold>Market Map</H3>
              </View>
            </Button>
          </View>
          <Container>
            <H3 bold>Our Location</H3>
            <View style={{height: 5}} />
            <DirMap />
          </Container>
          <DirectionMap width={this.props.appState.dim.tabletWidth} {...{lon,lat}} />
        </View>
      </PageScrollMB>
    );
  }
}

export default Welcome;
