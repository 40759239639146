// import libraries for making the header component
import React from "react";

import { Platform, ScrollView, View } from "react-native";

//    causing problems on safari...
//    keyboardDismissMode="on-drag"
const PageScrollMB = ({
  width,
  children,
  passRef,
  footerHeight,
  ...otherProps
}) => (
  <ScrollView
    ref={passRef}
    {...otherProps}
    showsVerticalScrollIndicator={Platform.OS === "web"}
    style={{
      // backgroundColor: "#fafafa",
      backgroundColor: "#fff",
    }}
    contentContainerStyle={{
      //width: Platform.OS === "web" ? undefined : width,
      alignItems: "center",
      overflow: "hidden",
    }}
  >
    <View
      style={{
        width,
      }}
    >
      <View
        style={{
          overflow: "hidden",
          flex: 1,
          //width: width - 5 * (Platform.OS === "web"),
          width: width,
        }}
      >
        {children}
      </View>
    </View>
    <View
      style={{
        height:
          typeof footerHeight !== "undefined" && footerHeight !== null
            ? footerHeight
            : 75,
      }}
    />
  </ScrollView>
);

export default PageScrollMB;
