// import library to help create a component
import React, { Component } from "react";
import { Platform, View, TextInput, Text } from "react-native";
import { SvgButton } from 'bl-components'
import gS from "../GlobalStyle.js";

const style1 = {
  textInputView: {
    backgroundColor: "#fff",
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderColor: "#CECECE",
  },
};

const style2 = {
  textInputView: {
    backgroundColor: "#fff",
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    margin: 10,
    borderWidth: 1,
    borderColor: "#CECECE",
  },
};

class InputField extends Component {
  constructor(props) {
    super(props);
    const { defaultString, initialString } = this.props;
    var initial;
    if (
      typeof initialString !== "undefined" &&
      typeof defaultString === "undefined"
    ) {
      initial = initialString;
    } else if (
      typeof initialString !== "undefined" &&
      typeof defaultString !== "undefined" &&
      initialString === ""
    ) {
      initial = defaultString;
    } else if (
      typeof initialString !== "undefined" &&
      typeof defaultString !== "undefined"
    ) {
      initial = initialString;
    } else if (
      typeof initialString === "undefined" &&
      typeof defaultString !== "undefined"
    ) {
      initial = defaultString;
    } else {
      initial = "";
    }

    this.state = {
      value: initial,
      selected: 0,
    };
  }

  render() {
    const { value, selected } = this.state;
    const {
      noShowLength,
      maxLength,
      onFocus,
      onEndEditing,
      onBlur,
      onChangeText,
      onChangeTextTransform,
      passRef,
      focusValue,
      ...otherProps
    } = this.props;
    var { defaultString } = this.props;
    defaultString = typeof defaultString === "undefined" ? "" : defaultString;
    const selectedStyle = {
      backgroundColor: "#F6FAFF",
      borderColor: "#2F80ED",
    };

    return (
      <View
        style={[
          typeof this.props.mult === "undefined"
            ? style1.textInputView
            : style2.textInputView,
          {
            height: typeof this.props.mult !== "undefined" ? 240 : 40,
            flexDirection:
              typeof this.props.mult !== "undefined" ? "column" : "row",
            alignItems:
              typeof this.props.mult !== "undefined" ? "flex-start" : "center",
          },
          selected ? selectedStyle : null,
        ]}
      >
        <TextInput
          ref={passRef}
          style={{
            ...gS.inputText,
            height: typeof this.props.mult !== "undefined" ? 200 : 45,
            textAlign: "left",
            textAlignVertical: "top",
            width: "100%",
            color: value === defaultString ? gS.darkGrey : gS.black,
          }}
          multiline={typeof this.props.mult !== "undefined"}
          autoCapitalize={"sentences"}
          maxFontSizeMultiplier={1.0}
          maxLength={maxLength}
          {...otherProps}
          value={value}
          onFocus={(e) => {
            if (Platform.OS === "web") {
              e.preventDefault();
              e.nativeEvent.preventDefault();
              //window.scrollBy(0, 0);
              //document.body.scrollTop = 0;
            }
            if (typeof onFocus !== "undefined") {
              onFocus();
            }
            if (value === defaultString) {
              this.setState({
                value: typeof focusValue === "undefined" ? "" : focusValue,
                selected: 1,
              });
            } else {
              this.setState({ selected: 1 });
            }
          }}
          onChangeText={(text) => {
            if (typeof onChangeText !== "undefined") onChangeText(text);
            this.setState({
              value:
                typeof onChangeTextTransform !== "undefined"
                  ? onChangeTextTransform(text)
                  : text,
            });
          }}
          onBlur={
            typeof onBlur === "undefined"
              ? () => {
                  if (value === "") {
                    this.setState({ value: defaultString, selected: 0 });
                  } else {
                    this.setState({ selected: 0 });
                  }
                  onEndEditing(value);
                }
              : () => onBlur(value, (state) => this.setState(state))
          }
        />
        {typeof this.props.eye !== "undefined" ? (
            <View style={{left: -20}}>
            <SvgButton onPress={this.props.eye} size={25} name={this.props.secureTextEntry ? 'eye' : 'no-eye'} color='#aaa' />
            </View>
        ) : null}
        {typeof maxLength !== "undefined" &&
        typeof noShowLength === "undefined" ? (
          <Text
            style={{
              marginLeft: 5,
              alignSelf:
                typeof this.props.mult !== "undefined" ? "flex-end" : "center",
              ...gS.H4,
              color: gS.darkGrey,
            }}
          >
            {value === this.props.defaultString ? 0 : value.length}/{maxLength}
          </Text>
        ) : null}
      </View>
    );
  }
}

//            onEndEditing={() => {
//              onEndEditing(value);
//              if (value === '') {
//                this.setState({value: defaultString, selected: 0});
//                onEndEditing(defaultString);
//              } else {
//                this.setState({selected: 0});
//                onEndEditing(value);
//              }
//            }}

export default InputField;
