import React, { useState, useEffect } from "react";
import { View } from "react-native";
import H2 from "../Text/H2";
import gS from "../GlobalStyle";
import Sep3 from "../Views/Sep3";

export var setTopRightButton = () => null;
export var setTopLeftButton = () => null;

export const TopBar = ({ name, children, leftButton, rightButton, height }) => {
  const [lb, setLB] = useState(
    typeof leftButton === "undefined" ? () => null : leftButton
  );
  const [rb, setRB] = useState(
    typeof rightButton === "undefined" ? () => null : rightButton
  );

  useEffect(() => {
    setTopRightButton = (rb) => setRB(rb);
    setTopLeftButton = (lb) => setLB(lb);
    return () => {
      setTopRightButton = () => null;
      setTopLeftButton = () => null;
    };
  }, [setRB, setLB, rb, lb]);

  return (
    <>
      <View
        style={{
          paddingTop: height - 50,
          height,
          width: "100%",
          backgroundColor: gS.white,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: 50,
            width: 90,
            //alignItems: "center",
            justifyContent: "center",
          }}
        >
          {lb}
        </View>
        <View
          style={{
            height: 50,
            backgroundColor: gS.white,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <H2 color="#3872BB">{name}</H2>
        </View>
        <View
          style={{
            height: 50,
            width: 90,
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          {rb}
        </View>
      </View>
      <Sep3 />
    </>
  );
};
