import AsyncStorage from '@react-native-async-storage/async-storage';

import {Alert} from 'react-native';

class PermStorage {
  constructor() {
    this.cred = {PHPSESSID: -1, username: ''};
  }
  getCred = async (callback) => {
    var storedData = await this.getData('@cred');
    if (storedData != null) {
      this.cred = storedData;
      await callback(this.cred);
    }
  };
  storeCred = (cred) => this.storeData('@cred', cred);
  getAllKeys = async () => {
    let keys = [];
    try {
      keys = await AsyncStorage.getAllKeys();
    } catch (e) {
      // read key error
    }
    console.log('Keys: ' + keys);
    // example console.log result:
  };
  getData = async (key) => {
    try {
      const jsonValue = await AsyncStorage.getItem(key);
      //return jsonValue
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      Alert.alert('Failed to retrieve saved data', '', [{text: 'Ok'}]);
      return null;
      //null
    }
  };
  storeData = async (key, value) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
      Alert.alert('Failed to pernamently save data', '', [{text: 'Ok'}]);
      return null;
    }
  };
}

var PS = new PermStorage();
export default PS;
