// import libraries for making the header component
import React, { Component } from "react";
import { Image, View } from "react-native";
import { CancelToken } from "axios";
import ImageManager from "./ImageManager";

const noImage = require("../Items/NewItem/NoImage.png");
class ImageLoading extends Component {
  constructor(props) {
    super(props);
    const { source } = this.props;
    // This search should be shortened to a substring
    var initial = noImage;
    var fetch = true;
    if (typeof source.uri === "string" && source.uri === "") fetch = false;
    if (
      typeof source.uri === "string" &&
      (source.uri.search("data:image/jpeg;base64,") !== -1 ||
        source.uri.search("data:image/png;base64,") !== -1)
    ) {
      initial = { uri: source.uri };
    } else if (typeof source.uri === "number") {
      initial = source.uri;
    } else if (typeof source !== "object") {
      initial = source;
    }
    // if we pass in a required image as source

    this.state = { uri: initial, fetch };
    this.modSource = noImage;
    this.cancelTokenSource = CancelToken.source();
  }
  componentDidMount() {
    const { source } = this.props;
    if (
      this.state.fetch &&
      typeof source.uri === "string" &&
      this.state.uri === noImage
    ) {
      ImageManager.getImage(
        source.uri,
        (newuri) => {
          if (newuri === "") {
            this.setState({ fetch: false });
          } else {
            this.setState({ uri: { uri: newuri }, fetch: false });
          }
        },
        this.cancelTokenSource.token
      );
    }
  }
  componentDidUpdate() {}

  /*
  componentDidMount() {
    const {source} = this.props;
    const {uri} = this.state;
    if (
      typeof source.uri === 'string' &&
      uri === this.noImage &&
      typeof source.uri !== 'undefined'
    ) {
      DataManager.getImage(source.uri, (newuri) => this.setUri(newuri));
    } else if (
      typeof uri !== 'string' ||
      uri.search('data:image/jpeg;base64,') === -1
    ) {
      this.setState({modSource: uri});
    } else {
      this.setState({modSource: uri});
    }
  }
  */
  componentWillUnmount() {
    this.cancelTokenSource.cancel("Image was unmounted");
    //console.log('unmounted')
  }
  render() {
    const { style } = this.props;

    return (
      <View style={style}>
        <Image source={this.state.uri} style={style} resizeMode={"cover"} />
      </View>
    );
  }
}
//return (
//  <View style={style}>
//    {uri === '' ? (
//      <LoadingView />
//    ) : (
//      <Image source={this.state.uri} style={style} resizeMode={'cover'} />
//    )}
//  </View>
//);

//const ImageLoading = ({source, style}) => {
//  const [loadingStart, setLoadingStart] = useState(false);
//  const [loadingEnd, setLoadingEnd] = useState(false);
//  const [mytimeout, setMyTimeout] = useState(null);
//  // useEffect(() => {
//  //   return () => {
//  //     clearTimeout(mytimeout);
//  //     setMyTimeout(null)
//  //   };
//  // }, [clearTimeout, mytimeout]);
//
// autoloading and caching
//  return (
//    <View style={style}>
//      <Image
//        source={source}
//        style={style}
//        resizeMode={'cover'}
//        onLoadStart={() => {
//          //setMyTimeout(setTimeout(() => setLoadingStart(true), 100));
//          setLoadingStart(true);
//        }}
//        onLoadEnd={() => {
//          setLoadingEnd(true);
//        }}
//      />
//      {loadingStart && !loadingEnd && <LoadingView />}
//    </View>
//  );
//};

export default ImageLoading;
