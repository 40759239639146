// import library to help create a component
import React, {useState} from 'react';
import {Platform, Pressable, View, FlatList} from 'react-native';
import DataManager from '../../Support/DataManager';
import {drawerNav} from '../../Router';

import {TextBox, H1, gS, ImageLoading, CouponDetail, Body} from 'bl-components';

const RenderItem = ({width, item: {coupon, vendor}, navigation}) => (
  <View
    style={{
      alignItems: 'center',
    }}
  >
    <View
      style={{
        width,
      }}
    >
      <View
        style={{
          width,
          height: 50,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <Pressable
          onPress={() => {
            DataManager.vendorClick(vendor.index)
            const Vendor = vendor;
            const {Products, Coupons} = DataManager.GetPC(Vendor.index);
            navigation.push('Vendor', {
              Vendor,
              Products,
              Coupons,
            });
          }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: width - 65,
          }}
        >
          <View style={{width: 20}} />
          <ImageLoading
            source={{uri: vendor.image}}
            style={{width: 40, height: 40, borderRadius: 1000}}
          />
          <View style={{width: width - 90, marginLeft: 10}}>
            <Body lines={1}>{vendor.name}</Body>
          </View>
        </Pressable>
      </View>
      <View
        style={{
          width,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <CouponDetail
          Item={coupon}
          userIndex={DataManager.id}
          onRedeem={() => {
            if (!DataManager.loggedin) {
              drawerNav.navigate('Log In');
              return false;
            }
            return true;
          }}
        />
      </View>
    </View>
  </View>
);
const VendorItemsQuick = ({width, all, navigation, VendorCoupons, Footer}) => {
  const [refreshing, setRefreshing] = useState(false);
  return (
    <FlatList
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        DataManager.fetch(() => setRefreshing(false));
      }}
      data={VendorCoupons}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      stickySectionHeadersEnabled={true}
      keyExtractor={(item, index) => JSON.stringify(item) + index}
      ListHeaderComponent={() => {
        const num = VendorCoupons.length;
        return (
          <View
            style={{
              ...gS.pageStyle,
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                width: width - 5 * (Platform.OS === 'web'),
              }}
            >
              <TextBox>
                <H1 textAlign="left">
                  {num +
                    (typeof all === 'undefined' || all
                      ? ' total coupon'
                      : ' result') +
                    (num !== 1 ? 's' : '')}
                </H1>
              </TextBox>
            </View>
          </View>
        );
      }}
      ListFooterComponent={Footer}
      renderItem={({item}) => <RenderItem {...{width, navigation, item}} />}
    />
  );
};

export default VendorItemsQuick;
