import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import H3 from "../Text/H3";
import ImageLoading from "./ImageLoading";

const Carousel = ({ images, width }) => {
  const [selected, setSelected] = useState(0);
  const [compWidth, setCompWidth] = useState(
    typeof width === "undefined" ? -1 : width
  );
  const w = compWidth < 0 ? 280 : compWidth;
  return (
    <View
      onLayout={(event) => {
        if (compWidth < 0) {
          setCompWidth(event.nativeEvent.layout.width);
        }
      }}
    >
      <View style={{ width: w, height: (w * 3) / 4 }}>
        <ImageLoading
          key={selected}
          source={{ uri: images[selected] }}
          style={{ width: w, height: (w * 3) / 4 }}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            width: w / 2,
            height: (w * 3) / 4,
          }}
          onPress={() =>
            setSelected(selected > 0 ? selected - 1 : images.length - 1)
          }
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            left: w / 2,
            width: w / 2,
            height: (w * 3) / 4,
          }}
          onPress={() =>
            setSelected(selected < images.length - 1 ? selected + 1 : 0)
          }
        />
      </View>
      {images.length > 1 ? (
        <H3 style={{ width: "100%", textAlign: "right" }}>
          {selected + 1}/{images.length}
        </H3>
      ) : null}
    </View>
  );
};

export default Carousel;
