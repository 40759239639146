import React from "react";
import { View, Pressable } from "react-native";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import gS from "../GlobalStyle";

// children are flexed row
const RadioSelect = ({ children, selected, onPress, height, width }) => {
  const styles = {
    pressable: {
      alignItems: "center",
      height: typeof height === "undefined" ? 50 : height,
      flexDirection: "row",
    },
    circleArea: {
      marginRight: 10,
      width: 20,
      height: 20,
    },
    innerCircle: {
      top: 5,
      left: 5,
      position: "absolute",
      width: 10,
      height: 10,
      zIndex: 2,
      borderRadius: 10,
      backgroundColor: selected ? gS.darkBlue : gS.white,
    },
    whiteCircle: {
      top: 2,
      left: 2,
      position: "absolute",
      width: 16,
      height: 16,
      zIndex: 1,
      borderRadius: 20,
      backgroundColor: gS.white,
    },
    outerCircle: {
      top: 0,
      left: 0,
      position: "absolute",
      width: 20,
      height: 20,
      zIndex: 0,
      borderRadius: 10,
      backgroundColor: gS.black,
    },
    childrenArea: {
      height: typeof height === "undefined" ? 25 : height,
      justifyContent: "center",
      width,
    },
  };
  return (
    <Pressable onPress={onPress} style={styles.pressable}>
      <View style={styles.circleArea}>
        <View style={styles.innerCircle} />
        <View style={styles.whiteCircle} />
        <View style={styles.outerCircle} />
      </View>
      <View style={styles.childrenArea}>
        {typeof body === "undefined" ? (
          <H3>{children}</H3>
        ) : (
          <Body>{children}</Body>
        )}
      </View>
    </Pressable>
  );
};

export default RadioSelect;
