// import library to help create a component
import React from "react";
import { View, TouchableOpacity } from "react-native";
import H3 from "../Text/H3";
import Body from "../Text/Body";
import gS from "../GlobalStyle";

const Button = ({
  style,
  green,
  grey,
  black,
  blue,
  onPress,
  children,
  body,
  underline,
  nocenter,
  color,
}) => {
  var textColor;
  if (typeof grey !== "undefined" && grey) {
    textColor = gS.darkGrey;
  } else if (typeof black !== "undefined" && black) {
    textColor = gS.black;
  } else if (typeof blue !== "undefined" && blue) {
    textColor = gS.darkBlue;
  } else if (typeof green !== "undefined" && green) {
    textColor = gS.green;
  } else {
    textColor = gS.red;
  }
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
    >
      <View
        style={{
          borderBottomWidth: typeof underline === "undefined" ? 0 : 1,
          borderColor: typeof color !== "undefined" ? color : textColor,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {typeof body === "undefined" ? (
          <H3
            style={{
              color: typeof color !== "undefined" ? color : textColor,
              ...style,
            }}
          >
            {children}
          </H3>
        ) : (
          <Body
            style={{
              color: typeof color !== "undefined" ? color : textColor,
              ...style,
            }}
          >
            {children}
          </Body>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default Button;
