import React, {useState} from 'react';
import {View, Pressable} from 'react-native';

import {
  Button,
  useActiveWidth,
  H1,
  Body,
  InputField,
  PageScrollMB,
} from 'bl-components';

import Svg, {Path, Rect} from 'react-native-svg';

const TopPart = () => (
  <Svg
    width="374"
    height="336"
    viewBox="0 0 374 336"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M-48.321 43.25L87.5 -134.757L223.321 43.25H-48.321Z"
      stroke="#E5E5E5"
      strokeWidth="10"
    />
    <Path
      transform="translate(216,0)"
      d="M10.679 80.25L146.5 -97.7573L282.321 80.25H10.679Z"
      stroke="#E5E5E5"
      strokeWidth="10"
    />
    <Rect
      viewBox="0 0 70 59"
      x="305"
      y="207"
      width="70"
      height="59"
      rx="5"
      fill="#E7E7E7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8049 72.4477C67.8049 72.4477 79.442 74.0381 79.7044 80.6721C79.8393 84.084 76.6155 87.8852 78.2564 88.8815C84.6693 92.7764 77.8604 94.6462 81.946 97.805C88.0228 102.504 84.6192 110.916 78.321 110.545C71.7969 110.161 75.1644 119.306 63.8828 116.986C57.5243 115.679 55.6439 114.338 51.0977 112.231C46.552 110.124 43.4701 98.4906 48.8244 92.5782C52.1507 88.838 55.4387 89.1021 57.1736 82.9858C58.9086 76.8699 60.6387 73.1029 63.6556 73.5156C64.7054 71.9177 67.8049 72.4477 67.8049 72.4477Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.9788 86.6275C75.0065 86.4992 75.1845 86.4864 75.2307 86.6099C75.6354 87.7011 76.6149 90.6502 75.4521 90.8273C74.1404 91.0273 74.7244 87.8026 74.9788 86.6275Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5291 95.9728C65.6392 90.778 65.7899 88.7073 65.7899 88.7073L72.1427 91.3721L71.7727 96.9875C71.6364 99.0546 69.8162 100.598 67.7543 100.396C65.5742 100.181 64.0712 98.1151 64.5291 95.9728Z"
      fill="#F4B784"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.6329 79.4877C67.6329 79.4877 68.2978 77.3858 69.7647 78.306C70.8132 78.9639 70.5117 78.3926 70.7086 77.8248C71.4386 75.7168 72.1179 80.5284 75.4468 81.4108C75.6446 83.9926 76.1587 93.3362 70.5583 93.1301C68.7495 93.0638 65.6927 92.1765 64.402 89.0889C63.622 87.2234 64.0843 85.241 64.8252 84.8139C67.2655 83.4063 67.1464 82.1003 67.6329 79.4877Z"
      fill="#FFC999"
    />
    <Path
      d="M72.3727 81.5298C72.4542 81.549 72.5079 81.6244 72.5022 81.7053L72.4981 81.7325L72.1871 83.0528L72.2101 83.287L72.2315 83.4803C72.2509 83.6488 72.2744 83.8358 72.3024 84.0403C72.4147 84.8593 72.5751 85.7895 72.792 86.8128C72.8086 86.891 72.768 86.9701 72.6948 87.0021C72.3142 87.1688 71.9254 87.275 71.5466 87.3328C71.3348 87.3651 71.1603 87.3779 71.0358 87.3793L70.9855 87.3792C70.8924 87.3771 70.8187 87.2999 70.8208 87.2069C70.8228 87.1231 70.8854 87.0551 70.9658 87.0438L70.9932 87.0422H71.0339L71.1246 87.0392C71.2242 87.034 71.3498 87.0219 71.4958 86.9996C71.7592 86.9594 72.0279 86.894 72.2943 86.7987L72.4334 86.7457L72.4128 86.6463C72.2352 85.7857 72.0978 84.993 71.9955 84.2788L71.9685 84.086C71.9345 83.8376 71.9069 83.6146 71.8853 83.4182L71.8601 83.1758L71.8492 83.0528L71.8532 83.0013L72.17 81.6552C72.1892 81.5737 72.2646 81.52 72.3456 81.5257L72.3727 81.5298Z"
      fill="#DD936F"
    />
    <Path
      d="M68.7935 87.3775C68.8379 87.2599 68.9693 87.2006 69.0869 87.245L69.2386 87.2987C69.2663 87.3081 69.2968 87.3184 69.3301 87.3293C69.5252 87.3937 69.7403 87.4581 69.9688 87.5181C70.4475 87.6441 70.9184 87.7338 71.356 87.7716C71.7073 87.8019 72.025 87.7974 72.3006 87.7542C72.4248 87.7348 72.5412 87.8197 72.5607 87.9439C72.5802 88.0681 72.4952 88.1845 72.371 88.204C72.0557 88.2534 71.7022 88.2584 71.3168 88.2251C70.8504 88.1849 70.3549 88.0905 69.853 87.9584C69.615 87.8959 69.3911 87.8288 69.1875 87.7617L69.011 87.7017C68.9757 87.6893 68.9472 87.6789 68.9261 87.6709C68.8085 87.6265 68.7491 87.4951 68.7935 87.3775Z"
      fill="#C44343"
    />
    <Path
      d="M72.2528 81.2691C73.5095 80.8101 74.4658 80.7139 75.1287 80.8293C75.2229 80.8457 75.2891 80.8632 75.3276 80.8767C75.442 80.9171 75.5021 81.0426 75.4617 81.157C75.4247 81.2619 75.3162 81.3211 75.2102 81.2992L75.1568 81.2839C75.1344 81.2781 75.0999 81.2704 75.0533 81.2623C74.4681 81.1603 73.5895 81.2487 72.4036 81.6819C72.2896 81.7235 72.1634 81.6649 72.1218 81.5509C72.0802 81.4369 72.1388 81.3107 72.2528 81.2691Z"
      fill="#253368"
    />
    <Path
      d="M67.4177 80.8923C68.0907 80.8297 68.8146 80.8369 69.4993 80.9621C69.8441 81.0251 70.1632 81.1166 70.4516 81.2399C70.5632 81.2876 70.615 81.4167 70.5673 81.5283C70.5196 81.6398 70.3904 81.6916 70.2789 81.6439C70.0226 81.5344 69.7345 81.4518 69.4203 81.3944C68.781 81.2774 68.0948 81.2707 67.4584 81.3299L67.3157 81.3444C67.2463 81.3522 67.1936 81.3593 67.16 81.3646C67.0401 81.3833 66.9277 81.3013 66.9089 81.1814C66.8902 81.0616 66.9721 80.9491 67.092 80.9304L67.1808 80.9181L67.2922 80.905C67.3317 80.9006 67.3736 80.8964 67.4177 80.8923Z"
      fill="#253368"
    />
    <Path
      d="M70.9991 89.0961C71.0922 89.0938 71.1694 89.1674 71.1717 89.2605C71.1738 89.3442 71.1144 89.4152 71.0347 89.4302L71.0074 89.4331L70.3987 89.448C70.3057 89.4503 70.2284 89.3767 70.2261 89.2837C70.2241 89.1999 70.2835 89.129 70.3632 89.1139L70.3905 89.1111L70.9991 89.0961Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.0375 86.2748C60.4891 84.1078 62.5927 84.161 62.5927 84.161C62.5927 84.161 62.7167 86.3367 63.3613 88.2616C61.2555 88.2778 61.0375 86.2748 61.0375 86.2748Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.2806 87.7612C62.3083 87.6324 62.4863 87.6201 62.5324 87.7436C62.9371 88.8348 63.9167 91.7835 62.7539 91.9611C61.4421 92.161 62.0262 88.9363 62.2806 87.7612Z"
      fill="white"
    />
    <Path
      d="M73.0337 83.7891C73.155 83.7919 73.2511 83.8925 73.2483 84.0138C73.239 84.424 73.4573 84.7392 73.6956 84.7445C73.9342 84.75 74.1668 84.4452 74.1761 84.035C74.1789 83.9137 74.2795 83.8176 74.4008 83.8203C74.5221 83.8231 74.6182 83.9237 74.6155 84.045C74.6011 84.6732 74.2024 85.1959 73.6856 85.1838C73.1689 85.1724 72.7946 84.6319 72.809 84.0038C72.8118 83.8824 72.9124 83.7863 73.0337 83.7891Z"
      fill="#253368"
    />
    <Path
      d="M68.0742 83.8469C68.1955 83.8496 68.2916 83.9502 68.2889 84.0716C68.2795 84.4816 68.4977 84.7967 68.7361 84.8022C68.9747 84.8078 69.2073 84.5029 69.2167 84.0927C69.2194 83.9714 69.32 83.8753 69.4414 83.8781C69.5627 83.8808 69.6588 83.9814 69.656 84.1028C69.6416 84.7309 69.2429 85.2536 68.7259 85.2416C68.2093 85.2295 67.8352 84.6895 67.8495 84.0615C67.8523 83.9402 67.9529 83.8441 68.0742 83.8469Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.9343 121.049C94.9343 121.049 86.4028 107.143 84.4833 104.733C79.8461 98.9121 75.18 100.367 74.2106 104.5C73.0425 109.484 89.1199 125.996 89.1199 125.996C89.1199 125.996 91.0966 128.376 94.0493 126.619C97.5394 124.543 94.9343 121.049 94.9343 121.049Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.3718 111.52C88.4731 110.267 82.1041 98.059 77.1089 101.235C72.8769 103.925 73.8679 108.233 80.9202 116.801L89.3718 111.52Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.333 100.302C100.358 114.927 99.5616 118.73 97.153 123.228C93.153 130.699 87.2046 126.914 88.7647 122.468C89.7438 119.677 92.9443 114.705 95.2633 110.146C98.5328 103.72 101.118 99.127 101.118 99.127L105.333 100.302Z"
      fill="#FFC999"
    />
    <Path
      d="M92.0582 116.24C92.1047 116.16 92.2078 116.132 92.2884 116.179C92.3609 116.221 92.3905 116.308 92.3618 116.384L92.35 116.409L89.7696 120.875C89.723 120.956 89.6199 120.984 89.5393 120.937C89.4668 120.895 89.4372 120.808 89.4659 120.732L89.4777 120.707L92.0582 116.24Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.032 99.3144L103.976 90.9793C103.976 90.9793 108.348 84.3431 109.742 84.8568C111.024 85.5187 107.917 87.2998 107.201 89.2079C108.462 89.1982 107.773 91.0298 107.773 91.0298C107.773 91.0298 108.775 91.0571 108.409 92.5622C109.302 93.1709 108.447 94.8702 108.447 94.8702C108.447 94.8702 106.288 99.3526 104.026 102.29C104.026 102.29 100.43 104.719 101.032 99.3144Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.749 96.4804C101.749 96.4804 102.293 93.5923 102.656 92.1263C103.018 90.6603 102.562 87.9423 103.598 88.721C104.295 89.2444 104.067 90.8295 103.966 92.0028C103.865 93.1757 105.585 96.0814 104.048 97.9192C103.01 99.1606 101.492 99.2358 101.749 96.4804Z"
      fill="#FFC999"
    />
    <Path
      d="M107.086 89.0843C107.155 89.0211 107.261 89.0252 107.324 89.0935C107.388 89.1618 107.384 89.2684 107.315 89.3316L107.238 89.4046C107.176 89.4645 107.107 89.5329 107.032 89.6087C106.819 89.8252 106.606 90.057 106.407 90.2941C106.204 90.5359 106.029 90.7698 105.887 90.9901C105.451 91.6716 105.402 92.1118 105.775 92.2961C105.859 92.3373 105.893 92.4384 105.852 92.5218C105.81 92.6053 105.709 92.6395 105.626 92.5983C105 92.2892 105.072 91.6375 105.604 90.8083C105.753 90.5747 105.937 90.3297 106.149 90.0776C106.354 89.8329 106.573 89.5947 106.792 89.3721L106.903 89.261L107.062 89.107C107.072 89.0975 107.08 89.0899 107.086 89.0843Z"
      fill="#DD936F"
    />
    <Path
      d="M107.79 90.9849C107.842 91.0622 107.821 91.1669 107.744 91.2187L107.665 91.273C107.602 91.3176 107.53 91.3687 107.454 91.4255C107.235 91.5876 107.016 91.762 106.812 91.9416C106.575 92.1506 106.375 92.3527 106.223 92.5414C105.886 92.9599 105.833 93.2364 106.059 93.3957C106.18 93.4808 106.326 93.4954 106.504 93.4446C106.695 93.3902 106.909 93.2639 107.128 93.0874C107.237 92.9989 107.341 92.9037 107.436 92.8085L107.538 92.7009C107.544 92.6946 107.549 92.6891 107.553 92.6843C107.614 92.614 107.72 92.6065 107.791 92.6675C107.861 92.7285 107.868 92.8349 107.807 92.9052L107.768 92.9494C107.743 92.9758 107.712 93.0084 107.675 93.0459C107.572 93.1494 107.459 93.2529 107.339 93.3498C107.088 93.5522 106.839 93.6998 106.597 93.7688C106.327 93.8456 106.077 93.8205 105.865 93.6712C105.426 93.3617 105.518 92.8797 105.96 92.33C106.126 92.1245 106.339 91.9094 106.589 91.6888C106.801 91.5023 107.027 91.322 107.253 91.1546L107.367 91.0711L107.556 90.9387C107.634 90.8869 107.738 90.9076 107.79 90.9849Z"
      fill="#DD936F"
    />
    <Path
      d="M108.052 92.484C108.144 92.4754 108.227 92.5436 108.235 92.6362C108.244 92.7289 108.176 92.811 108.083 92.8196C107.885 92.838 107.62 93.0011 107.291 93.3411C106.603 94.0531 106.442 94.5227 106.614 94.6383C106.796 94.761 107.272 94.5796 107.598 94.1713C107.729 94.0077 107.981 94.1817 107.875 94.3621C107.504 94.9908 107.09 95.45 106.61 95.8009L106.482 95.8917C106.239 96.0608 106.043 96.1648 105.607 96.3638L105.278 96.5122C104.259 96.9685 103.861 97.3453 103.584 98.3815C103.56 98.4714 103.467 98.5248 103.377 98.5007C103.287 98.4766 103.234 98.3842 103.258 98.2943C103.558 97.1769 104.011 96.7238 105.05 96.2456L105.527 96.0297C105.967 95.8272 106.135 95.7303 106.411 95.5287C106.618 95.3776 106.811 95.2046 106.995 95.0031L107.023 94.9703L106.986 94.9808C106.791 95.0295 106.607 95.0201 106.464 94.9411L106.425 94.9178C105.999 94.6307 106.232 93.9525 107.049 93.1069C107.428 92.7143 107.758 92.5113 108.052 92.484Z"
      fill="#DD936F"
    />
    <Path
      d="M104.089 90.3677C104.173 90.3661 104.244 90.4259 104.258 90.5057L104.261 90.533L104.303 92.743L104.346 92.8311L104.379 92.902C104.448 93.0548 104.517 93.225 104.582 93.4088C104.778 93.967 104.891 94.5284 104.878 95.0577C104.866 95.5357 104.749 95.9638 104.511 96.3255C104.46 96.4033 104.356 96.4248 104.278 96.3736C104.2 96.3224 104.179 96.2179 104.23 96.1402C104.431 95.8352 104.53 95.468 104.541 95.0492C104.553 94.5665 104.448 94.0434 104.264 93.5206C104.203 93.3466 104.137 93.1855 104.072 93.0414L104.021 92.9323C104.006 92.9026 103.995 92.8798 103.987 92.8644C103.978 92.8488 103.973 92.8321 103.969 92.8148L103.967 92.7886L103.924 90.5394C103.922 90.4464 103.996 90.3695 104.089 90.3677Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.9408 195.324C72.9311 195.207 80.1474 233.779 80.0674 235.864C79.8503 241.555 79.62 242.345 82.5982 243.973C83.3611 244.39 84.5525 244 85.7307 245.433C88.9562 249.359 93.8021 251.699 94.5096 249.543C94.9912 247.518 87.2925 240.701 86.6632 236.451C86.0093 232.035 84.458 193.024 83.6279 189.21C82.143 182.39 71.4282 171.041 72.9408 195.324Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.6112 244.628C80.6112 244.628 79.2902 241.465 80.0733 237.706C80.0733 237.706 82.9974 240.181 85.131 242.832C87.5945 245.893 88.215 246.068 88.215 246.068C91.4239 247.208 92.2312 245.378 92.2312 245.378C92.2312 245.378 93.9108 247.727 97.1522 250.316C100.054 252.634 88.4172 252.615 86.945 249.617C85.4729 246.619 83.4457 244.82 83.4457 244.82L82.3563 249.23L81.251 249.155L80.6112 244.628Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.4469 180.912L88.408 226.599L69.4179 227.221C69.4179 227.221 68.3834 205.018 67.6697 182.241C67.6697 182.241 66.4894 170.394 75.8765 169.881C85.2637 169.369 85.4469 180.912 85.4469 180.912Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.1832 181.147C84.4001 162.795 82.3065 143.186 80.568 139.202C80.568 139.202 78.4762 136.815 72.3885 140.764C65.29 145.368 66.6787 151.182 66.6787 151.182L67.794 182.111C67.9817 184.536 68.8465 192.061 78.1568 190.94C87.3207 189.837 85.2003 181.547 85.1832 181.147Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.863 128.336C66.3725 129.478 75.4151 129.569 76.5115 129.193C77.6075 128.818 76.7827 126.728 76.7827 126.728C81.6197 115.896 81.5516 116.059 81.8773 113.779C82.2003 111.515 80.4644 107.422 80.4644 107.422C80.4644 107.422 82.0021 103.117 81.5275 101.845C80.8859 100.126 74.592 100.39 73.4617 99.6949C72.3315 98.9993 72.4686 96.7567 72.4686 96.7567L63.8597 95.8901C63.8597 95.8901 63.6048 98.4258 63.0779 99.1715C62.0531 100.62 58.8697 98.8525 56.3371 101.747C51.8112 106.921 59.7253 117.963 62.2992 125.052C62.2992 125.052 61.0894 126.636 62.863 128.336Z"
      fill="#597EF7"
    />
    <Path
      d="M65.7972 99.4871L73.031 100.471C73.1513 100.487 73.2355 100.598 73.2192 100.718C73.2042 100.828 73.1099 100.908 73.0017 100.908L72.9718 100.906L65.738 99.9226C65.6178 99.9062 65.5335 99.7955 65.5499 99.6753C65.5649 99.565 65.6592 99.4851 65.7674 99.4851L65.7972 99.4871Z"
      fill="#253368"
    />
    <Path
      d="M67.5362 100.778L72.5578 101.851C72.6765 101.876 72.7521 101.993 72.7268 102.112C72.7035 102.221 72.6035 102.293 72.4956 102.285L72.466 102.281L67.4444 101.208C67.3257 101.182 67.25 101.065 67.2754 100.947C67.2987 100.838 67.3987 100.765 67.5066 100.774L67.5362 100.778Z"
      fill="#253368"
    />
    <Path
      d="M63.4635 112.186C63.5522 112.103 63.6913 112.108 63.774 112.197L63.809 112.233C63.858 112.281 63.9358 112.351 64.0421 112.437C64.2735 112.625 64.5514 112.813 64.8751 112.989C66.1549 113.684 67.7334 113.988 69.6116 113.678L69.7733 113.65C70.459 113.526 72.075 113.544 74.3664 113.667L75.0047 113.703C75.3289 113.722 75.6605 113.742 75.9978 113.765L76.337 113.787C76.9477 113.829 77.5473 113.873 78.1174 113.917L79.2421 114.009C79.363 114.019 79.4525 114.126 79.442 114.247C79.4315 114.368 79.325 114.457 79.2041 114.447L78.4993 114.388L77.7965 114.333C77.3148 114.297 76.8148 114.26 76.3071 114.226L75.6347 114.181C75.3024 114.16 74.9764 114.141 74.6583 114.123L73.9018 114.083C71.9322 113.985 70.5153 113.975 69.8958 114.075L69.8519 114.083C67.8001 114.456 66.0668 114.137 64.6652 113.375C64.3171 113.186 64.0171 112.982 63.7655 112.779L63.6706 112.7C63.5683 112.613 63.4956 112.543 63.4525 112.497C63.3698 112.408 63.3747 112.269 63.4635 112.186Z"
      fill="#253368"
    />
    <Path
      d="M80.3261 104.67C80.4373 104.666 80.532 104.746 80.5503 104.853L80.5533 104.882L80.6417 107.462L80.6889 107.572C80.7392 107.692 80.7953 107.828 80.8556 107.981C81.0278 108.415 81.2 108.885 81.3607 109.373C81.5784 110.035 81.7578 110.679 81.8861 111.288C82.049 112.06 82.1237 112.748 82.0934 113.331C82.0454 114.255 81.7882 115.424 81.3608 116.784C81.1512 117.452 80.9044 118.152 80.6281 118.875C80.3075 119.714 79.9641 120.538 79.6207 121.313L79.4472 121.701L79.2282 122.177C79.1988 122.24 79.1765 122.287 79.1619 122.317C79.1094 122.426 78.9781 122.472 78.8687 122.42C78.7593 122.367 78.7133 122.236 78.7659 122.126L78.89 121.862L79.047 121.52C79.1019 121.398 79.1594 121.27 79.2189 121.135C79.5594 120.367 79.8999 119.549 80.2176 118.718C80.491 118.003 80.7348 117.311 80.9416 116.653C81.3588 115.324 81.6088 114.188 81.6545 113.309C81.6826 112.769 81.6118 112.117 81.4561 111.378C81.3315 110.787 81.1562 110.158 80.9433 109.511C80.8118 109.111 80.6723 108.724 80.5316 108.359L80.4471 108.143C80.3998 108.023 80.3552 107.914 80.3141 107.815L80.2228 107.6C80.2152 107.583 80.2099 107.565 80.2069 107.547L80.2042 107.519L80.1141 104.897C80.1099 104.776 80.2049 104.674 80.3261 104.67Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.53 152.452L81.7126 144.67C81.7126 144.67 80.7471 135.74 76.8509 131.588L61.9618 129.94C61.9618 129.94 55.8016 132.946 52.9214 144.254L66.0782 151.953L73.53 152.452Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.6001 130.088L76.8509 131.588L76.6474 129.221L62.2878 127.703L61.6001 130.088Z"
      fill="#E4EBF7"
    />
    <Path
      d="M71.5273 131.198C71.6182 131.194 71.6949 131.265 71.6986 131.356C71.757 132.812 71.7671 134.386 71.7333 136.059C71.6708 139.158 71.4621 142.473 71.1534 145.82L71.0923 146.469C70.9895 147.54 70.8819 148.554 70.774 149.495L70.6878 150.229L70.5542 151.295C70.5421 151.385 70.4591 151.448 70.3689 151.436C70.2787 151.424 70.2154 151.341 70.2276 151.251L70.3103 150.602C70.3426 150.341 70.3774 150.051 70.4142 149.736L70.4789 149.173C70.5972 148.125 70.7146 146.99 70.8252 145.79C71.1333 142.45 71.3414 139.142 71.4038 136.052C71.4375 134.385 71.4274 132.818 71.3692 131.369C71.3656 131.278 71.4364 131.201 71.5273 131.198Z"
      fill="#A3B5D6"
    />
    <Path
      d="M61.6469 130.048L76.5993 131.438C76.6899 131.446 76.7565 131.527 76.7481 131.617C76.7405 131.699 76.6747 131.761 76.5956 131.766L76.5688 131.766L61.6164 130.376C61.5257 130.368 61.4591 130.287 61.4675 130.197C61.4751 130.115 61.5409 130.053 61.62 130.047L61.6469 130.048Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.4198 203.975C61.4373 203.859 58.1313 232.748 58.0689 234.833C57.8997 240.526 58.5312 241.326 55.4494 242.748C54.6601 243.113 53.4978 242.642 52.2251 243.993C48.7407 247.69 43.7472 249.696 43.1873 247.498C42.8441 245.443 50.988 239.165 51.9047 234.967C52.8566 230.607 48.2423 196.469 51.322 194.071C54.4021 191.674 61.0669 180.012 61.4198 203.975Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.3877 243.537C57.3877 243.537 58.4653 240.474 57.9388 236.67C57.9388 236.67 55.3091 238.938 53.0007 241.438C50.3349 244.324 49.7034 244.457 49.7034 244.457C46.4251 245.377 45.7435 243.496 45.7435 243.496C45.7435 243.496 43.9084 245.726 40.4991 248.089C37.4462 250.204 49.0574 250.976 50.73 248.084C52.4021 245.193 54.5471 243.536 54.5471 243.536L55.3342 248.01L56.4425 248.01L57.3877 243.537Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.7459 176.264L45.772 225.986L63.3523 227.437C63.3523 227.437 66.4355 200.63 67.5007 177.868C67.5007 177.868 68.8639 166.04 59.486 165.383C50.1076 164.725 49.7459 176.264 49.7459 176.264Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.409 177.03C69.4336 158.773 70.389 138.619 69.2776 134.418C69.2776 134.418 67.5747 131.739 60.9561 134.714C53.2384 138.182 52.9941 144.75 52.9941 144.75L49.6327 175.228C49.4481 177.653 48.7099 184.705 57.9995 185.979C67.1441 187.232 67.3651 177.427 67.409 177.03Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.7313 125.114C55.7313 125.114 61.6916 112.302 62.6188 109.11C64.857 101.402 61.3875 99.8973 57.403 101.662C52.5998 103.789 46.7779 124.727 46.7779 124.727C46.7779 124.727 46.2624 127.382 49.3834 128.844C53.0722 130.572 55.7313 125.114 55.7313 125.114Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3197 117.319C59.8303 115.863 67.8833 103.529 60.863 100.397C56.2835 98.3538 53.8138 102.562 49.8042 113.354L59.3197 117.319Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.9737 126.478C77.8415 127.818 76.7288 128.848 75.3832 128.877L51.7226 129.387C49.0054 129.446 46.831 127.147 47.0398 124.438C47.2437 121.789 49.6431 119.864 52.2728 120.24L75.7018 123.582C77.1124 123.783 78.1139 125.06 77.9737 126.478Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.0375 132.562L81.2393 116.29C81.3255 116.021 81.5729 115.838 81.8546 115.834L96.4418 115.637C96.8944 115.631 97.217 116.075 97.0698 116.504L91.6394 132.344C91.5524 132.599 91.3177 132.775 91.0488 132.787L76.6901 133.416C76.2348 133.436 75.8987 132.997 76.0375 132.562Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.8063 132.364L81.9176 116.375C82.0218 116.05 82.3215 115.828 82.6625 115.823L97.4299 115.623C97.7467 115.619 97.9722 115.93 97.8693 116.23L92.3634 132.29C92.2667 132.572 92.0075 132.766 91.7095 132.779L77.5965 133.398C77.0449 133.422 76.6385 132.889 76.8063 132.364Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.9171 117.364C84.0819 117.548 84.0225 117.87 83.7843 118.085C83.5457 118.299 83.2188 118.324 83.0535 118.141C82.8883 117.957 82.9476 117.634 83.1862 117.419C83.4244 117.205 83.7518 117.18 83.9171 117.364Z"
      fill="#ADC6FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.7214 117.556C83.8071 117.652 83.7763 117.819 83.6524 117.931C83.5289 118.041 83.3588 118.055 83.2731 117.959C83.1874 117.864 83.2182 117.696 83.3421 117.585C83.4656 117.474 83.6357 117.461 83.7214 117.556Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.5307 129.503C83.3687 129.421 83.8099 129.071 83.7304 128.373C84.7385 128.056 84.6255 127.202 84.6255 127.202C84.6255 127.202 86.5662 127.172 86.8927 126.343C87.0192 126.021 86.7731 125.676 86.4282 125.653C82.2709 125.371 79.3068 123.168 79.3068 123.168C79.3068 123.168 80.9407 120.768 80.768 119.661C80.717 119.333 80.2886 119.227 80.0886 119.492C78.3748 121.753 74.9378 123.527 74.9378 123.527C74.9378 123.527 70.8122 127.392 75.2876 129.145C75.2876 129.145 78.6986 130.504 81.0743 130.332C82.1984 130.251 82.5307 129.503 82.5307 129.503Z"
      fill="#FFC999"
    />
    <Path
      d="M81.5682 126.465C81.6029 126.403 81.6813 126.38 81.7434 126.415C82.0411 126.582 82.3891 126.717 82.7772 126.824C83.2395 126.952 83.7359 127.034 84.2354 127.078C84.4864 127.1 84.7005 127.11 84.8619 127.113L84.9786 127.113C85.0498 127.112 85.1082 127.169 85.1092 127.24C85.11 127.304 85.0652 127.357 85.0053 127.369L84.9821 127.371L84.9194 127.371C84.7459 127.37 84.5029 127.36 84.2126 127.334C83.6987 127.289 83.1875 127.205 82.7087 127.073C82.3019 126.96 81.935 126.817 81.6177 126.64C81.5556 126.605 81.5334 126.527 81.5682 126.465Z"
      fill="#DD936F"
    />
    <Path
      d="M81.1769 127.742C81.2079 127.678 81.2849 127.651 81.3489 127.682L81.4643 127.736C81.9582 127.959 82.4885 128.102 83.0212 128.18C83.35 128.229 83.6135 128.245 83.7773 128.243C83.8504 128.242 83.9088 128.299 83.9098 128.37C83.9106 128.434 83.8658 128.487 83.8059 128.498L83.7828 128.501L83.7286 128.501L83.6133 128.498C83.4472 128.491 83.233 128.472 82.9836 128.435C82.4289 128.354 81.876 128.204 81.3584 127.971L81.2368 127.914C81.1728 127.883 81.146 127.806 81.1769 127.742Z"
      fill="#DD936F"
    />
    <Path
      d="M80.4372 129.17C80.4681 129.106 80.5451 129.079 80.6091 129.11C80.746 129.176 81.0693 129.238 81.5088 129.289C81.7336 129.315 81.9748 129.336 82.216 129.354L82.4493 129.369L82.5372 129.374C82.6082 129.378 82.6629 129.438 82.6592 129.509C82.6556 129.58 82.5951 129.635 82.5241 129.631L82.4338 129.626C82.3625 129.622 82.283 129.617 82.1974 129.61C81.9528 129.593 81.7081 129.571 81.4793 129.544L81.2983 129.522C80.9216 129.473 80.6469 129.414 80.4971 129.342C80.4331 129.311 80.4062 129.234 80.4372 129.17Z"
      fill="#DD936F"
    />
    <Path
      d="M52.267 120.111L52.2902 120.112L75.1343 123.233C75.2048 123.243 75.2541 123.307 75.2445 123.378C75.2359 123.441 75.1837 123.486 75.1227 123.489L75.0995 123.488L52.2554 120.367C52.1849 120.357 52.1356 120.293 52.1452 120.222C52.1538 120.159 52.206 120.114 52.267 120.111Z"
      fill="#DD936F"
    />
    <Path
      d="M75.9143 149.374C75.9895 149.323 76.092 149.342 76.1434 149.417C76.189 149.484 76.1789 149.573 76.1233 149.628L76.1003 149.646L70.0651 153.771L63.5165 225.694C63.5091 225.776 63.4434 225.838 63.3643 225.843H63.3374C63.2559 225.836 63.1936 225.77 63.188 225.691L63.1883 225.664L69.744 153.664C69.7476 153.625 69.765 153.589 69.7926 153.562L69.8152 153.543L75.9143 149.374Z"
      fill="#A3B5D6"
    />
    <Path
      d="M62.2121 110.672C62.2614 110.561 62.3912 110.511 62.5021 110.56C62.6037 110.606 62.6542 110.718 62.6239 110.822L62.6136 110.85L59.6438 117.532C59.5985 117.634 59.4854 117.684 59.3814 117.654L59.3533 117.643L49.9499 113.436C49.8391 113.387 49.7895 113.257 49.839 113.146C49.8845 113.044 49.9975 112.994 50.1013 113.025L50.1293 113.035L59.3313 117.152L62.2121 110.672Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M278.417 187.981C278.923 189.352 280.285 190.213 281.74 190.083L311.23 187.462C314.5 187.172 316.828 184.155 316.279 180.918C315.73 177.675 312.528 175.593 309.341 176.406L280.653 183.723C278.792 184.198 277.751 186.18 278.417 187.981Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M265.081 243C265.081 243 260.319 247.074 253.289 244.522C250.645 243.562 248.187 245.352 249.616 247.798C250.675 249.609 253.163 251.277 256.594 252.534C261.191 254.217 261.571 251.576 263.858 252.955C265.183 253.754 268.26 254.684 268.997 253.76C270.284 252.147 270.511 251.086 271.761 249.04C277.847 239.079 275.647 234.778 265.081 243Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M289.62 242.919C289.62 242.919 288.423 247.069 282.889 251.457C279.26 254.335 283.118 257.385 290.785 254.008C295.155 252.082 293.485 250.266 296.055 249.631C297.544 249.263 300.325 247.857 300.154 246.838C299.855 245.056 299.235 244.278 298.595 242.263C295.478 232.45 290.835 231.288 289.62 242.919Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M298.756 240.513L293.949 197.408C293.56 193.917 289.873 191.439 285.904 192C282.072 192.543 279.489 195.713 280.161 199.049L288.777 241.768C288.777 241.768 293.107 243.261 298.756 240.513Z"
      fill="#4E5C93"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M343.802 187.58C343.802 187.58 319.202 189.439 317.531 187.017C316.795 185.952 316.267 191.276 314.919 200.061C317.092 204.078 321.253 207.304 326.702 207.153C335.611 206.905 343.726 205.901 344.643 199.937C345.303 195.648 344.754 191.059 343.802 187.58Z"
      fill="#ADC6FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M317.152 184.773L316.91 187.733C316.91 187.733 319.28 188.143 327.563 188.567C335.966 188.997 343.78 188.026 343.78 188.026L343.817 185.332C343.817 185.332 326.908 185.832 317.152 184.773Z"
      fill="#ADC6FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.038 200.605C287.192 204.397 290.316 207.39 294.112 207.38L336.43 207.263C341.341 207.249 345.259 203.158 345.06 198.249C344.861 193.341 340.624 189.58 335.727 189.965L293.54 193.279C289.756 193.576 286.883 196.812 287.038 200.605Z"
      fill="#ADC6FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M273.816 246.414L299.89 204.167C302.002 200.746 300.785 196.252 297.237 194.363C293.81 192.54 289.553 193.87 287.775 197.321L265 241.521C265 241.521 267.412 245.503 273.816 246.414Z"
      fill="#ADC6FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M309.992 186.115C312.947 186.525 315.708 184.556 316.283 181.63L321.499 155.116C322.265 151.216 319.597 147.474 315.66 146.928C311.77 146.389 308.211 149.192 307.825 153.1L305.167 179.991C304.871 182.997 307 185.7 309.992 186.115Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M305.175 170.175L318.255 171.933L320.92 154.47C321.503 150.648 318.838 147.09 315.006 146.575C311.174 146.059 307.664 148.787 307.217 152.628L305.175 170.175Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M322.147 141.939L321.644 130.524L331.912 129.985L333.634 140.773C334.153 144.031 331.845 147.064 328.567 147.431C325.241 147.803 322.295 145.283 322.147 141.939Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M318.858 129.967C318.858 129.967 317.132 128.509 316.585 129.664C316.038 130.818 317.584 134.591 319.287 134.52C321.561 134.425 318.858 129.967 318.858 129.967Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.718 138.931C329.718 138.931 326.672 141.987 322 139.737C318.637 138.117 317.535 131.131 317.624 125.914C317.675 122.961 318.861 121.255 321.618 120.104C324.376 118.954 332.087 118.146 333.003 126.727C334.023 136.285 329.718 138.931 329.718 138.931Z"
      fill="#FFC999"
    />
    <Path
      d="M322.697 126.772C322.77 126.745 322.849 126.776 322.887 126.84L322.899 126.866L323.542 128.635C323.547 128.649 323.55 128.665 323.551 128.68L323.551 128.703L323.09 133.507L324.96 133.334C325.038 133.327 325.108 133.378 325.127 133.451L325.131 133.476C325.139 133.554 325.088 133.624 325.014 133.643L324.989 133.647L322.93 133.838C322.841 133.846 322.765 133.778 322.759 133.692L322.759 133.666L323.234 128.708L322.603 126.973C322.577 126.901 322.607 126.821 322.672 126.784L322.697 126.772Z"
      fill="#DD936F"
    />
    <Path
      d="M329.028 125.544C329.261 125.473 329.506 125.604 329.577 125.836C329.648 126.068 329.517 126.314 329.285 126.385C328.822 126.526 328.035 126.568 326.745 126.56L326.235 126.555L325.056 126.539C324.814 126.537 324.619 126.338 324.621 126.095C324.623 125.853 324.821 125.658 325.064 125.66L326.617 125.68L326.982 125.682C328.034 125.682 328.708 125.642 329.028 125.544Z"
      fill="#253368"
    />
    <Path
      d="M326.527 134.547C326.641 134.505 326.767 134.564 326.809 134.678C326.851 134.791 326.792 134.918 326.679 134.96L326.598 134.987C326.553 135.002 326.495 135.02 326.424 135.041C326.223 135.099 325.988 135.158 325.722 135.212C324.925 135.375 324.021 135.469 323.029 135.459C322.907 135.458 322.81 135.359 322.811 135.237C322.812 135.116 322.912 135.019 323.033 135.02C323.993 135.029 324.867 134.939 325.634 134.782C325.846 134.738 326.036 134.692 326.204 134.646L326.302 134.619C326.414 134.586 326.49 134.561 326.527 134.547Z"
      fill="#FF7946"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M332.4 130.506L333.736 127.028C333.736 127.028 334.31 122.152 332.996 120.213C330.959 117.205 327.047 117.691 324.633 117.08C322.219 116.468 322.161 115.683 321.231 115.909C320.3 116.136 316.905 119.058 316.767 121.765C316.645 124.145 317.852 130.054 317.852 130.054C317.852 130.054 317.97 124.701 318.337 123.252C320.103 122.294 326.8 121.571 329.685 122.148C329.685 122.148 330.111 123.923 330.381 125.176C331.988 126.095 332.4 130.506 332.4 130.506Z"
      fill="#253368"
    />
    <Path
      d="M332.235 134.937C332.266 134.856 332.357 134.815 332.438 134.846C332.519 134.877 332.56 134.967 332.529 135.049C332.158 136.028 331.696 136.876 331.165 137.603C330.648 138.309 330.094 138.864 329.538 139.284C329.342 139.431 329.16 139.552 328.996 139.647C328.896 139.705 328.824 139.742 328.79 139.757C326.518 140.936 324.426 140.923 322.656 140.192C322.392 140.083 322.159 139.967 321.959 139.849L321.865 139.792C321.793 139.748 321.741 139.713 321.709 139.689C321.639 139.637 321.624 139.538 321.676 139.469C321.728 139.399 321.826 139.384 321.896 139.436L321.937 139.464C321.978 139.493 322.039 139.531 322.119 139.578C322.306 139.688 322.526 139.798 322.776 139.901C324.432 140.585 326.383 140.61 328.525 139.539L328.711 139.445C328.744 139.428 328.787 139.405 328.838 139.375C328.992 139.286 329.163 139.172 329.348 139.033C329.88 138.631 330.413 138.098 330.911 137.417C331.426 136.713 331.874 135.89 332.235 134.937Z"
      fill="#DD936F"
    />
    <Path
      d="M324.533 128.332C324.706 128.321 324.856 128.453 324.866 128.627C324.876 128.788 324.762 128.929 324.605 128.956L324.571 128.96L323.052 129.052C322.879 129.062 322.729 128.93 322.719 128.757C322.709 128.595 322.823 128.454 322.98 128.428L323.014 128.424L324.533 128.332Z"
      fill="#253368"
    />
    <Path
      d="M323.663 129.199C323.948 129.171 324.241 129.221 324.528 129.32C324.665 129.367 324.773 129.415 324.838 129.45C324.992 129.531 325.05 129.722 324.969 129.875C324.893 130.018 324.724 130.079 324.577 130.021L324.501 129.985C324.458 129.965 324.397 129.94 324.323 129.915C324.115 129.843 323.907 129.808 323.724 129.826C323.562 129.842 323.429 129.899 323.323 130.007L323.285 130.05C323.174 130.184 322.976 130.203 322.842 130.093C322.708 129.982 322.688 129.784 322.799 129.65C323.023 129.378 323.322 129.233 323.663 129.199Z"
      fill="#253368"
    />
    <Path
      d="M319.801 125.774C320.465 125.708 321.161 125.803 321.844 126C322.163 126.092 322.41 126.185 322.556 126.25C322.777 126.349 322.877 126.609 322.778 126.83C322.679 127.052 322.419 127.151 322.197 127.052L322.14 127.028C322.114 127.018 322.082 127.005 322.043 126.991C321.912 126.941 321.763 126.891 321.601 126.844C321.016 126.676 320.425 126.595 319.887 126.648C319.417 126.695 319.016 126.842 318.695 127.104C318.507 127.258 318.23 127.229 318.076 127.041C317.923 126.853 317.951 126.576 318.139 126.423C318.604 126.044 319.168 125.836 319.801 125.774Z"
      fill="#253368"
    />
    <Path
      d="M325.307 136.394C325.393 136.388 325.469 136.453 325.475 136.539C325.481 136.617 325.429 136.686 325.355 136.704L325.33 136.708L324.093 136.799C324.007 136.806 323.931 136.741 323.925 136.654C323.919 136.576 323.971 136.507 324.045 136.489L324.07 136.485L325.307 136.394Z"
      fill="#DD936F"
    />
    <Path
      d="M332.637 127.708C332.81 127.695 332.962 127.824 332.975 127.997C332.988 128.159 332.876 128.302 332.721 128.331L332.687 128.336L330.199 128.533C330.026 128.547 329.874 128.417 329.86 128.244C329.848 128.082 329.959 127.94 330.115 127.91L330.149 127.906L332.637 127.708Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.952 127.741L325.2 128.092C324.868 128.116 324.619 128.404 324.646 128.735C324.696 129.354 324.804 130.344 325.027 131.181C325.291 132.17 326.238 132.717 327.26 132.689C328.536 132.654 329.679 132.571 330.208 131.868C331.014 130.796 330.676 129.156 330.593 128.28C330.562 127.958 330.274 127.718 329.952 127.741Z"
      fill="white"
    />
    <Path
      d="M329.944 127.637L325.192 127.987C324.802 128.016 324.51 128.355 324.541 128.744C324.614 129.636 324.736 130.496 324.926 131.208C325.193 132.208 326.147 132.824 327.263 132.793L327.495 132.786C329.084 132.728 329.834 132.54 330.291 131.931C330.62 131.494 330.786 130.948 330.828 130.296C330.854 129.885 330.834 129.49 330.774 128.944L330.706 128.36C330.703 128.328 330.7 128.299 330.697 128.27C330.661 127.892 330.323 127.609 329.944 127.637ZM329.959 127.846C330.225 127.826 330.463 128.026 330.488 128.29L330.503 128.433L330.551 128.839C330.621 129.442 330.646 129.855 330.619 130.282C330.579 130.897 330.424 131.405 330.124 131.805C329.699 132.37 328.939 132.538 327.257 132.584C326.233 132.612 325.368 132.054 325.129 131.154C324.943 130.457 324.822 129.607 324.75 128.727C324.728 128.454 324.933 128.216 325.207 128.196L329.959 127.846Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M322.537 128.235L317.157 128.631C317.016 129.432 317.432 132.458 318.404 133.042C319.688 133.598 322.165 133.133 322.505 132.445C323.02 131.4 323.097 129.474 323.026 128.653C323.005 128.402 322.787 128.216 322.537 128.235Z"
      fill="white"
    />
    <Path
      d="M322.529 128.13L317.15 128.527C317.102 128.53 317.062 128.566 317.054 128.613C316.848 129.777 317.482 132.61 318.35 133.131C319.637 133.69 322.212 133.275 322.599 132.491C323.045 131.586 323.231 129.809 323.131 128.644C323.104 128.336 322.837 128.108 322.529 128.13ZM322.544 128.339C322.738 128.325 322.905 128.468 322.922 128.662C323.019 129.792 322.837 131.535 322.411 132.398C322.083 133.063 319.63 133.459 318.445 132.945L318.403 132.918C317.688 132.405 317.117 129.916 317.242 128.777L317.248 128.73L322.544 128.339Z"
      fill="#253368"
    />
    <Path
      d="M330.439 127.423C330.612 127.406 330.766 127.532 330.783 127.705C330.799 127.866 330.691 128.011 330.535 128.044L330.501 128.049L324.623 128.638C324.45 128.655 324.296 128.529 324.278 128.356C324.262 128.194 324.371 128.049 324.526 128.017L324.56 128.011L330.439 127.423Z"
      fill="#253368"
    />
    <Path
      d="M322.881 128.095C323.055 128.084 323.204 128.217 323.214 128.39C323.223 128.552 323.109 128.693 322.953 128.719L322.918 128.723L317.141 129.067C316.968 129.077 316.819 128.945 316.808 128.772C316.799 128.61 316.913 128.469 317.069 128.443L317.104 128.439L322.881 128.095Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M332.879 127.757C333.414 127.065 334.333 126.786 335.079 127.429C336.28 128.466 335.779 132.885 332.913 133.394C331.8 133.367 332.046 128.837 332.879 127.757Z"
      fill="#FFC999"
    />
    <Path
      d="M334.139 128.013C334.217 127.973 334.312 128.004 334.351 128.081C334.391 128.158 334.36 128.253 334.283 128.293L334.246 128.313C334.223 128.327 334.194 128.345 334.159 128.368C334.062 128.432 333.965 128.509 333.875 128.596C333.532 128.929 333.387 129.315 333.534 129.763C333.573 129.879 333.624 129.932 333.742 129.989L333.821 130.025C334.075 130.136 334.192 130.241 334.244 130.5C334.323 130.899 334.157 131.22 333.844 131.458C333.695 131.571 333.547 131.642 333.443 131.678C333.361 131.706 333.272 131.662 333.244 131.58C333.216 131.497 333.259 131.408 333.342 131.38L333.369 131.369C333.387 131.362 333.409 131.352 333.436 131.34C333.51 131.304 333.585 131.259 333.654 131.207C333.88 131.036 333.988 130.826 333.935 130.561C333.91 130.438 333.872 130.394 333.735 130.331L333.657 130.297C333.434 130.198 333.311 130.09 333.236 129.861C333.045 129.283 333.235 128.779 333.656 128.37C333.761 128.268 333.873 128.18 333.985 128.106C334.053 128.061 334.107 128.03 334.139 128.013Z"
      fill="#DD936F"
    />
    <Path
      d="M320.936 129.573C321.542 129.529 322.06 130.041 322.109 130.703C322.118 130.824 322.027 130.929 321.906 130.938C321.785 130.947 321.68 130.856 321.671 130.735C321.639 130.303 321.318 129.986 320.968 130.012C320.619 130.037 320.348 130.398 320.379 130.831C320.388 130.952 320.297 131.057 320.176 131.066C320.055 131.075 319.95 130.984 319.941 130.863C319.893 130.201 320.33 129.618 320.936 129.573Z"
      fill="#253368"
    />
    <Path
      d="M327.65 129.078C328.256 129.034 328.774 129.546 328.823 130.208C328.832 130.329 328.741 130.434 328.62 130.443C328.499 130.452 328.394 130.361 328.385 130.24C328.353 129.808 328.032 129.491 327.682 129.517C327.333 129.542 327.062 129.903 327.094 130.336C327.103 130.457 327.012 130.562 326.891 130.571C326.77 130.58 326.664 130.489 326.656 130.368C326.607 129.706 327.045 129.123 327.65 129.078Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M343.632 180.355C343.632 180.355 343.74 169.442 344.395 164.527C345.138 158.96 347.441 148.846 345.745 147.49C343.271 145.51 335.687 143.044 335.687 143.044C335.687 143.044 331.886 143.48 327.612 144.39C324.228 145.111 324.696 142.985 320.594 143.663C318.857 143.95 312.718 146.081 311.595 147.325C308.562 150.682 310.838 156.83 312.09 162.833C313.961 171.803 316.874 179.99 316.874 179.99C316.874 179.99 314.824 183.953 316.556 185.933C317.531 186.946 317.397 186.489 322.801 186.502C328.953 186.517 341.796 186.201 342.725 185.871C345.714 184.809 343.632 180.355 343.632 180.355Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M322.112 142.01L326.477 144.468L322.061 149.026L320.351 143.781L322.112 142.01Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.375 144.576L330.255 150.011L335.959 143.309L333.43 140.258L326.375 144.576Z"
      fill="#597EF7"
    />
    <Path
      d="M326.11 145.007C326.231 145.022 326.316 145.132 326.301 145.252L326.285 145.396L326.251 145.731L326.217 146.099C326.18 146.519 326.144 146.999 326.109 147.539L326.08 148.01C325.868 151.658 325.84 156.177 326.106 161.494C326.488 169.132 327.439 177.478 329.084 186.45C329.106 186.57 329.027 186.684 328.907 186.706C328.788 186.728 328.673 186.649 328.652 186.529C327.004 177.538 326.05 169.173 325.667 161.516L325.634 160.825C325.398 155.675 325.439 151.293 325.656 147.746L325.671 147.511C325.705 146.968 325.742 146.484 325.779 146.061L325.814 145.688L325.863 145.217C325.864 145.21 325.865 145.204 325.865 145.198C325.879 145.088 325.972 145.007 326.08 145.005L326.11 145.007Z"
      fill="#253368"
    />
    <Path
      d="M339.068 155.523L334.677 155.668C333.329 155.711 331.911 155.755 330.441 155.8C330.319 155.804 330.223 155.907 330.228 156.029L330.25 156.491C330.329 158.007 330.453 159.301 330.611 160.388C330.703 161.023 330.801 161.553 330.9 161.982L330.925 162.088C330.956 162.214 330.984 162.325 331.011 162.419L331.031 162.487C331.05 162.553 331.065 162.598 331.073 162.622C331.088 162.663 331.115 162.699 331.15 162.725L335.233 165.735C335.31 165.793 335.417 165.792 335.494 165.735L339.371 162.848C339.428 162.805 339.461 162.737 339.46 162.666L339.295 155.737C339.292 155.615 339.19 155.519 339.068 155.523ZM338.861 155.97L339.018 162.563L335.362 165.285L331.467 162.413L331.452 162.362C331.422 162.261 331.389 162.136 331.353 161.985C331.256 161.584 331.16 161.084 331.068 160.478L331.023 160.162C330.879 159.129 330.765 157.91 330.691 156.493L330.678 156.233L335.492 156.081L338.861 155.97Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.425 149.72C326.465 150.109 326.761 150.397 327.085 150.363C327.409 150.33 327.64 149.987 327.599 149.597C327.559 149.208 327.263 148.92 326.939 148.954C326.614 148.987 326.384 149.33 326.425 149.72Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.548 157.659C326.588 158.049 326.884 158.337 327.209 158.303C327.533 158.269 327.763 157.927 327.723 157.537C327.682 157.148 327.386 156.86 327.062 156.893C326.737 156.927 326.507 157.27 326.548 157.659Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M326.795 165.022C326.836 165.411 327.132 165.699 327.456 165.665C327.78 165.632 328.011 165.289 327.97 164.899C327.929 164.51 327.634 164.222 327.309 164.256C326.985 164.289 326.755 164.632 326.795 165.022Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M327.414 173.002C327.454 173.392 327.75 173.68 328.074 173.646C328.399 173.612 328.629 173.269 328.589 172.88C328.548 172.491 328.252 172.203 327.928 172.236C327.604 172.27 327.373 172.613 327.414 173.002Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M328.617 181.877C328.657 182.267 328.954 182.555 329.278 182.521C329.602 182.487 329.832 182.144 329.792 181.755C329.751 181.366 329.455 181.078 329.131 181.111C328.807 181.145 328.576 181.488 328.617 181.877Z"
      fill="white"
    />
    <Path
      d="M335.172 142.91C335.241 142.81 335.378 142.786 335.478 142.855C335.568 142.918 335.597 143.037 335.549 143.133L335.532 143.161L331.337 149.186C331.266 149.287 331.129 149.309 331.032 149.242L331.006 149.221L326.286 144.798C326.198 144.715 326.193 144.576 326.276 144.487C326.352 144.407 326.473 144.396 326.561 144.457L326.587 144.477L331.121 148.726L335.172 142.91Z"
      fill="#253368"
    />
    <Path
      d="M320.105 144.182C320.209 144.141 320.325 144.185 320.377 144.279L320.39 144.306L322.391 149.438L326.114 144.475C326.18 144.386 326.3 144.362 326.394 144.413L326.421 144.431C326.51 144.497 326.534 144.617 326.483 144.711L326.465 144.738L322.506 150.017C322.409 150.147 322.214 150.129 322.139 149.994L322.125 149.965L319.98 144.466C319.936 144.353 319.992 144.226 320.105 144.182Z"
      fill="#253368"
    />
    <Path
      d="M311.557 156.547C311.678 156.535 311.786 156.622 311.798 156.743C312.123 159.9 312.598 162.727 313.288 165.728L313.388 166.158C313.787 167.851 314.243 169.578 314.885 171.847L317.251 180.095C317.265 180.145 317.261 180.198 317.24 180.245C316.27 182.411 316.376 184.123 317.127 185.413C317.253 185.629 317.387 185.813 317.521 185.965L317.583 186.033L317.638 186.09L317.877 186.107C319.252 186.199 320.739 186.26 322.323 186.292L322.691 186.299C326.02 186.356 329.575 186.289 333.093 186.139L333.752 186.109C334.671 186.067 335.521 186.023 336.287 185.978L337.516 185.9C337.637 185.892 337.743 185.983 337.751 186.104C337.76 186.225 337.669 186.33 337.548 186.339L336.758 186.39L335.886 186.441C335.305 186.474 334.682 186.506 334.021 186.537L333.772 186.548C330.032 186.719 326.231 186.799 322.683 186.738C320.831 186.706 319.105 186.636 317.53 186.524C317.484 186.52 317.44 186.502 317.404 186.472C317.234 186.328 316.989 186.049 316.747 185.634C315.941 184.249 315.815 182.432 316.782 180.195L316.807 180.139L314.667 172.69L314.462 171.966C313.819 169.692 313.361 167.959 312.96 166.258C312.212 163.078 311.704 160.115 311.361 156.788C311.349 156.667 311.436 156.559 311.557 156.547Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M339.934 186.556C342.912 186.718 345.5 184.527 345.83 181.562L348.82 154.706C349.26 150.757 346.29 147.249 342.321 147.033C338.4 146.819 335.087 149.909 335.027 153.836L334.616 180.854C334.571 183.874 336.918 186.391 339.934 186.556Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M334.793 170.558L347.982 171.037L348.938 153.398C349.146 149.537 346.149 146.255 342.285 146.114C338.421 145.974 335.192 149.029 335.121 152.895L334.793 170.558Z"
      fill="#597EF7"
    />
    <Path
      d="M335.108 152.962C335.219 152.964 335.31 153.048 335.322 153.156L335.324 153.186L335.016 170.346L347.989 170.818C348.101 170.822 348.189 170.908 348.2 171.015L348.201 171.045C348.197 171.156 348.111 171.245 348.003 171.256L347.973 171.257L334.785 170.777C334.675 170.773 334.587 170.689 334.575 170.583L334.573 170.554L334.884 153.178C334.887 153.056 334.987 152.96 335.108 152.962Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M293.129 196.574L318.989 194.545L294.281 184.889L272.813 188.68L293.129 196.574Z"
      fill="#CCD7EE"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.306 194.718L311.088 193.244L293.583 186.231L277.552 188.984L292.306 194.718Z"
      fill="#4E5C93"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M318.87 195.862L318.993 194.554L292.618 196.416L292.949 197.848L318.87 195.862Z"
      fill="#4E5C93"
    />
    <Path
      d="M289.95 187.118C289.972 187.069 290.025 187.045 290.075 187.056L290.096 187.064L304.272 193.567C304.328 193.592 304.352 193.657 304.326 193.712C304.304 193.76 304.252 193.785 304.202 193.773L304.181 193.766L290.004 187.263C289.949 187.238 289.925 187.173 289.95 187.118Z"
      fill="#CCD7EE"
    />
    <Path
      d="M291.948 186.494C291.969 186.446 292.021 186.42 292.071 186.431L292.092 186.438L307.968 193.401C308.024 193.425 308.049 193.49 308.025 193.546C308.003 193.594 307.951 193.62 307.901 193.609L307.88 193.602L292.004 186.639C291.949 186.615 291.923 186.55 291.948 186.494Z"
      fill="#CCD7EE"
    />
    <Path
      d="M289.625 188.427C289.648 188.379 289.701 188.355 289.751 188.368L289.772 188.375L301.662 194.03C301.716 194.057 301.74 194.122 301.714 194.177C301.691 194.225 301.638 194.249 301.588 194.236L301.567 194.229L289.677 188.574C289.623 188.548 289.599 188.482 289.625 188.427Z"
      fill="#CCD7EE"
    />
    <Path
      d="M286.24 188.497C286.262 188.449 286.316 188.426 286.365 188.438L286.386 188.445L298.276 194.101C298.331 194.127 298.354 194.192 298.328 194.247C298.305 194.295 298.252 194.319 298.202 194.307L298.182 194.299L286.292 188.644C286.237 188.618 286.214 188.552 286.24 188.497Z"
      fill="#CCD7EE"
    />
    <Path
      d="M283.096 188.781C283.119 188.733 283.172 188.709 283.222 188.721L283.243 188.729L295.132 194.384C295.187 194.41 295.21 194.476 295.184 194.531C295.162 194.579 295.109 194.602 295.059 194.59L295.038 194.583L283.148 188.927C283.093 188.901 283.07 188.836 283.096 188.781Z"
      fill="#CCD7EE"
    />
    <Path
      d="M308.118 192.201C308.178 192.198 308.23 192.245 308.232 192.306C308.235 192.359 308.199 192.405 308.149 192.417L308.127 192.42L292.098 193.127C292.038 193.13 291.986 193.083 291.983 193.022C291.981 192.969 292.017 192.923 292.067 192.911L292.089 192.907L308.118 192.201Z"
      fill="#CCD7EE"
    />
    <Path
      d="M304.694 190.682C304.755 190.679 304.806 190.726 304.809 190.787C304.811 190.84 304.776 190.886 304.726 190.898L304.704 190.901L288.675 191.608C288.614 191.61 288.563 191.563 288.56 191.503C288.558 191.45 288.594 191.404 288.643 191.391L288.665 191.388L304.694 190.682Z"
      fill="#CCD7EE"
    />
    <Path
      d="M300.395 189.509C300.456 189.506 300.507 189.553 300.51 189.614C300.512 189.667 300.476 189.713 300.427 189.725L300.405 189.729L284.376 190.435C284.315 190.438 284.264 190.391 284.261 190.33C284.259 190.277 284.294 190.231 284.344 190.219L284.366 190.216L300.395 189.509Z"
      fill="#CCD7EE"
    />
    <Path
      d="M297.616 187.08C297.677 187.077 297.728 187.124 297.731 187.184C297.733 187.238 297.697 187.283 297.648 187.296L297.626 187.299L281.597 188.006C281.536 188.008 281.485 187.961 281.482 187.901C281.48 187.848 281.515 187.802 281.565 187.789L281.587 187.786L297.616 187.08Z"
      fill="#CCD7EE"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.928 197.848L291.773 197.856L272.567 190.117L267.233 156.198L288.111 162.105L292.928 197.848Z"
      fill="#4E5C93"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.731 197.855L271.477 189.703C271.292 189.629 271.16 189.462 271.129 189.265L265.967 156.666C265.902 156.256 266.284 155.918 266.683 156.031L286.484 161.663C286.701 161.724 286.861 161.909 286.891 162.132L291.731 197.855Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M307.644 187.361C308.15 188.731 309.512 189.593 310.967 189.463L340.457 186.842C343.727 186.551 346.055 183.534 345.506 180.297C344.957 177.055 341.755 174.973 338.568 175.786L309.88 183.103C308.019 183.578 306.978 185.559 307.644 187.361Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M309.286 189.559C307.198 191.025 300.735 190.064 300.735 190.064L296.426 192.196C295.76 192.462 295.601 192.018 295.271 192.018C294.383 192.018 294.383 191.085 294.383 191.085C294.077 190.94 293.584 191.174 293.45 190.33C293.45 190.33 292.828 190.286 292.784 189.531C292.761 189.132 296.809 183.372 296.809 183.372L302.851 183.537C302.851 183.537 311.678 183.702 312.667 185.682C313.657 187.662 309.286 189.559 309.286 189.559Z"
      fill="#FFC999"
    />
    <Path
      d="M300.279 186.398C300.365 186.368 300.46 186.413 300.491 186.499C300.519 186.576 300.485 186.661 300.415 186.699L300.391 186.711L297.942 187.585L294.943 190.849C294.923 190.867 294.893 190.901 294.861 190.946L294.837 190.981C294.753 191.108 294.717 191.23 294.744 191.33C294.791 191.498 295.02 191.649 295.511 191.747C295.601 191.765 295.659 191.852 295.641 191.942C295.623 192.032 295.536 192.09 295.446 192.072C294.845 191.952 294.511 191.731 294.425 191.417C294.367 191.207 294.429 190.998 294.56 190.799C294.607 190.728 294.653 190.672 294.687 190.636L294.705 190.618L297.726 187.331C297.738 187.318 297.752 187.307 297.767 187.298L297.792 187.287L300.279 186.398Z"
      fill="#DD936F"
    />
    <Path
      d="M298.798 184.984C298.88 184.944 298.979 184.979 299.019 185.062C299.054 185.136 299.029 185.224 298.964 185.269L298.941 185.283L297.021 186.199L294.148 189.777L294.111 189.82L294.093 189.842C294.049 189.895 294.005 189.955 293.965 190.018C293.873 190.16 293.812 190.298 293.794 190.419C293.757 190.672 293.912 190.841 294.409 190.922C294.499 190.936 294.561 191.021 294.546 191.111C294.532 191.202 294.447 191.263 294.356 191.249C293.694 191.142 293.401 190.82 293.467 190.371C293.492 190.195 293.571 190.017 293.686 189.838C293.734 189.764 293.785 189.695 293.836 189.633L293.869 189.593C293.879 189.581 293.887 189.572 293.892 189.567L293.898 189.56L296.786 185.962C296.796 185.949 296.808 185.938 296.822 185.929L296.843 185.917L298.798 184.984Z"
      fill="#DD936F"
    />
    <Path
      d="M301.816 187.902C301.906 187.885 301.992 187.945 302.008 188.035C302.023 188.116 301.976 188.194 301.901 188.221L301.875 188.228L298.992 188.752L295.868 191.79L295.867 191.795C295.866 191.814 295.868 191.833 295.873 191.851L295.883 191.878C295.93 191.972 296.085 192.039 296.422 192.03C296.701 192.023 297.252 191.773 297.969 191.347C298.116 191.26 298.268 191.166 298.424 191.067C298.763 190.852 299.107 190.622 299.436 190.392L299.602 190.275L299.866 190.085C299.89 190.068 299.917 190.057 299.946 190.054L300.111 190.038L300.279 190.024C300.368 190.016 300.464 190.01 300.566 190.003C301.343 189.952 302.206 189.935 303.106 189.971L303.727 189.999C306.351 190.125 307.885 189.976 309.671 189.137L309.79 189.08L309.852 189.064L324.699 188.073C324.791 188.067 324.87 188.136 324.876 188.228C324.881 188.31 324.826 188.382 324.748 188.4L324.721 188.404L309.905 189.392L309.811 189.437C308.005 190.286 306.456 190.451 303.888 190.338L303.092 190.302C302.205 190.267 301.353 190.284 300.588 190.334L300.395 190.347C300.334 190.351 300.276 190.356 300.221 190.361L300.026 190.379L299.793 190.546C299.74 190.583 299.684 190.623 299.626 190.663C299.293 190.896 298.945 191.129 298.602 191.347C298.443 191.448 298.288 191.543 298.138 191.632C297.369 192.089 296.788 192.352 296.43 192.361C295.978 192.373 295.7 192.254 295.586 192.025C295.532 191.916 295.525 191.805 295.546 191.702C295.55 191.682 295.554 191.665 295.558 191.654C295.564 191.637 295.572 191.622 295.583 191.608L295.6 191.588L298.798 188.479C298.815 188.462 298.837 188.449 298.86 188.441L298.884 188.435L301.816 187.902Z"
      fill="#DD936F"
    />
    <Path
      d="M335.805 176.201C335.893 176.177 335.984 176.23 336.007 176.318C336.028 176.398 335.987 176.48 335.915 176.512L335.889 176.521L308.091 183.862L308.044 183.867L296.897 183.54L292.93 189.608L292.916 189.637C292.911 189.649 292.905 189.663 292.898 189.679L292.889 189.705C292.861 189.784 292.845 189.862 292.847 189.93C292.853 190.114 292.992 190.208 293.434 190.165C293.525 190.156 293.606 190.223 293.615 190.314C293.623 190.405 293.557 190.486 293.466 190.495C292.85 190.554 292.529 190.337 292.516 189.94C292.513 189.826 292.536 189.71 292.576 189.595C292.593 189.548 292.61 189.509 292.627 189.473L292.645 189.44L296.671 183.282C296.697 183.241 296.739 183.215 296.786 183.208L296.814 183.207L308.03 183.536L335.805 176.201Z"
      fill="#DD936F"
    />
    <Path
      d="M305.201 169.957L314.096 171.028C314.217 171.043 314.302 171.152 314.288 171.273C314.275 171.383 314.182 171.465 314.073 171.466L314.044 171.465L305.149 170.393C305.028 170.378 304.942 170.269 304.957 170.148C304.97 170.038 305.063 169.957 305.171 169.955L305.201 169.957Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.632 138.469C214.632 138.469 209.614 130.844 204.063 124.517C199.001 118.747 193.696 114.443 189.907 117.489C185.149 121.314 192.617 129.054 196.085 133.251C198.581 136.271 204.375 142.086 207.635 144.894C212.834 149.372 218.606 142.889 214.632 138.469Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197.484 134.943C194.015 130.747 183.998 122.237 189.854 117.425C193.985 114.031 199.46 118.689 205.848 126.676L197.484 134.943Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.142 164.972C189.142 164.972 208.785 152.187 214.106 145.44C217.081 141.668 213.96 135.871 208.61 137.807C204.22 139.397 185.107 160.282 185.107 160.282C185.107 160.282 182.963 161.963 184.967 164.483C187.046 167.097 189.142 164.972 189.142 164.972Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.123 169.625C184.123 169.625 189.455 166.315 189.127 163.384C188.831 160.731 185.393 160.651 185.393 160.651C185.393 160.651 182.698 160.894 179.868 161.945C178.014 162.634 174.294 163.418 174.391 164.462C174.518 165.823 178.191 164.4 178.876 165.122C180.263 166.584 174.72 169.43 174.069 170.374C172.797 172.219 177.758 173.644 184.123 169.625Z"
      fill="#FFC999"
    />
    <Path
      d="M179.59 167.917C179.652 167.852 179.756 167.849 179.821 167.911C179.886 167.973 179.889 168.077 179.826 168.142L179.729 168.24C179.711 168.258 179.691 168.278 179.669 168.299C179.543 168.422 179.395 168.559 179.229 168.707C178.753 169.128 178.218 169.548 177.635 169.941C176.704 170.569 175.739 171.064 174.76 171.378C174.674 171.405 174.583 171.358 174.555 171.272C174.528 171.186 174.575 171.095 174.661 171.067C175.607 170.764 176.545 170.282 177.453 169.671C178.023 169.287 178.547 168.874 179.013 168.463C179.143 168.347 179.261 168.239 179.366 168.139L179.499 168.009C179.542 167.967 179.572 167.936 179.59 167.917Z"
      fill="#DD936F"
    />
    <Path
      d="M181.093 168.836C181.161 168.777 181.264 168.785 181.323 168.853C181.382 168.921 181.374 169.024 181.306 169.083C180.078 170.139 178.842 170.94 177.66 171.52C177.246 171.724 176.867 171.885 176.532 172.009L176.397 172.057C176.354 172.072 176.314 172.086 176.276 172.098L176.14 172.141L176.107 172.151C176.02 172.174 175.931 172.123 175.907 172.036C175.883 171.949 175.935 171.86 176.022 171.836L176.122 171.806L176.23 171.77C176.269 171.757 176.31 171.742 176.353 171.727L176.419 171.703C176.744 171.583 177.112 171.426 177.517 171.227C178.675 170.659 179.887 169.873 181.093 168.836Z"
      fill="#DD936F"
    />
    <Path
      d="M182.689 169.261C182.754 169.198 182.857 169.199 182.92 169.263C182.983 169.327 182.982 169.431 182.918 169.494L182.86 169.548L182.783 169.616C182.755 169.641 182.725 169.667 182.692 169.695C182.506 169.852 182.279 170.03 182.009 170.223C181.24 170.775 180.283 171.35 179.127 171.918C179.046 171.958 178.949 171.925 178.909 171.844C178.869 171.763 178.902 171.665 178.983 171.626C180.123 171.065 181.064 170.5 181.818 169.959C182.049 169.793 182.246 169.641 182.412 169.504L182.482 169.446C182.588 169.355 182.657 169.293 182.689 169.261Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.591 221.61C179.7 202.968 181.246 187.217 181.246 187.217C181.246 187.217 186.32 172.193 194.237 181.773C198.072 186.412 198.013 198.985 196.364 219.954C196.364 219.954 196.479 229.748 189.286 229.793C183.265 229.83 182.591 221.61 182.591 221.61Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.891 208.863C188.758 211.22 197.391 208.078 197.391 208.078C197.523 202.372 197.991 195.388 196.488 175.4C186.348 168.996 178.785 182.906 178.785 182.906L180.891 208.863Z"
      fill="#597EF7"
    />
    <Path
      d="M196.538 204.589C196.566 204.676 196.519 204.769 196.432 204.797L196.249 204.853L196.006 204.922C195.919 204.946 195.825 204.972 195.726 204.998C195.156 205.148 194.5 205.297 193.767 205.437C189.818 206.189 185.454 206.352 181.01 205.557C180.921 205.541 180.861 205.456 180.877 205.366C180.893 205.276 180.979 205.217 181.068 205.233C185.468 206.02 189.793 205.859 193.705 205.113C194.358 204.989 194.948 204.857 195.47 204.724L195.83 204.629C195.92 204.604 196.004 204.581 196.081 204.558L196.331 204.483C196.417 204.455 196.51 204.503 196.538 204.589Z"
      fill="#253368"
    />
    <Path
      d="M178.748 183.585C178.83 183.579 178.902 183.632 178.922 183.709L178.926 183.736L181.046 208.738L181.072 208.745C181.159 208.771 181.258 208.798 181.37 208.828L181.456 208.851C181.878 208.96 182.365 209.07 182.912 209.172C187.451 210.023 192.47 209.845 197.331 207.925C197.415 207.891 197.511 207.933 197.545 208.018C197.578 208.102 197.536 208.198 197.452 208.231C192.526 210.178 187.447 210.357 182.851 209.496C182.297 209.392 181.802 209.281 181.374 209.17L181.202 209.124L180.98 209.062C180.912 209.042 180.865 209.028 180.841 209.02C180.787 209.003 180.746 208.959 180.732 208.905L180.727 208.877L178.598 183.764C178.59 183.673 178.657 183.593 178.748 183.585Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.312 104.208L184.327 99.1337C184.327 99.1337 188.224 96.6499 188.722 99.2251C189.23 101.851 187.513 104.061 185.8 104.362C184.675 104.559 184.312 104.208 184.312 104.208Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.984 104.208L171.968 99.1337C171.968 99.1337 168.071 96.6499 167.573 99.2251C167.065 101.851 168.789 104.391 170.495 104.362C171.637 104.343 171.984 104.208 171.984 104.208Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.946 101.05C185.29 106.615 183.595 110.742 178.027 110.742C172.211 110.742 170.85 105.257 170.232 100.596C169.075 91.8826 174.217 89.7904 178.522 89.7904C182.827 89.7904 186.812 93.7086 185.946 101.05Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.973 114.54C169.973 114.54 172.032 119.981 178.522 119.981C186.055 119.981 186.422 114.337 186.422 114.337L184.67 113.776C184.264 113.645 183.977 113.284 183.945 112.859C183.837 111.423 183.597 107.893 183.447 103.256C176.977 101.13 173.398 103.072 173.398 103.072C173.398 103.072 173.037 108.702 172.701 112.772C172.669 113.172 172.412 113.517 172.038 113.662C171.397 113.912 170.419 114.304 169.973 114.54Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.561 97.0912C182.998 96.0255 182.605 94.1209 182.605 94.1209C182.732 94.1793 178.645 96.2408 176.542 96.7194C174.749 97.1276 173.58 96.6412 175.676 94.3683C173.362 95.6594 171.904 96.6609 171.151 97.2182C170.753 97.5121 170.516 97.9718 170.507 98.4666L170.438 102.246L168.953 98.6991C168.953 98.6991 167.881 99.1118 167.386 98.0391C166.76 96.6816 168.5 93.9354 168.5 93.9354C168.5 93.9354 167.008 94.3731 167.222 92.7599C167.434 91.1467 171.511 89.0478 171.511 89.0478C171.511 89.0478 170.269 88.9819 170.738 87.7343C171.206 86.4871 176.13 86.8207 176.13 86.8207C176.13 86.8207 175.305 85.501 177.082 85.3714C179.091 85.2246 182.482 87.8108 182.482 87.8108C182.482 87.8108 184.956 85.501 185.437 89.3181C187.926 89.7079 188.916 91.935 187.101 92.5951C188.916 94.1622 189.06 95.1936 187.245 95.3584C188.235 96.6781 188.544 98.5137 186.688 99.1939L185.699 102.329L185.516 98.7914C185.48 98.1067 185.127 97.4774 184.561 97.0912Z"
      fill="#253368"
    />
    <Path
      d="M180.14 97.851C180.979 97.647 181.846 97.5749 182.665 97.7152C183.006 97.7735 183.33 97.8681 183.633 98.0025C183.799 98.0763 183.874 98.2711 183.8 98.4375C183.726 98.6038 183.531 98.6788 183.365 98.605C183.114 98.4937 182.843 98.4143 182.554 98.3649C181.836 98.242 181.055 98.3069 180.295 98.4915C180.159 98.5246 180.033 98.5594 179.918 98.5946L179.763 98.6447C179.721 98.6589 179.691 98.6698 179.673 98.6766C179.503 98.7423 179.313 98.6579 179.247 98.4881C179.181 98.3183 179.266 98.1274 179.435 98.0618L179.513 98.0335C179.546 98.022 179.586 98.0086 179.632 97.9937C179.783 97.9449 179.953 97.8963 180.14 97.851Z"
      fill="#253368"
    />
    <Path
      d="M176.092 98.1851C176.162 98.1466 176.249 98.165 176.297 98.225L176.314 98.2494L176.994 99.4864C177.004 99.503 177.01 99.521 177.013 99.5395L177.015 99.5676L176.954 103.348L178.139 103.57C178.219 103.585 178.274 103.656 178.272 103.734L178.27 103.761C178.255 103.84 178.184 103.896 178.106 103.894L178.079 103.891L176.76 103.643C176.69 103.63 176.638 103.575 176.628 103.507L176.627 103.481L176.688 99.6055L176.028 98.4066C175.989 98.3365 176.008 98.2503 176.068 98.2016L176.092 98.1851Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.223 100.72C175.223 101.403 174.835 101.957 174.356 101.957C173.878 101.957 173.491 101.403 173.491 100.72C173.491 100.037 173.878 99.483 174.356 99.483C174.835 99.483 175.223 100.037 175.223 100.72Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.358 100.72C182.358 101.403 181.97 101.957 181.492 101.957C181.013 101.957 180.626 101.403 180.626 100.72C180.626 100.037 181.013 99.483 181.492 99.483C181.97 99.483 182.358 100.037 182.358 100.72Z"
      fill="#253368"
    />
    <Path
      d="M178.899 106.317C178.989 106.317 179.062 106.39 179.062 106.48C179.062 106.561 179.003 106.628 178.926 106.641L178.899 106.643H177.498C177.407 106.643 177.334 106.57 177.334 106.48C177.334 106.399 177.394 106.332 177.471 106.319L177.498 106.317H178.899Z"
      fill="#DD936F"
    />
    <Path
      d="M172.665 97.9559C173.608 97.6723 174.603 97.6854 175.541 97.8758L175.684 97.9065C175.866 97.9473 176.004 97.9864 176.091 98.0155C176.263 98.0735 176.356 98.2604 176.298 98.4329C176.244 98.594 176.078 98.6856 175.915 98.65L175.762 98.6053C175.659 98.577 175.541 98.5485 175.409 98.5218C174.569 98.3512 173.678 98.3394 172.855 98.5871C172.701 98.6334 172.552 98.6885 172.407 98.7527C172.241 98.8266 172.046 98.7516 171.972 98.5852C171.898 98.4188 171.973 98.2241 172.14 98.1502C172.31 98.0746 172.485 98.01 172.665 97.9559Z"
      fill="#253368"
    />
    <Path
      d="M180.011 104.656C180.107 104.62 180.214 104.67 180.25 104.766C180.285 104.863 180.235 104.969 180.139 105.005C179.904 105.091 179.659 105.158 179.404 105.208C178.51 105.383 177.56 105.341 176.649 105.164C176.509 105.137 176.38 105.108 176.262 105.079L176.068 105.028C176.038 105.02 176.015 105.013 175.998 105.008C175.9 104.977 175.846 104.872 175.877 104.775C175.908 104.677 176.012 104.622 176.11 104.653L176.233 104.688L176.27 104.698C176.402 104.732 176.553 104.767 176.72 104.799C177.588 104.967 178.492 105.008 179.332 104.843C179.569 104.797 179.795 104.735 180.011 104.656Z"
      fill="#FF7A46"
    />
    <Path
      d="M173.428 108.757C173.49 108.692 173.594 108.689 173.659 108.752C174.798 109.843 176.269 110.416 178.136 110.416C180.116 110.416 181.647 109.888 182.808 108.857C182.875 108.798 182.978 108.804 183.038 108.871C183.098 108.938 183.091 109.041 183.024 109.101C181.8 110.188 180.193 110.742 178.136 110.742C176.186 110.742 174.634 110.137 173.433 108.987C173.368 108.925 173.366 108.822 173.428 108.757Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.395 150.863C163.085 155.648 166.22 157.133 166.22 157.133C173.47 157.625 192.451 157.463 192.451 157.463C194.926 155.318 192.286 151.193 192.286 151.193C192.286 151.193 199.283 119.462 196.778 117.673C194.469 116.023 190.361 114.723 184.941 113.817C184.79 114.154 183.271 116.786 178.198 116.848C172.645 116.916 171.451 114.424 171.25 113.987C166.917 114.868 162.683 116.023 159.879 118.003C159.047 118.59 161.765 133.376 165.395 150.863Z"
      fill="#E4EBF7"
    />
    <Path
      d="M185.913 116.167C185.988 116.116 186.091 116.135 186.142 116.211C186.193 116.286 186.174 116.388 186.099 116.44C182.001 119.237 177.657 119.457 173.656 118.156C173.158 117.993 172.711 117.819 172.322 117.645C172.185 117.584 172.066 117.527 171.964 117.476L171.837 117.41C171.757 117.366 171.728 117.266 171.772 117.186C171.816 117.107 171.916 117.078 171.996 117.122L172.051 117.151L172.112 117.182C172.209 117.231 172.325 117.285 172.457 117.345C172.835 117.514 173.271 117.684 173.758 117.842C177.67 119.115 181.91 118.9 185.913 116.167Z"
      fill="#A3B5D6"
    />
    <Path
      d="M196.411 126.447L196.438 126.449C196.519 126.463 196.576 126.533 196.575 126.613L196.573 126.639L192.459 151.159L192.537 151.293C192.559 151.331 192.581 151.372 192.605 151.415C192.738 151.659 192.871 151.934 192.995 152.233C193.456 153.344 193.674 154.474 193.513 155.537C193.398 156.291 193.094 156.976 192.575 157.571C192.516 157.639 192.411 157.646 192.343 157.587C192.274 157.527 192.267 157.423 192.327 157.354C192.803 156.808 193.082 156.181 193.187 155.488C193.338 154.494 193.131 153.419 192.691 152.36C192.571 152.072 192.443 151.807 192.315 151.572L192.251 151.457L192.155 151.294C192.152 151.29 192.149 151.286 192.147 151.283C192.131 151.257 192.122 151.227 192.121 151.197L192.123 151.166L196.248 126.585C196.262 126.504 196.332 126.447 196.411 126.447Z"
      fill="#A3B5D6"
    />
    <Path
      d="M160.993 126.697C161.074 126.683 161.151 126.729 161.178 126.804L161.185 126.83L165.557 150.834C165.561 150.861 165.56 150.888 165.551 150.914L165.509 151.056C165.502 151.082 165.494 151.111 165.485 151.143C165.435 151.329 165.385 151.538 165.338 151.767C165.155 152.659 165.075 153.569 165.154 154.413C165.258 155.526 165.631 156.415 166.327 157.007C166.396 157.066 166.404 157.17 166.345 157.239C166.286 157.309 166.182 157.317 166.113 157.258C165.344 156.603 164.937 155.635 164.825 154.444C164.743 153.563 164.826 152.622 165.015 151.701C165.064 151.465 165.115 151.249 165.167 151.057L165.225 150.853L160.861 126.889C160.846 126.808 160.893 126.731 160.967 126.704L160.993 126.697Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.256 184.602C183.256 184.602 178.801 185.097 175.667 184.293C171.216 183.151 165.807 177.631 162.508 174.496C162.631 164.722 165.824 160.412 165.824 160.412L192.759 160.604C192.759 160.604 196.039 165.546 196.534 176.105C196.534 176.105 186.062 184.204 183.256 184.602Z"
      fill="#597EF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.571 160.654L165.891 157.443L192.58 157.548L192.924 160.608C192.924 160.608 168.292 160.715 165.571 160.654Z"
      fill="#597EF7"
    />
    <Path
      d="M183.917 160.846C184.008 160.847 184.081 160.921 184.08 161.012L184.036 166.402L183.976 172.283C183.965 173.219 183.955 174.045 183.944 174.742L183.922 176.044C183.92 176.156 183.917 176.26 183.915 176.358C183.889 177.509 183.178 178.281 182.031 178.738C181.629 178.898 181.201 179.008 180.772 179.077C180.622 179.102 180.483 179.119 180.358 179.131L180.196 179.143C180.105 179.147 180.028 179.076 180.025 178.985C180.021 178.894 180.091 178.818 180.182 178.814L180.224 178.811C180.25 178.809 180.285 178.807 180.327 178.803C180.445 178.792 180.577 178.775 180.72 178.752C181.126 178.686 181.532 178.582 181.909 178.432C182.946 178.019 183.563 177.348 183.586 176.35L183.604 175.42L183.64 172.827C183.653 171.817 183.665 170.659 183.678 169.377L183.716 165.267L183.751 161.01C183.751 160.919 183.826 160.845 183.917 160.846Z"
      fill="#253368"
    />
    <Path
      d="M165.572 160.489L180.199 160.526L191.032 160.538C191.644 160.538 192.215 160.537 192.742 160.537C192.833 160.537 192.907 160.61 192.907 160.701C192.907 160.792 192.833 160.866 192.742 160.866L184.463 160.863L174.496 160.843L165.571 160.818C165.48 160.818 165.406 160.744 165.406 160.653C165.407 160.562 165.481 160.489 165.572 160.489Z"
      fill="#253368"
    />
    <Path
      d="M190.49 160.939C190.581 160.927 190.663 160.99 190.675 161.08L190.692 161.194C190.7 161.244 190.71 161.304 190.723 161.375C190.765 161.612 190.819 161.877 190.887 162.162C191.08 162.976 191.341 163.79 191.68 164.548C192.432 166.234 193.452 167.394 194.77 167.803C194.857 167.83 194.906 167.922 194.879 168.009C194.852 168.096 194.76 168.145 194.673 168.118C193.246 167.675 192.166 166.447 191.379 164.682C191.031 163.904 190.764 163.071 190.566 162.238C190.509 161.995 190.46 161.767 190.421 161.557L190.398 161.433C190.387 161.369 190.377 161.31 190.369 161.257L190.349 161.124C190.337 161.033 190.4 160.951 190.49 160.939Z"
      fill="#253368"
    />
    <Path
      d="M167.369 160.614C167.459 160.629 167.52 160.714 167.504 160.804L167.471 160.984C167.464 161.021 167.456 161.059 167.448 161.1C167.4 161.334 167.342 161.595 167.273 161.876C167.076 162.678 166.829 163.48 166.53 164.23C165.806 166.043 164.895 167.267 163.755 167.623C163.668 167.65 163.576 167.602 163.549 167.515C163.521 167.428 163.57 167.336 163.657 167.309C164.675 166.99 165.533 165.837 166.223 164.108C166.517 163.373 166.759 162.585 166.953 161.797C167.01 161.567 167.059 161.351 167.101 161.152L167.171 160.796C167.174 160.777 167.177 160.761 167.179 160.748C167.193 160.668 167.263 160.611 167.343 160.611L167.369 160.614Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.223 158.98C179.215 159.299 178.877 159.55 178.467 159.541C178.058 159.531 177.731 159.265 177.738 158.947C177.745 158.628 178.083 158.377 178.493 158.386C178.903 158.395 179.23 158.661 179.223 158.98Z"
      fill="#253368"
    />
    <Path
      d="M180.121 157.546C180.203 157.546 180.271 157.606 180.284 157.684L180.286 157.711V184.767C180.286 184.858 180.212 184.932 180.121 184.932C180.039 184.932 179.971 184.872 179.959 184.794L179.957 184.767V157.711C179.957 157.62 180.03 157.546 180.121 157.546Z"
      fill="#253368"
    />
    <Path
      d="M192.33 157.278C192.421 157.278 192.494 157.352 192.494 157.443C192.494 157.525 192.435 157.593 192.356 157.606L192.33 157.608H165.891C165.8 157.608 165.726 157.534 165.726 157.443C165.726 157.361 165.786 157.293 165.864 157.28L165.891 157.278H192.33Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.138 238.728C167.603 253.024 164.483 262.631 163.922 277.097C163.854 278.851 161.32 278.91 158.946 278.681C153.484 282.871 143.49 283.905 142.195 281.921C141.418 280.73 145.099 276.456 148.465 275.728C155.986 274.099 156.079 265.464 156.079 265.464C156.079 265.464 156.474 243.462 157.077 226.604C157.077 226.604 158.134 214.286 164.534 214.949C175.613 216.098 171.004 233.849 170.138 238.728Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.991 281.627C137.991 281.627 138.894 283.969 144.604 284.199C150.315 284.43 153.181 282.181 156.201 282.016C161.846 281.706 164.371 280.513 164.371 280.513C164.371 280.513 164.58 281.382 164.001 282.477C163.107 284.165 157.939 283.098 154.719 284.247C152.391 285.078 150.591 285.504 146.787 286.023C142.983 286.543 138.39 285.024 137.964 283.719C137.538 282.413 137.991 281.627 137.991 281.627Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.182 280.701C164.182 280.701 162.867 281.307 158.577 281.917C157.551 282.063 156.541 282.242 154.74 282.68C151.971 283.352 143.16 286.375 138.458 282.455C137.772 281.529 138.078 280.553 138.541 279.821C138.981 279.124 139.682 278.633 140.484 278.441C142.913 277.858 148.718 276.477 152.015 272.46C152.401 271.989 153.031 271.806 153.608 272C154.306 272.235 155.198 272.673 155.789 273.472C156.281 274.136 154.512 276.309 155.108 276.475C156.748 276.933 159.597 276.735 161.509 275.722C163.11 274.874 164.147 274.015 164.147 274.015C164.147 274.015 165.152 278.361 164.182 280.701Z"
      fill="#4E5C93"
    />
    <Path
      d="M140.057 279.853C140.119 279.771 140.236 279.755 140.318 279.816L140.353 279.841C140.396 279.871 140.464 279.913 140.556 279.964C140.766 280.08 141.02 280.197 141.321 280.306C142.719 280.816 144.54 280.987 146.805 280.612C146.906 280.595 147.002 280.664 147.019 280.765C147.036 280.866 146.967 280.962 146.866 280.979C144.538 281.364 142.652 281.187 141.193 280.656C140.874 280.539 140.602 280.414 140.376 280.289L140.28 280.234C140.193 280.183 140.131 280.142 140.094 280.114C140.012 280.052 139.996 279.935 140.057 279.853Z"
      fill="#A3B5D6"
    />
    <Path
      d="M150.247 274.338C150.283 274.242 150.39 274.192 150.486 274.228L150.572 274.263L150.603 274.276C150.693 274.315 150.793 274.362 150.9 274.418C151.206 274.576 151.511 274.77 151.797 275.002C152.18 275.312 152.498 275.668 152.728 276.07C152.779 276.159 152.748 276.273 152.659 276.324C152.57 276.375 152.456 276.344 152.405 276.255C152.2 275.895 151.912 275.574 151.563 275.29C151.298 275.076 151.014 274.895 150.729 274.748C150.655 274.709 150.584 274.675 150.519 274.646L150.385 274.588C150.373 274.583 150.363 274.579 150.357 274.577C150.26 274.541 150.211 274.434 150.247 274.338Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.531 237.35C183.479 251.692 185.451 261.479 184.523 275.78C184.411 277.515 186.791 277.839 189.05 277.862C193.772 282.56 203.079 284.628 204.497 282.81C205.349 281.719 203.305 278.291 200.205 277.236C192.451 274.596 193.08 265.15 193.08 265.15C193.08 265.15 194.921 243.444 196.051 226.783C196.051 226.783 196.295 214.543 190.202 214.525C179.653 214.493 182.208 232.455 182.531 237.35Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M208.487 282.985C208.487 282.985 207.402 285.1 202.001 284.743C196.6 284.386 194.127 281.98 191.3 281.521C186.015 280.662 183.757 279.285 183.757 279.285C183.757 279.285 183.473 280.083 183.908 281.171C184.58 282.85 189.554 282.366 192.471 283.771C194.581 284.788 196.232 285.37 199.762 286.241C203.292 287.113 207.77 286.144 208.302 284.958C208.835 283.771 208.487 282.985 208.487 282.985Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.916 279.481C183.916 279.481 185.095 280.184 189.072 281.189C190.024 281.43 190.957 281.7 192.609 282.293C195.149 283.205 203.142 286.936 207.965 283.718C208.703 282.915 208.513 281.965 208.151 281.229C207.806 280.528 207.196 279.996 206.46 279.734C204.231 278.941 198.904 277.058 196.202 272.943C195.886 272.461 195.311 272.225 194.748 272.35C194.068 272.501 193.184 272.824 192.547 273.516C192.017 274.093 193.464 276.317 192.886 276.414C191.296 276.68 188.633 276.206 186.934 275.061C185.511 274.101 184.621 273.188 184.621 273.188C184.621 273.188 183.238 277.18 183.916 279.481Z"
      fill="#4E5C93"
    />
    <Path
      d="M206.478 281.046C206.562 280.996 206.67 281.023 206.72 281.107C206.77 281.191 206.742 281.299 206.659 281.349L206.619 281.371C206.58 281.392 206.524 281.42 206.452 281.452C206.288 281.526 206.092 281.6 205.865 281.668C204.419 282.103 202.525 282.129 200.195 281.483C200.101 281.457 200.046 281.36 200.072 281.266C200.098 281.172 200.195 281.117 200.289 281.143C202.555 281.771 204.381 281.746 205.764 281.33C205.976 281.266 206.157 281.198 206.307 281.131L206.403 281.086C206.439 281.068 206.463 281.055 206.478 281.046Z"
      fill="#A3B5D6"
    />
    <Path
      d="M197.464 274.762C197.559 274.738 197.655 274.795 197.679 274.889C197.703 274.983 197.645 275.079 197.551 275.103L197.445 275.134C197.356 275.162 197.257 275.197 197.151 275.24C196.845 275.361 196.54 275.517 196.256 275.712C195.936 275.932 195.664 276.186 195.453 276.477C195.396 276.556 195.286 276.574 195.207 276.517C195.128 276.46 195.111 276.35 195.168 276.271C195.403 275.945 195.705 275.663 196.057 275.421C196.364 275.211 196.692 275.043 197.02 274.912C197.107 274.878 197.188 274.848 197.264 274.822L197.339 274.798C197.397 274.78 197.44 274.768 197.464 274.762Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.072 142.25C145.072 142.25 148.725 133.885 153.124 126.709C157.135 120.165 161.634 115.024 165.884 117.384C171.222 120.348 165.173 129.242 162.466 133.965C160.517 137.364 155.793 144.078 153.055 147.397C148.69 152.691 141.903 147.279 145.072 142.25Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.374 135.87C164.081 131.147 172.512 121.063 165.926 117.313C161.279 114.667 156.673 120.186 151.731 129.14L161.374 135.87Z"
      fill="#E4EBF7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.376 128.743C173.376 128.743 158.485 145.267 151.368 149.174C147.387 151.359 142.4 147.376 145.003 142.609C147.139 138.696 169.561 124.081 169.561 124.081C169.561 124.081 171.455 122.327 173.528 124.675C175.679 127.112 173.376 128.743 173.376 128.743Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.172 127.441H175.313C174.732 127.441 174.252 126.989 174.216 126.409L173.457 114.244C173.418 113.61 173.92 113.075 174.555 113.075H181.931C182.565 113.075 183.068 113.61 183.029 114.244L182.27 126.409C182.234 126.989 181.753 127.441 181.172 127.441Z"
      fill="#A3B5D6"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.172 128.071H175.313C174.732 128.071 174.252 127.62 174.216 127.039L173.457 114.875C173.418 114.241 173.92 113.706 174.555 113.706H181.931C182.565 113.706 183.068 114.241 183.029 114.875L182.27 127.039C182.234 127.62 181.753 128.071 181.172 128.071Z"
      fill="#253368"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.266 130C174.266 130 169.218 131.635 167.425 129.499C165.801 127.565 167.4 125.666 167.4 125.666C167.4 125.666 167.981 123.732 170.457 122.046C171.625 121.251 173.785 118.873 173.784 119.864C173.783 121.842 173.731 122.353 173.901 123.278C174.124 124.491 180.265 123.435 181.335 123.605C183.429 123.937 181.168 128.263 174.266 130Z"
      fill="#FFC999"
    />
    <Path
      d="M181.421 124.457C181.499 124.414 181.597 124.443 181.639 124.521C181.681 124.598 181.653 124.696 181.575 124.738C181.211 124.937 180.824 125.113 180.417 125.268C179.462 125.631 178.442 125.864 177.421 125.994C177.064 126.039 176.732 126.068 176.433 126.084L176.218 126.094L176.053 126.097L176.027 126.095C175.951 126.083 175.893 126.017 175.893 125.937C175.893 125.857 175.951 125.791 176.027 125.779L176.064 125.776L176.147 125.775C176.205 125.773 176.27 125.771 176.342 125.768L176.416 125.764C176.707 125.748 177.031 125.72 177.381 125.675C178.378 125.549 179.374 125.321 180.303 124.968C180.697 124.818 181.071 124.648 181.421 124.457Z"
      fill="#DD936F"
    />
    <Path
      d="M181.06 125.89C181.135 125.844 181.234 125.868 181.28 125.944C181.326 126.02 181.301 126.119 181.226 126.165L181.188 126.187C181.164 126.2 181.132 126.218 181.094 126.238L180.957 126.31C180.885 126.347 180.805 126.386 180.718 126.427C180.306 126.621 179.822 126.814 179.269 126.994C178.212 127.339 177.031 127.595 175.733 127.724C175.645 127.733 175.566 127.669 175.557 127.58C175.549 127.492 175.613 127.414 175.701 127.405C176.975 127.278 178.134 127.027 179.17 126.689C179.71 126.513 180.181 126.325 180.581 126.137C180.693 126.084 180.792 126.035 180.879 125.989L180.942 125.956C181.001 125.924 181.041 125.901 181.06 125.89Z"
      fill="#DD936F"
    />
    <Path
      d="M178.985 127.78C179.068 127.749 179.161 127.792 179.191 127.875C179.222 127.958 179.179 128.05 179.096 128.081C177.953 128.502 176.935 128.783 176.056 128.949C175.791 128.999 175.555 129.035 175.348 129.06L175.247 129.072C175.108 129.087 175.013 129.094 174.962 129.095C174.873 129.098 174.799 129.029 174.796 128.94C174.794 128.852 174.863 128.778 174.952 128.775L175.018 128.771C175.066 128.768 175.131 128.762 175.213 128.753C175.434 128.729 175.696 128.69 175.996 128.634C176.858 128.471 177.859 128.195 178.985 127.78Z"
      fill="#DD936F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.202 222.756C176.715 204.438 176.555 188.613 176.555 188.613C176.555 188.613 172.816 173.203 164.091 182.052C159.865 186.337 159.596 199.558 158.627 219.9C158.627 219.9 157.654 229.646 164.816 230.32C170.81 230.885 172.202 222.756 172.202 222.756Z"
      fill="#FFC999"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.012 210.207C166.969 211.866 158.645 207.979 158.645 207.979C159.286 202.638 160.191 193.768 162.691 172.846C173.353 167.355 179.385 184.534 179.385 184.534L175.012 210.207Z"
      fill="#597EF7"
    />
    <Path
      d="M159.8 204.579C159.835 204.495 159.932 204.456 160.016 204.491L160.121 204.534L160.25 204.585C160.445 204.66 160.674 204.744 160.935 204.835C161.683 205.094 162.541 205.353 163.497 205.595C167.137 206.518 171.095 206.938 175.154 206.575C175.245 206.566 175.325 206.633 175.333 206.724C175.341 206.815 175.274 206.895 175.184 206.903C171.084 207.27 167.09 206.846 163.416 205.915C162.531 205.691 161.729 205.452 161.019 205.212L160.827 205.146C160.638 205.081 160.465 205.018 160.309 204.96L160.038 204.856C159.968 204.828 159.917 204.808 159.887 204.795C159.803 204.759 159.764 204.662 159.8 204.579Z"
      fill="#253368"
    />
    <Path
      d="M183.448 184.109C183.619 184.075 183.847 184.328 183.535 184.43L183.315 184.493L181.457 184.989L179.478 185.509L175.175 210.235C175.165 210.291 175.127 210.338 175.076 210.359L175.049 210.368L174.96 210.387C174.888 210.401 174.803 210.418 174.707 210.435C174.43 210.485 174.11 210.535 173.748 210.582C169.101 211.181 163.855 210.686 158.867 208.272L158.571 208.127C158.49 208.086 158.457 207.987 158.497 207.906C158.538 207.824 158.637 207.791 158.719 207.832L158.79 207.868C163.778 210.342 169.049 210.856 173.706 210.255C174.011 210.216 174.286 210.174 174.529 210.132L174.78 210.086L174.869 210.069L179.171 185.348C179.181 185.294 179.217 185.248 179.266 185.226L179.3 185.215L182.818 184.286L183.389 184.13L183.403 184.126L183.423 184.117L183.448 184.109Z"
      fill="#253368"
    />
    <Path
      d="M166.955 125.82C167.028 125.77 167.128 125.788 167.177 125.862C167.222 125.927 167.212 126.013 167.158 126.066L167.135 126.085L148.988 138.458C148.914 138.508 148.815 138.489 148.765 138.416C148.72 138.351 148.73 138.265 148.785 138.211L148.807 138.193L166.955 125.82Z"
      fill="#DD936F"
    />
    <Path
      d="M205.732 126.559C205.797 126.495 205.901 126.496 205.965 126.561C206.022 126.618 206.028 126.707 205.983 126.771L205.964 126.794L197.6 135.061C197.535 135.125 197.431 135.124 197.367 135.059C197.31 135.002 197.304 134.913 197.349 134.849L197.368 134.826L205.732 126.559Z"
      fill="#A3B5D6"
    />
    <Path
      d="M151.596 129.045C151.643 128.979 151.729 128.957 151.8 128.99L151.826 129.005L156.664 132.416C156.738 132.469 156.756 132.572 156.703 132.646C156.657 132.712 156.57 132.734 156.499 132.701L156.474 132.686L151.636 129.274C151.561 129.222 151.544 129.119 151.596 129.045Z"
      fill="#A3B5D6"
    />
  </Svg>
);

const Page2 = ({navigation: {navigate}}) => {
  const width = useActiveWidth();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState({name: null, state: null, city: null});

  //const [footerHeight, setFooterHeight] = useState(null);
  //const onFocus = () => setFooterHeight(500);
  //<ScrollMB75 {...{footerHeight}}>

  return (
    <PageScrollMB footerHeight={500}>
      <View style={{alignItems: 'center'}}>
        <TopPart />
      </View>
      <View style={{height: 30}} />
      <View style={{width, height: 80, flexDirection: 'row'}}>
        <Pressable
          onPress={() => setPage(0)}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomWidth: 1,
            borderColor: page ? '#666666' : '#0081CA',
          }}
        >
          <Body color={page ? '#666666' : '#0081CA'}>Search by location</Body>
        </Pressable>
        <Pressable
          onPress={() => setPage(1)}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomWidth: 1,
            borderColor: !page ? '#666666' : '#0081CA',
          }}
        >
          <Body color={!page ? '#666666' : '#0081CA'}>Search by name</Body>
        </Pressable>
      </View>
      <View style={{height: 30}} />
      <H1 bold color="#0081CA" style={{fontSize: 28}}>
        Choose where to shop
      </H1>
      <View style={{margin: 30}}>
        <Body>
          We will automatically connect you to your closest local market!
          Discover what they have to offer. You can always find another market
          later!
        </Body>
        {page ? (
          <InputField
            defaultString="Enter Flea Market Name"
            onEndEditing={(name) => setSearch({...search, name})}
          />
        ) : (
          <>
            <InputField
              defaultString="State"
              onEndEditing={(state) => setSearch({...search, state})}
            />
            <InputField
              defaultString="City"
              onEndEditing={(city) => setSearch({...search, city})}
            />
          </>
        )}
      </View>
      <Button onPress={() => navigate('Select Market', {search})}>Find</Button>
    </PageScrollMB>
  );
};

export default Page2;
