import React from "react";
import { View } from "react-native";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

export const openMap = () => null;
const MapContainer = ({ google, width, lon, lat }) => {
  if (Math.abs(lon) > 0 && Math.abs(lat) > 0)
    return (
      <View style={{ width, height: width }}>
        <Map
          google={google}
          zoom={14}
          style={{ width, height: width }}
          initialCenter={{
            lat,
            lng: lon,
          }}
        >
          <Marker name={"Mesa Market Place"} />
        </Map>
      </View>
    );
  else return null;
};

export default GoogleApiWrapper({
  //    AIzaSyCk-XX8qunq2i3slKybeSyhdlMidRdhATE
  apiKey: "AIzaSyA7tvY6WpXwqdqxy8VU-BDgjP4Altrhqks",
})(MapContainer);
