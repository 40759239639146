import React, {useState} from 'react';
import {View} from 'react-native';

import DataManager from '../../Support/DataManager';
import {Alert, Button, H3, InputField, PageScrollMB} from 'bl-components';

const Page1 = ({navigation: {pop}}) => {
  const [problem, setProblem] = useState('');
  const [submitting, setSubmitting] = useState(false);
  return (
    <PageScrollMB>
      <View style={{margin: 20}}>
        <H3>Describe your problem related to the market</H3>
      </View>
      <View style={{marginLeft: 10, marginRight: 10}}>
        <InputField mult maxLength={3000} onEndEditing={(p) => setProblem(p)} />
      </View>
      <View style={{marginTop: 20, alignItems: 'center'}}>
        {!submitting ? (
          <Button
            style={{width: 200}}
            onPress={async () => {
              if (problem !== '') {
                setSubmitting(true);
                await DataManager.submitProblem(problem);
                Alert(
                  'Success',
                  'Your problem was successfully reported to the market',
                  () => pop(),
                );
                setSubmitting(false);
              } else
                Alert(
                  'No text provided',
                  'Please describe your problem and then submit it',
                );
            }}
          >
            Submit
          </Button>
        ) : null}
      </View>
    </PageScrollMB>
  );
};

export default Page1;
