// import library to help create a component
import React, { Component } from "react";
import { Animated, Easing, TouchableOpacity, View } from "react-native";
import Body from "../Text/Body";
import gS from "../GlobalStyle";
import Chevron from "../Icons/Chevron";

class DropOver extends Component {
  state = {
    opacity: new Animated.Value(0),
    height: new Animated.Value(0),
    zIndex: -1,
    toggle: false,
    childHeight: 200,
  };

  hideContent = () => {
    const { height } = this.state;

    Animated.timing(height, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false, // <-- neccessary
    }).start(() => this.setState({ toggle: false, zIndex: -10 }));
  };
  showContent = () => {
    this.setState({ zIndex: 10 });
    const { height } = this.state;

    Animated.timing(height, {
      toValue: 1,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false, // <-- neccessary
    }).start(() => this.setState({ toggle: true }));
  };

  render() {
    const { height, toggle, zIndex, childHeight } = this.state;
    // Label is a string for the cheveron and the label is a component
    const { children, label, arrowLabel } = this.props;
    const maxHeight = height.interpolate({
      inputRange: [0, 1],
      outputRange: [40, childHeight], // <-- value that larger than your content's height
    });
    const opacity = height.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 0, 1], // <-- value that larger than your content's height
    });
    const rotate = height.interpolate({
      inputRange: [0, 1],
      outputRange: ["180deg", "270deg"], // <-- value that larger than your content's height
    });

    return (
      <TouchableOpacity
        style={{
          top: 0,
          width: "100%",
          paddingTop: 15,
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: gS.white,
          zIndex,
        }}
        onPress={!toggle ? this.showContent : this.hideContent}
      >
        <Animated.View
          style={{
            height: maxHeight,
            width: "100%",
            zIndex,
            //transform: [{scaleX: maxHeight}]
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {label()}
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Body color="#898989" bold>
                {arrowLabel}
              </Body>
              <View style={{ width: 10 }} />
              <Animated.View style={{ transform: [{ rotate: rotate }] }}>
                <Chevron color={"#525252"} />
              </Animated.View>
            </View>
          </View>
          <Animated.View
            onLayout={({
              nativeEvent: {
                layout: { height },
              },
            }) => this.setState({ childHeight: height + 50 })}
            style={{ opacity: opacity }}
          >
            {children}
          </Animated.View>
        </Animated.View>
      </TouchableOpacity>
    );
  }
}

export default DropOver;
