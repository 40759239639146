// import library to help create a component
import React, {Component} from 'react';
import {Platform, View, Image} from 'react-native';
import DataManager from '../../Support/DataManager';

import {
  PassField,
  UserField,
  ActivityButton,
  Sep,
  TextBox,
  H1,
  PageScrollMB,
  WordButton,
} from 'bl-components';

class Login extends Component {
  // class level property
  state = {
    username: 'username',
    password: 'password',
    buttonVisible: true,
    scrollViewRef: null,
  };
  scrollToRef = null;
  passScrollToRef = null;
  scrollButtonHandler = (y) => {
    if (
      Platform.OS !== 'web' &&
      typeof this.state.scrollViewRef.scrollTo === 'function'
    ) {
      this.state.scrollViewRef.scrollTo({x: 0, y: y, animated: true});
    }
  };
  // componentDidMount() {
  //   DataManager.tryStoredCred();
  // }

  submitLogin = () => {
    this.setState({buttonVisible: false});
    DataManager.cred.username = this.state.username;
    const failure = () => {
      this.setState({buttonVisible: true});
    };
    const success = () => {
      this.setState({buttonVisible: true});
      this.props.navigation.pop();
    };
    DataManager.Login(this.state.password, () => null, success, failure);
  };

  // Can only return one tag so wrap with a view
  render() {
    const ImageStyle = {
      resizeMode: 'contain',
      width: '100%',
      height: 250,
    };
    return (
      <PageScrollMB
        width={this.props.appState.dim.tabletWidth}
        passRef={(ref) => {
          this.setState({scrollViewRef: ref});
        }}
      >
        <View style={{backgroundColor: '#fff'}}>
          <Image
            style={ImageStyle}
            source={require('../../Support/Login.png')}
          />
          <TextBox>
            <H1>Welcome!</H1>
          </TextBox>
          <View
            ref={(view) => {
              this.scrollToRef = view;
            }}
          />
          <View style={{alignItems: 'center'}}>
            <View style={{width: '90%'}}>
              <UserField
                defaultString="Email"
                onFocus={() =>
                  this.scrollToRef.measure((fx, fy, width, height, px, py) =>
                    this.scrollButtonHandler(fy + 50),
                  )
                }
                onEndEditing={(text) => this.setState({username: text})}
              />
              <Sep />
              <PassField
                defaultString="Password"
                onFocus={() =>
                  this.scrollToRef.measure((fx, fy, width, height, px, py) =>
                    this.scrollButtonHandler(fy + 150),
                  )
                }
                onEndEditing={(text) => this.setState({password: text})}
              />
            </View>
            <View style={{margin: 20, width: 200}}>
              <ActivityButton
                isVisible={this.state.buttonVisible}
                onPress={this.submitLogin}
              >
                Log In
              </ActivityButton>
            </View>
            <View style={{height: 20, margin: 20}}>
              <WordButton
                blue
                underline
                onPress={() => this.props.navigation.navigate('Create Account')}
              >
                Create Account
              </WordButton>
            </View>
            <View style={{height: 20, margin: 0}}>
              <WordButton
                grey
                underline
                onPress={() =>
                  this.props.navigation.navigate('Forgot Password')
                }
              >
                Forgot Password
              </WordButton>
            </View>
          </View>
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
          <Sep />
        </View>
      </PageScrollMB>
    );
  }
}

export default Login;
