// import libraries for making the header component
import React from "react";
import Body from "../Text/Body";
import H3 from "../Text/H3";
import gS from "../GlobalStyle";

function monthConvert(string) {
  let monthIndex = parseInt(string, 10);
  let monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthArray[monthIndex];
}
function dayConvert(string) {
  let dayIndex = parseInt(string, 10);
  let dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return dayArray[dayIndex];
}

const MyDate = ({ date: d, year, blue }) => {
  const date = new Date(d);
  return (
    <H3
      style={{ color: typeof blue === "undefined" ? gS.darkGrey : gS.darkBlue }}
    >
      {dayConvert(date.getDay()) +
        ", " +
        monthConvert(date.getMonth()) +
        " " +
        date.getDate() +
        (typeof year !== "undefined" ? " " + date.getFullYear() : "")}
    </H3>
  );
};

export function ShortDate({ date }) {
  return (
    <H3 style={{ color: gS.darkGrey }}>
      {date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()}
    </H3>
  );
}

export const stringMyDateShort = (date, year) => {
    const d = new Date(date)
    return (monthConvert(d.getMonth()) +
        " " +
        d.getDate() +
        (year ? ", " + d.getFullYear() : ""))
}

export const MyDateShort = ({ date, year, body, blue }) => {
  return body ? (
    <Body
      style={{ color: typeof blue === "undefined" ? gS.darkGrey : gS.darkBlue }}
    >
      {stringMyDateShort(date, typeof year !== "undefined")}
    </Body>
  ) : (
    <H3
      style={{ color: typeof blue === "undefined" ? gS.darkGrey : gS.darkBlue }}
    >
      {stringMyDateShort(date, typeof year !== "undefined")}
    </H3>
  );
};
export const MonthDate = ({ date, body, blue }) => {
  return body ? (
    <Body
      style={{ color: typeof blue === "undefined" ? gS.darkGrey : gS.darkBlue }}
    >
      {monthConvert(date.getMonth()) + " " + date.getFullYear()}
    </Body>
  ) : (
    <H3
      style={{ color: typeof blue === "undefined" ? gS.darkGrey : gS.darkBlue }}
    >
      {monthConvert(date.getMonth()) + " " + date.getFullYear()}
    </H3>
  );
};

export const MyTime = ({ time }) => {
  const stime = time.split(":");
  const hr = parseInt(stime[0], 10);
  return (
    <H3 style={{ color: gS.darkGrey }}>
      {(hr % 12) + ":" + stime[1] + (hr / 12 ? " P.M." : " A.M.")}
    </H3>
  );
};

export const MyTimeTimestamp = ({ timestamp }) => {
  const date = new Date(timestamp)
  const hr = date.getHours();
  const min = date.getMinutes();
  var smin = ''+min;
  smin = smin.length-1?smin:('0'+smin)
  return (
    <H3 style={{ color: gS.darkGrey }}>
      {(hr % 12) + ":" + smin + (hr / 12 ? " P.M." : " A.M.")}
    </H3>
  );
};

export default MyDate;
