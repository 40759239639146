import React from 'react';
import {Platform, View} from 'react-native';

import {Button, useActiveWidth, H1, H3, PageScrollMB} from 'bl-components';

const Bullet = ({children}) => (
  <View
    style={{
      marginTop: 10,
      height: 35,
      width: 35,
    }}
  >
    <View
      style={{
        height: 30,
        width: 30,
        borderLeftWidth: 3,
        borderBottomWidth: 3,
        borderBottomLeftRadius: 3,
        borderColor: '#0081CA',
      }}
    >
      <View
        style={{
          top: -5,
          height: 30,
          width: 30,
          marginLeft: 2,
          borderWidth: 3,
          borderRadius: 3,
          borderColor: '#0081CA',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <H3 bold color="#0081CA">
          {children}
        </H3>
      </View>
    </View>
  </View>
);

const Page1 = ({navigation: {navigate}}) => {
  const width = useActiveWidth();
  return (
    <PageScrollMB>
      {Platform.OS !== 'web' ? <View style={{height: 40}} /> : null}
      <View
        style={{
          left: 40,
          width: 10,
          height: 150,
          backgroundColor: '#E6E6E6',
          position: 'absolute',
          zIndex: -10,
        }}
      />
      <View
        style={{
          left: 10,
          width: width - 50,
          top: 110,
          height: 10,
          backgroundColor: '#E6E6E6',
          position: 'absolute',
          zIndex: -10,
        }}
      />
      <View
        style={{
          right: 40,
          width: 10,
          top: 110,
          height: 90,
          backgroundColor: '#E6E6E6',
          position: 'absolute',
          zIndex: -10,
        }}
      />
      <View
        style={{
          right: 40,
          width,
          top: 250,
          height: 10,
          backgroundColor: '#E6E6E6',
          position: 'absolute',
          zIndex: -10,
        }}
      />
      <View style={{height: 30}} />
      <H1 bold color="#0081CA" style={{fontSize: 28}}>
        Welcome!
      </H1>
      <View style={{height: 30}} />
      <View
        style={{
          width: width - 100,
          height: 75,
          backgroundColor: '#00AD10',
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          justifyContent: 'center',
        }}
      >
        <H1 color="white" bold>
          Discover Local Markets
        </H1>
      </View>
      <View style={{height: 30}} />
      <View
        style={{
          alignItems: 'flex-end',
          width,
          height: 75,
        }}
      >
        <View
          style={{
            width: width - 100,
            height: 75,
            backgroundColor: '#FFC700',
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            justifyContent: 'center',
          }}
        >
          <H1 color="white" bold>
            Discover Local Markets
          </H1>
        </View>
      </View>
      <View style={{height: 60}} />
      <View
        style={{
          top: 200,
          left: (width - 600) / 2,
          position: 'absolute',
          transform: [{scaleX: 3}, {scaleY: 0.5}],
          width: 600, // 2.5*w
          height: 600,
          borderWidth: 2,
          borderRadius: 1000,
          borderColor: '#00AD10',
        }}
      />
      <View
        style={{
          width: width - 80,
          padding: 40,
          height: 320,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Bullet>1</Bullet>
          <View style={{width: 30}} />
          <H3>Choose the state you shop in the most</H3>
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Bullet>2</Bullet>
          <View style={{width: 30}} />
          <H3>Select a market that interests you</H3>
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Bullet>3</Bullet>
          <View style={{width: 30}} />
          <H3>Follow the market and the vendors to stay updated!</H3>
        </View>
      </View>
      <View style={{height: 20}} />
      <Button blue onPress={() => navigate('Page2')}>
        Get Started
      </Button>
    </PageScrollMB>
  );
};

export default Page1;
