// import library to help create a component
import React, { Component } from "react";
import { TouchableOpacity, View } from "react-native";
import VendorInfo from "./VendorInfo";
import CouponDetail from "../Items/CouponDetail";
import ItemDetail from "../Items/ItemDetail";
import ItemList from "../Items/ItemList";
import HorizBar from "../Bars/HorizBar";
import { PageScrollMB, Search, H3 } from "bl-components";

const couponSearchFilter = ({ title }, searchReg) => {
  return title.search(searchReg) > -1;
};
const couponSort = ({ title: t1 }, { title: t2 }, searchReg) => {
  const s1 = t1.toLowerCase().search(searchReg) > -1;
  const s2 = t2.toLowerCase().search(searchReg) > -1;
  return s2 - s1;
};
const productSearchFilter = ({ tags, hiddenTags, title }, searchReg) => {
  const s =
    hiddenTags
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      tags
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      title.toLowerCase().search(searchReg) >
    -1;
  return s;
};
const productSearchSort = (
  { hiddenTags: ht1, tags: t1, title: ti1 },
  { hiddenTags: ht2, tags: t2, title: ti2 },
  searchReg
) => {
  const s1 =
    ht1
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t1
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      ti1.toLowerCase().search(searchReg) >
    -1;
  const s2 =
    ht2
      .map((i) => i.toLowerCase().search(searchReg) > -1)
      .reduce((pv, cv) => pv + cv, 0) +
      t2
        .map((i) => i.toLowerCase().search(searchReg) > -1)
        .reduce((pv, cv) => pv + cv, 0) +
      ti2.toLowerCase().search(searchReg) >
    -1;
  return s2 - s1;
};
class VendorComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      update: 0,
      search: "",
    };

    this.Products = this.products("");
    this.Coupons = this.coupons("");
  }

  // componentDidUpdate(prevState) {
  //   if (prevState.update !== this.state.update) {
  //     this.Products = this.products("");
  //     this.Coupons = this.coupons("");
  //   }
  // }

  products = (search) => {
    const { Products, ItemNavigate } = this.props;
    // var se = `(${search}|`+search.split(" ").reduce((pv,cv) => `${pv}${cv}|`, '')
    // se = se.substring(0, se.length-1) + ')'
    // const searchReg = new RegExp(se.toLowerCase(), "i");
    const searchReg = new RegExp(
      search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").toLowerCase(),
      "i"
    );

    return Products.filter((i) =>
      search === "" ? true : productSearchFilter(i, searchReg)
    )
      .map((i) => i.category)
      .sort()
      .sort((c1, c2) =>
        (c1 === "Featured" && c2 === "Featured") ||
        (c1 !== "Featured" && c2 !== "Featured")
          ? 0
          : c1 === "Featured"
          ? -1
          : 1
      )
      .filter((i, ind, self) => self.indexOf(i) === ind)
      .map((category) => ({
        title: category,
        data: Products.filter((Item) => Item.category === category)
          .sort((v1, v2) => productSearchSort(v1, v2, searchReg))
          .map((item) => {
            return typeof this.props.productRender !== "undefined" ? (
              this.props.productRender(item)
            ) : (
              <ItemDetail
                Item={item}
                onPress={
                  typeof ItemNavigate === "undefined"
                    ? () => console.log("No Item Navigate")
                    : () => ItemNavigate(item)
                }
              />
            );
          }),
      }));
  };
  coupons = (search) => {
    const searchReg = new RegExp(
      search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").toLowerCase(),
      "i"
    );
    return this.props.Coupons.filter((i) =>
      search === "" ? true : couponSearchFilter(i, searchReg)
    )
      .sort((v1, v2) => couponSort(v1, v2, searchReg))
      .map((i) => i.category)
      .filter((i, ind, self) => self.indexOf(i) === ind)
      .map((category) => ({
        title: category,
        data: this.props.Coupons.filter((Item) => Item.category === category)
          .sort((v1, v2) => couponSort(v1, v2, searchReg))
          .map((item) => {
            return typeof this.props.productRender !== "undefined" ? (
              this.props.couponRender(item)
            ) : (
              <CouponDetail
                onRedeem={this.props.onRedeem}
                Item={item}
                userIndex={
                  typeof this.props.userIndex === "undefined"
                    ? -1
                    : this.props.userIndex
                }
              />
            );
          }),
      }));
  };

  // Render method must be defined that returns some jsx
  // const search = this.state.search;
  // const searchReg = new RegExp(search.toLowerCase(), "i");
  // Category sort
  // Items.sort(({title: t1}, {title: t2}) => {
  //   const s1 = t1.toLowerCase().search(searchReg) > -1;
  //   const s2 = t2.toLowerCase().search(searchReg) > -1;
  //   return s2 - s1;
  // });
  render() {
    const setSelectedIndex =
      typeof this.props.setSelectedIndex === "undefined"
        ? () => null
        : this.props.setSelectedIndex;
    const alwaysRender = typeof this.props.alwaysRender !== "undefined";
    if (this.Products.length || this.Coupons.length || alwaysRender) {
      const Items =
        (this.Products.length && this.Coupons.length) || alwaysRender
          ? !this.state.selectedIndex
            ? this.Products
            : this.Coupons
          : this.Products.length
          ? this.Products
          : this.Coupons;

      return (
        <ItemList
          onRefresh={
            typeof this.props.onRefresh !== "undefined"
              ? () => {
                  this.props.onRefresh();
                  this.setState({ update: 0 });
                }
              : undefined
          }
          Items={Items}
          itemWidth={!this.state.selectedIndex ? 160 : 340}
          ListHeaderComponentStyle={{ zIndex: 1000 }}
          ListHeaderComponent={() => (
            <>
              <VendorInfo
                MessageNavigate={this.props.MessageNavigate}
                onInfoRoute={this.props.onInfoRoute}
                Map={this.props.Map}
                favInitial={
                  typeof this.props.favInitial === "undefined"
                    ? false
                    : this.props.favInitial
                }
                numPosts={
                  typeof this.props.numPosts === "undefined"
                    ? this.props.Products.length + this.props.Coupons.length
                    : this.props.numPosts
                }
                favCallback={
                  typeof this.props.favCallback === "undefined"
                    ? (_, s) => s()
                    : this.props.favCallback
                }
                Vendor={this.props.Vendor}
                followComponent={this.props.followComponent}
                shareCallback={this.props.shareCallback}
              />
              {(this.Products.length && this.Coupons.length) || alwaysRender ? (
                <HorizBar
                  underlineWidth={20}
                  initial={this.state.selectedIndex}
                  barStrings={["Listings", "Coupons"]}
                  onChange={(index) => {
                    this.Products = this.products("");
                    this.Coupons = this.coupons("");
                    this.setState({ selectedIndex: index, search: "" });
                    setSelectedIndex(index);
                  }}
                />
              ) : null}
              <View style={{ margin: 10 }}>
                <Search
                  width={this.props.width}
                  initialValue={this.state.search}
                  setSearch={(search) => {
                    if (this.state.selectedIndex)
                      this.Coupons = this.coupons(search);
                    else this.Products = this.products(search);
                    this.setState({ search });
                  }}
                  predictiveContent={
                    this.state.selectedIndex
                      ? (search, setNewSearch) => {
                          const searchReg = new RegExp(
                            search
                              .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
                              .toLowerCase(),
                            "i"
                          );
                          const Predict = this.props.Coupons.filter(
                            (_, ind) => ind < 500
                          )
                            .map(({ title }) => title)
                            .filter((i, ind, self) => self.indexOf(i) === ind)
                            .filter((_, ind) => ind < 20)
                            .filter(
                              (i) => i.toLowerCase().search(searchReg) > -1
                            )
                            .sort((i1, i2) => {
                              const s1 = i1.toLowerCase().search(searchReg);
                              const s2 = i2.toLowerCase().search(searchReg);
                              return s1 - s2;
                            });
                          return (
                            <View style={{ margin: 20 }}>
                              {Predict.map((i, ind) => (
                                <TouchableOpacity
                                  key={i + ind}
                                  style={{ marginBottom: 10 }}
                                  onPress={() => setNewSearch(i)}
                                >
                                  <H3>{i}</H3>
                                </TouchableOpacity>
                              ))}
                            </View>
                          );
                        }
                      : (search, setNewSearch) => {
                          const searchReg = new RegExp(
                            search
                              .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
                              .toLowerCase(),
                            "i"
                          );
                          const Predict = this.props.Products.filter(
                            (_, ind) => ind < 500
                          )
                            .map(({ tags, hiddenTags, title }) => [
                              ...tags,
                              ...hiddenTags,
                              title,
                            ])
                            .flat()
                            .filter((i, ind, self) => self.indexOf(i) === ind)
                            .filter((_, ind) => ind < 20)
                            .filter(
                              (i) => i.toLowerCase().search(searchReg) > -1
                            )
                            .sort((i1, i2) => {
                              const s1 = i1.toLowerCase().search(searchReg);
                              const s2 = i2.toLowerCase().search(searchReg);
                              return s1 - s2;
                            });
                          return (
                            <View style={{ margin: 20 }}>
                              {Predict.map((i, ind) => (
                                <TouchableOpacity
                                  key={i + ind}
                                  style={{ marginBottom: 10 }}
                                  onPress={() => setNewSearch(i)}
                                >
                                  <H3>{i}</H3>
                                </TouchableOpacity>
                              ))}
                            </View>
                          );
                        }
                  }
                />
              </View>
            </>
          )}
        />
      );
    } else {
      return (
        <PageScrollMB>
          <VendorInfo
            MessageNavigate={this.props.MessageNavigate}
            onInfoRoute={this.props.onInfoRoute}
            Map={this.props.Map}
            favInitial={
              typeof this.props.favInitial === "undefined"
                ? false
                : this.props.favInitial
            }
            numPosts={
              typeof this.props.numPosts === "undefined"
                ? this.props.Products.length + this.props.Coupons.length
                : this.props.numPosts
            }
            favCallback={
              typeof this.props.favCallback === "undefined"
                ? (_, s) => s()
                : this.props.favCallback
            }
            Vendor={this.props.Vendor}
            followComponent={this.props.followComponent}
            shareCallback={this.props.shareCallback}
          />
        </PageScrollMB>
      );
    }
  }
}

export default VendorComp;
