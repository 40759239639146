import React, { useEffect, useRef, useState, useCallback } from "react";

import SvgButton from "../Buttons/SvgButton";
import Hamburger from "../Buttons/Hamburger";
import { setTopLeftButton, setTopRightButton, TopBar } from "../Router/TopBar";

import {
  Platform,
  View,
  Animated,
  Easing,
  Pressable,
  PanResponder,
} from "react-native";

export var OpenDrawer = () => null;
export var CloseDrawer = () => null;

export const Drawer = ({
  dim: { windowWidth: width, windowHeight: height },
  children,
}) => {
  const translate1X = new Animated.Value(0);
  const translate2X = new Animated.Value(0);
  //const scaleX = new Animated.Value(1);
  const duration = 150;
  const pan = useRef(new Animated.ValueXY()).current;
  const changePosition = (open) => {
    const toValue = width;
    if (open) {
      Animated.timing(translate2X, {
        toValue: 0,
        duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web",
      }).start(() => {
        Animated.timing(translate1X, {
          toValue: 0,
          duration: 1,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }).start(() => null);
      });
    } else {
      Animated.parallel([
        Animated.timing(translate1X, {
          toValue,
          duration: 1,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
        Animated.timing(translate2X, {
          toValue: toValue * 0.8,
          duration,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
      ]).start(() => pan.setValue({ x: 0, y: 0 }));
    }
  };

  /*
  const pan = useRef(new Animated.ValueXY()).current;
  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: (_, {dx}) => {
			  console.log(pan.x._value)
        translate2X.setValue(pan.x._value);
      },
      onPanResponderMove: Animated.event(
        [
          null,
          { dx: pan.x, dy: pan.y }
        ]
      ),
      onPanResponderRelease: () => {
        translate2X.flattenOffset();
      }
    })
  ).current;
  */

  const panResponder = PanResponder.create({
    //onStartShouldSetPanResponder: (_, {vy}) => {console.log(vy); return vy===0?true:false},
    //onMoveShouldSetPanResponder: (_, {dy}) => {console.log(dy); return Math.abs(dy)<10?true:false},
    onMoveShouldSetPanResponderCapture: (_, { dx, dy }) =>
      Math.abs(dx) > 2 && Math.abs(dy) < 2,
    onMoveShouldSetPanResponder: (_, { dx, dy }) =>
      Math.abs(dx) > 2 && Math.abs(dy) < 2,
    //onPanResponderGrant: (_, {dx}) => {
    //    pan.setValue({
    //      x: pan.x._value,
    //      y: pan.y._value
    //    });
    //},
    //onPanResponderMove: Animated.event([
    //  null,
    //  {
    //    dx: pan.x, // x,y are Animated.Value
    //    //dy: pan.y,
    //  },
    //]),
    onPanResponderMove: (_, { dx }) => {
      //console.log((pan.x._value+dx))
      //console.log(Math.abs(pan.x._value+dx) > width/3)
      pan.setValue({
        x: dx > 0 ? 0 : dx,
        y: 0,
      });
    },
    onPanResponderRelease: () => {
      if (Math.abs(pan.x._value) > width / 3) {
        CloseDrawer();
      } else {
        Animated.spring(
          pan, // Auto-multiplexed
          { toValue: { x: 0, y: 0 }, useNativeDriver: Platform.OS !== "web" } // Back to zero
        ).start();
      }
    },
  });

  CloseDrawer = () => {
    changePosition(true);
  };
  OpenDrawer = () => {
    changePosition(false);
  };

  return (
    <Animated.View
      style={{
        position: "absolute",
        height,
        width: width,
        top: 0,
        left: -width,
        zIndex: 1000,
        transform: [{ translateX: translate1X }],
        backgroundColor: "rgba(0,0,0,0.8)",
      }}
    >
      <Animated.View
        style={{
          height,
          width: width * 0.8,
          transform: [
            { translateX: -width * 0.8 },
            { translateX: translate2X },
            { translateX: pan.x },
          ],
          zIndex: 100,
          backgroundColor: "#fff",
        }}
        {...panResponder.panHandlers}
      >
        {children}
      </Animated.View>
      <Pressable
        onPress={() => changePosition(true)}
        style={{
          position: "absolute",
          top: 0,
          height,
          width,
        }}
      />
    </Animated.View>
  );
};

export var OpenRightDrawer = () => null;
export var CloseRightDrawer = () => null;

export const RightDrawer = ({
  appState,
  dim: { windowWidth: width, windowHeight: height },
  children,
}) => {
  const translate1X = new Animated.Value(0);
  const translate2X = new Animated.Value(0);
  const pan = useRef(new Animated.ValueXY()).current;
  const changePosition = (open) => {
    const duration = 250;
    const toValue = width;
    if (open) {
      Animated.timing(translate2X, {
        toValue: 0,
        duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web",
      }).start(() => {
        Animated.timing(translate1X, {
          toValue: 0,
          duration: 1,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }).start(() => null);
      });
    } else {
      Animated.parallel([
        Animated.timing(translate1X, {
          toValue,
          duration: 1,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
        Animated.timing(translate2X, {
          toValue: -3.8 * width,
          duration,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web",
        }),
      ]).start(() => pan.setValue({ x: 0, y: 0 }));
    }
  };

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponderCapture: (_, { dx, dy }) =>
      Math.abs(dx) > 2 && Math.abs(dy) < 2,
    onMoveShouldSetPanResponder: (_, { dx, dy }) =>
      Math.abs(dx) > 2 && Math.abs(dy) < 2,
    onPanResponderMove: (_, { dx }) => {
      pan.setValue({
        x: dx < 0 ? 0 : dx,
        y: 0,
      });
    },
    onPanResponderRelease: () => {
      if (Math.abs(pan.x._value) > width / 3) {
        CloseRightDrawer();
      } else {
        Animated.spring(
          pan, // Auto-multiplexed
          { toValue: { x: 0, y: 0 }, useNativeDriver: Platform.OS !== "web" } // Back to zero
        ).start();
      }
    },
  });

  CloseRightDrawer = () => {
    changePosition(true);
  };
  OpenRightDrawer = () => {
    changePosition(false);
  };

  var newChildren = children;
  if (typeof children === "object") {
    if (Array.isArray(children)) {
      newChildren = children.map((i, ind) =>
        React.cloneElement(i, { OpenRightDrawer, CloseRightDrawer, key: ind })
      );
    } else {
      newChildren = React.cloneElement(children, {
        OpenRightDrawer,
        CloseRightDrawer,
      });
    }
  }

  return (
    <Animated.View
      style={{
        position: "absolute",
        height,
        width: width,
        top: 0,
        left: -width,
        zIndex: 1000,
        transform: [{ translateX: translate1X }],
        backgroundColor: "rgba(0,0,0,0.8)",
      }}
    >
      <Pressable
        onPress={() => changePosition(true)}
        style={{
          position: "absolute",
          top: 0,
          height,
          width,
        }}
      />
      <Animated.View
        style={{
          height,
          width: width * 0.8,
          transform: [
            { translateX: 4 * width },
            { translateX: translate2X },
            { translateX: pan.x },
          ],
          zIndex: 100,
          backgroundColor: "#fff",
        }}
        {...panResponder.panHandlers}
      >
        {newChildren}
      </Animated.View>
    </Animated.View>
  );
};
/*
              <View
                style={{
                  zIndex: 10000,
                  backgroundColor: "#B10101",
                  top: -11,
                  right: -3,
                  borderRadius: 100,
                  width: 22,
                  height: 22,
                        alignItems: 'center', justifyContent: 'center',
                  position: "absolute",
                }}
              >
                <Body style={{fontSize: 12, fontWeight: "900"}} white>{unread>99?99:unread}</Body>
              </View>
              */
const Layout = ({
  tabs,
  children,
  appState,
  leftDrawer,
  RightDrawerComponent,
  initialIndex,
  getNavigation,
  sendMessage,
  getMessages,
  getConversations,
  getUnread,
  onNotificationPress,
  onInactiveMessaging,
}) => {
  const { windowWidth: width, windowHeight: height } = appState.dim;
  const topHeight = Platform.OS === "ios" ? 100 : 60;
  const bottomHeight = Platform.OS === "web" ? 70 : 90;

  const [ls, setLS] = useState(true);
  const [rs, setRS] = useState(false);
  const activeTabBar = width <= 950;
  const childHeight = !activeTabBar
    ? height - topHeight
    : height - topHeight - bottomHeight;
  const rightDrawerWidth = rs && width > 1300 ? 350 : 0;
  const leftDrawerWidth =
    ls * (width > 950) ? 350 : 0;

  const childWidth = width - leftDrawerWidth - rightDrawerWidth;

  var newChildren = children;
  const [index, setIndex] = useState(
    typeof initialIndex === "undefined" ? 0 : initialIndex
  );
  const [drawerUpdate, setDrawerUpdate] = useState(
    "tabbar" 
  );

  const toggleRightDrawer = useCallback(() => {
    if (width > 1300) {
      setRS(!rs);
    } else {
      OpenRightDrawer();
    }
  }, [setRS, rs, width]);
  const toggleLeftDrawer = useCallback(() => {
    if (activeTabBar) {
      OpenDrawer();
    } else {
      setLS(!ls);
    }
  }, [activeTabBar, ls, setLS]);
  appState.events.toggleLeftDrawer = toggleLeftDrawer;
  appState.events.toggleRightDrawer = toggleRightDrawer;
  appState.tabs = tabs;
  appState.tabIndex = index;
  appState.events.setTabIndex = setIndex;
  appState.dim = {
    ...appState.dim,
    tabletWidth: childWidth > 600 ? 600 : childWidth,
    width: childWidth,
    height: childHeight,
    rightDrawerWidth,
    leftDrawerWidth,
    activeTabBar,
    isMobile: activeTabBar,
    tabBarHeight: bottomHeight,
  };
  if (typeof children === "object") {
    if (Array.isArray(children)) {
      newChildren = children.map((i, ind) =>
        React.cloneElement(i, {
          appState,
          key: ind,
        })
      );
    } else {
      newChildren = React.cloneElement(children, {
        appState,
      });
    }
  }
  const notifs = false;
  const [unread, setUnread] = useState(null);
  useEffect(() => {
    setTopLeftButton(() => (
      <Hamburger color="#3872BB" onPress={toggleLeftDrawer} />
    ));
  }, [toggleLeftDrawer]);
  useEffect(() => {
    const activeMessaging =
      typeof sendMessage !== "undefined" &&
      sendMessage !== null &&
      typeof getMessages !== "undefined" &&
      getMessages !== null &&
      typeof getConversations !== "undefined" &&
      getConversations !== null &&
      typeof getUnread !== "undefined" &&
      getUnread !== null;
    setTopRightButton(() => (
      <View
        style={{
          width: 90,
          justifyContent: "space-around",
          flexDirection: "row",
        }}
      >
        <View style={{ width: 30, height: 30 }}>
          {unread !== null && unread ? (
            <View
              style={{
                zIndex: 1000,
                backgroundColor: "#B10101",
                top: -4,
                right: 2,
                borderRadius: 10,
                width: 12,
                height: 12,
                position: "absolute",
              }}
            />
          ) : null}
          <SvgButton
            name="chat"
            color="#3872BB"
            onPress={() => {
              if (activeMessaging) {
                getNavigation().navigate("Message Portal", {
                  sendMessage,
                  getMessages,
                  getConversations,
                });
              } else {
                if (typeof onInactiveMessaging === "function")
                  onInactiveMessaging();
              }
            }}
          />
        </View>
        <View style={{ width: 30, height: 30 }}>
          {notifs ? (
            <View
              style={{
                zIndex: 1000,
                backgroundColor: "#B10101",
                top: -4,
                right: 2,
                borderRadius: 10,
                width: 12,
                height: 12,
                position: "absolute",
              }}
            />
          ) : null}
          <SvgButton
            name="notification"
            color="#3872BB"
            onPress={
              typeof onNotificationPress === "function"
                ? onNotificationPress
                : toggleRightDrawer
            }
          />
        </View>
      </View>
    ));
    if (activeMessaging) {
      if (unread == null) (async () => setUnread(await getUnread()))();
      const interval = setInterval(
        async () => setUnread(await getUnread()),
        2000
      );
      return () => clearInterval(interval);
    }
  }, [
    toggleRightDrawer,
    index,
    sendMessage,
    getMessages,
    getConversations,
    getUnread,
    setUnread,
    getNavigation,
    notifs,
    onInactiveMessaging,
    onNotificationPress,
    unread,
  ]);
  return (
    <View style={{ width, height }}>
      <TopBar
        name={"BuyLo"}
        key={rs + "" + ls}
        rightButton={() => (
          <View style={{ width: 30, height: 30 }}>
            {notifs ? (
              <View
                style={{
                  backgroundColor: "#B10101",
                  top: -4,
                  right: 2,
                  borderRadius: 10,
                  width: 8,
                  height: 8,
                  position: "absolute",
                }}
              />
            ) : null}
            <SvgButton
              name="notification"
              color="#3872BB"
              onPress={() => {
                if (width > 1300) {
                  setRS(!rs);
                } else {
                  OpenRightDrawer();
                }
              }}
            />
          </View>
        )}
        leftButton={() => (
          <Hamburger color="#3872BB" onPress={toggleLeftDrawer} />
        )}
        height={topHeight}
      />
      <View
        style={{
          width,
          height,
          flexDirection: "row",
        }}
      >
        {typeof leftDrawer === "function" && leftDrawerWidth ? (
          <View style={{ width: leftDrawerWidth, height: childHeight }}>
            {React.createElement(
              leftDrawer,
              {
                tabs,
                currentTabIndex: index,
                currentPage: drawerUpdate,
                setTabIndex: (index) => {
                  setDrawerUpdate('tabbar')
                  getNavigation().popToTop();
                  setIndex(index);
                },
                key: drawerUpdate,
                navigate: (str, params = {}, noham = true) =>{
                  setDrawerUpdate(str)
                  getNavigation().navigate(str, params, noham)},

              },
              null
            )}
          </View>
        ) : null}
        <View style={{ width: childWidth, height: childHeight }}>
          {newChildren}
        </View>
        {typeof RightDrawerComponent === "function" && rightDrawerWidth ? (
          <View style={{ width: rightDrawerWidth, height: childHeight }}>
            <RightDrawerComponent {...{ appState }} />
          </View>
        ) : null}
      </View>

      {typeof leftDrawer === "function" && !leftDrawerWidth ? (
        <Drawer dim={appState.dim}>
          {React.createElement(
            leftDrawer,
            {
              navigate: (str, params = {}, noham = true) => {
                getNavigation().navigate(str, params, noham);
                CloseDrawer();
              },
            },
            null
          )}
        </Drawer>
      ) : null}
      {typeof RightDrawerComponent === "function" && !rightDrawerWidth ? (
        <RightDrawer dim={appState.dim}>
          <RightDrawerComponent {...{ appState }} />
        </RightDrawer>
      ) : null}
    </View>
  );
};
export default Layout;
