import React from 'react';
import Router from './Router';
import './index.css';
import './style.css';

// import Map from './Map';
// import data from './map.json';

const App = () => {
  // return <Map initialSection='' initialSubsection='' data={data} />;
  return <Router />;
};

export default App;
