// import libraries for making the header component
import React from "react";
import { View, TouchableOpacity } from "react-native";
import {HoverShadowWrapper} from 'bl-components'

// props.childern has anything passed between Card tags
/*
const Container = (props) => {
  var { children, height, width, style, onPress, ...otherProps } = props;
  if (typeof width === "undefined") {
    width = null;
  }
  if (typeof height === "undefined") {
    height = null;
  }

  if (typeof onPress === "undefined")
    return (
      <View
        {...otherProps}
        style={[gS.containerStyle, { width: width, height: height }]}
      >
        {props.children}
      </View>
    );
  else
    return (
      <View
        {...otherProps}
        style={[gS.containerStyle, { width: width, height: height }]}
      >
        <TouchableOpacity onPress={onPress}>{props.children}</TouchableOpacity>
      </View>
    );
};
*/
const Container = ({ width, children, onPress }) => typeof onPress === 'undefined' ? (
  <View
    style={{
      margin: 10,
      padding: 10,
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: "#E5E5E5",
      borderRadius: 5,
      width,
    }}
  >
    {children}
  </View>
) : (
  <HoverShadowWrapper>
    {(shadow) => (
      <TouchableOpacity
        style={{
      margin: 10,
      padding: 10,
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: "#E5E5E5",
      borderRadius: 5,
      width,
          ...shadow
        }}
        {...{ onPress }}
      >
    {children}
      </TouchableOpacity>
    )}
  </HoverShadowWrapper>
);

export default Container;
