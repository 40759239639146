import React, { useState, useRef, useEffect } from "react";

import { View } from "react-native";

import { useGesture } from "@use-gesture/react";

import { SvgIconCanvas } from "../Icons/SvgIcon";

import { useActiveWidth } from "../Hooks/useActiveWidth";

function contained(left, top, w, h, tapX, tapY) {
  return left < tapX && left + w > tapX && top < tapY && top + h > tapY;
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 */
function roundRect(ctx, x, y, width, height, radius) {
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
}
function roundRectTop(ctx, x, y, width, height, radius) {
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.lineTo(x + width, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.lineTo(x, y + height);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
}

function drawPin(ctx, x, y, color) {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x + 20, y - 25);
  ctx.lineTo(x - 20, y - 25);
  ctx.fill();
  ctx.beginPath();
  ctx.arc(x, y - 20 - 55, 55, 0, Math.PI * 2, true); // Outer circle
  ctx.fill();
  ctx.fillStyle = "white";
  ctx.beginPath();
  ctx.arc(x, y - 20 - 55, 50, 0, Math.PI * 2, true); // inner circle
  ctx.fill();
}

function getLines(ctx, text, maxWidth) {
  var words = text.split(" ");
  var lines = [];
  var currentLine = words[0];

  for (var i = 1; i < words.length; i++) {
    var word = words[i];
    var width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

const MapWeb = ({
  initialSection,
  initialSubsection,
  data,
  width,
  setSpace,
  onInfoRoute,
  disabled,
  fontScale = 1,
}) => {
  const [mouse, setMouse] = useState({ tap: false, down: false, prev: [0, 0] });

  const initialSectionFilter = data.sections.filter(
    (i) => i.name === initialSection
  );
  // iP is initial position
  var iP = { set: false, X: 0, Y: 0 };
  if (initialSectionFilter.length === 1) {
    const initialSubsectionFilter = initialSectionFilter[0].sections.filter(
      (i) => i.name === initialSubsection
    );
    if (initialSubsectionFilter.length === 1) {
      iP = {
        set: true,
        X: initialSectionFilter[0].left + initialSubsectionFilter[0].left + 75,
        Y:
          initialSectionFilter[0].top +
          initialSubsectionFilter[0].top +
          initialSubsectionFilter[0].height / 2,
      };
    }
  }
  // initial map scale
  const scal = iP.set ? (5.0 * width) / 800 : (0.5 * width) / 800;

  const [transform, setTransform] = useState({
    prevTapPosition: [-10000, -10000],
    tapPosition: [-10000, -10000],
    scale: scal,
    X: !iP.set * -0.7 * 600 + iP.set * -iP.X,
    Y: !iP.set * -1.15 * 600 + iP.set * -iP.Y - 20,
    initial: true,
  });

  const target = useRef();

  const onWheel = (deltaY, sensitivity) => {
    const newScale = 1 + sensitivity * deltaY;
    const val = transform.scale * newScale;
    const scale = val < 0 ? 0.2 : val > 15 ? 15 : val < 0.2 ? 0.2 : val;
    const [x, y] = transform.tapPosition;
    setTransform({
      ...transform,
      scale,
      tapPosition: [
        (x - width / 2) * newScale + width / 2,
        (y - width / 2) * newScale + width / 2,
      ],
      initial: false,
    });
  };
  const showBooths = transform.scale > (0.7 * width) / 600;
  useEffect(() => {
    (async () => {
      const canvas = target.current;
      const ctx = canvas.getContext("2d");
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const { scale, prevTapPosition, tapPosition, X, Y, initial } = transform;

      ctx.transform(
        transform.scale,
        0,
        0,
        transform.scale,
        canvas.width / 2,
        canvas.height / 2
      );

      const offsetX = -X;
      const offsetY = -Y;

      const tapX = (tapPosition[0] - canvas.width / 2) / scale + offsetX;
      const tapY = (tapPosition[1] - canvas.height / 2) / scale + offsetY;
      const prevTapX =
        (prevTapPosition[0] - canvas.width / 2) / scale + offsetX;
      const prevTapY =
        (prevTapPosition[1] - canvas.height / 2) / scale + offsetY;
      data.sections.forEach((i) => {
        i.sections.forEach((i2, index2) => {
          // sC stands for selected Color
          const { top, left, height, info } = i2;
          const w = i2.width;
          const containsMe = contained(
            i.left + left,
            i.top + top,
            w,
            height,
            prevTapX,
            prevTapY
          );
          const viewButton =
            typeof onInfoRoute === "function" &&
            typeof info === "object" &&
            typeof info.route === "string";
          if (viewButton && containsMe) {
            const hasDescription =
              typeof info.description === "string" &&
              info.description.length > 0;
            const descriptionLines = hasDescription
              ? getLines(ctx, info.description, 100)
              : [];
            const descriptionLinesNum =
              descriptionLines.length > 6 ? 5 : descriptionLines.length;
            const boxHeight =
              10 +
              8 * (hasDescription || (viewButton && !hasDescription)) +
              descriptionLinesNum *
                (5 * (descriptionLinesNum % 2) +
                  7 * (descriptionLinesNum % 2 === 0));

            const x = left + i.left + w * 1.2;
            const y = top + i.top;
            const containsInfo = contained(
              x + 10,
              y + boxHeight,
              100,
              15,
              tapX,
              tapY
            );
            if (containsInfo) {
              onInfoRoute(info.route);
            }
          }
        });
      });

      var popupData = { x: -10, y: -10, info: {} };

      // create the section layouts on the bottom layer
      data.sections.forEach((i, index) => {
        const { top, left, height, backgroundColor } = i;
        const w = i.width;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(left - offsetX, top - offsetY, w, height);
      });
      // render sections
      data.sections.forEach((i) => {
        const { sections } = i;
        sections.forEach((i2, index2) => {
          // sC stands for selected Color
          const { top, left, height, name, color, sC, backgroundColor, info } =
            i2;
          const w = i2.width;
          const containsMe = contained(
            i.left + left,
            i.top + top,
            w,
            height,
            tapX,
            tapY
          );
          if (containsMe && showBooths) {
            if (typeof setSpace !== "undefined") {
              setSpace({ section: i.name, subsection: i2.name, info });
            }
            popupData = {
              x: left + i.left - offsetX + w * 1.2,
              y: top + i.top - offsetY,
              info,
            };
            const color = typeof sC === "undefined" ? backgroundColor : sC;
            ctx.fillStyle = color;
            ctx.fillRect(
              left + i.left - offsetX - 1,
              top + i.top - offsetY - 1,
              w + 2,
              height + 2
            );
            ctx.fillStyle = color;

            ctx.fillRect(
              left + i.left - offsetX,
              top + i.top - offsetY,
              w,
              height
            );
          } else {
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(
              left + i.left - offsetX,
              top + i.top - offsetY,
              w,
              height
            );
          }
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = typeof color === "undefined" ? "black" : color;
          ctx.font = `${fontScale * 6}px Arial`;
          getLines(ctx, name, w).map((i3, index) =>
            ctx.fillText(
              i3,
              left + i.left + w / 2 - offsetX,
              top + i.top + height / 2 - offsetY + 7 * index
            )
          );
        });
      });
      if (!showBooths) {
        await Promise.all(
          data.sections.map(async (i, index) => {
            const { name, top, left, height, color, pins } = i;
            const w = i.width;
            if (typeof pins === "undefined") {
              drawPin(
                ctx,
                left + w / 2 - offsetX,
                top + height / 2 - offsetY,
                "red"
              );
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = color;
              ctx.font = "45px Arial";
              getLines(ctx, name, w).map((i3, index3) =>
                ctx.fillText(
                  i3,
                  left + w / 2 - offsetX,
                  top + height / 2 - offsetY + 30 * index3 - 70
                )
              );
            } else {
              await Promise.all(
                pins.map(async ({ top, left, text, icon, color }) => {
                  drawPin(
                    ctx,
                    i.left + left - offsetX,
                    i.top + top - offsetY,
                    color
                  );
                  ctx.textAlign = "center";
                  ctx.textBaseline = "middle";
                  ctx.fillStyle = "#000";
                  ctx.font = "50px Arial";
                  ctx.save();
                  if (icon === "") {
                    ctx.fillText(
                      text,
                      i.left + left - offsetX,
                      i.top + top - offsetY - 75
                    );
                  } else {
                    await SvgIconCanvas(
                      ctx,
                      i.left + left - offsetX - 30,
                      i.top + top - offsetY - 75 - 30,
                      icon,
                      color,
                      60,
                      () => {
                        ctx.restore();
                      }
                    );
                  }
                })
              );
            }
          })
        );
      }

      if (
        typeof popupData.info !== "undefined" &&
        JSON.stringify(popupData.info) !== "{}"
      ) {
        //const s = 3.75/transform.scale
        const viewButton =
          typeof onInfoRoute === "function" &&
          typeof popupData.info.route === "string";
        const hasDescription =
          typeof popupData.info.description === "string" &&
          popupData.info.description.length > 0;
        const descriptionLines = hasDescription
          ? getLines(ctx, popupData.info.description, 100)
          : [];
        const descriptionLinesNum =
          descriptionLines.length > 6 ? 5 : descriptionLines.length;
        const boxWidth = 110;
        const onlyText = !viewButton && !hasDescription;
        const boxHeight =
          10 +
          5 * !onlyText +
          8 * (hasDescription || (viewButton && !hasDescription)) +
          15 * viewButton +
          7 * descriptionLinesNum;

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#D9D9D9";
        // pointing triangle
        ctx.beginPath();
        ctx.moveTo(popupData.x + 1, popupData.y);
        ctx.lineTo(popupData.x - 10, popupData.y + 5);
        ctx.lineTo(popupData.x + 1, popupData.y + 10);
        ctx.fill();
        // end triangle
        roundRect(ctx, popupData.x, popupData.y - 4, boxWidth, boxHeight, 5);
        ctx.stroke();
        roundRect(ctx, popupData.x, popupData.y - 4, boxWidth, boxHeight, 5);
        ctx.fill();

        if (!onlyText) {
          roundRectTop(ctx, popupData.x, popupData.y - 4, boxWidth, 15, 5);
          ctx.stroke();
          roundRectTop(ctx, popupData.x, popupData.y - 4, boxWidth, 15, 5);
          ctx.fill();
        }

        ctx.textAlign = "left";
        ctx.textBaseline = "top";
        ctx.fillStyle = "#000";
        ctx.font = `${8}px Arial`;
        const titleLines = getLines(ctx, popupData.info.title, 100);
        ctx.fillText(
          titleLines[0] + (titleLines.length > 1 ? "..." : ""),
          popupData.x + 5,
          popupData.y //+ 1//*onlyText
        );

        if (!onlyText) {
          ctx.textAlign = "left";
          ctx.textBaseline = "top";
          ctx.fillStyle = "#000";
          ctx.font = `${6}px Arial`;
          descriptionLines
            .filter((_, index) => index < 5)
            .map((i, index) =>
              ctx.fillText(
                i + (index === 4 && descriptionLines.length > 5 ? "..." : ""),
                popupData.x + 5,
                popupData.y + 7 * index + 15
              )
            );
        }

        if (viewButton) {
          ctx.fillStyle = "#2198EE";
          ctx.fillRect(
            popupData.x + 10,
            popupData.y + boxHeight - 20,
            boxWidth - 20,
            11
          );
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#fff";
          ctx.font = `${7}px Arial`;
          ctx.fillText(
            "View",
            popupData.x + boxWidth / 2,
            popupData.y + boxHeight - 14
          );
        }
      }

      ctx.setTransform(1, 0, 0, 1, 0, 0);
      if (initial) {
        if (iP.set) {
          setTransform({
            ...transform,
            tapPosition: [8 * scal, canvas.height / 2 - 20 * scal],
            initial: false,
          });
        } else {
          ctx.fillStyle = "#000000";
          ctx.globalAlpha = 0.85;
          ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          ctx.globalAlpha = 1.0;

          ctx.textAlign = "center";
          ctx.fillStyle = "#ffffff";
          ctx.font = "25px Arial";
          ctx.fillText(
            "Zoom in to explore booths",
            ctx.canvas.width / 2,
            ctx.canvas.height / 2
          );
        }
      }
      ctx.fillStyle = "#fff";
      roundRect(ctx, ctx.canvas.width - 80, ctx.canvas.width - 80, 60, 60, 10);
      ctx.fill();
      roundRect(ctx, ctx.canvas.width - 150, ctx.canvas.width - 80, 60, 60, 10);
      ctx.fill();
      await SvgIconCanvas(
        ctx,
        ctx.canvas.width - 145,
        ctx.canvas.width - 75,
        "plus",
        "#949494",
        50
      );
      ctx.fillStyle = "#949494";
      ctx.fillRect(ctx.canvas.width - 65, ctx.canvas.width - 52, 30, 4);
    })();
  }, [
    transform,
    setTransform,
    scal,
    iP,
    data,
    showBooths,
    setSpace,
    onInfoRoute,
    fontScale,
  ]);

  return (
    <canvas
      width={width}
      height={width}
      style={{ backgroundColor: "#fff" }}
      ref={target}
      onMouseMove={(e) => {
        if (mouse.down) {
          const deltaX = e.clientX - mouse.prev[0];
          const deltaY = e.clientY - mouse.prev[1];
          const scaledDX = deltaX / transform.scale;
          const scaledDY = deltaY / transform.scale;
          setTransform({
            prevTapPosition: [-10000, -10000],
            tapPosition: [
              transform.tapPosition[0] + deltaX,
              transform.tapPosition[1] + deltaY,
            ],
            scale: transform.scale,
            X: transform.X + scaledDX,
            Y: transform.Y + scaledDY,
            initial: false,
          });
          setMouse({ tap: false, down: true, prev: [e.clientX, e.clientY] });
        }
      }}
      onMouseDown={(e) =>
        setMouse({ down: true, prev: [e.clientX, e.clientY], tap: true })
      }
      onMouseUp={(e) => {
        if (mouse.tap) {
          const { x, y } = target.current.getBoundingClientRect();
          const ctx = target.current.getContext("2d");

          const tapXPlain = e.clientX - x;
          const tapYPlain = e.clientY - y;
          const containsMinus = contained(
            ctx.canvas.width - 80,
            ctx.canvas.width - 80,
            60,
            60,
            tapXPlain,
            tapYPlain
          );
          const containsPlus = contained(
            ctx.canvas.width - 150,
            ctx.canvas.width - 80,
            60,
            60,
            tapXPlain,
            tapYPlain
          );

          if (containsPlus) {
            onWheel(1, 0.33);
          } else if (containsMinus) {
            onWheel(-1, 0.33);
          } else {
            setTransform({
              prevTapPosition: transform.tapPosition,
              tapPosition: [tapXPlain, tapYPlain],
              scale: transform.scale,
              X: transform.X,
              Y: transform.Y,
              initial: false,
            });
          }
        }
        setMouse({
          down: false,
          prev: [e.clientX, e.clientY],
          start: [e.clientX, e.clientY],
        });
      }}
      onWheel={(e) => {
        onWheel(-e.deltaY, 0.002);
      }}
    ></canvas>
  );
};

const MapMobile = ({
  initialSection,
  initialSubsection,
  data,
  width,
  setSpace,
  onInfoRoute,
  onHover,
  disabled,
  fontScale = 1,
}) => {
  const initialSectionFilter = data.sections.filter(
    (i) => i.name === initialSection
  );
  // iP is initial position
  var iP = { set: false, X: 0, Y: 0 };
  if (initialSectionFilter.length === 1) {
    const initialSubsectionFilter = initialSectionFilter[0].sections.filter(
      (i) => i.name === initialSubsection
    );
    if (initialSubsectionFilter.length === 1) {
      iP = {
        set: true,
        X: initialSectionFilter[0].left + initialSubsectionFilter[0].left + 75,
        Y:
          initialSectionFilter[0].top +
          initialSubsectionFilter[0].top +
          initialSubsectionFilter[0].height / 2,
      };
    }
  }
  // initial map scale
  const scal = iP.set ? (5.0 * width) / 800 : (0.5 * width) / 800;

  const [transform, setTransform] = useState({
    prevTapPosition: [-10000, -10000],
    tapPosition: [-10000, -10000],
    scale: scal,
    X: !iP.set * -0.7 * 600 + iP.set * -iP.X,
    Y: !iP.set * -1.15 * 600 + iP.set * -iP.Y - 20,
    initial: true,
  });

  const target = useRef();

  const onPinch = (scale) => {
    const [x, y] = transform.tapPosition;
    setTransform({
      ...transform,
      scale,
      tapPosition: [
        ((x - width / 2) * scale) / transform.scale + width / 2,
        ((y - width / 2) * scale) / transform.scale + width / 2,
      ],
      initial: false,
    });
  };
  const onWheel = (deltaY, sensitivity) => {
    //? transform.scale * (1 + (0.03 * deltaY) / Math.abs(deltaY))
    const newScale = 1 + sensitivity * deltaY;
    const val = transform.scale * newScale;
    const scale = val < 0 ? 0.2 : val > 15 ? 15 : val < 0.2 ? 0.2 : val;
    const [x, y] = transform.tapPosition;
    setTransform({
      ...transform,
      scale,
      tapPosition: [
        (x - width / 2) * newScale + width / 2,
        (y - width / 2) * newScale + width / 2,
      ],
      initial: false,
    });
  };
  //document.addEventListener('gestureend', (e) => e.preventDefault())
  const showBooths = transform.scale > (0.7 * width) / 600;
  useGesture(
    {
      onDrag: ({ xy, delta, tap, touches, offset: [d, a] }) => {
        if (tap) {
          const { x, y } = target.current.getBoundingClientRect();
          const ctx = target.current.getContext("2d");

          const tapXPlain = xy[0] - x;
          const tapYPlain = xy[1] - y;
          const containsMinus = contained(
            ctx.canvas.width - 80,
            ctx.canvas.width - 80,
            60,
            60,
            tapXPlain,
            tapYPlain
          );
          const containsPlus = contained(
            ctx.canvas.width - 150,
            ctx.canvas.width - 80,
            60,
            60,
            tapXPlain,
            tapYPlain
          );

          if (containsPlus) {
            onWheel(1, 0.33);
          } else if (containsMinus) {
            onWheel(-1, 0.33);
          } else {
            setTransform({
              prevTapPosition: transform.tapPosition,
              tapPosition: [tapXPlain, tapYPlain],
              scale: transform.scale,
              X: transform.X,
              Y: transform.Y,
              initial: false,
            });
          }
        } else if (touches < 2) {
          const scaledDX = delta[0] / transform.scale;
          const scaledDY = delta[1] / transform.scale;
          setTransform({
            prevTapPosition: [-10000, -10000],
            tapPosition: [
              transform.tapPosition[0] + delta[0],
              transform.tapPosition[1] + delta[1],
            ],
            scale: transform.scale,
            X: transform.X + scaledDX,
            Y: transform.Y + scaledDY,
            initial: false,
          });
        }
      },
      //onDragStart: state =>
      //  typeof onHover === "function" ? onHover(true) : null,
      //onDragEnd: state =>
      //  typeof onHover === "function" ? onHover(false) : null,
      //onPinch: ({delta}) => onWheel(Math.sqrt(delta[0]*delta[0] + delta[1]*delta[1])),
      //onPinch: ({ offset: [d, a] }) => alert(d), //set({ zoom: d / 200, rotateZ: a }),
      onPinch: ({ offset: [scale, angle] }) => {
        onPinch(scale * scal);
      }, //set({ zoom: d / 200, rotateZ: a }),
      //onPinchStart: (state) => alert('pinch detected'),
      // onPinchEnd: state => doSomethingWith(state),
      // onScroll: state => doSomethingWith(state),
      //onScrollStart: (state) => alert('scroll detected'),
      // onScrollEnd: state => doSomethingWith(state),
      //onMove: ({xy}) => setXY(xy),
      // onMoveStart: state => doSomethingWith(state),
      // onMoveEnd: state => doSomethingWith(state),
      onWheel: ({ delta }) => {
        onWheel(-delta[1], 0.002);
      }, // delta[1] is the y value
      // onWheelStart: state => doSomethingWith(state),
      // onWheelEnd: state => doSomethingWith(state),
      onHover: ({ active }) =>
        typeof onHover === "function" ? onHover(active) : null,
    },
    {
      target,
      //eventOptions: {passive: false},
      pinch: {
        filterTaps: true,
        enabled: !disabled,
      },
      drag: {
        pointer: {
          touch: true,
          capture: false,
          lock: true,
        },
        enabled: !disabled,
      },
      scroll: { enabled: false },
      //hover: {enabled: false},
    }
  );
  useEffect(() => {
    (async () => {
      const canvas = target.current;
      const ctx = canvas.getContext("2d");
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      //ctx.save();
      //ctx.restore();

      const { scale, prevTapPosition, tapPosition, X, Y, initial } = transform;

      ctx.transform(
        transform.scale,
        0,
        0,
        transform.scale,
        canvas.width / 2,
        canvas.height / 2
      );

      const offsetX = -X;
      const offsetY = -Y;

      const tapX = (tapPosition[0] - canvas.width / 2) / scale + offsetX;
      const tapY = (tapPosition[1] - canvas.height / 2) / scale + offsetY;
      const prevTapX =
        (prevTapPosition[0] - canvas.width / 2) / scale + offsetX;
      const prevTapY =
        (prevTapPosition[1] - canvas.height / 2) / scale + offsetY;
      data.sections.forEach((i) => {
        i.sections.forEach((i2, index2) => {
          // sC stands for selected Color
          const { top, left, height, info } = i2;
          const w = i2.width;
          const containsMe = contained(
            i.left + left,
            i.top + top,
            w,
            height,
            prevTapX,
            prevTapY
          );
          const viewButton =
            typeof onInfoRoute === "function" &&
            typeof info === "object" &&
            typeof info.route === "string";
          if (viewButton && containsMe) {
            const hasDescription =
              typeof info.description === "string" &&
              info.description.length > 0;
            const descriptionLines = hasDescription
              ? getLines(ctx, info.description, 100)
              : [];
            const descriptionLinesNum =
              descriptionLines.length > 6 ? 5 : descriptionLines.length;
            const boxHeight =
              10 +
              8 * (hasDescription || (viewButton && !hasDescription)) +
              descriptionLinesNum *
                (5 * (descriptionLinesNum % 2) +
                  7 * (descriptionLinesNum % 2 === 0));

            const x = left + i.left + w * 1.2;
            const y = top + i.top;
            const containsInfo = contained(
              x + 10,
              y + boxHeight,
              100,
              15,
              tapX,
              tapY
            );
            if (containsInfo) {
              onInfoRoute(info.route);
            }
          }
        });
      });

      var popupData = { x: -10, y: -10, info: {} };

      // create the section layouts on the bottom layer
      data.sections.forEach((i, index) => {
        const { top, left, height, backgroundColor } = i;
        const w = i.width;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(left - offsetX, top - offsetY, w, height);
      });
      // render sections
      data.sections.forEach((i) => {
        const { sections } = i;
        sections.forEach((i2, index2) => {
          // sC stands for selected Color
          const { top, left, height, name, color, sC, backgroundColor, info } =
            i2;
          const w = i2.width;
          const containsMe = contained(
            i.left + left,
            i.top + top,
            w,
            height,
            tapX,
            tapY
          );
          if (containsMe && showBooths) {
            if (typeof setSpace !== "undefined") {
              setSpace({ section: i.name, subsection: i2.name, info });
            }
            popupData = {
              x: left + i.left - offsetX + w * 1.2,
              y: top + i.top - offsetY,
              info,
            };
            const color = typeof sC === "undefined" ? backgroundColor : sC;
            ctx.fillStyle = color;
            ctx.fillRect(
              left + i.left - offsetX - 1,
              top + i.top - offsetY - 1,
              w + 2,
              height + 2
            );
            ctx.fillStyle = color;

            ctx.fillRect(
              left + i.left - offsetX,
              top + i.top - offsetY,
              w,
              height
            );
          } else {
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(
              left + i.left - offsetX,
              top + i.top - offsetY,
              w,
              height
            );
          }
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = typeof color === "undefined" ? "black" : color;
          ctx.font = `${fontScale * 6}px Arial`;
          getLines(ctx, name, w).map((i3, index) =>
            ctx.fillText(
              i3,
              left + i.left + w / 2 - offsetX,
              top + i.top + height / 2 - offsetY + 7 * index
            )
          );
        });
      });
      if (!showBooths) {
        await Promise.all(
          data.sections.map(async (i, index) => {
            const { name, top, left, height, color, pins } = i;
            const w = i.width;
            if (typeof pins === "undefined") {
              drawPin(
                ctx,
                left + w / 2 - offsetX,
                top + height / 2 - offsetY,
                "red"
              );
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = color;
              ctx.font = "45px Arial";
              getLines(ctx, name, w).map((i3, index3) =>
                ctx.fillText(
                  i3,
                  left + w / 2 - offsetX,
                  top + height / 2 - offsetY + 30 * index3 - 70
                )
              );
            } else {
              await Promise.all(
                pins.map(async ({ top, left, text, icon, color }) => {
                  drawPin(
                    ctx,
                    i.left + left - offsetX,
                    i.top + top - offsetY,
                    color
                  );
                  ctx.textAlign = "center";
                  ctx.textBaseline = "middle";
                  ctx.fillStyle = "#000";
                  ctx.font = "50px Arial";
                  ctx.save();
                  if (icon === "") {
                    ctx.fillText(
                      text,
                      i.left + left - offsetX,
                      i.top + top - offsetY - 75
                    );
                  } else {
                    await SvgIconCanvas(
                      ctx,
                      i.left + left - offsetX - 30,
                      i.top + top - offsetY - 75 - 30,
                      icon,
                      color,
                      60,
                      () => {
                        ctx.restore();
                      }
                    );
                  }
                })
              );
            }
          })
        );
      }

      if (
        typeof popupData.info !== "undefined" &&
        JSON.stringify(popupData.info) !== "{}"
      ) {
        //const s = 3.75/transform.scale
        const viewButton =
          typeof onInfoRoute === "function" &&
          typeof popupData.info.route === "string";
        const hasDescription =
          typeof popupData.info.description === "string" &&
          popupData.info.description.length > 0;
        const descriptionLines = hasDescription
          ? getLines(ctx, popupData.info.description, 100)
          : [];
        const descriptionLinesNum =
          descriptionLines.length > 6 ? 5 : descriptionLines.length;
        const boxWidth = 110;
        const onlyText = !viewButton && !hasDescription;
        const boxHeight =
          10 +
          5 * !onlyText +
          8 * (hasDescription || (viewButton && !hasDescription)) +
          15 * viewButton +
          7 * descriptionLinesNum;

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#D9D9D9";
        // pointing triangle
        ctx.beginPath();
        ctx.moveTo(popupData.x + 1, popupData.y);
        ctx.lineTo(popupData.x - 10, popupData.y + 5);
        ctx.lineTo(popupData.x + 1, popupData.y + 10);
        ctx.fill();
        // end triangle
        roundRect(ctx, popupData.x, popupData.y - 4, boxWidth, boxHeight, 5);
        ctx.stroke();
        roundRect(ctx, popupData.x, popupData.y - 4, boxWidth, boxHeight, 5);
        ctx.fill();

        if (!onlyText) {
          roundRectTop(ctx, popupData.x, popupData.y - 4, boxWidth, 15, 5);
          ctx.stroke();
          roundRectTop(ctx, popupData.x, popupData.y - 4, boxWidth, 15, 5);
          ctx.fill();
        }

        ctx.textAlign = "left";
        ctx.textBaseline = "top";
        ctx.fillStyle = "#000";
        ctx.font = `${8}px Arial`;
        const titleLines = getLines(ctx, popupData.info.title, 100);
        ctx.fillText(
          titleLines[0] + (titleLines.length > 1 ? "..." : ""),
          popupData.x + 5,
          popupData.y //+ 1//*onlyText
        );

        if (!onlyText) {
          ctx.textAlign = "left";
          ctx.textBaseline = "top";
          ctx.fillStyle = "#000";
          ctx.font = `${6}px Arial`;
          descriptionLines
            .filter((_, index) => index < 5)
            .map((i, index) =>
              ctx.fillText(
                i + (index === 4 && descriptionLines.length > 5 ? "..." : ""),
                popupData.x + 5,
                popupData.y + 7 * index + 15
              )
            );
        }

        if (viewButton) {
          ctx.fillStyle = "#2198EE";
          ctx.fillRect(
            popupData.x + 10,
            popupData.y + boxHeight - 20,
            boxWidth - 20,
            11
          );
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#fff";
          ctx.font = `${7}px Arial`;
          ctx.fillText(
            "View",
            popupData.x + boxWidth / 2,
            popupData.y + boxHeight - 14
          );
        }
      }

      // // tap debug
      // ctx.beginPath();
      // ctx.fillStyle = '#ff0000';
      // ctx.moveTo(tapX, tapY); // Outer circle
      // ctx.arc(tapX, tapY, 3, 0, Math.PI * 2, true); // Outer circle
      // ctx.fill();

      ctx.setTransform(1, 0, 0, 1, 0, 0);
      if (initial) {
        if (iP.set) {
          setTransform({
            ...transform,
            tapPosition: [8 * scal, canvas.height / 2 - 20 * scal],
            initial: false,
          });
        } else {
          ctx.fillStyle = "#000000";
          ctx.globalAlpha = 0.85;
          ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          ctx.globalAlpha = 1.0;

          ctx.textAlign = "center";
          ctx.fillStyle = "#ffffff";
          ctx.font = "25px Arial";
          ctx.fillText(
            "Zoom in to explore booths",
            ctx.canvas.width / 2,
            ctx.canvas.height / 2
          );
        }
      }
      ctx.fillStyle = "#fff";
      roundRect(ctx, ctx.canvas.width - 80, ctx.canvas.width - 80, 60, 60, 10);
      ctx.fill();
      roundRect(ctx, ctx.canvas.width - 150, ctx.canvas.width - 80, 60, 60, 10);
      ctx.fill();
      await SvgIconCanvas(
        ctx,
        ctx.canvas.width - 145,
        ctx.canvas.width - 75,
        "plus",
        "#949494",
        50
      );
      ctx.fillStyle = "#949494";
      ctx.fillRect(ctx.canvas.width - 65, ctx.canvas.width - 52, 30, 4);
    })();
  }, [
    transform,
    setTransform,
    scal,
    iP,
    data,
    showBooths,
    setSpace,
    onInfoRoute,
    fontScale,
  ]);

  return (
    <canvas
      width={width}
      height={width}
      style={{ backgroundColor: "#fff" }}
      ref={target}
    ></canvas>
  );
};
export const Map = ({
  initialSection,
  initialSubsection,
  data,
  height: h,
  width: w,
  setSpace,
  onInfoRoute,
  disabled,
  fontScale = 1,
}) => {
  const margin = 50;
  const width = (typeof h === "undefined" ? w : w > h ? h : w) - margin;
  return typeof width === "undefined" || width < 950 - margin ? (
    <MapMobile
      {...{
        initialSection,
        initialSubsection,
        data,
        width,
        setSpace,
        onInfoRoute,
        disabled,
        fontScale,
      }}
    />
  ) : (
    <MapWeb
      {...{
        initialSection,
        initialSubsection,
        data,
        width,
        setSpace,
        onInfoRoute,
        disabled,
        fontScale,
      }}
    />
  );
};

export const MapWrapperWidth = ({
  initialSection,
  initialSubsection,
  data,
}) => {
  const width = useActiveWidth();
  return (
    <View
      style={{
        width: width + 6,
        height: width + 6,
        borderWidth: 3,
        borderColor: "#333",
        overflow: "hidden",
        userSelect: "none",
      }}
      key={width}
    >
      <Map {...{ initialSection, initialSubsection, data, width }} />
    </View>
  );
};

export default Map;
