// import library to help create a component
import React from "react";
import { View } from "react-native";
import gS from "../GlobalStyle";

const Chevron = ({ color, rotateZ, scale }) => (
  <View
    style={{
      width: 12,
      height: 12,
      transform: [
        { rotateZ: typeof rotateZ === "undefined" ? "0deg" : rotateZ },
      ],
    }}
  >
    <View
      style={{
        borderColor: typeof color === "undefined" ? gS.black : color,
        width: 12,
        height: 12,
        borderLeftWidth: 3,
        borderTopWidth: 3,
        transform: [{ rotateZ: "-45deg" }],
      }}
    />
  </View>
);

export default Chevron;
