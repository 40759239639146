import React, { useCallback, useState, useEffect } from "react";
import { Platform, Easing, Animated } from "react-native";
import { View } from "react-native";
import { setTopLeftButton } from "./TopBar";
import BackButton from "../Buttons/BackButton";
import Hamburger from "../Buttons/Hamburger";

var maxStackLength = 1;
export var navigate = () => null;
const StackStates = [];
var notifs = false;
export const SetNotifsAlert = (state) => {
  notifs = state;
  StackStates.forEach((i) => i({ notifs }));
};
var anyStack = false;

export const Stack = ({
  appState,
  names,
  components,
  updateHeader,
  initialStack,
  setNavigation,
  header,
  backLength,
  analytics=()=>null,
}) => {
  const { windowWidth: width, width: w } = appState.dim;
  const [state, setState] = useState({
    push: false,
    popTop: -1,
    pop: false,
    noHamburger: false,
    cameFromPop: false,
    cameFromPush: typeof initialStack !== "undefined",
    stack:
      typeof initialStack !== "undefined" ? initialStack.stack : [names[0]],
    paramsStack:
      typeof initialStack !== "undefined" ? initialStack.params : [{}],
    translateXStack:
      typeof initialStack !== "undefined"
        ? initialStack.stack.map(
            (_, index, me) =>
              new Animated.Value(-width * (index !== me.length - 1))
          )
        : [new Animated.Value(0)],
    noBack: false,
    notifs,
  });
  const {
    cameFromPop,
    cameFromPush,
    paramsStack,
    stack,
    push,
    pop,
    popTo,
    translateXStack,
    noBack,
  } = state;
  const localNavigate = useCallback(
    (name, params = {}, noHamburger = false) => {
      const exists = names.indexOf(name);
      //const exists = stack.indexOf(name);
      if (exists >= 0) {
        analytics( name );
        stack.push(name);
        paramsStack.push(params);
        translateXStack.push(new Animated.Value(width));
        maxStackLength = stack.length;
        anyStack = true;

        setState({
          ...state,
          cameFromPop: false,
          cameFromPush: true,
          noHamburger,
          pop: false,
          push: true,
          stack,
          paramsStack,
          translateXStack,
        });
        return true;
      } else {
        return false;
      }
    },
    [state, setState, paramsStack, names, stack, translateXStack, width, analytics]
  );
  navigate = localNavigate;
  const {
    events: { toggleLeftDrawer },
  } = appState;

  useEffect(() => {
    if (noBack) {
      return;
    }
    //if ((cameFromPush || cameFromPop) && updateHeader) {
    if (updateHeader) {
      if (stack.length > 1) {
        setTimeout(() => {
          setTopLeftButton(() => (
            <BackButton
              color="#3872BB"
              onPress={() => {
                setTopLeftButton(() => null);
                if (!pop) {
                  if (stack.length <= 2) anyStack = false;
                  setState({
                    ...state,
                    cameFromPop: false,
                    cameFromPush: false,
                    pop: true,
                  });
                }
              }}
            />
          ));
        }, 100);
      } else if (!state.noHamburger && !anyStack) {
        setTopLeftButton(() => (
          <Hamburger
            color="#3872BB"
            onPress={() => {
              if (typeof toggleLeftDrawer === "function") toggleLeftDrawer();
            }}
          />
        ));
      }
    }
  }, [
    cameFromPop,
    cameFromPush,
    backLength,
    stack,
    pop,
    state,
    updateHeader,
    localNavigate,
    names,
    noBack,
    toggleLeftDrawer,
  ]);

  //const index = names.indexOf(stack[stack.length - 1]);
  //const lastIndex = names.indexOf(stack[stack.length - 1]);

  const navigation = {
    maxStackLength: maxStackLength,
    stackLength: stack.length,
    push: localNavigate,
    popToTop: () => {
      anyStack = false;
      setState({ ...state, popTo: 0 });
    },
    popTo: (page) => {
      if (stack.length <= 2) anyStack = false;
      setState({ ...state, popTo: stack.indexOf(page) });
    },

    pop: () => {
      if (stack.length <= 2) anyStack = false;
      setState({ ...state, pop: true });
    },
    navigate: (name, params = {}, noHamburger = false) => {
      if (name === "Message Portal" && stack.indexOf("Message Portal") > -1)
        return;
      if (name === "Message" && stack.indexOf("Message") > -1) return;
      localNavigate(name, params, noHamburger);
    },
  };

  useEffect(() => {
    if (typeof setNavigation !== "undefined") setNavigation(navigation);
  }, [setNavigation, navigation]);

  const topHeight = Platform.OS === "ios" ? 100 : 60;

  if (pop || popTo > -1) {
    // ipad compatibility
    if (Platform.OS !== "web" && width <= 950) {
      Animated.parallel([
        Animated.timing(translateXStack[stack.length - 1], {
          toValue: width,
          duration: 400,
          easing: Easing.inOut(Easing.sin),
          useNativeDriver: true,
        }),
        Animated.timing(
          translateXStack[popTo > -1 ? popTo : stack.length - 2],
          {
            toValue: 0,
            duration: 400,
            easing: Easing.inOut(Easing.sin),
            useNativeDriver: true,
          }
        ),
      ]).start(() => {
        if (popTo > -1) {
          stack.splice(1);
          paramsStack.splice(1);
          translateXStack.splice(1);
        } else {
          stack.pop();
          paramsStack.pop();
          translateXStack.pop();
        }
        setState({
          ...state,
          stack,
          translateXStack,
          paramsStack,
          popTo: -1,
          pop: false,
          cameFromPop: true,
          cameFromPush: false,
          noBack: false,
        });
      });
    } else {
      Animated.parallel([
        Animated.timing(translateXStack[stack.length - 1], {
          toValue: width,
          duration: 0,
          useNativeDriver: false,
        }),
        Animated.timing(
          translateXStack[popTo > -1 ? popTo : stack.length - 2],
          {
            toValue: 0,
            duration: 0,
            useNativeDriver: false,
          }
        ),
      ]).start(() => {
        if (popTo > -1) {
          stack.splice(popTo + 1);
          paramsStack.splice(popTo + 1);
          translateXStack.splice(popTo + 1);
        } else {
          stack.pop();
          paramsStack.pop();
          translateXStack.pop();
        }
        setState({
          ...state,
          stack,
          translateXStack,
          paramsStack,
          popTo: -1,
          cameFromPop: true,
          cameFromPush: false,
          pop: false,
          noBack: false,
        });
      });
    }
  }

  if (push) {
    if (Platform.OS !== "web" && width <= 950) {
      Animated.parallel([
        Animated.timing(translateXStack[stack.length - 1], {
          toValue: 0,
          duration: 400,
          easing: Easing.inOut(Easing.sin),
          useNativeDriver: true,
        }),
        Animated.timing(translateXStack[stack.length - 2], {
          toValue: -width,
          duration: 400,
          easing: Easing.inOut(Easing.sin),
          useNativeDriver: true,
        }),
      ]).start(() =>
        setState({
          ...state,
          cameFromPop: false,
          cameFromPush: true,
          push: false,
        })
      );
    } else {
      Animated.parallel([
        Animated.timing(translateXStack[stack.length - 1], {
          toValue: 0,
          duration: 0,
          useNativeDriver: false,
        }),
        Animated.timing(translateXStack[stack.length - 2], {
          toValue: -width,
          duration: 0,
          useNativeDriver: false,
        }),
      ]).start(() =>
        setState({
          ...state,
          cameFromPop: false,
          cameFromPush: true,
          push: false,
        })
      );
    }
  }

  return (
    <View style={{ flex: 1 }}>
      {stack.map((name, i) => (
        <Animated.View
          key={i}
          style={{
            transform: [
              { translateX: translateXStack[i] },
              {
                translateX:
                  -!(pop || push) *
                  (i !== state.stack.length - 1) *
                  appState.dim.width,
              },
            ],
            flex:
              (i === state.stack.length - 2) * (pop || push) +
              (i === state.stack.length - 1) +
              0.00001,
            height: appState.dim.height,
            top:
              (stack[stack.length - 2] === "Log In") *
              (i === state.stack.length - 2) *
              (pop || push)
                ? topHeight
                : undefined,
            position:
              (i === state.stack.length - 2) * (pop || push)
                ? "absolute"
                : "relative",
            width: w,
          }}
        >
          {React.createElement(
            components[names.indexOf(stack[i])],
            {
              appState,
              updateHeader: stack.length === 1 && !(pop || push),
              navigation,
              route: {
                params: paramsStack[i],
                setNoBack: () => {
                  if (!noBack)
                    setState({
                      ...state,
                      cameFromPop: false,
                      cameFromPush: false,
                      noBack: true,
                    });
                },
              },
            },
            null
          )}
        </Animated.View>
      ))}
    </View>
  );
};
