// import library to help create a component
import React from "react";

import InputField from "./InputField";

const UserField = ({ onEndEditing, onFocus, defaultString }) => {
  return (
    <InputField
      autoCompleteType={"username"}
      autoCapitalize={"none"}
      returnKeyType="done"
      onFocus={() => {
        if (onFocus === "function") {
          onFocus();
        }
      }}
      {...{ onEndEditing, defaultString }}
    />
  );
};

export default UserField;
