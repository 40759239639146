import React from "react";
import { View } from "react-native";
import H3 from "../../Text/H3";
import Body from "../../Text/Body";
import FullScreenModal from "./FullScreenModal";
/*
 */

const Alert = ({ title, body, deleteMe, callback, buttons }) => (
  <FullScreenModal
    popup={true}
    exitButtons={
      typeof buttons !== "undefined"
        ? buttons.map((i) => ({
            ...i,
            callback: () => {
              if (typeof i.callback === "function") i.callback();
              deleteMe();
            },
          }))
        : [
            {
              name: "OK, GOT IT",
              callback: () => {
                callback();
                deleteMe();
              },
              color: { blue: true },
            },
          ]
    }
    containerWidth={250}
    button="Messages"
  >
    <View style={{ paddingLeft: 25, paddingRight: 25, width: "100%" }}>
      <H3
        bold
        style={{ color: "rgba(0,0,0,0.75)", fontSize: 20 }}
        textAlign="left"
      >
        {typeof title === "undefined" ? "Alert!" : title}
      </H3>
      <View style={{ height: 15 }} />
      <Body style={{ color: "#5E6366", fontSize: 16 }} textAlign="left">
        {typeof body === "undefined"
          ? "Please acknowledge what happened."
          : body}
      </Body>
    </View>
  </FullScreenModal>
);

export default Alert;
