import React, { useState } from "react";
import { TouchableOpacity, Pressable, View, ScrollView } from "react-native";
import Body from "../Text/Body";
import H3 from "../Text/H3";
import { container } from "../GlobalStyle";

const PageDropDown = ({ width, values, setValue, value }) => {
  const [visible, setVisible] = useState(false);
  const w = typeof width === "undefined" ? 150 : width;
  return (
    <View style={{ zIndex: 1000 }}>
      <Pressable
        onPress={() => setVisible(!visible)}
        style={{
          width: w,
          height: 50,
          ...container,
          borderColor: visible ? "#4C94FF" : "#D9D9D9",
          borderRadius: 5,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Body style={{ fontSize: 15 }} color="#999">
          {value}
        </Body>
        <View
          style={{
            top: 5,
            width: 0,
            height: 0,
            borderWidth: 10,
            borderColor: "rgba(0,0,0,0)",
            borderTopColor: "#777",
          }}
        />
      </Pressable>
      {visible ? (
        <ScrollView
          style={{
            ...container,
            margin: 0,
            padding: 0,
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            position: "absolute",
            top: 60,
            height: 40 * values.length > 300 ? 300 : 40 * values.length,
            width: w,
            zIndex: 1000000000,
            backgroundColor: "#fff",
          }}
        >
          {values.map((value) => (
            <TouchableOpacity
              key={value}
              onPress={() => {
                setVisible(!visible);
                setValue(value);
              }}
              style={{
                height: 40,
                width: w,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <H3>{value}</H3>
            </TouchableOpacity>
          ))}
        </ScrollView>
      ) : null}
    </View>
  );
};

export default PageDropDown;
