// import libraries for making the header component
import React from "react";
import { View, ScrollView, Pressable } from "react-native";
import { useActiveWidth } from "../Hooks/useActiveWidth";
import H1 from "../Text/H1";
import H3 from "../Text/H3";
import Chevron from "../Icons/Chevron.js";

import LinearGradient from "./Gradient.js";
import ImageLoading from "../Images/ImageLoading";

const resturantName = "Mesa Market Grill";

export const ConcessionsCategoryList = ({
  Concessions,
  onPress,
  navigation,
  image,
  height,
}) => {
  const width = useActiveWidth();

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          width,
          height: (width * 3.0) / 4.0,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoading
          style={{ width, height: (width * 3.0) / 4.0 }}
          source={
            typeof image === "undefined" || image === ""
              ? require("./AZ.png")
              : { uri: image }
          }
        />
        <LinearGradient {...{ width }}>
          <H1 style={{ marginLeft: 15 }} bold white textAlign="left">
            {resturantName}
          </H1>
        </LinearGradient>
      </View>
      <View style={{ flex: 1, height }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              width,
              height: (width * 3.0) / 4.0,
              opacity: 1,
            }}
          />
          {
            Object.keys(Concessions.categories).map((i) => (
              <Pressable
                key={i}
                onPress={() =>
                  onPress({ resturantName, name: i, ...Concessions.categories[i] })
                }
                style={{
                  backgroundColor: "#fff",
                  padding: 25,
                  borderTopWidth: 2,
                  borderColor: "#999",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <H1 style={{ width: "70%" }} textAlign="left">
                  {i}
                </H1>
                <Chevron color="#999" rotateZ="180deg" />
              </Pressable>
            )) /*categories.map(i  => (
        <View style={{backgroundColor: '#fff', padding: 30, borderTopWidth: 2, color: '#000'}}>
          <H1 textAlign='left'>{i}</H1>
        </View>
      ))*/
          }
        </ScrollView>
      </View>
    </View>
  );
};
/*
const ConcessionsList = ({Concessions, onPress, navigation, image, height}) => {
  const width = useActiveWidth();

  return (
    <View style={{flex: 1}}>
      <View
        style={{
          width,
          height: (width * 3.0) / 4.0,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image
          style={{width, height: (width * 3.0) / 4.0}}
          source={
            typeof image === 'undefined' || image === ''
              ? require('../../Support/AZ.png')
              : {uri: image}
          }
        />
      </View>
      <View style={{flex: 1, height}}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              width,
              height: (width * 3.0) / 4.0,
              opacity: 1,
            }}
          />
          {
            Object.keys(Concessions).map((i) => (
              <Pressable
                key={i}
                onPress={() => onPress(Concessions[i])}
                style={{
                  backgroundColor: '#fff',
                  padding: 25,
                  borderTopWidth: 2,
                  borderColor: '#999',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <H1 style={{width: '70%'}} textAlign="left">{i}</H1>
                <Image
                  style={{borderRadius: 5, width: 80, height: 80}}
                  source={
                    Concessions[i].image !== ''
                      ? {uri: Concessions[i].image}
                      : require('../../Support/AZ.png')
                  }
                />
              </Pressable>
            ))
          }
        </ScrollView>
      </View>
    </View>
  );
};
*/

const ConcessionsList = ({
  Concessions,
  onPress,
  navigation,
  image,
  height,
}) => {
  const width = useActiveWidth();
  const numAccross = Math.floor(width / 150);
  const numExtra =
    numAccross - (Object.keys(Concessions.items).length % numAccross);


  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          width,
          height: (width * 3.0) / 4.0,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoading
          style={{ width, height: (width * 3.0) / 4.0 }}
          source={
            typeof image === "undefined" || image === ""
              ? require("./AZ.png")
              : { uri: image }
          }
        />
        <LinearGradient {...{ width }}>
          <H1 style={{ marginLeft: 15 }} bold white textAlign="left">
            {Concessions.resturantName}
          </H1>
        </LinearGradient>
      </View>
      <View style={{ flex: 1, height }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              width,
              height: (width * 3.0) / 4.0,
              opacity: 1,
            }}
          />
          <View
            style={{
              padding: 25,
              borderBottomWidth: 1,
              borderColor: "#999",
              backgroundColor: "#fff",
            }}
          >
            <H1 bold blue lines={1} textAlign="left">
              {Concessions.name}
            </H1>
          </View>
          <View
            style={{
              backgroundColor: "#fff",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {Object.keys(Concessions.items).map((i) => (
              <Pressable
                key={i}
                onPress={() =>
                  onPress({
                    resturantName: Concessions.resturantName,
                    name: i,
                    ...Concessions.items[i],
                  })
                }
                style={{
                  backgroundColor: "#fff",
                  padding: 15,
                }}
              >
                <View style={{ width: 150 }}>
                  <ImageLoading
                    style={{ borderRadius: 5, width: 150, height: 150 }}
                    source={
                      Concessions.items[i].image !== ""
                        ? { uri: Concessions.items[i].image }
                        : require("./AZ.png")
                    }
                  />
                  <H3 lines={1}>{i}</H3>
                </View>
              </Pressable>
            ))}
            {Array(numExtra)
              .fill(0)
              .map((_, ind) => (
                <View key={ind} style={{ width: 150 + 15 * 2 }} />
              ))}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default ConcessionsList;
