// import libraries for making the header component
import React, {Component, useState} from 'react';
import {View} from 'react-native';

import DataManager from '../../Support/DataManager';

import {
  Alert,
  Confirm,
  PassField,
  H2,
  H3,
  Body,
  Button,
  PageScrollMB,
  RadioSelect,
} from 'bl-components';

// Just need a functional component to show detail to the user
//const Clone = (obj) => JSON.parse(JSON.stringify(obj));

class ChangePassword extends Component {
  state = {
    toggleChangeCred: 0,
    newUsername1: '',
    newUsername2: '',
    newPassword1: '',
    newPassword2: '',
  };

  submitNewPassword = () => {
    if (
      this.state.newPassword1 === this.state.newPassword2 &&
      this.state.newPassword1.length > 7
    ) {
      Confirm(
        () => {
          //success = DataManager.refreshItems;
          this.setState({toggleChangeCred: 0});

          DataManager.changePassword(
            {password: this.state.newPassword1},
            () => null,
          );
        },
        'Change Password',
        'This will change your password and will not require to log back in.',
      );
    } else {
      Alert(
        'Error',
        'Passwords do not match or password is not longer than 7 characters.',
      );
    }
  };

  // Render method must be defined that returns some jsx
  render() {
    return (
      <View style={{margin: 10}}>
        <H2 navy>Change Password</H2>
        <View style={{marginTop: 10}}>
          <Body>Enter New Password:</Body>
        </View>
        <PassField
          defaultString="password"
          onEndEditing={(text) => this.setState({newPassword1: text})}
        />
        <View style={{marginTop: 10}}>
          <Body>Confirm New Password:</Body>
        </View>
        <PassField
          defaultString="password"
          onEndEditing={(text) => this.setState({newPassword2: text})}
        />
        <View style={{alignItems: 'center'}}>
          <Button style={{width: 200}} onPress={this.submitNewPassword}>
            Update Password
          </Button>
        </View>
      </View>
    );
  }
}
const NotificationSettings = (navigate) => {
  const [methods, setMethods] = useState(DataManager.accountSettings.methods);
  const [types, setTypes] = useState(DataManager.accountSettings.types);
  return (
    <View style={{margin: 10, flex: 1}}>
      <H2 navy>Notification Settings</H2>
      <View style={{marginTop: 10}}>
        <H3>Notification Methods</H3>
        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
          <View style={{width: 160}}>
            <RadioSelect
              selected={methods.indexOf('email') > -1}
              height={50}
              onPress={() => {
                const ind = methods.indexOf('email');
                if (ind > -1)
                  setMethods(methods.filter((_, ind2) => ind2 !== ind));
                else setMethods(methods.concat(['email']));
              }}
            >
              Email
            </RadioSelect>
          </View>
          <View style={{width: 160}}>
            <RadioSelect
              selected={methods.indexOf('push') > -1}
              height={50}
              onPress={() => {
                const ind = methods.indexOf('push');
                if (ind > -1)
                  setMethods(methods.filter((_, ind2) => ind2 !== ind));
                else setMethods(methods.concat(['push']));
              }}
            >
              Apple/Android
            </RadioSelect>
          </View>
        </View>
      </View>
      <View style={{marginTop: 10}}>
        <H3>Notification Types</H3>
        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
          <View style={{width: 160}}>
            <RadioSelect
              selected={types.indexOf('market') > -1}
              height={50}
              onPress={() => {
                const ind = types.indexOf('market');
                if (ind > -1) setTypes(types.filter((_, ind2) => ind2 !== ind));
                else setTypes(types.concat(['market']));
              }}
            >
              Market
            </RadioSelect>
          </View>
          <View style={{width: 160}}>
            <RadioSelect
              selected={types.indexOf('vendor') > -1}
              height={50}
              onPress={() => {
                const ind = types.indexOf('vendor');
                if (ind > -1) setTypes(types.filter((_, ind2) => ind2 !== ind));
                else setTypes(types.concat(['vendor']));
              }}
            >
              Vendor
            </RadioSelect>
          </View>
        </View>
      </View>
      <Body>Notifications currently cannot be disabled for messages</Body>
      <View style={{alignItems: 'center', marginTop: 10}}>
        <Button
          style={{width: 300}}
          onPress={() => {
            Confirm(
              async () => {
                const success = await DataManager.updateAccountSettings(
                  types,
                  methods,
                );
                if (success) Alert('Success', 'Account settings were updated.');
              },
              'Update Notification Settings',
              'This will change your notification settings to the settings displayed.  Any changes will only apply to future notifications.',
            );
          }}
        >
          Update Notification Settings
        </Button>
      </View>
    </View>
  );
};
const AccountSettings = () => (
  <PageScrollMB>
    <NotificationSettings />
    <ChangePassword />
  </PageScrollMB>
);

export default AccountSettings;
