import React from 'react';
import {View} from 'react-native';

import {H3, PageScrollMB} from 'bl-components';

const Page1 = ({navigation: {navigate}}) => {
  return (
    <PageScrollMB>
      <View style={{margin: 20}}>
        <H3>How to use this app:</H3>
      </View>
      <View style={{height: 20}} />
    </PageScrollMB>
  );
};

export default Page1;
