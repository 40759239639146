// import libraries for making the header component
import React from "react";
import BaseText from "./BaseText";
import gS from "../GlobalStyle.js";

const Body = ({ style, children, ...props }) => {
  return (
    <BaseText {...props} style={[gS.Body, style]}>
      {children}
    </BaseText>
  );
};

export default Body;
