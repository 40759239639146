// import library to help create a component
import React from "react";

import { PopupX, Map } from "bl-components";

const MapPopup =
  (appState, MapData, initialSection = "", initialSubsection = "") =>
  () =>
    PopupX((close) => {
      const { windowWidth, windowHeight } = appState.dim;
      const width =
        windowHeight - windowWidth < 100 ? windowHeight - 200 : windowWidth;
      return (
        <Map
          width={width}
          {...{ initialSection, initialSubsection }}
          data={MapData}
          onInfoRoute={(route) => {
            close();
            appState.events.injectURL("?" + route);
          }}
        />
      );
    });

export default MapPopup;
