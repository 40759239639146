// import libraries for making the header component
import React from "react";
import BaseText from "./BaseText";
import gS from "../GlobalStyle.js";

const Count = ({ style, children, ...props }) => {
  return (
    <BaseText {...props} style={[gS.Body, style]}>
      {parseInt(children, 10) > 999 && parseInt(children, 10) < 1000000
        ? Math.round(children / 100) / 10 + "k"
        : parseInt(children, 10) > 999999
        ? Math.round(children / 100000) / 10 + "M"
        : children}
    </BaseText>
  );
};

export default Count;
