// import libraries for making the header component
import React from "react";
import { TouchableOpacity, View } from "react-native";
import Follow from "./Follow";

import H3 from "../Text/H3";
import H2 from "../Text/H2";
import Count from "../Text/Count";
import Body from "../Text/Body";
import TextBox from "../Views/TextBox";

import WebsiteLink from "../Buttons/WebsiteLink";

import ImageLoading from "../Images/ImageLoading";

import Button from "../Buttons/Button";
import MarketMap from "../Maps/Map";
import { PopupX } from "../Popups/Popups";
import SvgIcon from "../Icons/SvgIcon";

import gS from "../GlobalStyle";
import SocialIconLinked from "../Buttons/SocialIconLinked";

import { useActiveWidth } from "../Hooks/useActiveWidth";

import { Tags, Grid, GridItem } from "bl-components";

const socialMediaNames = ["Facebook", "Instagram", "Twitter"];

const FloorImage = ({ Vendor }) => {
  const { image } = Vendor;
  if (image !== "") {
    return (
      <ImageLoading
        style={{ width: 125, height: 100, borderRadius: 5 }}
        source={{ uri: image }}
      />
    );
  } else {
    return (
      <View
        style={{
          backgroundColor: gS.grey,
          width: 125,
          height: 100,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Body>No Photo</Body>
      </View>
    );
  }
};

const VendorInfo = ({
  MessageNavigate,
  Vendor,
  favCallback,
  numPosts,
  followComponent,
  shareCallback,
  showWebsite,
  favInitial,
  Map,
  onInfoRoute,
}) => {
  const width = useActiveWidth();
  const { tags, index, name, description, spaces, website, numFollowers } =
    Vendor;

  return (
    <View style={{ width }}>
      <TextBox>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <FloorImage Vendor={Vendor} />
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                flex: 1,
                margin: 0,
              }}
            >
              <View style={{ flex: 1, alignItems: "center" }}>
                <Count>{typeof numPosts === "undefined" ? 0 : numPosts}</Count>
                <Body>Posts</Body>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <Count>
                  {typeof numFollowers === "undefined" ? 0 : numFollowers}
                </Count>
                <Body>Followers</Body>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {typeof followComponent === "undefined" ? (
                <Follow
                  VendorIndex={index}
                  initial={
                    typeof favInitial === "undefined" ? false : favInitial
                  }
                  callback={(toggleState, success, failure) => {
                    typeof favCallback === "undefined"
                      ? success()
                      : favCallback(toggleState, success, failure);
                  }}
                  type="Vendor"
                />
              ) : (
                followComponent()
              )}
              {typeof shareCallback === "undefined" ? null : (
                <TouchableOpacity
                  onPress={() =>
                    shareCallback({
                      vendor: index,
                    })
                  }
                  style={{
                    flexDirection: "row",
                    width: 75,
                    justifyContent: "space-between",
                  }}
                >
                  <SvgIcon name="share" color="#008BD0" />
                  <Body>Share</Body>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </TextBox>
      <View
        style={{
          margin: 10,
          marginTop: 0,
        }}
      >
        <H2 bold>{name}</H2>
        {typeof tags !== "undefined" && tags.length ? (
          <>
            <View style={{ height: 10 }} />
            <Tags {...{ tags }} />
          </>
        ) : null}
      </View>
      {typeof MessageNavigate === "function" ? (
        <View style={{ alignItems: "center" }}>
          <View style={{ width: 200, margin: 10 }}>
            <Button blue onPress={() => MessageNavigate()}>
              <H3 white>Send Message</H3>
            </Button>
          </View>
        </View>
      ) : null}
      <View
        style={{
          margin: 2,
        }}
      >
        {website === "" && description === "" ? null : (
          <>
            <TextBox flexDirectionRow>
              <H3 bold>About Us</H3>
              <View style={{ width: 200 }}>
                {website === "" && typeof showWebsite === "undefined" ? null : (
                  <WebsiteLink
                    style={{ margin: 0, marginBottom: 10 }}
                    url={website}
                  >
                    Visit Our Website
                  </WebsiteLink>
                )}
              </View>
            </TextBox>
            <TextBox style={{ marginTop: 0, paddingTop: 0 }}>
              <Body>{description}</Body>
            </TextBox>
          </>
        )}
        <View style={{ alignItems: "center" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              margin: 10,
              width: 300,
            }}
          >
            {socialMediaNames
              .filter(
                (name) =>
                  typeof Vendor[name] !== "undefined" &&
                  Vendor[name] !== null &&
                  Vendor[name] !== ""
              )
              .map((name) => (
                <SocialIconLinked
                  key={name}
                  username={Vendor[name]}
                  type={name.toLowerCase()}
                />
              ))}
          </View>
        </View>
      </View>
      <View style={{ margin: 10 }}>
        {typeof spaces !== "undefined" && spaces.length ? (
          <>
            <H3 bold>Our Location</H3>
            <Grid {...{ width }} style={{ padding: 10 }}>
              {spaces
                .sort(({ section: s1 }, { section: s2 }) => s1 - s2)
                .map(({ section, subsection }) => (
                  <GridItem width={140} key={section + subsection}>
                    <View
                      style={{
                        flexDirection: "row",
                        width: 140,
                        height: 50,
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      {typeof Map === "undefined" ? null : (
                        <Button
                          gold
                          onPress={() =>
                            PopupX((close) => (
                              <MarketMap
                                width={width}
                                initialSection={section}
                                initialSubsection={subsection}
                                onInfoRoute={(route) =>
                                  onInfoRoute(route, close)
                                }
                                data={Map}
                              />
                            ))
                          }
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <H3>{`${section}-${subsection}`}</H3>
                            <View style={{ width: 10 }} />
                            <SvgIcon color="black" size={18} name="map" />
                          </View>
                        </Button>
                      )}
                    </View>
                  </GridItem>
                ))}
            </Grid>
          </>
        ) : null}
      </View>
    </View>
  );
};

export default VendorInfo;
