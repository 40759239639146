import React from "react";
import { View, Modal } from "react-native";
import Button from "../../Buttons/Button";
import { useDimensions } from "../../Hooks/useDimensions";

const Popup = ({ popup, children, exitButtons, containerWidth, ...other }) => {
  const { width, height } = useDimensions();
  const column = {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };

  const style = {
    width: width,
    height: height,
    backgroundColor: "rgba(0,0,0,0.8)",
    flex: 1,
  };

  return (
    <Modal
      style={{ ...style }}
      animationType="fade"
      transparent={true}
      visible={popup}
    >
      <View style={{ ...style }}>
        <View
          style={{
            ...column,
            position: "absolute",
            left: 0,
            top: 0,
            height,
            width,
            flex: 1,
          }}
        >
          <View
            style={{
              marginTop: 10,
              paddingTop: 25,
              marginBottom: 10,
              width: typeof width === "undefined" ? "80%" : containerWidth,
              borderRadius: 10,
              backgroundColor: "#fff",
              flexShrink: 1,
            }}
          >
            {children}
            <View
              style={{
                padding: 25,
                flexDirection: exitButtons.length > 2 ? "column" : "row",
                zIndex: -100,
              }}
            >
              {exitButtons.map((item, index, self) => (
                <View
                  key={index}
                  style={{
                    flexGrow: 1,
                    marginRight: 10 * (index !== self.length - 1),
                  }}
                >
                  <Button
                    body
                    key={item.name}
                    style={{ flexGrow: 1 }}
                    {...item.color}
                    onPress={() => {
                      item.callback();
                    }}
                  >
                    {item.name}
                  </Button>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default Popup;
