// import library to help create a component
import React, {useState} from "react";
import InputField from "./InputField";

const PassField = ({ onEndEditing, onFocus, defaultString }) => {
    const [secure, setSecure] = useState(true)
    return (
  <InputField
    maxLength={20}
    noShowLength
    eye={() => setSecure(!secure)}
    autoCompleteType={"password"}
    returnKeyType="done"
    secureTextEntry={secure}
    onFocus={() => {
      if (typeof onFocus === "function")
        onFocus();
    }}
    {...{ onEndEditing, defaultString }}
  />
);
}

export default PassField;
