// import libraries for making the header component
import React from "react";
import { StyleSheet, Image, View } from "react-native";

const style = StyleSheet.create({
  logoStyle: {
    resizeMode: "contain",
    height: "100%",
    width: "90%",
  },
  logoViewStyle: {
    borderWidth: 0,
    borderRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});
// props.childern has anything passed between Card tags
const Logo = () => {
  return (
    <View style={style.logoViewStyle}>
      <Image style={style.logoStyle} source={require("./user.png")} />
    </View>
  );
};

export default Logo;
