import React from "react";
import { View } from "react-native";

export const GridItem = ({ children, width }) => (
  <View
    style={{
      width,
    }}
  >
    {children}
  </View>
);

export const Grid = ({ children: ch, style, width, around }) => {
  if (!ch.length) return null;
  const children = ch.filter((i) => typeof i === "object").flat();
  const itemWidth = children[0].props.width;
  const numAccross = Math.floor(width / itemWidth);
  //const w = children.map( i => i.props.width ).reduce((v1, v2) => v1+v2) % width
  if (numAccross <= 1) {
    return (
      <View
        style={{
          alignItems: "center",
          ...style,
        }}
      >
        {children}
      </View>
    );
  } else {
    const numRows = Math.ceil(children.length / numAccross);
    const childrenArr = Array(numRows)
      .fill(Array(numAccross).fill(0))
      .map((i, rowInd) =>
        i.map((j, colInd) => (
          <React.Fragment key={rowInd + colInd}>
            {numAccross * rowInd + colInd >= children.length ? (
              <View
                style={{
                  //backgroundColor: "black",
                  width: itemWidth,
                }}
              />
            ) : (
              children[numAccross * rowInd + colInd]
            )}
          </React.Fragment>
        ))
      );
    //const w = (150 + 20) * (numAccross - (5 % numAccross));
    return (
      <View
        style={{
          ...style,
        }}
      >
        {childrenArr.map((i, ind) => (
          <View
            style={{
              justifyContent:
                typeof around === "undefined" && numAccross !== 2
                  ? "space-between"
                  : "space-around",
              flexDirection: "row",
            }}
            key={ind}
          >
            {i}
          </View>
        ))}
      </View>
    );
  }
};

export const VGrid = ({ children: ch, style, width, around }) => {
  if (!ch.length) return null;
  const children = ch.filter((i) => typeof i === "object").flat();
  const itemWidth = children[0].props.width;
  const numAccross = Math.floor(width / itemWidth);
  if (numAccross === 1) {
    return (
      <View
        style={{
          alignItems: "center",
          ...style,
        }}
      >
        {children}
      </View>
    );
  } else {
    const numCol = Math.ceil(children.length / numAccross);
    const childrenArr = Array(numAccross)
      .fill(Array(numCol).fill(0))
      .map((i, rowInd) =>
        i.map((j, colInd) => (
          <React.Fragment key={rowInd + colInd}>
            {numAccross * colInd + rowInd >= children.length ? (
              <View
                style={{
                  width: itemWidth,
                }}
              />
            ) : (
              children[numAccross * colInd + rowInd]
            )}
          </React.Fragment>
        ))
      );
    //const w = (150 + 20) * (numAccross - (5 % numAccross));
    return (
      <View
        style={{
          alignItems: "center",
        }}
      >
        <View
          style={{
            ...style,
            flexDirection: "row",
          }}
        >
          {childrenArr.map((i, ind) => (
            <View style={{ width: itemWidth }} key={ind}>
              {i}
            </View>
          ))}
        </View>
      </View>
    );
  }
};
