import React from "react";
import { ActivityIndicator, View } from "react-native";
import Logo from "../misc/Logo";
import { useActiveWidth } from "../Hooks/useActiveWidth";

const Loading = () => {
  const width = useActiveWidth();
  return (
    <View
      style={{
        justifyContent: "center",
        backgroundColor: "#fff",
        flex: 1,
        alignItems: "center",
        margin: 20,
      }}
    >
      <View
        style={{
          justifyContent: "center",
          backgroundColor: "#fff",
          flex: 1,
          width,
        }}
      >
        <Logo />
        <ActivityIndicator size="large" color={"blue"} />
      </View>
    </View>
  );
};

export default Loading;
