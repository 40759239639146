// import library to help create a component
import React, { Component } from "react";
import { Animated, Easing, Pressable, View } from "react-native";
import Body from "../Text/Body";
import Hamburger from "../Buttons/Hamburger";
import gS from "../GlobalStyle";
import Chevron from "../Icons/Chevron";
import { useActiveWidth } from "../Hooks/useActiveWidth";

const ActiveWidth = ({ children }) => {
  const width = useActiveWidth();
  return <View style={{ width }}>{children}</View>;
};

class DropDown extends Component {
  state = {
    contentHeight: 0,
    opacity: new Animated.Value(0),
    height: new Animated.Value(0),
    zIndex: -1,
    toggle: false,
  };

  hideContent = () => {
    const { height } = this.state;

    Animated.timing(height, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false, // <-- neccessary
    }).start(() => this.setState({ toggle: false, zIndex: -10 }));
  };
  showContent = () => {
    this.setState({ zIndex: 10 });
    const { height } = this.state;

    Animated.timing(height, {
      toValue: 1,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false, // <-- neccessary
    }).start(() => this.setState({ toggle: true }));
  };

  render() {
    const { contentHeight, height, toggle } = this.state;
    // Label is a string for the cheveron and the label is a component
    const { children, label, arrowLabel, hamburger } = this.props;
    const maxHeight = height.interpolate({
      inputRange: [0, 1],
      outputRange: [50, contentHeight < 50 ? 50 : contentHeight], // <-- value that larger than your content's height
    });
    const opacity = height.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 0, 1], // <-- value that larger than your content's height
    });
    const rotate = height.interpolate({
      inputRange: [0, 1],
      outputRange: ["180deg", "270deg"], // <-- value that larger than your content's height
    });

    return (
      <ActiveWidth>
        <Pressable
          style={{
            flex: 1,
          }}
          onPress={!toggle ? this.showContent : this.hideContent}
        >
          <Animated.View
            style={{
              height: maxHeight,
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor: gS.white,
            }}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: 50,
              }}
            >
              {typeof hamburger === "undefined" ? null : (
                <Hamburger
                  color={gS.darkGrey}
                  onPress={!toggle ? this.showContent : this.hideContent}
                />
              )}
              <View style={{ flexDirection: "row" }}>{label()}</View>
              {typeof hamburger !== "undefined" ? null : (
                <View
                  style={{
                    right: 0,
                    position: "absolute",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  onPress={!toggle ? this.showContent : this.hideContent}
                >
                  <Body>{arrowLabel + "  "}</Body>
                  <Animated.View style={{ transform: [{ rotate: rotate }] }}>
                    <Chevron color={gS.darkGrey} />
                  </Animated.View>
                </View>
              )}
            </View>
            <Animated.View
              onLayout={(e) => {
                if (contentHeight !== e.nativeEvent.layout.height + 50) {
                  this.setState({
                    contentHeight: e.nativeEvent.layout.height + 50,
                  });
                }
              }}
              style={{ opacity: opacity }}
            >
              {children}
              <View style={{ height: 10 }} />
            </Animated.View>
          </Animated.View>
        </Pressable>
      </ActiveWidth>
    );
  }
}

export default DropDown;
